<template>
    <div class="users-body" v-if="loading">
        {{isLoading}}
        <Loader />
    </div>
    <div class="users-body" v-else>
        <div class="su-docs-info">
            <div class="cards-list c-company__list js-masonry" v-if="getDocs.length">
                <Card v-for="doc, idx in getDocs" :doc="doc" :key="idx" :kind="'instruction'"  @getDelDocId="openDelPopup"/>
            </div>

            <div class="su-empty su-about-empty" v-else>
                <EmptyBlock />
            </div>

            <div class="docs-add">
                <router-link :to="{ name: 'DocsNew', params: { docKind: 'instruction' }  }" class="btn">
                    Добавить документ
                </router-link>
            </div>

            <transition>
                <div class="popup" v-if="showDelPopup" @click="closePopup" key="del">
                    <div class="popup__window" @click.stop="">
                        <div class="popup__inner">
                            <div class="popup__title">
                                Вы уверены что хотите удалить документ?
                            </div>
                            <div class="popup__controlls">
                                <Button :class="'btn--red'" @click="deleteDoc">Удалить</Button>
                                <Button :class="'btn--grey'" @click="closePopup">Отмена</Button>
                            </div>
                        </div>
                    </div>

                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import Input from '@/components/UI/Input.vue'
    import Button from '@/components/UI/Button.vue'
    import Loader from '@/components/Loader.vue'
    import EmptyBlock from '@/components/Empty.vue'
    import Card from '@/components/Cards/DocsCard.vue'

    export default {
        components: {
            Input,
            Button,
            Card,
            Loader,
            EmptyBlock
        },
        data() {
            return {
                 isSend: false,
                loading: true,
                delPopupId: null,
                showDelPopup: false,
                queryOption: {searchTxt: '', ordering: '', types: 1}
            }
        },
        created() {
            let queryOption = this.queryOption
            this.$store.dispatch('uploadDocsInfo', queryOption)
        },
        computed: {
            isLoading() {
                this.getDocs
            },
            getDocs() {
                let docsList = this.$store.getters.getDocsInstr
                if (docsList) {
                    this.loading = false
                }
                return docsList
            }
        },
        methods: {
            openDelPopup(data) {
                this.showDelPopup = true
                this.delPopupId = data
            },
            async deleteDoc() {
                let _queryOption = this.queryOption
                _queryOption.delDocId = this.delPopupId
                this.isSend = true
                await this.$store.dispatch('deleteDocsInfo', _queryOption)
                this.isSend = false
                this.showDelPopup = false
                this.delPopupId = ''
            },
            closePopup(event) {
                this.showDelPopup = false
                this.delPopupId = ''
            },
        }
    }
</script>


<style lang="scss" scoped>
    .cards-list {
        grid-template-columns: repeat(2, 1fr)
    }
</style>