<template>
   <div class="su-view su-ratings">
    <SubTitle :title="'Рейтинги и отзывы'"/>
    <Search @search='onSearch'/>
    <Tabs :tabs="tabs"/>
    <router-view></router-view>
  </div>
</template>

<script>

import SubTitle from '@/components/UI/SubTitle.vue'
import Search from '@/components/Controlls/Search.vue'
import Tabs from '@/components/Controlls/Tabs.vue'

export default {
  name: 'RatingsView',
  components: {
    SubTitle,
    Search,
    Tabs,
  },
  data() {
    return {
      tabs: [
        { title: 'На проверке', url: 'RatingsСhecking'},
        { title: 'Одобренные', url: 'RatingsConfirmed'},
        { title: 'Отклоненные', url: 'RatingsCanceled'},
      ],
    }
  },
  methods:{
    onSearch(searchTxt) {
      console.log( this.$store.state );
      let _queryObject = this.$store.state.rating.queryObject
      _queryObject.search = searchTxt
      this.$store.dispatch('uploadRatings', _queryObject)
    }
  }
}
</script>

<style lang="scss" scoped>
   .cards-list {
        grid-template-columns: repeat(2, 1fr)
    }
</style>