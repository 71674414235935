<template>
  <div class="su-view su-payments">
    <div class="users-body" v-if="loading">
      {{isLoading}}
      <Loader />
    </div>
    <div class="users-body" v-else>
      <div class="su-view su-payments">
        <SubTitle :title="'Платежи'" />
        <Search @search='onSearch' />

        <div class="su-payments-info" v-if="getPayments">
          <div class="cards-list c-company__list js-masonry">
            <Card v-for="payment, idx in getPayments" :key="idx" :payment="payment" />
          </div>
        </div>
        <div class="su-empty su-about-empty" v-else>
          <EmptyBlock />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Loader from '@/components/Loader.vue'
  import EmptyBlock from '@/components/Empty.vue'
  import SubTitle from '@/components/UI/SubTitle.vue'
  import Search from '@/components/Controlls/Search.vue'
  import Card from '@/components/Cards/PaymentCard.vue'

  export default {
    name: 'PaymentsView',
    components: {
      Loader,
      EmptyBlock,
      SubTitle,
      Search,
      Card,
    },
    data() {
      return {
        loading: true,

      }
    },
    created() {
      let paymentsInfo = {
        userId: this.$route.params.id ? this.$route.params.id : null,
        status: '',
        search: '',
      }
      this.$store.dispatch('uploadPayments', paymentsInfo)
    },
    computed: {
      isLoading() {
        this.getPayments
      },
      getPayments() {
        let paymentsList = this.$store.getters.getPayments
        if (paymentsList != null) {
          this.loading = false
        }
        return paymentsList
      }
    },
    methods: {
      onSearch(searchTxt) {
        let _paymentsInfo = this.$store.state.payments.paymentsInfo

        _paymentsInfo.offset = null
        _paymentsInfo.search = searchTxt
        this.$store.dispatch('uploadPayments', _paymentsInfo)
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>