<template>
    <div class="su-view su-turnover">
        <div class="users-body" v-if="loading">
            {{isLoading}}
            <Loader />
        </div>
        <div class="users-body" v-else>
            <div class="su-view su-turnover">
                <Search @search='onSearch' />
                <!-- v-if="!getUserTurnover.length" -->
                <div class="su-turnover-content su-turnover__content">
                    <div class="su-turnover__card">
                        <form class="form-turnover" @submit.prevent="onSubmit(0)">
                            <div class="su-turnover__card-header">
                                <div class="su-turnover__card-title">
                                    <p>
                                        Сделки из лотов
                                    </p>
                                </div>
                            </div>
                            <div class="su-turnover__card-body">
                                <div class="form__item" :class="{ 'form__item--error': $v.lotsAmount.$error}">
                                    <input class="form__input" id="lotsAmount" v-model.number.lazy="lotsAmount"
                                        :class="{error: $v.lotsAmount.$error}" type="number"
                                        @change="$v.lotsAmount.$touch()" placeholder="Сумма">
                                    <label for="lotsAmount" class="form__label"><span>Сумма</span></label>
                                    <p class="form__item-error" v-if="!$v.lotsAmount.required">
                                        Обязательное поле
                                    </p>
                                </div>
                                <div class="form__item" :class="{ 'form__item--error': $v.lotsMsg.$error}">
                                    <textarea class="form__input textarea" id="lotsMsg" v-model.lazy="lotsMsg"
                                        :class="{error: $v.lotsMsg.$error}" type="text" @change="$v.lotsMsg.$touch()"
                                        placeholder="Комментарий" />
                                    <label for="lotsMsg" class="form__label"><span>Комментарий</span></label>
                                    <p class="form__item-error" v-if="!$v.lotsMsg.required">
                                        Обязательное поле
                                    </p>
                                </div>
                                <div class="form__controlls" :class="{'form-send': isSend}">
                                    <Button>
                                        Добавить
                                    </Button>
                                </div>
                                <div class="form__controlls form__controlls--history">
                                    <Button :class="{'btn--disabled': !getLotsTurnover.length, 'opened': showLotsHistory}" @click="showLotsHistory = !showLotsHistory" type="button">
                                        История записей 
                                        <img src="@/assets/images/icons/arrow-down--wh.svg" alt="">
                                    </Button>
                                </div>
                                <div class="su-turnover__card-history" v-show="showLotsHistory">
                                    <div class="history-item js-history-item" v-for="item in getLotsTurnover" :key="item.id">
                                        <div class="history-item__header" @click="openItem">
                                            <span>
                                                Сумма
                                            </span>
                                            <p>
                                                {{ item.amount }} ₽
                                            </p>
                                        </div>
                                        <div class="history-item__body">
                                            <span>
                                                Комментарий
                                            </span>
                                            <p>
                                                {{ item.comment }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="su-turnover__card-footer" >
                                <div class="form__item form__item--focused">
                                    <input class="form__input" type="text" placeholder="Итого" :value="totalLotAmount + '  ₽'" disabled>
                                    <label for="lotsMsg" class="form__label"><span>Итого</span></label>
                                </div>
                            </div>
                        </form>
                    </div>

                     <div class="su-turnover__card">
                        <form class="form-turnover" @submit.prevent="onSubmit(1)">
                            <div class="su-turnover__card-header">
                                <div class="su-turnover__card-title">
                                    <p>
                                        Сделки из контрагентов
                                    </p>
                                </div>
                            </div>
                            <div class="su-turnover__card-body">
                                <div class="form__item" :class="{ 'form__item--error': $v.agentAmount.$error}">
                                        <input class="form__input" id="agentAmount"  v-model.number.lazy="agentAmount"
                                        :class="{error: $v.agentAmount.$error}" type="number"
                                        @change="$v.agentAmount.$touch()" placeholder="Сумма">
                                    <label for="agentAmount" class="form__label"><span>Сумма</span></label>
                                    <p class="form__item-error" v-if="!$v.agentAmount.required">
                                        Обязательное поле
                                    </p>
                                </div>
                                <div class="form__item" :class="{ 'form__item--error': $v.agentMsg.$error}">
                                    <textarea class="form__input textarea" id="agentMsg" v-model.lazy="agentMsg"
                                        :class="{error: $v.agentMsg.$error}" type="text" @change="$v.agentMsg.$touch()"
                                        placeholder="Комментарий" />
                                    <label for="agentMsg" class="form__label"><span>Комментарий</span></label>
                                    <p class="form__item-error" v-if="!$v.agentMsg.required">
                                        Обязательное поле
                                    </p>
                                </div>
                                <div class="form__controlls" :class="{'form-send': isSend}">
                                    <Button>
                                        Добавить
                                    </Button>
                                </div>
                                <div class="form__controlls form__controlls--history">
                                    <Button @click="showAgentHistory = !showAgentHistory" :class="{'btn--disabled': !getAgentTurnover.length, 'opened': showAgentHistory}" type="button">
                                        История записей 
                                        <img src="@/assets/images/icons/arrow-down--wh.svg" alt="">
                                    </Button>
                                </div>
                                <div class="su-turnover__card-history" v-show="showAgentHistory">
                                  <div class="history-item js-history-item" v-for="item in getAgentTurnover" :key="item.id">
                                        <div class="history-item__header" @click="openItem">
                                            <span>
                                                Сумма
                                            </span>
                                            <p>
                                                {{ item.amount }} ₽
                                            </p>
                                        </div>
                                        <div class="history-item__body">
                                            <span>
                                                Комментарий
                                            </span>
                                            <p>
                                                {{ item.comment }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="su-turnover__card-footer">
                                <div class="form__item form__item--focused">
                                    <input class="form__input" type="text" placeholder="Итого" :value="totalAgentAmount + ' ₽'" disabled>
                                    <label for="lotsMsg" class="form__label"><span>Итого</span></label>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
                <!-- <div class="su-empty su-about-empty" v-else>
                    <EmptyBlock />
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    import Loader from '@/components/Loader.vue'
    import EmptyBlock from '@/components/Empty.vue'
    import SubTitle from '@/components/UI/SubTitle.vue'
    import Search from '@/components/Controlls/Search.vue'
    import Button from '@/components/UI/Button.vue'
    import {
        required
    } from 'vuelidate/lib/validators'

    export default {
        name: 'PaymentsView',
        components: {
            Loader,
            EmptyBlock,
            SubTitle,
            Search,
            Button,
        },
        data() {
            return {
                loading: true,
                isSend: false,
                showMsg: false,
                showLotsHistory: false,
                showAgentHistory: false,
                lotsAmount: '',
                lotsMsg: '',
                totalLotAmount: 0,
                agentAmount: '',
                agentMsg: '',
                totalAgentAmount: 0,
            }
        },
        validations: {
            lotsAmount: {
                required
            },
            agentAmount: {
                required
            },
            lotsMsg: {
                required
            },
            agentMsg: {
                required
            }
        },
        created() {
            let userId = this.$route.params.id ? this.$route.params.id : null
            this.$store.dispatch('pageTitle', 'Обороты')
            this.$store.dispatch('uploadUserTurnover', userId)
        },
        computed: {
            isLoading() {
                this.getLotsTurnover
                this.getAgentTurnover
            },
            getLotsTurnover() {
                let lotsTurnover = this.$store.getters.getLotsTurnover
                if (lotsTurnover != null) {
                    this.loading = false
                    this.totalLotAmount = 0
                    lotsTurnover.forEach(element => {
                        this.totalLotAmount += element.amount
                    });
                }
                return lotsTurnover
            },
            getAgentTurnover() {
                let agentTurnover = this.$store.getters.getAgentTurnover
                if (agentTurnover != null) {
                    this.loading = false
                    this.totalAgentAmount = 0
                    agentTurnover.forEach(element => {
                        this.totalAgentAmount += element.amount
                    });
                }
                return agentTurnover
            }
        },
        methods: {
            onSearch(searchTxt) {
                // let _paymentsInfo = this.$store.state.paymentsInfo
                // _paymentsInfo.search = searchTxt
                // this.$store.dispatch('uploadPayments', _paymentsInfo)
            },
            onSubmit(type) {
                let turnoverInfo

                if (type == 0) {
                    this.$v.lotsAmount.$touch()
                    this.$v.lotsMsg.$touch()
                    if (!this.$v.lotsAmount.$invalid || !this.$v.lotsMsg.$invalid) {
                        turnoverInfo = {
                            comment: this.lotsMsg,
                            type: type,
                            amount: this.lotsAmount,
                            user: this.$route.params.id 
                        }
                    } else {
                        return false
                    }
                } 
                if (type == 1) {
                    this.$v.agentAmount.$touch()
                    this.$v.agentMsg.$touch()
                    if (!this.$v.agentAmount.$invalid || !this.$v.agentMsg.$invalid) {
                        turnoverInfo = {
                            comment: this.agentMsg,
                            type: type,
                            amount: this.agentAmount,
                            user: this.$route.params.id 
                        }
                    } else {
                        return false
                    }
                }

                this.isSend = true
                this.$store.dispatch('sendTurnover', turnoverInfo)
                this.isSend = false
                this.lotsMsg = ''
                this.lotsAmount = ''
                this.agentMsg = ''
                this.agentAmount = ''
                this.$v.$reset()
            },
            openItem() {
                let elem = event.target.classList.contains('.js-history-item') 
                    ?  event.target
                    : event.target.closest('.js-history-item')
               
                elem.classList.contains('opened') ? elem.classList.remove('opened') : elem.classList.add('opened')
            }
          
        }
    }
</script>

