<template>
    <div class="users-body" v-if="loading">
        {{isLoading}}
        <Loader />
    </div>
    <div class="users-body" v-else>
        <div class="su-docs-info">
            <div class="su-docs__filter-block">
                <div class="su-docs__select-block">
                    <div class="su-docs__select" :class="{'opened': showListType}"
                         tabindex="0" @focus="showListType = true" @blur="showListType = false">
                        <div class="text">
                            <p>Тип: </p>
                            <span v-for="type, idx in typeArray" :key="idx">{{type.name}}</span>
                        </div>
                        <img src="@/assets/images/icons/arrow-down--wh.svg" alt="arrow">
                    </div>
                    <transition name="fade">
                        <div class="select-list" v-show="showListType">
                            <ul>
                                <li v-for="type, idx in getTypes" :key="idx">
                                    <input type="checkbox" class="select__input js-select-input"
                                        :value="{id: type.id, name: type.title}" :id="type.id" v-model="typeArray"
                                        @change='updateTypes'>
                                    <label :for="type.id">
                                        {{type.title}}
                                    </label>
                                </li>
                            </ul>
                            <Button :class="'btn--grey'" @click="resetType"> Сбросить </Button>
                        </div>
                    </transition>
                </div>
                <div class="su-docs__select-block">
                    <div class="su-docs__select" :class="{'opened': showListTag}" 
                    tabindex="0" @focus="showListTag = true" @blur="showListTag = false">
                        <div class="text">
                            <p>Теги: </p>
                            <span v-for="tag, idx in tagArray" :key="idx">{{tag.name}}</span>
                        </div>
                        <img src="@/assets/images/icons/arrow-down--wh.svg" alt="arrow">
                    </div>
                    <transition name="fade">
                        <div class="select-list" v-show="showListTag">
                            <ul>
                                <li v-for="tag, idx in getTags" :key="idx">
                                    <input type="checkbox" class="select__input js-select-input"
                                        :value="{id: tag.id, name: tag.tag}" :id="tag.id" v-model="tagArray"
                                        @change='updateTags'>
                                    <label :for="tag.id">
                                        {{tag.tag}}
                                    </label>
                                </li>
                            </ul>
                            <Button :class="'btn--grey'" @click="resetTags"> Сбросить </Button>
                        </div>
                    </transition>
                </div>
            </div>

            <div class="cards-list c-company__list js-masonry" v-if="getDocs.length">
                <Card v-for="doc, idx in getDocs" :key="idx" :doc="doc" :kind="'signature'"
                    @getDelDocId="openDelPopup" />
            </div>
            <div class="su-empty su-about-empty" v-else>
                <EmptyBlock />
            </div>

             <div class="docs-add">
                <router-link :to="{ name: 'DocsNew', params: { docKind: 'signature' }  }" class="btn">
                    Добавить документ
                </router-link>
            </div>

            <transition>
                <div class="popup" v-if="showDelPopup" @click="closePopup" key="del">
                    <div class="popup__window" @click.stop="">
                        <div class="popup__inner">
                            <div class="popup__title">
                                Вы уверены что хотите удалить документ?
                            </div>
                            <div class="popup__controlls" :class="{'form-send': isSend}">
                                <Button :class="'btn--red'" @click="deleteDoc">Удалить</Button>
                                <Button :class="'btn--grey'" @click="closePopup">Отмена</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import Input from '@/components/UI/Input.vue'
    import Button from '@/components/UI/Button.vue'
    import EmptyBlock from '@/components/Empty.vue'
    import Loader from '@/components/Loader.vue'
    import Card from '@/components/Cards/DocsCard.vue'

    export default {
        components: {
            Input,
            Button,
            Card,
            Loader,
            EmptyBlock,
        },
        data() {
            return {
                isSend: false,
                showListType: false,
                showListTag: false,
                typeArray: [],
                tagArray: [],
                isCheckAll: false,
                loading: true,
                delPopupId: null,
                showDelPopup: false,
            }
        },
        created() {
            let queryOption = {
                searchTxt: '',
                ordering: '',
                types: [],
                tags: []
            }
            this.$store.dispatch('uploadDocsSignature', queryOption)
            this.$store.dispatch('uploadDocsTags')
            this.$store.dispatch('uploadDocsTypes')
        },
        mounted() {
            this.$store.state.docs.queryObject = {
                searchTxt: '',
                types: [],
                tags: []
            }
        },
        computed: {
            isLoading() {
                this.getDocs
            },
            getDocs() {
                let docsList = this.$store.getters.getDocsSignature
                if (docsList) {
                    this.loading = false
                }
                return docsList
            },
            getTags() {
                return this.$store.getters.getDocsTags
            },
            getTypes() {
                return this.$store.getters.getDocsTypes
            }
        },
        methods: {
            async resetType() {
                let queryOption = this.$store.state.docs.queryObject
                this.typeArray = []
                queryOption.types = []
                await this.$store.dispatch('uploadDocsSignature', queryOption)
            },
            async updateTypes() {
                let queryOption = this.$store.state.docs.queryObject
                queryOption.types = []
                this.typeArray.forEach(elem => {
                    queryOption.types.push(elem.id);
                })
                await this.$store.dispatch('uploadDocsSignature', queryOption)
            },
            async resetTags() {
                let queryOption = this.$store.state.docs.queryObject
                this.tagArray = []
                queryOption.tags = []
                await this.$store.dispatch('uploadDocsSignature', queryOption)
            },
            async updateTags() {
                let queryOption = this.$store.state.docs.queryObject
                queryOption.tags = []
                this.tagArray.forEach(elem => {
                    queryOption.tags.push(elem.id);
                })
                await this.$store.dispatch('uploadDocsSignature', queryOption)
            },
            openDelPopup(data) {
                this.showDelPopup = true
                this.delPopupId = data
            },
            async deleteDoc() {
                let _queryOption = this.$store.state.docs.queryObject
                _queryOption.delDocId = this.delPopupId
                this.isSend = true
                await this.$store.dispatch('deleteDocsSignature', _queryOption)
                this.isSend = false
                this.showDelPopup = false
                this.delPopupId = ''
            },
            closePopup(event) {
                this.showDelPopup = false
                this.delPopupId = ''
            },
        }
    }
</script>

<style lang="scss" scoped>
    .cards-list {
        grid-template-columns: repeat(2, 1fr)
    }
</style>