<template>
    <div class="custom__filters js-custom-filters">
        <div class="custom__select" :class="{'opened': showList}" tabindex="0" @click="getSupervisor">
            <div class="text">
                {{filterNames}}
            </div>
            <img src="@/assets/images/icons/arrow-down.svg" alt="arrow">
        </div>
        <transition name="fade">
            <div class="select-list" v-show="showList">
                <ul @scroll="uploadInfoList">
                    <li class="su-goods__select-item js-filter-item" v-for="user, idx in supervisorAll" :key="idx">
                        <input type="checkbox" name="user" class="select__input js-select-input"
                            :id="'supervisor-' + user.id" :value="user" v-model="supervisorCurrent">
                        <label :for="'supervisor-' + user.id"> {{user.name}} </label>
                    </li>
                </ul>
                <Button @click="supervisorFiltration">Показать</Button>
            </div>
        </transition>
    </div>
</template>


<script>
    import Button from '@/components/UI/Button.vue'
    import API from '@/services/API'
    export default {
        components: {
            Button,
        },
        data() {
            return {
                showList: false,
                supervisorAll: [],
                supervisorCurrent: [],
                upload: false,
            }
        },
        computed: {
            filterNames() {
                let nameArray = this.supervisorCurrent.map(elem => elem.name)
                let nameString = nameArray <= 1 ? nameArray[0] : nameArray.join(', ')
                return nameString
            }
        },
        methods: {
            getSupervisor(event) {
                if (event.target.closest('.custom__select').classList.contains('opened')) {
                    this.showList = false
                    return
                }
                this.showList = true
                this.supervisorAll = this.$store.getters.getAllSupervisors
            },
            supervisorFiltration() {
                const windowBody = document.body;
                let queryOption = this.$store.state.security.queryObject
                queryOption.supervisor = this.supervisorCurrent.map(elem => '&supervisor=' + elem.id).join('')
                queryOption.offset = null
                this.showList = false
                API.toCloseSorting(this, windowBody)
                this.$store.dispatch('uploadSecurity', queryOption)
            },
            async uploadInfoList(elem) {
                let list = elem.target
                let item = list.querySelectorAll('.js-filter-item')
                let itemLength = item.length
                if (this.upload == false && list.scrollHeight - 300 < list.scrollTop) {
                    let offset = itemLength
                    this.upload = true
                    await this.$store.dispatch('fetchSupervisors', {offset, search: this.search})
                    this.upload = false
                }
            },
        }
    }
</script>

<style lang="scss">
    .sorting {
        width: 40rem;
    }

    .custom__select {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 31rem;
        min-height: 6.2rem;
        padding: 2rem 2.4rem;
        font-size: 1.6rem;
        background-color: #F7F7FA;
        border-radius: 10rem;
    }

    .custom__select .text {
        text-align: left;
    }

    .custom__select img {
        width: 2.4rem;
        height: 2.4rem;
    }

    .custom__select.opened img {
        transform: scale(1, -1);
    }

    .custom__filters {
        position: relative;
    }

    .custom__select-list {
        top: 100%;
    }
</style>