<template>
    <div class="card-item c-promocode js-masonry-item" :class="{'c-promocode--disabled': !promocode.isValid}">
        <div class=" card-item__content js-masonry-content">
            <div class="add-admin__title add-admin__title--disabled">
                <p>
                    {{promocode.code}}
                </p>
            </div>
             <div class="form__row">
                <div class="form__item">
                    <input disabled class="form__input form__input--focused" :id="'usersCount-' + promocode.id" 
                        :value="promocode.usersCount">
                    <label :for="'dateStart-' + promocode.id" class="form__label"><span>Количество активаций</span></label>
                </div>
            </div>
            <div class="form__row">
                <div class="form__item">
                    <input disabled class="form__input form__input--focused" :id="'dateStart-' + promocode.id" 
                        :value="Math.floor(new Date(+promocode.startDt).getTime()/1000.0) | moment('DD.MM.YYYY')">
                    <label :for="'dateStart-' + promocode.id" class="form__label"><span>Дата начала </span></label>
                </div>
                <div class="form__item">
                    <input disabled class="form__input form__input--focused" :id="'timeStart-' + promocode.id"
                        :value="Math.floor(new Date(+promocode.startDt).getTime()/1000.0) | moment('HH:mm')">
                    <label :for="'timeStart-' + promocode.id" class="form__label"><span>Время начала</span></label>
                </div>
            </div>
            <div class="form__row">
                <div class="form__item">
                    <input disabled class="form__input form__input--focused" :id="'dateEnd-' + promocode.id"
                        :value="Math.floor(new Date(+promocode.endDt).getTime()/1000.0) | moment('DD.MM.YYYY')">
                    <label :for="'dateEnd-' + promocode.id" class="form__label"><span>Дата окончания</span></label>
                </div>
                <div class="form__item">
                    <input disabled class="form__input form__input--focused" :id="'timeEnd-' + promocode.id"
                        :value="Math.floor(new Date(+promocode.endDt).getTime()/1000.0) | moment('HH:mm')">
                    <label :for="'timeEnd-' + promocode.id" class="form__label"><span>Время окончания</span></label>
                </div>
            </div>
            <div class="form__controlls">
                <Button :class="'btn--grey'" @click="getPromocodeId(promocode.id)">
                    Удалить
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
    import API from "@/services/API";
    import Button from '@/components/UI/Button.vue'

    export default {
        components: {
            Button,
        },
        props: {
            promocode: {
                type: Object,
                require: true
            },
        },
        data() {
            return {}
        },
        mounted() {
            API.resizeAllMasonryItems()
        },
        updated() {
            this.$nextTick(function () {
                API.resizeAllMasonryItems()
            })
        },

        methods: {
            getPromocodeId(codeId) {
                this.$emit('getPromocodeId', codeId)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .c-promocode {
        .form__label span,
        .form__input:disabled {
            color: #191B1D;
       }
    }
    .c-promocode--disabled {
        color: #8A8C96;
        .form__label span,
        .form__input:disabled  {
            color: #8A8C96;
        }
    }

    .form__input:disabled {
        background-color: #fff;
        border-color: #fff;
        padding-left: 0;
    }
    .form__label span {
        left: 0;
    }
</style>