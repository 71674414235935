<template>
  <div class="table__tr js-user-elem">
    <div class="table__td">
      <div class="duser-info">
        <router-link class="text-green" :to="({ name: 'User', params: { id: user.id } })">
          {{ user.name }}
        </router-link>
        <p class="text-grey">
          ОГРН {{ user.ogrn }} <br>
          <!-- ИНН {{ user.inn }} <br>
          Дата регистрации <br>
          {{ Math.floor(new Date(+user.createdAt).getTime()/1000.0) | moment("DD.MM.YYYY") }} -->
        </p>
      </div>
    </div>
    <div class="table__td">
      <div class="duser-response" v-if="user.deletionStatus == 3">
        <div class="duser__title">
          <p class="text-red">
            Пользователь удален
          </p>
        </div>
      </div>
      <div class="duser-response" v-else-if="user.deletionStatus == 2">
        <div class="duser__title">
          <p class="text-red">
            Запрос отклонен
          </p>
        </div>
        <div class="duser__reason">
                   <span class="duser__reason-label">
                        Причина отклонения
                    </span>
          <p v-html="user.moderationMessage"></p>
        </div>
      </div>
      <div class="duser-response" v-else>

        <div class="duser__reason">
                    <span class="duser__reason-label">
                        Причина отклонения
                    </span>
          <p contenteditable class="duser__reason-text js-reason-parag" :class="'js-reason-parag-' + user.id"></p>
          <span class="duser-response__error-text">Укажите причину отказа</span>
        </div>
        <div class="duser__controls">
          <Button :class="'btn--red'" @click="onDelete(user.id)">Удалить</Button>
          <Button :class="'btn--grey'" @click="onCanceled(user.id)">Отклонить</Button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import Button from '@/components/UI/Button'


export default {
  components: {
    Button
  },
  props: {
    user: {
      type: Object,
      require: true
    }
  },
  methods: {
    onDelete(id) {
      this.$store.dispatch('deleteUser', id)
    },
    onCanceled(id) {
      const reasonElem = document.querySelector(`.js-reason-parag-${id}`)
      const parentBlock = reasonElem.closest('.duser-response')
      let reasonTxt = reasonElem.innerHTML.trim()

      if (!reasonTxt.length) {
        parentBlock.classList.add('duser-response--error')
        return
      }

      let canceledObject = {id: id, moderationMessage: reasonTxt}
      parentBlock.classList.remove('duser-response--error')
      this.$store.dispatch('canceledDeleteUser', canceledObject)
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
  &__td {
    max-width: 50%;
    padding: 3.2rem 6.5rem;
  }

}
</style>