<template>
  <div class="table__tr">
    <div class="table__td">
      <p class="text text-grey">
        {{ item.uniqueNumber }}
      </p>
    </div>
    <div class="table__td">
      <p class="text" :class="item.revokedAt ? 'text-grey' : 'text-green'">
        {{ item.revokedAt ? 'Отозвана' : 'Активна' }}
      </p>
    </div>
    <div class="table__td">
      <div @click="getPrivateDoc(item.owner.id,item.id)" class="link text-green">
        {{ item.fileInitialName }}
      </div>
    </div>
    <div class="table__td">
      <p class="text text-grey">
        {{ Math.floor(new Date(+item.signedAt).getTime() / 1000.0) | moment("DD.MM.YYYY") }}
        в
        {{ Math.floor(new Date(+item.signedAt).getTime() / 1000.0) | moment("HH:mm") }}
      </p>
    </div>
    <div class="table__td">
      <p class="text text-grey" v-if="item.revokedAt">
        {{ Math.floor(new Date(+item.revokedAt).getTime() / 1000.0) | moment("DD.MM.YYYY") }}
        в
        {{ Math.floor(new Date(+item.revokedAt).getTime() / 1000.0) | moment("HH:mm") }}
      </p>
    </div>
    <div class="table__td">
      <div class="profile__info">
        <div class="profile__avatar">
          <img
              :src="item.owner.supervisor.avatar.base64Preview"
              alt="avatar"
              class="avatar"
              v-if="item.owner.supervisor.avatar"
          >
          <img src="@/assets/images/no_avatar.png" alt="avatar" class="avatar" v-else>
        </div>
        <div class="profile__requisites">
          {{ item.owner.supervisor.name }}
          <p>ИНН: {{ item.owner.supervisor.inn }}</p>
          <p>
            {{
              getFio(item.owner.supervisor)
            }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    item: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      fileUrl: ''
    }
  },
  created() {
  },
  computed: {},
  methods: {
    async getPrivateDoc(userId, docId) {
      await axios.get(
          `${this.$store.state.API_HOST}/api/${this.$store.state.API_VERSION}/admin/employees/${userId}/documents/${docId}`, {
            headers: {
              Authorization: `JWT ${this.$store.state.API_TOKEN}`
            },
          }).then(response => {
        let url = response.data.url
        window.open(url, '_blank');
      })
    },
    getFio(user) {
      let _parts = [];
      _parts.push(user.lastName)
      _parts.push(user.firstName)
      _parts.push(user.middleName)

      return _parts.join(' ')
    },
  }
}
</script>

<style lang="scss" scoped>
.table__td {
  align-items: center;
  justify-content: center;
  padding: 3.2rem;

  .text {
    font-size: 1.4rem;
  }

  .link {
    font-size: 1.6rem;
  }

  &:nth-of-type(1) {
    justify-content: flex-start;
    width: 18rem;
  }

  &:nth-of-type(2) {
    width: 16rem;
  }

  &:nth-of-type(3) {
    width: 28rem;
  }

  &:nth-of-type(4),
  &:nth-of-type(5) {
    width: 26rem;
  }

  &:nth-of-type(6) {
    width: 42rem;
  }
}

.profile__info {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: .8rem;
  font-size: 1.2rem;
  font-weight: 500;

  .avatar {
    width: 3rem;
  }

  p {
    font-size: 1rem;
    color: #8A8C96;
  }
}
</style>