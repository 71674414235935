<template>
  <div class="su-view su-analytics">
    <SubTitle :title="'Аналитика'" />
    <div class="su-analytics__content">
      <div class="su-analytics__leftside">
        <form class="form-analytic" @submit.prevent="getAnalytics">
          <div class="su-analytics__block">
            <div class="su-analytics__title">
              <p>
                Выбор пользователя
              </p>
            </div>
            <div class="su-goods__select-block">
              <div class="su-goods__select">
                <div class="su-goods__select-input" :class="{'opened': showListUser}" tabindex="0" contenteditable
                  @focus="showListUser = true" @blur="showListUser = false" v-html="userFilterName">
                </div>
              </div>

              <transition name="fade">
                <div class="su-goods__select-list" v-show="showListUser">
                  <ul @scroll="uploadInfoList">
                    <li class="su-goods__select-item js-filter-item" @click="userChoiced($event, user.id)"
                      v-for="user, idx in getAllUsers" :key="idx">
                      <div class="su-goods__select-avatar">
                        <img :src="user.profile.avatar.file" alt="avatar" class="avatar" v-if="user.profile.avatar">
                        <img src="@/assets/images/no_avatar.png" alt="avatar" class="avatar" v-else>
                      </div>
                      <div class="su-goods__select-name">
                        {{ user.profile.name }}
                      </div>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
          </div>
          <div class="su-analytics__block">
            <div class="su-analytics__title">
              <p>
                Выбор товара
              </p>
            </div>
            <div class="form__item" :class="{ 'form__item--error': $v.catName.$error}">
              <input class="form__input form__input--arrow" id="cat-name" v-model.lazy="catName"
                :class="{error: $v.catName.$error}" type="text" @change="$v.catName.$touch()" placeholder="Категория"
                readonly @focus="showListCat = true" @blur="showListCat = false">
              <label for="cat-name" class="form__label">
                <span>Категория</span>
                <img src="@/assets/images/icons/arrow-down.svg" alt="down">
              </label>
              <p class="form__item-error" v-if="!$v.catName.required">
                Обязательное поле
              </p>
              <transition name="fade">
                <div class="select-list" v-show="showListCat">
                  <ul>
                    <li v-for="cat, idx in getCats" :key="idx">
                      <input type="radio" name="cat" :value="cat.title" class="select__input js-select-input"
                        :id="cat.id" @change="catChoiced(cat)">
                      <label :for="cat.id">
                        <span>
                          {{cat.title}}
                        </span>
                      </label>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
            <div class="form__item" v-show="subcatList.length" :class="{ 'form__item--error': $v.subcatName.$error}">
              <input class="form__input  form__input--arrow" id="subcat-name" v-model.lazy="subcatName"
                :class="{error: $v.subcatName.$error}" type="text" @change="$v.subcatName.$touch()"
                placeholder="Подкатегория" readonly :disabled="!subcatList" @focus="showListSubcat = true"
                @blur="showListSubcat = false">
              <label for="subcat-name" class="form__label">
                <span>Подкатегория</span>
                <img src="@/assets/images/icons/arrow-down.svg" alt="down">
              </label>
              <p class="form__item-error" v-if="!$v.subcatName.required">
                Обязательное поле
              </p>
              <transition name="fade">
                <div class="select-list" v-show="showListSubcat">
                  <ul>
                    <li v-for="subcat, idx in subcatList" :key="idx">
                      <input type="radio" name="subcat" :value="subcat.title" class="select__input js-select-input"
                        :id="subcat.id" @change="subcatChoiced(subcat)">
                      <label :for="subcat.id">
                        <span>
                          {{subcat.title}}
                        </span>
                      </label>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
            <div class="form__item" v-show="productList.length" :class="{ 'form__item--error': $v.productName.$error}">
              <input class="form__input form__input--arrow" id="product-name" v-model.lazy="productName"
                :class="{error: $v.productName.$error}" type="text" @change="$v.productName.$touch()"
                placeholder="Товары" readonly :disabled="!productList" @focus="showListProduct = true"
                @blur="showListProduct = false">
              <label for="product-name" class="form__label">
                <span>Товары</span>
                <img src="@/assets/images/icons/arrow-down.svg" alt="down">
              </label>
              <p class="form__item-error" v-if="!$v.productName.required">
                Обязательное поле
              </p>
              <transition name="fade">
                <div class="select-list" v-show="showListProduct">
                  <ul>
                    <li v-for="product, idx in productList" :key="idx">
                      <input type="radio" name="product" :value="product.title" class="select__input js-select-input"
                        :id="product.id" @change="productChoiced(product)">
                      <label :for="product.id">
                        <span>
                          {{product.title}}
                        </span>
                      </label>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
            <div class="su-analytics__title">
              <p>
                По закупке / по продаже
              </p>
            </div>
            <div class="form__item" :class="{ 'form__item--error': $v.typeName.$error}">
              <input class="form__input form__input--arrow" id="type-name" v-model.lazy="typeName"
                :class="{error: $v.typeName.$error}" type="text" @change="$v.typeName.$touch()"
                placeholder="По закупке / по продаже" readonly @focus="showListType = true"
                @blur="showListType = false">
              <label for="type-name" class="form__label">
                <span> По закупке / по продаже</span>
                <img src="@/assets/images/icons/arrow-down.svg" alt="down">
              </label>
              <p class="form__item-error" v-if="!$v.typeName.required">
                Обязательное поле
              </p>
              <transition name="fade">
                <div class="select-list" v-show="showListType">
                  <ul>
                    <li>
                      <input type="radio" name="type" value="buy" class="select__input js-select-input" id="type-buy"
                        @change="typeChoiced(1)">
                      <label for="type-buy">
                        <span>
                          По закупке
                        </span>
                      </label>
                    </li>
                    <li>
                      <input type="radio" name="type" value="sell" class="select__input js-select-input" id="type-sell"
                        @change="typeChoiced(0)">
                      <label for="type-sell">
                        <span>
                          По продаже
                        </span>
                      </label>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
            <div class="su-analytics__title">
              <p>
                Локация
              </p>
            </div>
            <div class="form__item">
              <input class="form__input form__input--arrow" id="region-name" v-model.lazy="regionName" type="text"
                placeholder="Регион" readonly @focus="showListRegion = true" @blur="showListRegion = false">
              <label for="region-name" class="form__label">
                <span>Регион</span>
                <img src="@/assets/images/icons/arrow-down.svg" alt="down">
              </label>
              <transition name="fade">
                <div class="select-list" v-show="showListRegion">
                  <ul>
                    <li v-for="region, idx in getRegions" :key="idx">
                      <input type="radio" name="region" :value="region.title" class="select__input js-select-input"
                        :id="region.id" @change="regionChoiced(region)">
                      <label :for="region.id">
                        <span>
                          {{region.title}}
                        </span>
                      </label>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
            <div class="form__item">
              <input class="form__input form__input--arrow" id="city-name" v-model.lazy="cityName" type="text"
                placeholder="Населенный пункт" readonly :disabled="!cityList" @focus="showListCity = true"
                @blur="showListCity = false">
              <label for="city-name" class="form__label">
                <span>Населенный пункт</span>
                <img src="@/assets/images/icons/arrow-down.svg" alt="down">
              </label>
              <transition name="fade">
                <div class="select-list" v-show="showListCity">
                  <ul>
                    <li v-for="city, idx in cityList" :key="idx">
                      <input type="radio" name="city" :value="city.title" class="select__input js-select-input"
                        :id="city.id" @change="cityChoiced(city)">
                      <label :for="city.id">
                        <span>
                          {{city.title}}
                        </span>
                      </label>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
            <div class="form__item">
              <input class="form__input form__input--arrow" id="town-name" v-model.lazy="townName" type="text"
                placeholder="Округ" readonly :disabled="!townList" @focus="showListTown = true"
                @blur="showListTown = false">
              <label for="city-name" class="form__label">
                <span>Округ</span>
                <img src="@/assets/images/icons/arrow-down.svg" alt="down">
              </label>
              <transition name="fade">
                <div class="select-list" v-show="showListTown">
                  <ul>
                    <li v-for="town, idx in townList" :key="idx">
                      <input type="radio" name="town" :value="town.title" class="select__input js-select-input"
                        :id="town.id" @change="townChoiced(town)">
                      <label :for="town.id">
                        <span>
                          {{town.title}}
                        </span>
                      </label>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
            <div class="form__item">
              <input class="form__input form__input--arrow" id="town-name" v-model.lazy="districtName" type="text"
                placeholder="Район" readonly :disabled="!districtList" @focus="showListDistrict = true"
                @blur="showListDistrict = false">
              <label for="city-name" class="form__label">
                <span>Район</span>
                <img src="@/assets/images/icons/arrow-down.svg" alt="down">
              </label>
              <transition name="fade">
                <div class="select-list" v-show="showListDistrict">
                  <ul>
                    <li v-for="district, idx in districtList" :key="idx">
                      <input type="radio" name="district" :value="district.title" class="select__input js-select-input"
                        :id="district.id" @input="addDistrict(district)">
                      <label :for="district.id">
                        <span>
                          {{district.title}}
                        </span>
                      </label>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
            <div class="form__controlls" :class="{'form-send': isSend}">
              <Button>
                Применить
              </Button>
            </div>
          </div>
        </form>
      </div>

      <div class="su-analytics__rightside">
        <div class="su-analytics__block su-analytics__block--large">
          <div class="su-analytics__title">
            <p>
              Средняя цена на товар
            </p>
            <span v-if="analitics">
              {{analitics.avgPrice}} ₽
            </span>
            <span v-else>
              0 ₽
            </span>
          </div>
          <div class="su-analytics__title su-analytics__title--graph">
            <p>
              График цен
            </p>
          </div>
              <i class="canvas-property"></i>
          <div class="su-analytics__graph" v-show="analitics">
            <div class="su-analytics__graph-body">
              <div class="su-analytics__graph-canvas">
                <canvas id="canvas"></canvas>
              </div>
              <div class="su-analytics__graph-volume">

              </div>
              <div class="su-analytics__graph-timeline">

              </div>
            </div>
            <div class="su-analytics__graph-footer">
              <span class="active js-period-span" @click="getAnalytics(0)">
                День
              </span>
              <span class="js-period-span" @click="getAnalytics(1)">
                Неделя
              </span>
              <span class="js-period-span" @click="getAnalytics(2)">
                Месяц
              </span>
              <span class="js-period-span" @click="getAnalytics(3)">
                Полгода
              </span>
              <span class="js-period-span" @click="getAnalytics(4)">
                Год
              </span>
            </div>
          </div>

          <EmptyBlock v-show="!analitics" />

        </div>
        <div class="su-analytics__block su-analytics__block--large" v-show="false">
          <div class="su-analytics__title">
            <p>
              Объем рынка
            </p>
          </div>
          <template v-if="analitics">
            <div class="su-analytics__title">
              <p>
                Количество размещенных лотов
              </p>
            </div>
            <div class="form__item form__item--focused">
              <input disabled class="form__input" placeholder="Количество активных лотов по товару"
                :value="analitics.activeCount">
              <label class="form__label"><span> Количество активных лотов по товару</span></label>
            </div>
            <div class="form__item form__item--focused">
              <input disabled class="form__input" placeholder="Количество закрытых лотов по товару"
                :value="analitics.closedCount">
              <label class="form__label"><span> Количество закрытых лотов по товару</span></label>
            </div>
            <div class="su-analytics__title">
              <p>
                Объем товара в системе
              </p>
            </div>
            <div class="form__item form__item--focused">
              <input disabled class="form__input" placeholder="Объем товара в активных лотах"
                :value="analitics.activeQuantity">
              <label class="form__label"><span> Объем товара в активных лотах</span></label>
            </div>
            <div class="form__item form__item--focused">
              <input disabled class="form__input" placeholder="Объем товара в закрытых лотах"
                :value="analitics.closedQuantity">
              <label class="form__label"><span> Объем товара в закрытых лотах</span></label>
            </div>
            <div class="su-analytics__title">
              <p>
                Общая сумма предложения по товару
              </p>
            </div>
            <div class="form__item form__item--focused">
              <input disabled class="form__input" placeholder="Общая сумма товара в активных лотах"
                :value="analitics.activePriceSum + ' ₽'">
              <label class="form__label"><span> Общая сумма товара в активных лотах</span></label>
            </div>
            <div class="form__item form__item--focused">
              <input disabled class="form__input" placeholder="Общая сумма товара в закрытых лотах"
                :value="analitics.closedPriceSum + ' ₽'">
              <label class="form__label"><span> Общая сумма товара в закрытых лотах</span></label>
            </div>
          </template>
          <template v-else>
            <EmptyBlock />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    required
  } from 'vuelidate/lib/validators'
  import EmptyBlock from '@/components/Empty.vue'
  import SubTitle from '@/components/UI/SubTitle.vue'
  import Button from '@/components/UI/Button.vue'

  export default {
    name: 'AnalyticsView',
    components: {
      EmptyBlock,
      SubTitle,
      Button,
    },
    data() {
      return {
        loading: true,
        isSend: false,
        showListUser: false,
        showListCat: false,
        showListSubcat: false,
        showListProduct: false,
        showListType: false,
        showListRegion: false,
        showListCity: false,
        showListTown: false,
        showListDistrict: false,
        userFilterName: '<p>Не выбрано</p>',
        userID: null,
        catName: '',
        catInfo: {},
        subcatList: [],
        subcatName: '',
        productList: [],
        productName: '',
        productID: null,
        typeID: null,
        typeName: '',
        regionName: '',
        regionID: null,
        cityList: null,
        cityName: '',
        cityID: null,
        townList: null,
        townName: '',
        townID: null,
        districtList: null,
        districtName: '',
        districtID: null,
        avgRange: 0,
        coord: null,
        analitics: null,
        upload: false
      }
    },
    validations: {
      catName: {
        required
      },
      subcatName: {
        required
      },
      productName: {
        required
      },
      typeName: {
        required
      },
    },
    created() {
      this.$store.dispatch('getAllUsersLight')
      this.$store.dispatch('uploadCats')
      this.$store.dispatch('uploadRegions')
    },
    computed: {
      // isLoading() {
      //   this.allUserLoaded
      //   this.usersCSV
      // },
      getAllUsers() {
        let userList = this.$store.getters.getAllUsersLight
        if (userList) {
          this.loading = false
        }
        return userList
      },
      getCats() {
        return this.$store.getters.getCats
      },
      getRegions() {
        return this.$store.getters.getRegions
      }
    },
    methods: {
      userChoiced(event, userID) {
        let elem = event.target.classList.contains('js-filter-item') ? event.target : event.target.closest(
          '.js-filter-item')
        let elemHTML = elem.innerHTML
        this.userFilterName = elemHTML
        this.userID = userID
      },
      async catChoiced(event) {
        this.subcatList = []
        this.subcatName = ''
        this.productList = [],
          this.productName = ''
        this.productID = null,
          this.catInfo = {}

        this.catName = event.title
        this.catInfo.catId = event.id
        this.productID = event.id
        await this.$store.dispatch('uploadSingleCat', this.catInfo)
        this.subcatList = this.$store.getters.getSingleCat.subCategories
      },
      async subcatChoiced(event) {
        this.productList = [],
          this.productName = ''
        this.productID = null,
          this.catInfo = {}

        this.subcatName = event.title
        this.catInfo.catId = event.id
        this.productID = event.id
        await this.$store.dispatch('uploadSingleCat', this.catInfo)
        this.productList = this.$store.getters.getSingleCat.subCategories
      },
      productChoiced(event) {
        this.productName = event.title
        this.productID = event.id
      },
      typeChoiced(event) {
        this.typeID = event
        this.typeName = event ? 'По закупке' : 'По продаже'
      },
      async regionChoiced(event) {
        this.cityName = ''
        this.cityID = null
        this.cityList = null

        this.townName = ''
        this.townID = null
        this.townList = null

        this.districtName = ''
        this.districtID = null
        this.districtList = null

        this.regionName = event.title
        this.regionID = event.id
        await this.$store.dispatch('uploadCities', event.id)
        this.cityList = this.$store.getters.getCities
      },
      async cityChoiced(event) {
        this.townName = ''
        this.townID = null
        this.townList = null

        this.districtName = ''
        this.districtID = null
        this.districtList = null

        this.cityName = event.title
        this.cityID = event.id
        await this.$store.dispatch('uploadTowns', event.id)
        this.townList = this.$store.getters.getTowns
      },
      async townChoiced(event) {
        this.districtName = ''
        this.districtID = null
        this.districtList = null

        this.townName = event.title
        this.townID = event.id
        await this.$store.dispatch('uploadDistricts', event.id)
        this.districtList = this.$store.getters.getDistricts
      },
      addDistrict(district) {
        this.districtID = district.id
        this.districtName = district.title

      },

      drawLine() {
        let _cEl = document.querySelector('.canvas-property')
        let _cElProp = _cEl.getBoundingClientRect()
        let canvas = document.getElementById('canvas');
        let ctx = canvas.getContext('2d');
        let coord = Object.values(this.analitics.avgPricesByInterval)
        let stepX = _cElProp.width / coord.length
        let stepY = _cElProp.height

        let maxYRound = Math.max(...coord) > 0 ? Math.round(Math.max(...coord) / 1000) : 1
        let maxY = Math.max(...coord) > 0 ? Math.max(...coord) : 1

        canvas.width = _cElProp.width
        canvas.height = _cElProp.height

        ctx.lineWidth = 1
        ctx.strokeStyle = '#008069'

        ctx.beginPath();
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.moveTo(0, stepY);


        coord.forEach((elem, idx) => {
          let x = stepX * (idx + 1);
          let _yPer = Math.round(((elem / maxY) * (stepY - maxYRound)))
          let y = _yPer > 0 ? stepY - _yPer : stepY

          ctx.lineTo(x, y)
          ctx.stroke();
        })
      },

      drawDashPeriods(divider, term) {
        let volumesBlock = document.querySelector('.su-analytics__graph-volume')
        let preiodsBlock = document.querySelector('.su-analytics__graph-timeline')
        let coord = Object.values(this.analitics.avgPricesByInterval)

        volumesBlock.innerHTML = ''
        preiodsBlock.innerHTML = ''

        coord.forEach((elem, idx) => {
          if (idx == 0) {
            preiodsBlock.innerHTML += `
              <div style="position: relative;display: inline-flex;flex-direction: column;align-items: flex-end;justify-content: flex-end;height: 100%">
                <span style="display: block;width: 1px;height:100%;"></span>
                <span style="position: absolute; bottom: -1.6rem; left: 0;">${idx + 1 }<span>
              </div>`
          } else if (idx == coord.length - 1) {
            preiodsBlock.innerHTML += `<div style="position: relative;display: inline-flex;flex-direction: column;align-items: flex-end;justify-content: flex-end;height: 100%">
                <span style="display: block;width: 1px;height:100%;"></span>
                <span style="position: absolute; bottom: -1.6rem; right: 0;">${idx + 1 }<span>
              </div>`
          } else if (idx % divider == 0) {
            preiodsBlock.innerHTML += `
            <div style="position: relative;display: inline-flex;flex-direction: column;align-items: flex-end;justify-content: flex-end;height: 100%">
              <span style="display: block;width: 1px;height:100%;border-left: 1px dashed #ccc;"></span>
              <span style="position: absolute; bottom: -1.6rem; left: 50%; transform: translateX(-50%)">${idx + term}<span>
            </div>`
          }
        })

        let floor
        let max

        if (Math.max(...coord) >= 1000) {
          max = Math.round(Math.max(...coord) / 1000)
          floor = false
        } else {
          max = Math.round(Math.max(...coord))
          floor = true
        }
       
        if( max != 0) {
          volumesBlock.innerHTML += `
              <div style="position: relative;display: inline-flex;align-items: center;justify-content: flex-start;width: 100%">
              </div>
              <div style="position: relative;display: inline-flex;align-items: center;justify-content: flex-start;width: 100%">
                <span style="display:block;height: 1px;width:100%;border-top: 1px dashed #ccc;"></span>
                <span style="position: absolute; top: 50%; left: -4.4rem; transform: translateY(-50%)">${floor ? '0.' : ''}${Math.round( (max / 3) * 2)}${floor ? 'k' : '.0k'}<span>
              </div>
              <div style="position: relative;display: inline-flex;align-items: center;justify-content: flex-start;width: 100%">
                <span style="display:block;height: 1px;width:100%;border-top: 1px dashed #ccc;"></span>
                <span style="position: absolute; top: 50%; left: -4.4rem; transform: translateY(-50%)">${floor ? '0.' : ''}${Math.round(max / 3 )}${floor ? 'k' : '.0k'}<span>
              </div>
              <div style="position: relative;display: inline-flex;align-items: center;justify-content: flex-start;width: 100%">
                <span style="display:block;height: 1px;width:100%;border-top: 1px dashed #ccc;"></span>
                <span style="position: absolute; top: 50%; left: -4.4rem; transform: translateY(-50%)">${0}<span>
              </div>
          `
        }
      
      },

      async getAnalytics(_avgRange) {
        let divider = 4
        let term = 0

        if (typeof _avgRange == 'number') {
          this.avgRange = _avgRange
          let allSpan = document.querySelectorAll('.js-period-span')
          allSpan.forEach((elem, idx) => {
            if (_avgRange != idx && elem.classList.contains('active')) {
              elem.classList.remove('active')
            }
            if (_avgRange == idx) {
              if (!elem.classList.contains('active')) {
                elem.classList.add('active')
              }
            }
          })

          switch(_avgRange) {
            case 4: 
              divider = 1
              term = 1
              break
            case 3: 
              divider = 1
              term = 1
              break
            case 2: 
              divider = 3
              term = 0
              break
            case 1: 
              divider = 1
              term = 1
              break
            default:
              divider = 4
              term = 0
          }
        }

        this.$v.$touch()
        let customVuelidate

        if (this.productList.length) {
          customVuelidate = !this.$v.$invalid
        } else if (this.subcatList.length) {
          customVuelidate = !this.$v.catName.$invalid && !this.$v.typeName.$invalid && !this.$v.subcatName.$invalid
        } else {
          customVuelidate = !this.$v.catName.$invalid && !this.$v.typeName.$invalid
        }

        if (customVuelidate) {
          const analyticsInfo = {
            city: this.cityID,
            product: this.productID,
            type: this.typeID,
            avg_prices_range: this.avgRange,
            user: this.userID
          }
          this.isSend = true
          await this.$store.dispatch('uploadAnalytics', analyticsInfo)
          this.isSend = false

          this.analitics = this.$store.getters.getAnalytics

          this.drawDashPeriods(divider, term)
          this.drawLine()
          // this.$v.$reset()
        }
      },

      async uploadInfoList(elem) {
        let list = elem.target
        let item = list.querySelectorAll('.js-filter-item')
        let itemLength = item.length
        if (this.upload == false && list.scrollHeight - 300 < list.scrollTop) {
          let offset = itemLength
          this.upload = true
          await this.$store.dispatch('getAllUsersLight', {offset})
          this.upload = false
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .su-goods__select-block {
    max-width: 100%;
  }

  .su-analytics {
    &__content {
      display: flex;
      gap: 2.4rem;

      &>* {
        flex: 1 0 calc(05% - 1.2rem);
      }
    }

    &__block {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 2.6rem;
      border-radius: 3rem;
      background-color: #ffffff;

      &+.su-analytics__block {
        margin-top: 2.6rem;
      }

      .canvas-property {
        position: absolute;
        left: -1000%;
        height: calc(23.5rem - 8.6rem);
        width: calc(100% - 9.6rem);
        opacity: 0;
        pointer-events: none;
        background-color: red;

      }
    }

    &__block--large {
      min-height: 50rem;
    }

    &__title {
      margin-bottom: 1.6rem;
      font-size: 1.6rem;
      font-weight: 500;
      color: #B8BABE;

      span {
        display: inline-block;
        margin-top: 1.6rem;
        font-size: 1.7rem;
        font-weight: 600;
        color: #191B1D;
      }
    }

    &__title--graph {
      margin-top: 4.5rem;
    }

    &__graph-body {
      position: relative;
      padding-left: 4.4rem;
    }

    &__graph-canvas {
      height: 23.5rem;
      border: .1rem solid #F7F7FA;

      canvas {
        width: 100%;
        height: 100%;
      }
    }

    &__graph-volume {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 4.4rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      font-size: 1.4rem;
      font-weight: 500;
      color: #B8BABE;
    }

    &__graph-timeline {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 4.4rem;
      display: flex;
      justify-content: space-between;
      font-size: 1.4rem;
      font-weight: 500;
      color: #B8BABE;
    }

    &__graph-footer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      margin-top: 3.2rem;

      span {
        padding: .9rem 1.2rem;
        font-size: 1.4rem;
        font-weight: 500;
        background-color: #F7F7FA;
        border-radius: 10rem;
        cursor: pointer;

        &.active {
          background-color: #008069;
          color: #fff;
        }
      }
    }
  }

  .form__item+.su-analytics__title {
    margin-top: 2.6rem;
  }
</style>