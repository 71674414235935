<template>
  <div class="su-view su-lots">
    <SubTitle :title="'Лоты пользователей'"/>
    <div class="su-lots__search-block">
    <Search @search='onSearch'/>
    <div class="su-lots__switch-block" @click="toggleSwitch">
      <div class="su-lots__switch-item" data-show-cat="all" :class="{'active': true}">
        Все
      </div>
      <div class="su-lots__switch-item" data-show-cat="auction">
        Только аукционы
      </div>
    </div>
    </div>
    <Tabs :tabs="tabs"/>
    <router-view :showOnlyAuct="showOnlyAuct"></router-view>
  </div>
</template>

<script>
import Search from '@/components/Controlls/Search.vue'
import SubTitle from '@/components/UI/SubTitle.vue'
import Tabs from '@/components/Controlls/Tabs.vue'

export default {
  name: 'LotsView',
  components: {
    Search,
    SubTitle,
    Tabs,
  },
  data() {
    return {
      showOnlyAuct: false,
      tabs: [
        { title: 'Лоты закупок', url: 'LotsBuy' },
        { title: 'Лоты продаж', url: 'LotsSell' },
      ],
    }
  },
  methods:{
    onSearch(searchTxt) {
      let _lotsInfo = this.$store.state.lots.lotsInfo
      _lotsInfo.search = searchTxt
      this.$store.dispatch('uploadLots', _lotsInfo)
    },
    toggleSwitch(event) {
      let elem = event.target
      let showCat = elem.dataset.showCat
      let _lotsInfo = this.$store.state.lots.lotsInfo

      if(elem.classList.contains('active')) return
      if(showCat == 'auction') {
        elem.previousElementSibling.classList.remove('active')
        elem.classList.add('active')
        this.showOnlyAuct = true
      } else {
        elem.nextElementSibling.classList.remove('active')
        elem.classList.add('active')
        this.showOnlyAuct = false
      }

      _lotsInfo.showOnlyAuct = this.showOnlyAuct ? 'is_auction=true' : ''
      _lotsInfo.offset = 0
      this.$store.dispatch('uploadLots', _lotsInfo)
      this.$store.dispatch('uploadLotsCount', _lotsInfo)

    },
  }
}
</script>

<style lang="scss" scoped>

</style>
