<template>
  <div class="lot-single">
    {{isLoading}}
    <div class="users-body" v-if="loading">
      <Loader />
    </div>
    <template v-else>
      <div class="ls-header">
        <div class="ls-header__leftside">
          <span class="ls-header__type">
            {{ getLotSingle.type == 0 ? 'Лот продажи' : 'Лот закупки' }}
          </span>
          <span class="ls-header__auction" v-if="getLotSingle.isAuction">
            Аукцион
          </span>
          <template v-if="getLotSingle.delivery">
            <span class="ls-header__delivery red" v-if="getLotSingle.delivery.updateIntended">
              {{ getLotSingle.delivery.currentValue ? 'Возможна доставка' : 'Без учета доставки' }}
            </span>
          </template>
          <template v-if="getLotSingle.delivery != null">
            <span class="ls-header__delivery" v-if="getLotSingle.delivery.valueAfterUpdate != null">
              {{ getLotSingle.delivery.updateIntended.valueAfterUpdate ? 'Возможна доставка' : 'Без учета доставки'  }}
            </span>
            <span class="ls-header__delivery" v-else>
              {{ getLotSingle.delivery ? 'Возможна доставка' : 'Без учета доставки'  }}
            </span>
          </template>
        </div>
        <SubTitle :title="'Лот №' + getLotSingle.uniqueNumber" :backBtn="true" />
        <div class="ls-header__rightside">

          <span class="ls-header__status" :class="getStatus.class" v-if="getLotSingle.status != null">
            {{ getStatus.text }}
            <span v-if="getLotSingle.status == 1 && (getLotSingle.updateStatus == 0 || getLotSingle.updateStatus == 2)">
              {{ getActiveDate }}
            </span>
          </span>
        </div>
      </div>

      <div class="ls-body">
        <div class="ls-leftside">
          <div class="ls-body__block-wrap">

            <div class="ls-body__block">
              <div class="ls-body__block-title">
                <p>
                  Категория товара
                </p>
              </div>
              <Row :isUpdate="getLotSingle.category.updateIntended" v-if="getLotSingle.category">
                <template v-slot:label>
                  <p class="row__label">
                    Категория
                  </p>
                </template>
                <template v-slot:current-text>
                  <p class="row__text">
                    {{ getLotSingle.category.valueAfterUpdate ? getLotSingle.category.valueAfterUpdate.title : getLotSingle.category.title }}
                  </p>
                </template>
                <template v-slot:old-text v-if="getLotSingle.category.updateIntended">
                  <p class="row__text">
                    {{ getLotSingle.category.currentValue.title }}
                  </p>
                </template>
              </Row>
              <Row :isUpdate="getLotSingle.category.updateIntended" v-if="getLotSingle.subCategory">
                <template v-slot:label>
                  <p class="row__label">
                    Подкатегория
                  </p>
                </template>
                <template v-slot:current-text>
                  <p class="row__text">
                    {{ getLotSingle.subCategory.valueAfterUpdate ? getLotSingle.subCategory.valueAfterUpdate.title : getLotSingle.subCategory.title }}
                  </p>
                </template>
                <template v-slot:old-text v-if="getLotSingle.subCategory.updateIntended">
                  <p class="row__text">
                    {{ getLotSingle.subCategory.currentValue.title }}
                  </p>
                </template>
              </Row>
              <Row :isUpdate="getLotSingle.product.updateIntended" v-if="getLotSingle.product">
                <template v-slot:label>
                  <p class="row__label">
                    Наименование товара
                  </p>
                </template>
                <template v-slot:current-text>
                  <p class="row__text">
                    {{ getLotSingle.product.valueAfterUpdate ? getLotSingle.product.valueAfterUpdate.title : getLotSingle.product.title }}
                  </p>
                </template>
                <template v-slot:old-text v-if="getLotSingle.product.updateIntended">
                  <p class="row__text">
                    {{ getLotSingle.product.currentValue.title }}
                  </p>
                </template>
              </Row>
            </div>

            <div class="ls-body__block">
              <div class="ls-body__block-title">
                <p>
                  Информация о лоте
                </p>
              </div>
              <div class="ls-body__media" v-if="getLotSingle.avatar">
                <span class="ls-body__media-label">
                  Главное фото
                </span>
                <div class="ls-body__media-image">
                  <img :src="getAvatar" alt="avatar" :class="{ update: getLotSingle.avatar.needToCheck }">
                </div>
              </div>
              <Row :isUpdate="getLotSingle.price.updateIntended" v-if="getLotSingle.price">
                <template v-slot:label>
                  <p class="row__label">
                    Цена за единицу меры объема
                  </p>
                </template>
                <template v-slot:current-text>
                  <p class="row__text">
                    {{ getLotSingle.price.valueAfterUpdate ? getPrice.new : getPrice }} &#8381;
                  </p>
                </template>
                <template v-slot:old-text v-if="getLotSingle.price.updateIntended">
                  <p class="row__text">
                    {{ getPrice.old }} &#8381;
                  </p>
                </template>
                <template v-slot:current-attent v-if="getLotSingle.nds">
                  <p class="row__attent">
                    {{ getLotSingle.nds.updateIntended ? getNds.new.text : getNds.text }}
                  </p>
                </template>
                <template v-slot:old-attent v-if="getLotSingle.nds.updateIntended">
                  <p class="row__attent">
                    {{ getNds.old.text }}
                  </p>
                </template>
                <template v-slot:descr>
                  <div class="row__descr">
                    <p>
                      {{ getLotSingle.type == 0 ? 'Цена указана с учетом самовывоза' : 'Цена указана с учётом поставки по адресу' }}
                    </p>
                  </div>
                </template>
              </Row>

              <Row :class="'textarea'" :isUpdate="getLotSingle.description.updateIntended"
                v-if="getLotSingle.description">
                <template v-slot:label>
                  <p class="row__label">
                    Описание
                  </p>
                </template>
                <template v-slot:current-text>
                  <p class="row__text">
                    {{ getLotSingle.description.updateIntended === undefined ? getLotSingle.description : getLotSingle.description.valueAfterUpdate }}
                  </p>
                </template>
                <template v-slot:old-text v-if="getLotSingle.description.updateIntended">
                  <p class="row__text">
                    {{ getLotSingle.description.currentValue }}
                  </p>
                </template>
              </Row>
              <Row :isUpdate="getLotSingle.quantity.updateIntended" v-if="getLotSingle.quantity">
                <template v-slot:label>
                  <p class="row__label">
                    Количество товара
                  </p>
                </template>
                <template v-slot:current-text>
                  <p class="row__text">
                    {{ getLotSingle.quantity.valueAfterUpdate ? getLotSingle.quantity.valueAfterUpdate : getLotSingle.quantity }}
                  </p>
                </template>
                <template v-slot:old-text v-if="getLotSingle.quantity.updateIntended">
                  <p class="row__text">
                    {{ getLotSingle.quantity.currentValue }}
                  </p>
                </template>
              </Row>
              <Row :isUpdate="getLotSingle.product.updateIntended" v-if="getLotSingle.product">
                <template v-slot:label>
                  <p class="row__label">
                    Мера объема
                  </p>
                </template>
                <template v-slot:current-text>
                  <p class="row__text">
                    {{ getLotSingle.product.valueAfterUpdate ? getLotSingle.product.valueAfterUpdate.volumeMeasurement.title : getLotSingle.product.volumeMeasurement.title }}
                  </p>
                </template>
                <template v-slot:old-text v-if="getLotSingle.product.updateIntended">
                  <p class="row__text">
                    {{ getLotSingle.product.currentValue.volumeMeasurement.title }}
                  </p>
                </template>
              </Row>
            </div>
          </div>

          <div class="ls-body__block-wrap" v-if="getLotSingle.status == 3">
            <div class="ls-body__block">
              <Row :class="'textarea'" :isCanceled="getLotSingle.moderationMessage">
                <template v-slot:label>
                  <p class="row__label">
                    Причина отклонения
                  </p>
                </template>
                <template v-slot:current-text>
                  <p class="row__text">
                    {{ getLotSingle.moderationMessage }}
                  </p>
                </template>
              </Row>
            </div>
          </div>
        </div>

        <div class="ls-rightside">
          <div class="ls-body__block-wrap">

            <div class="ls-body__block" v-if="getLotSingle.media && getLotSingle.media.length">
              <div class="ls-body__block-title">
                <p>
                  Медиа
                </p>
              </div>
              <div class="ls-body__media">
                <div class="ls-body__media-image js-gallery-main-slider" @click="openPopup">
                  <swiper ref="headerSlider" class="header-slider" :options="headerSwiper">
                    <swiper-slide class="" v-for="item, idx in getLotSingle.media" :key="idx">
                      <span class="ls-body__media-label">
                        {{ item.type == 1 ? 'Фото' : 'Видео' }}
                      </span>
                      <span class="ls-body__media-update" v-if="item.temporaryFile">
                        Обновлено
                      </span>
                      <span v-if="item.type == 2" class="ls-body__media-play"></span>

                      <img :src="item.file" alt="gallery" :class="{ update: item.temporaryFile }" v-if="item.type == 1">
                      <img :src="item.thumbnail" alt="gallery" :data-video-src="item.file"
                        :class="{ update: item.temporaryFile }" v-else-if="item.type == 2">
                    </swiper-slide>
                  </swiper>
                </div>
                <div class="swiper-navigation-wrap">
                  <div class="swiper-button swiper-button-prev">
                    <img src="@/assets/images/icons/arrow-left.svg" alt="arrow">
                  </div>
                  <div class="swiper-button swiper-button-next">
                    <img src="@/assets/images/icons/arrow-right.svg" alt="arrow">
                  </div>
                </div>
                <div class="swiper-pagination-wrapper">
                  <div class="swiper-pagination" slot="pagination"></div>
                </div>
                <swiper ref="footerSlider" class="footer-slider" :options="footerSwiper">
                  <swiper-slide class="ls-body__media-thumbnail js-media-thumbnail"
                    v-for="item, idx in getLotSingle.media" :key="idx">
                    <img :src="item.file" alt="gallery" :class="{ update: item.temporaryFile }" v-if="item.type == 1"
                      @click="changeTopSlider(idx)">
                    <img :src="item.thumbnail" alt="gallery" :data-video-src="item.file"
                      :class="{ update: item.temporaryFile }" v-else-if="item.type == 2" @click="changeTopSlider(idx)">
                  </swiper-slide>
                </swiper>
              </div>
            </div>

            <div class="ls-body__block" v-if="getLotSingle.documents && getLotSingle.documents.length">
              <div class="ls-body__block-title">
                <p>
                  Документы
                </p>
              </div>
              <template v-if="getLotSingle.documents">
                <template v-if="getLotSingle.documents.needToCheck">
                    <RowDoc v-for="doc, idx in getLotSingle.documents.data" :key="idx" :isUpdate="[0,3].includes(doc.status)" :isCanceled="doc.status == 1">
                      <template v-slot:current-text>
                        <a  :href="doc.file" class="row__text" target="_blank">
                          {{doc.title.length > 70 ? doc.title.substring(0, 70) + '...' : doc.title}}
                        </a>
                      </template>
                  </RowDoc>
                </template>
                <template v-else>
                 <RowDoc v-for="doc, idx in getLotSingle.documents" :key="idx" :isUpdate="[0,3].includes(doc.status)" :isCanceled="doc.status == 1"  class="ai-center">
                  <template v-slot:current-text>
                    <a :href="doc.file" target="_blank"  class="row__text">
                      {{doc.title.length > 70 ? doc.title.substring(0, 70) + '...' : doc.title}}
                    </a>
                  </template>
                </RowDoc>
                </template>
              </template>
            </div>

            <div class="ls-body__block">
              <div class="ls-body__block-title">
                <p>
                  {{ getLotSingle.type == 0 ? 'Адрес местонахождения товара' : 'Адрес поставки' }}
                </p>
              </div>
              <Row :isUpdate="getLotSingle.region.updateIntended" v-if="getLotSingle.region">
                <template v-slot:label>
                  <p class="row__label">
                    Регион
                  </p>
                </template>
                <template v-slot:current-text>
                  <p class="row__text">
                    {{ getLotSingle.region.valueAfterUpdate ? getLotSingle.region.valueAfterUpdate.title : getLotSingle.region.title }}
                  </p>
                </template>
                <template v-slot:old-text v-if="getLotSingle.region.updateIntended">
                  <p class="row__text">
                    {{ getLotSingle.region.currentValue.title }}
                  </p>
                </template>
              </Row>
              <Row :isUpdate="getLotSingle.city.updateIntended" v-if="getLotSingle.city">
                <template v-slot:label>
                  <p class="row__label">
                    Населенный пункт
                  </p>
                </template>
                <template v-slot:current-text>
                  <p class="row__text">
                    {{ getLotSingle.city.valueAfterUpdate ? getLotSingle.city.valueAfterUpdate.title : getLotSingle.city.title }}
                  </p>
                </template>
                <template v-slot:old-text v-if="getLotSingle.city.updateIntended">
                  <p class="row__text">
                    {{ getLotSingle.city.currentValue.title }}
                  </p>
                </template>
              </Row>

              <template
                v-if="getLotSingle.delivery && getLotSingle.type == 0 && (typeof getLotSingle.delivery != 'object' || getLotSingle.deliveryRadius.valueAfterUpdate != null)">
                <Row :isUpdate="getLotSingle.deliveryRadius.updateIntended">
                  <template v-slot:label>
                    <p class="row__label">
                      Радиус доставки
                    </p>
                  </template>
                  <template v-slot:current-text>
                    <p class="row__text">
                      {{ getLotSingle.deliveryRadius.valueAfterUpdate ? getLotSingle.deliveryRadius.valueAfterUpdate + ' км' : getLotSingle.deliveryRadius + ' км' }}
                    </p>
                  </template>
                  <template v-slot:old-text v-if="getLotSingle.deliveryRadius.updateIntended">
                    <p class="row__text">
                      {{ getLotSingle.deliveryRadius.currentValue + ' км'}}
                    </p>
                  </template>
                </Row>
              </template>
            </div>

            <div class="ls-body__block">
              <div class="ls-body__block-title">
                <p>
                  Срок активности лота
                </p>
              </div>
              <Row>
                <template v-slot:label>
                  <p class="row__label">
                    Время
                  </p>
                </template>
                <template v-slot:current-text>
                  <p class="row__text">
                    {{ getActiveDate }}
                  </p>
                </template>
              </Row>
            </div>

            <div class="ls-body__block">
              <div class="ls-body__block-title">
                <p>
                  Порядок оплаты
                </p>
              </div>
              <Row :isUpdate="getLotSingle.paymentType.updateIntended" v-if="getLotSingle.paymentType != null">
                <template v-slot:label>
                  <p class="row__label">
                    Порядок оплаты
                  </p>
                </template>
                <template v-slot:current-text>
                  <p class="row__text">
                    {{ getLotSingle.paymentType.updateIntended ? getPaymentType.newValue.text  : getPaymentType.text }}
                  </p>
                </template>
                <template v-slot:old-text v-if="getLotSingle.paymentType.updateIntended">
                  <p class="row__text">
                    {{getPaymentType.oldValue.text}}
                  </p>
                </template>
              </Row>
              <Row v-if="getLotSingle.paymentType == 2">
                <template v-slot:label>
                  <p class="row__label">
                    Количество дней
                  </p>
                </template>
                <template v-slot:current-text>
                  <p class="row__text" v-if="getLotSingle.postponementDaysCount">
                    {{ getLotSingle.postponementDaysCount.valueAfterUpdate != null ? getLotSingle.postponementDaysCount.valueAfterUpdate : getLotSingle.postponementDaysCount }}
                  </p>
                   <p class="row__text" v-else>
                    0 дней
                  </p>
                </template>
              </Row>
              <Row v-if="typeof getLotSingle.paymentType == 'object' && getLotSingle.paymentType.valueAfterUpdate == 2">
                <template v-slot:label>
                  <p class="row__label">
                    Количество дней
                  </p>
                </template>
                <template v-slot:current-text>
                  <p class="row__text">
                    {{ getLotSingle.postponementDaysCount.valueAfterUpdate != null ? getLotSingle.postponementDaysCount.valueAfterUpdate : getLotSingle.postponementDaysCount }}
                  </p>
                </template>
              </Row>
            </div>

          </div>
        </div>
      </div>

      <div class="ls-footer"
        v-if="getLotSingle.status == 1 && getLotSingle.updateStatus === 1 || getLotSingle.status == 0">
        <div class="ls-footer__block js-reason-block">
          <Row :class="'textarea'">
            <template v-slot:label>
              <p class="row__label">
                Причина отклонения
              </p>
            </template>
            <template v-slot:current-text>
              <p class="row__text js-reason-parag" contenteditable></p>
            </template>
          </Row>
          <span class="error-text">Укажите причину отказа</span>
        </div>

        <div class="ls-footer__controlls" :class="{'form-send': isSend}">
          <Button @click="toPublishLot(getLotSingle.id)">Опубликовать</Button>
          <Button @click="toCancelLot(getLotSingle.id)" :class="'btn--red'">Отклонить</Button>
        </div>
      </div>

      <div class="ls-footer" v-else></div>

      <transition name="fadeIn">
        <div class="popup" v-show="showPopup" @click="closePopup">
          <div class="popup__window" @click.stop="">
            <span class="popup__close" @click="closePopup">
              <img src="@/assets/images/icons/close.svg" alt="close">
            </span>
            <template v-if="videoLink.length">
              <div class="popup__inner js-media-inner" v-for="video, idx in videoLink" :key="idx">
                <video controls class="popup__media">
                  <source :src="videoLink" type="video/mp4">
                  Your browser does not support the video tag.
                </video>
              </div>
            </template>
            <template v-if="imageLink.length">
              <div class="popup__inner js-media-inner" v-for="image, idx in imageLink" :key="idx">
                <img :src="image" alt="" class="popup__media">
              </div>
            </template>
          </div>
        </div>
      </transition>

    </template>
  </div>
</template>

<script>
  import API from '@/services/API'
  import Loader from '@/components/Loader.vue'
  import SubTitle from '@/components/UI/SubTitle.vue'
  import Button from '@/components/UI/Button.vue'
  import Row from '@/components/UI/SingleDescrRow.vue'
  import RowDoc from '@/components/UI/SingleDescrRowDoc.vue'

  export default {
    name: 'LotSingleView',
    components: {
      SubTitle,
      Button,
      Row,
      RowDoc,
      Loader,
    },
    data() {
      return {
        loading: true,
        isSend: false,
        showPopup: false,
        isImageUpdate: false,
        videoLink: [],
        imageLink: [],
        headerSwiper: {
          slidesPerView: 1,
          allowTouchMove: false,
          effect: "fade",
          speed: 200,
          pagination: {
            el: '.swiper-pagination',
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        },
        footerSwiper: {
          slidesPerView: 5,
        }
      }
    },
    created() {
      let lotId = this.$route.params.lot
      let localLotId = localStorage.getItem('lotId') || null
      if (lotId != localLotId) {
        this.loading = true
        this.$store.state.lots.getLotSingle = null
        localStorage.setItem('lotId', lotId)
      }
      this.$store.dispatch('uploadLotSingle', lotId)
    },
    computed: {
      isLoading() {
        this.getLotSingle
      },
      getLotSingle() {
        if (this.$store.getters.getLotSingle != null) {
          this.loading = false
          return this.$store.getters.getLotSingle
        }


      },
      getPaymentType() {
        let payment = {
          0: {
            text: 'Предоплата'
          },
          1: {
            text: 'При получении'
          },
          2: {
            text: 'Отсрочка'
          },
          3: {
            text: 'Безопасная сделка'
          }
        }


        if (this.getLotSingle.paymentType.updateIntended) {
          return {
            newValue: payment[this.getLotSingle.paymentType.valueAfterUpdate],
            oldValue: payment[this.getLotSingle.paymentType.currentValue]
          }
        }

        return this.getLotSingle.paymentType.valueAfterUpdate != null ? payment[this.getLotSingle.paymentType
          .valueAfterUpdate] : payment[this.getLotSingle.paymentType]
      },
      getNds() {
        let nds = {
          0: {
            text: 'Цена без учета НДС'
          },
          1: {
            text: 'Цена с учетом НДС 10%'
          },
          2: {
            text: 'Цена с учетом НДС 10%'
          },
        }

        if (this.getLotSingle.nds.updateIntended) {
          return {
            new: nds[this.getLotSingle.nds.valueAfterUpdate],
            old: nds[this.getLotSingle.nds.currentValue]
          }
        }
        if (this.getLotSingle.nds.currentValue) {
          return nds[this.getLotSingle.nds.currentValue];
        }

        return nds[this.getLotSingle.nds];
      },
      getStatus() {
        let status = {
          0: {
            text: 'Лот на проверке',
            class: 'c-lots__status--new'
          },
          1: {
            text: 'Лот активен',
            class: 'c-lots__status--opened'
          },
          2: {
            text: 'Лот закрыт',
            class: 'c-lots__status--closed'
          },
          3: {
            text: 'Лот отклонен',
            class: 'c-lots__status--canceled'
          },
        }

        let lotStatus = this.getLotSingle.status
        let lotUpdateStatus = this.getLotSingle.updateStatus

        if (lotStatus == 1) {
          if (lotUpdateStatus == 1) {
            return status[0];
          }
          if (lotUpdateStatus == 3) {
            return status[3];
          }
        }

        return status[this.getLotSingle.status];
      },
      getAvatar() {
        if (this.getLotSingle.avatar.needToCheck) {
          return this.getLotSingle.avatar.data.file
        }
        return this.getLotSingle.avatar.file
      },
      getActiveDate() {
        let nowDate = new Date().getTime()
        let endDate = this.getLotSingle.activeUntil;

        return endDate > nowDate ? API.calcActiveDate(endDate) : '0 часов'
      },
      getPrice() {
        if (this.getLotSingle.price.valueAfterUpdate) {
          return {
            new: API.breakNumString(this.getLotSingle.price.valueAfterUpdate),
            old: API.breakNumString(this.getLotSingle.price.currentValue)
          }
        }
        return API.breakNumString(this.getLotSingle.price)

      },
    },
    methods: {
      changeTopSlider(idx) {
        this.$refs.headerSlider.$swiper.slideTo(idx, 0)
      },

      openPopup(event) {
        this.showPopup = true
        let element = event.target
        let isUpdate = element.classList.contains('update') ? 'update' : ''

        if (element.dataset.videoSrc) {
          this.isImageUpdate = isUpdate.length
          this.videoLink.push(element.dataset.videoSrc)
        } else {
          this.isImageUpdate = isUpdate.length
          this.imageLink.push(element.src)
        }
      },
      closePopup(event) {
        this.showPopup = false
        this.videoLink = []
        this.imageLink = []
      },
      async toPublishLot(id) {
        let lotUpdateStatus = this.getLotSingle.updateStatus
        let lotInfo = {
          id: id,
          moderationMessage: null
        }

        if (lotUpdateStatus === 1) {
          lotInfo.updateStatus = 2
        } else {
          lotInfo.status = 1
        }

        this.isSend = true
        await this.$store.dispatch('editLot', lotInfo)
        this.isSend = false
      },
      async toCancelLot(id) {
        const reasonElem = document.querySelector(`.js-reason-parag`)
        const parentBlock = reasonElem.closest('.js-reason-block')
        let reasonTxt = reasonElem.innerText.trim()

        if (!reasonTxt.length) {
          parentBlock.classList.add('show-error')
          return
        }

        let lotUpdateStatus = this.getLotSingle.updateStatus

        let lotInfo = {
          id: id,
          moderationMessage: reasonTxt
        }

        if (lotUpdateStatus === 1) {
          lotInfo.updateStatus = 3
        } else {
          lotInfo.status = 3
        }

        parentBlock.classList.remove('show-error')

        this.isSend = true
        await this.$store.dispatch('editLot', lotInfo)
        this.isSend = false
      }
    },
  }
</script>

<style lang="scss" scoped>
  .wrapper {
    .popup__window {
      max-width: 120rem;
      height: 92rem;
    }
  }
</style>