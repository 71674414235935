<template>
    <div class="su-view">
        <div class="f-block__wrap" v-if="getSingleDoc">
            <form class="form-doc-new" v-if="getDocKind == 'signature'" @submit.prevent="editingSingleDoc">

                <div class="f-block">
                    <div class="f-block__inner">
                        <div class="f-block__body">
                            <div class="form__item" :class="{ 'form__item--error': $v.title.$error}">
                                <input class="form__input" id="title" v-model.lazy="title"
                                    :class="{error: $v.title.$error}" type="text" @change="$v.title.$touch()"
                                    placeholder="Наименование документа">
                                <label for="title" class="form__label"><span>Наименование документа</span></label>
                                <p class="form__item-error" v-if="!$v.title.required">
                                    Обязательное поле
                                </p>
                                <p class="form__item-error" v-if="!$v.title.maxLength">
                                    Максимальное количество 100 символов
                                </p>
                            </div>
                            <div class="f-block__tag-list" v-show="tags">
                                <p class="item js-tag" v-for="tag, idx in tags" :key="idx">
                                    {{tag}} <span @click="deleteTag(idx)">&times;</span>
                                </p>
                            </div>
                            <div class="form__item form__item--custom-margin"
                                :class="{ 'form__item--error': $v.tags.$error}">
                                <input class="form__input form__input--tags" id="tags" v-model="tags" type="text"
                                    :class="{error: $v.tags.$error}" @change="$v.tags.$touch()">

                                <input class="form__input form__input--singleTag" id="singleTag" v-model="singleTag"
                                    type="text" placeholder="Теги" @keydown.enter.prevent='addTag'>
                                <label for="singleTag" class="form__label"><span>Теги</span></label>
                                <span class="add-tag" v-show="singleTag.length" @click="addTag"> 
                                    <img src="@/assets/images/icons/plus--green.svg" alt="add">
                                </span>
                                <p class="form__item-error" v-if="!$v.tags.required">
                                    Обязательное поле
                                </p>
                            </div>
                            <div class="form__item" :class="{ 'form__item--error': $v.docTypeId.$error}">
                                <input class="form__input form__input--arrow" id="docType" v-model.lazy="docTypeName"
                                    :class="{error: $v.docTypeId.$error}" type="text" @change="$v.docTypeId.$touch()"
                                    placeholder="Тип" readonly @focus="showListType = true"
                                    @blur="showListType = false">
                                <label for="docType" class="form__label">
                                    <span>Тип</span>
                                    <img src="@/assets/images/icons/arrow-down.svg" alt="down">
                                </label>
                                <p class="form__item-error" v-if="!$v.docTypeId.required">
                                    Обязательное поле
                                </p>
                                <transition name="fade">
                                    <div class="select-list" v-show="showListType">
                                        <ul>
                                            <li v-for="type, idx in getTypes" :key="idx">
                                                <input type="radio" name="doc-type"
                                                    :value="{id: type.id, name: type.title}"
                                                    class="select__input js-select-input" :id="type.id"
                                                    @change="docTypeInfo(type)">
                                                <label :for="type.id">
                                                    {{type.title}}
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </transition>
                            </div>
                            <div class="form__item" :class="{ 'form__item--error': $v.fileName.$error}">
                                <label for="file" class="with-file"><img src="@/assets/images/icons/pencil.svg"
                                        alt="pencil" v-show="fileName"></label>
                                <input class="form__input form__input--filename" id="fileName"
                                    :class="{error: $v.fileName.$error}" type="text" @change="$v.fileName.$touch()"
                                    v-show="fileName" v-model="fileName">
                                <input class="form__input form__input--file" id="file" type="file" placeholder="Тип"
                                    @change="getFilename" readonly>
                                <label for="file" class="btn" v-show="!fileName">Загрузить документ</label>
                                <p class="form__item-error" v-if="!$v.fileName.required">
                                    Обязательное поле
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="form__controlls" :class="{'form-send': isSend}">
                    <Button>
                        Сохранить
                    </Button>
                    <Button type="button" class="btn--white" @click="$router.back()">
                        Отмена
                    </Button>
                </div>
            </form>

            <form class="form-doc-new" v-else @submit.prevent="editingSingleDoc">
                <div class="f-block">
                    <div class="f-block__inner">
                        <div class="f-block__body">
                            <div class="form__item" :class="{ 'form__item--error': $v.title.$error}">
                                <input class="form__input" id="title" v-model.lazy="title"
                                    :class="{error: $v.title.$error}" type="text" @change="$v.title.$touch()"
                                    placeholder="Наименование документа">
                                <label for="title" class="form__label"><span>Наименование документа</span></label>
                                <p class="form__item-error" v-if="!$v.title.required">
                                    Обязательное поле
                                </p>
                            </div>
                            <div class="form__item" :class="{ 'form__item--error': $v.fileName.$error}">
                                <label for="file" class="with-file"><img src="@/assets/images/icons/pencil.svg"
                                        alt="pencil" v-show="fileName"></label>
                                <input class="form__input form__input--filename" id="fileName"
                                    :class="{error: $v.fileName.$error}" type="text" @change="$v.fileName.$touch()"
                                    v-show="fileName" v-model="fileName">
                                <input class="form__input form__input--file" id="file" type="file" placeholder="Тип"
                                    @change="getFilename" readonly>
                                <label for="file" class="btn" v-show="!fileName">Загрузить документ</label>
                                <p class="form__item-error" v-if="!$v.fileName.required">
                                    Обязательное поле
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="form__controlls" :class="{'form-send': isSend}">
                    <Button>
                        Сохранить
                    </Button>
                    <Button type="button" class="btn--white" @click="$router.back()">
                        Отмена
                    </Button>
                </div>
            </form>
        </div>
    </div>


</template>

<script>
    import {required, maxLength} from 'vuelidate/lib/validators'
    import Button from '@/components/UI/Button.vue'

    export default {
        components: {
            Button,
        },
        data() {
            return {
                title: '',
                docTypeName: null,
                docTypeId: null,
                singleTag: '',
                tags: null,
                file: null,
                fileName: '',
                isSend: false,
                showListType: false
            }
        },
        validations: {
            title: { required, maxLength: maxLength(100) }, 
            docTypeId: {
                required,
            },
            tags: {
                required
            },
            fileName: {
                required,
            },
        },
        created() {
            let docId = this.$route.params.docId
            let docKind = this.$route.params.docKind
            this.$store.dispatch('uploadSingleDoc', {docKind, docId})
            this.$store.dispatch('uploadDocsTypes')
        },
        computed: {
            getDocKind() {
                return this.$route.params.docKind
            },
            getSingleDoc() {
                let singleDoc = this.$store.getters.getSingleDoc
                if(singleDoc) {
                    this.title = singleDoc.title
                    this.fileName = singleDoc.file
                    this.file = singleDoc.file
                    if (this.getDocKind == 'signature' ) {
                        this.docTypeName = singleDoc.documentType.title
                        this.docTypeId = singleDoc.documentType.id
                        this.tags = singleDoc.tags
                    } else {
                        this.docTypeName =  this.$route.params.docKind == 'legal' ? 0 : 1
                    }
                }
                return singleDoc
            },
            getTypes() {
                return this.$store.getters.getDocsTypes
            },
        },
        methods: {
            getFilename() {
                let _file = event.target.files[0]
                this.file = _file
                this.fileName = _file.name
            },
            addTag() {
                this.tags.push(this.singleTag)
                this.singleTag = ''
            },
            docTypeInfo(type) {
                this.docTypeName = type.title
                this.docTypeId = type.id
            },
            deleteTag(idx) {
                this.tags.splice(idx, 1)
            },
            async editingSingleDoc() {
                this.$v.$touch()

                if (this.getDocKind == 'signature' && !this.$v.$invalid) {
                    const docInfo = {
                        docKind: this.$route.params.docKind,
                        docId: this.$route.params.docId,
                        title: this.title,
                        docTypeId: this.docTypeId,
                        tags: this.tags,
                        file: typeof this.file == 'object' ? this.file : null
                    }
                    this.isSend = true
                    await this.$store.dispatch('editingSingleDoc', docInfo)
                    this.$store.dispatch('uploadDocsTags')
                    this.isSend = false
                    this.title = ''
                    this.docTypeId = null
                    this.docTypeName = null
                    this.tags = null
                    this.fileName = ''
                    this.$v.$reset()
                } 
                
                if (this.getDocKind != 'signature' && !this.$v.fileName.$invalid && !this.$v.title.$invalid) {
                    const docInfo = {
                        docKind: this.$route.params.docKind,
                        docId: this.$route.params.docId,
                        title: this.title,
                        docTypeId: this.getDocKind == 'legal' ? 0 : 1,
                        file: typeof this.file == 'object' ? this.file : null
                    }
                    this.isSend = true
                    await this.$store.dispatch('editingSingleDoc', docInfo)
                    this.isSend = false
                    this.title = ''
                    this.docTypeId = null
                    this.docTypeName = null
                    this.tags = null
                    this.fileName = ''
                    this.$v.$reset()
                }
            },
           
        }

    }
</script>

<style lang="scss" scoped>
    .f-block {
        &__row {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 1.6rem;
            font-size: 1.6rem;
            font-weight: 700;
            color: #008069;

            span {
                font-size: 2rem;
                font-weight: 400;
                cursor: pointer;
            }
        }

        &__title {
            margin-right: 1rem;
            font-size: 1.6rem;
            font-weight: 500;
            color: #8A8C96;

        }

        &__tag-list {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 1.6rem;
            margin-top: 1.6rem;

            .item {
                display: inline-flex;
                align-items: center;
                justify-content: space-between;
                gap: 1.2rem;
                padding: .8rem 1.2rem;
                font-size: 1.4rem;
                font-weight: 500;
                line-height: 2rem;
                color: #008069;
                background-color: #D6F3EE;
                border-radius: 10rem;

                span {
                    font-size: 1.6rem;
                    transform: translateY(-.1rem);
                    cursor: pointer;
                }
            }
        }
    }

    .form__item--custom-margin {
        margin-top: 1.6rem;
    }

    .form__item {
        & .add-tag {
            position: absolute;
            top: 1.5rem;
            right: 2rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 3.2rem;
            height: 3.2rem;
            background-color: #D6F3EE;
            border-radius: 50%;
            cursor: pointer;

            img {
                width: 2.4rem;
            }
        }

        .with-file {
            position: absolute;
            top: 2rem;
            right: 2.4rem;
            width: 2.4rem;
            height: 2.4rem;
            cursor: pointer;

            img {
                width: 100%;
            }
        }
    }

    .form__input--singleTag {
        padding-right: 6rem;
    }

    .form__input--tags {
        position: absolute;
        top: 0;
        opacity: 0;
        pointer-events: none;
    }

    .form__input--file {
        position: absolute;
        opacity: 0;
        left: -1000%;
        pointer-events: none;
    }

    .form__input--filename {
        padding-right: 6rem;
        color: #008069;
    }

    .form__controlls {
        display: flex;
        justify-content: space-between;
        gap: 1.6rem;
        width: 100%;
        max-width: 59.5rem;
        margin: 0 auto;
        margin-top: 1.6rem;

    }
</style>