<template>
    <div class="su-view su-counterparties">
        {{isLoading}}
        <div class="users-body" v-if="loading">
            <Loader />
        </div>
        <div class="users-body" v-else>
            <div class="counterparties__header" v-if="getUserLightInfo">
                <div class="counterparties__header-item">
                    <p>
                        Порядковый номер
                    </p>
                    <p>
                        {{getUserLightInfo.uniqueNumber}}
                    </p>
                </div>
                <div class="counterparties__header-item">
                    <p>
                        Пользователь
                    </p>
                    <router-link :to="({ name: 'User', params: { id: getUserLightInfo.id } })">
                        {{getUserLightInfo.name}}
                    </router-link>
                </div>
                <div class="counterparties__header-item">
                    <p>
                        ОГРН
                    </p>
                    <p>
                        {{getUserLightInfo.ogrn}}
                    </p>
                </div>
                <div class="counterparties__header-item">
                    <p>
                        ИНН
                    </p>
                    <p>
                        {{getUserLightInfo.inn}}
                    </p>
                </div>
                <div class="counterparties__header-item">
                    <p>
                        Дата регистрации в сервисе
                    </p>
                    <p>
                        {{ Math.floor(new Date(+getUserLightInfo.createdAt).getTime()/1000.0) | moment("DD.MM.YYYY") }}
                    </p>
                </div>
            </div>
            <div class="counterparties__search-block">
                <Search @search='onSearch' />
                <div class="counterparties__switch-block" @click="toggleSwitch">
                    <div class="counterparties__switch-item" data-is-filter="false" :class="{'active': true}">
                        Все
                    </div>
                    <div class="counterparties__switch-item" data-is-filter="true">
                        Зарегистрированны позже
                    </div>
                </div>
            </div>
            <p class="counterparties__count">
                Всего: {{getUserLightInfo.counterpartiesCount}}
            </p>
            <CounterpartiesTable :userList="getUserCounterparties" v-if="getUserCounterparties" />
        </div>

    </div>
</template>

<script>
    import Loader from '@/components/Loader.vue'
    import EmptyBlock from '@/components/Empty.vue'
    import SubTitle from '@/components/UI/SubTitle.vue'
    import Search from '@/components/Controlls/Search.vue'
    import CounterpartiesTable from '@/components/CounterpartiesTable/Table.vue'

    export default {
        name: 'GoodsView',
        components: {
            Loader,
            EmptyBlock,
            SubTitle,
            Search,
            CounterpartiesTable,
        },
        data() {
            return {
                isSend: false,
                loading: true,
                queryOption: {
                    userId: this.$route.params.id,
                    searchTxt: '',
                    ordering: {},
                    filter: ''
                }
            }
        },
        created() {
            let userLightId = this.$route.params.id
            let localUserLightId = localStorage.getItem('userLightId') || null
            if (userLightId != localUserLightId) {
                this.$store.state.counterparties.userLightId = null
                this.loading = true
                this.$store.dispatch('pageTitle', ' ')
            }

            this.$store.dispatch('uploadUserLightInfo', userLightId)
            this.$store.dispatch('uploadCounterparties', this.queryOption)
        },
        computed: {
            isLoading() {
                this.getUserLightInfo
            },
            getUserLightInfo() {
                let userLightInfo = this.$store.getters.getUserLightInfo
                this.$store.dispatch('pageTitle', 'Контрагенты')
                if (userLightInfo) {
                    this.loading = false
                }
                return userLightInfo
            },
            getUserCounterparties() {
                let userCounterparties = this.$store.getters.getUserCounterparties
                return userCounterparties
            },
        },
        methods: {
            onSearch(searchTxt) {
                this.queryOption.searchTxt = searchTxt
                this.queryOption.offset = 0
                this.$store.dispatch('uploadCounterparties', this.queryOption)
            },
            toggleSwitch(event) {
                let elem = event.target
                let isFilter = elem.dataset.isFilter

                if (elem.classList.contains('active')) return

                if (isFilter == 'true') {
                    elem.previousElementSibling.classList.remove('active')
                    elem.classList.add('active')
                    this.queryOption.filter = `&from_dt=${this.getUserLightInfo.createdAt}`
                } else {
                    elem.nextElementSibling.classList.remove('active')
                    elem.classList.add('active')
                    this.queryOption.filter = ``
                }

                this.queryOption.offset = 0
                this.$store.dispatch('uploadCounterparties', this.queryOption)
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>