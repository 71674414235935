const RegistryTabs = [
  {
    title: '№ документа',
    isSorting: false,
    isFilter: false,
  },
  // {
  //   title: 'Имя файла',
  //   isSorting: false,
  //   isFilter: false,
  // },
  {
    title: 'Дата подписания',
    isSorting: false,
    isFilter: false,
  },

  {
    title: 'Наименование подписантов',
    isSorting: false,
    isFilter: false,
  },
 
 
]

export default RegistryTabs