<template>
    <div class="card-item c-admin js-masonry-item">
        <div class=" card-item__content js-masonry-content">
            <div class="add-admin__title">
                <p>
                    {{admin.name}}
                </p>
            </div>
            <div class="form__item">
                <input disabled class="form__input" :id="'email-' + admin.id" :value="admin.email">
                <label for="email" class="form__label"><span>Логин</span></label>
            </div>
            <div class="form__item">
                <input disabled class="form__input" :id="'password-' + admin.id" type="text" :value="admin.password">
                <label for="password" class="form__label"><span>Пароль</span></label>
            </div>
            <div class="form__controlls">
                <Button :class="'btn--grey'" @click="getAdminId(admin.id)">
                    Удалить
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
    import API from "@/services/API";
    import Button from '@/components/UI/Button.vue'

    export default {
        components: {
            Button,
        },
        props: {
            admin: {
                type: Object,
                require: true
            },
        },
        data() {
            return {

            }
        },
        mounted() {
            API.resizeAllMasonryItems()
        },
        updated() {
            this.$nextTick(function () {
                API.resizeAllMasonryItems()
            })
        },

        methods: {
            getAdminId(adminId) {
                this.$emit('getAdminId', adminId)
            }
        }
    }
</script>

<style lang="scss" scoped>


</style>