const CounterpartiesTabs = [
  {
    title: 'Порядковый <br> номер',
    isSorting: true,
    isFilter: false,
    sorting: {
      sortingTabs: [{
        sortingName: 'target_unique_number',
        sortingAsc: 'target__unique_number',
        sortingDesc: '-target__unique_number',
        sortingAscTitle: 'В порядке возрастания',
        sortingDescTitle: 'В порядке убывания '
      }, ],
    }
  },
  {
    title: 'Пользователь',
    isSorting: false,
    isFilter: false,
    sorting: {}
  },
  {
    title: 'Дата регистрации в&nbsp;сервисе',
    isSorting: true,
    isFilter: false,
    sorting: {
      sortingTabs: [{
        sortingName: 'target_created_at',
        sortingAsc: 'target__created_at',
        sortingDesc: '-target__created_at',
        sortingAscTitle: 'Сначала старые ',
        sortingDescTitle: 'Сначала новые'
      }, ],
    }
  },
  {
    title: 'ОГРН',
    isSorting: false,
    isFilter: false,
    sorting: {}
  },
  {
    title: 'ИНН',
    isSorting: false,
    isFilter: false,
    sorting: {}
  },
  {
    title: 'Промокод',
    isSorting: false,
    isFilter: false,
    sorting: {}
  },
  // {
  //   title: 'Регион <br> Населенный пункт',
  //   isSorting: false,
  //   isFilter: false,
  //   sorting: {}
  // },
  {
    title: 'Дата добавления в&nbsp;контрагенты',
    isSorting: true,
    isFilter: false,
    sorting: {
      sortingTabs: [{
        sortingName: 'created_at',
        sortingAsc: 'created_at',
        sortingDesc: '-created_at',
        sortingAscTitle: 'Сначала старые',
        sortingDescTitle: 'Сначала новые'
      }, ],
    }
  },
]

export default CounterpartiesTabs