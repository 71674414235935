<template>
  <div class="chat-single">
    <div class="users-body" v-if="loading">
      {{isLoading}}
      <Loader />
    </div>
    <div class="users-body" v-else>
      <div class="download-chat-wrap">
        <SubTitle :title="`Чат № ${this.$route.params.chatNumber} <br> <span>${getChatSingle.title ? getChatSingle.title : ''}</span>`" :backBtn="true" />
        <!-- <span class="cs-header__status" :class="getMsgClass">
          {{getChatStatus}}
        </span> -->
        <div class="download-chat-block">
          <img src="@/assets/images/icons/dots.svg" alt="dots" class="js-tooltip-icon"
            :data-id="'tooltip-id-download-chat'">
          <div class="download-chat-tooltip" :style="tooltipElemCoord" id="tooltip-id-download-chat">
            <button class="btn" @click="downloadChat">Выгрузить чат</button>
          </div>
        </div>
      </div>
      <div class="cs-chat-content" v-if="getChatSingleMsgs">
        
          <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
          :paginate-elements-by-height="1400" :pdf-quality="2" :manual-pagination="false" :pdf-margin="10"
          pdf-format="a4" pdf-orientation="portrait" pdf-content-width="800px"
          :filename="'chat #' + this.$route.params.chatNumber" ref="html2Pdf">
          <section slot="pdf-content">
            <div class="download-chat-wrap">
              <SubTitle :title="`Чат № ${this.$route.params.chatNumber} <br> <span>${getChatSingle.title ? getChatSingle.title : ''}</span>`" :backBtn="true" />
            </div>
            <div class="cs-chat-content">
              <div class="cs-chat__header">
                <div class="cs-chat__header-leftside">
                  <div class="cs-chat__profile"
                    :to="({ name: 'User', params: { id: getChatSingle.deal.buyer.id } })">
                    <div class="cs-chat__header-avatar">
                      <img :src="getChatSingle.deal.buyer.profile.avatar.file" alt="avatar"
                        v-if="getChatSingle.deal.buyer.profile.avatar">
                      <img src="@/assets/images/avatar.svg" alt="avatar" v-else>
                    </div>
                    <div class="cs-chat__header-name">
                      <p>
                        {{getChatSingle.deal.buyer.profile.name}}
                      </p>
                      <span>
                      {{getChatSingle.deal.buyer.profile.inn}}
                      </span>
                      <br>
                      <span>
                        Покупатель
                      </span>
                    </div>
                  </div>
                  <div class="cs-chat__profile"
                    :to="({ name: 'User', params: { id: getChatSingle.deal.seller.id } })">
                    <div class="cs-chat__header-avatar">
                      <img :src="getChatSingle.deal.seller.profile.avatar.file" alt="avatar"
                        v-if="getChatSingle.deal.seller.profile.avatar">
                      <img src="@/assets/images/avatar.svg" alt="avatar" v-else>
                    </div>
                    <div class="cs-chat__header-name">
                      <p>
                        {{getChatSingle.deal.seller.profile.name}}
                      </p>
                       <span>
                      {{getChatSingle.deal.seller.profile.inn}}
                      </span>
                      <br>
                      <span>
                        Продавец
                      </span>
                    </div>
                  </div>
                </div>
                <div class="cs-chat__header-rightside">
                  <div class="cs-chat__header-status" :class="getMsgClass">
                    <span>
                      {{getChatStatus}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="cs-chat__body">
                  <Message v-for="message, idx in getChatSingleMsgs" :key="idx" :msg="message" :isRender="false" />
              </div>
            </div>
          </section>
        </vue-html2pdf>

        <div class="cs-chat__header">
          <div class="cs-chat__header-leftside">
            <router-link class="cs-chat__profile" :to="({ name: 'User', params: { id: getChatSingle.deal.buyer.id } })">
              <div class="cs-chat__header-avatar">
                <img :src="getChatSingle.deal.buyer.profile.avatar.file" alt="avatar"
                  v-if="getChatSingle.deal.buyer.profile.avatar">
                <img src="@/assets/images/avatar.svg" alt="avatar" v-else>
              </div>
              <div class="cs-chat__header-name">
                <p>
                  {{getChatSingle.deal.buyer.profile.name}}
                </p>
                <span>
                  Покупатель
                </span>
              </div>
            </router-link>
            <router-link class="cs-chat__profile"
              :to="({ name: 'User', params: { id: getChatSingle.deal.seller.id } })">
              <div class="cs-chat__header-avatar">
                <img :src="getChatSingle.deal.seller.profile.avatar.file" alt="avatar"
                  v-if="getChatSingle.deal.seller.profile.avatar">
                <img src="@/assets/images/avatar.svg" alt="avatar" v-else>
              </div>
              <div class="cs-chat__header-name">
                <p>
                  {{getChatSingle.deal.seller.profile.name}}
                </p>
                <span>
                  Продавец
                </span>
              </div>
            </router-link>
          </div>
          <div class="cs-chat__header-rightside">
            <div class="cs-chat__header-status" :class="getMsgClass">
              <span>
                {{getChatStatus}}
              </span>
            </div>
          </div>
        </div>
        <div class="cs-chat__body">
            <Message v-for="message, idx in getChatSingleMsgs" :key="idx" :msg="message" @openPopup="openPopup" />
            <transition name="fadeIn">
              <div class="popup" v-show="showPopup" @click="closePopup">
                <div class="popup__window" @click.stop="">
                  <span class="popup__close" @click="closePopup">
                    <img src="@/assets/images/icons/close.svg" alt="close">
                  </span>
                  <div class="popup__inner js-media-inner"></div>
                </div>
              </div>
            </transition>
        </div>
      </div>

      <div class="su-empty su-about-empty" v-else>
        <EmptyBlock />
      </div>
    </div>
  </div>
</template>

<script>
  import VueHtml2pdf from 'vue-html2pdf'

  import Loader from '@/components/Loader.vue'
  import Message from '@/components/Cards/ChatMsg.vue'
  import EmptyBlock from '@/components/Empty.vue'
  import Card from '@/components/Cards/ChatCard.vue'
  import SubTitle from '@/components/UI/SubTitle.vue'
  import Button from '@/components/UI/Button.vue'
  export default {
    components: {
      VueHtml2pdf,
      Loader,
      EmptyBlock,
      Card,
      SubTitle,
      Button,
      Message,
    },
    data() {
      return {
        loading: true,
        openDownloadChat: false,
        showPopup: false,
        tooltipElemCoord: {
          top: '5rem',
          left: '',
          right: ''
        },
      }
    },
    created() {
      let chatId = this.$route.params.chatId
      this.$store.dispatch('uploadChatSingle', chatId)
      this.$store.dispatch('uploadChatSingleMsgs', chatId)
      this.$store.dispatch('readedByAdmin', chatId)
    },
    mounted() {
      this.$store.chatSingle = null
      this.loading = true
    },
    computed: {
      isLoading() {
        this.getChatSingle
        this.getChatSingleMsgs
        this.isLoaded
      },
      getChatSingle() {
        let getChatSingle = this.$store.getters.getChatSingle
        return getChatSingle
      },
      getChatSingleMsgs() {
        let chatSingleMsgs = this.$store.getters.getChatSingleMsgs
        return chatSingleMsgs
      },
      isLoaded() {
        if (this.getChatSingle != null && this.getChatSingleMsgs !=null) {
          this.loading = false
        }
      },

      getMsgClass() {
        let classes = {
          0: 'c-chat--waiting',
          1: 'c-chat--waiting',
          2: 'c-chat--success',
          3: 'c-chat--waiting',
          4: 'c-chat--success',
          5: 'c-chat--success',
          6: 'c-chat--abort',
          7: 'c-chat--abort',
        }

        return classes[this.getChatSingle.deal.status]
      },
      getChatStatus() {
        let lastCondition = this.getChatSingle.deal.lastConditionUserId
        let buyerId = this.getChatSingle.deal.buyer.id

        let status = {
          0: 'Чат создан',
          1: `${lastCondition == buyerId ? 'Покупатель' : 'Продавец'} предложил сделку`,
          2: 'Сделка заключена',
          3: `${lastCondition == buyerId ? 'Продавец' : 'Покупатель' } не принял сделку`,
          4: 'Продавец предложил закрыть сделку',
          5: 'Покупатель предложил закрыть сделку',
          6: 'Сделка закрыта',
          7: 'Чат закрыт'
        }

        return status[this.getChatSingle.deal.status]
      },
    },
    methods: {
      openPopup(data) {
        this.showPopup = true
        let mediaWrapper = document.querySelector('.js-media-inner')

        mediaWrapper.innerHTML = `<image src="${data}" alt="" class="popup__media">`
      },
      closePopup(event) {
        this.showPopup = false
        let mediaWrapper = document.querySelector('.js-media-inner')
        mediaWrapper.innerHTML = ``
      },
      downloadChat() {
        this.$refs.html2Pdf.generatePdf();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .cards-list {
    grid-template-columns: repeat(2, 1fr)
  }

  .chat-single {
    display: flex;
    flex-direction: column;
    height: calc(100% - 7.2rem);
  }

  .cs-chat__profile {
    display: inline-flex;
    gap: 14px;
    margin-right: 26px;
    text-decoration: none;
  }

  .cs-header__status {
    position: absolute;
    top: 2rem;
    right: 9rem;
    padding: .9rem 1.2rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: #8A8C96;
    background-color: #F7F7FA;
    border-radius: 10rem;
  }

  .download-chat-wrap {
    position: relative;

    .download-chat-block {
      position: absolute;
      right: 0;
      top: 0;
      width: 7.2rem;
      height: 7.2rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 2rem;

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: .1rem;
        height: 3.2rem;
        background-color: #F7F7FA;
      }
    }

    .download-chat-tooltip {
      z-index: 5;
      display: none;
      position: absolute;
      width: 95vw;
      max-width: 39rem;
      padding: 3.2rem;
      background-color: #ffffff;
      border-radius: 3rem;
      box-shadow: 0 -.2rem 2rem rgba(113, 112, 128, 0.1);

      &.open-tooltip {
        display: block;
      }
    }
  }
</style>