<template>
    <router-link class="card-item c-lots js-masonry-item" :to="({ name: 'LotSingle', params: { lot: lot.id } })" :class="{'c-lots--noauct': !lot.isAuction}">
        <div class="js-masonry-content card-item__content">
            <div class="c-lots__header">
                <div class="c-lots__status" :class="getStatus.class">
                    <p>
                        {{ getStatus.text }}
                         <span v-if="lot.status == 1 && (lot.updateStatus == 0 || lot.updateStatus == 2)">
                            {{ getActiveDate }}
                        </span>
                    </p>
                </div>
                 <div class="c-lots__type" v-if="lot.isAuction">
                    <p>
                        Аукцион
                    </p>
                </div>
            </div>
            <div class="c-lots__body">
                <div class="c-lots__info">
                    <p class="c-lots__info-descr">
                        Лот № {{ lot.uniqueNumber }}
                    </p>
                    <div class="c-lots__arrow">
                        <img src="@/assets/images/icons/arrow-right.svg" alt="arrow-right">
                    </div>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
import API from "@/services/API";

export default {
    props: {
        lot: {
            type: Object,
            require: true
        }
    },
    mounted() {
        API.resizeAllMasonryItems()
    },
    updated() {
            this.$nextTick(function () {
                API.resizeAllMasonryItems()
            })
        },
    computed: {
        getStatus() {
            let status = {
                0: {text: 'Лот на проверке', class: 'c-lots__status--new'},
                1: {text: 'Лот активен', class: 'c-lots__status--opened'},
                2: {text: 'Лот закрыт', class: 'c-lots__status--closed'},
                3: {text: 'Лот отклонен', class: 'c-lots__status--canceled'},
            }

            let lotStatus = this.lot.status
            let lotUpdateStatus = this.lot.updateStatus

            if (lotStatus == 1) {
                if (lotUpdateStatus == 1) {
                    return status[0];
                }
                if (lotUpdateStatus == 3) {
                    return status[3];
                }
            }

            return status[this.lot.status];
        },
        getActiveDate() {
            let endDate = this.lot.activeUntil;
            return API.calcActiveDate(endDate)
        },
    }

}
</script>

<style lang="scss">

</style>