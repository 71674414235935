<template>
    <div class="empty-block">
    <img src="@/assets/images/empty.svg" alt="empty">
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.empty-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;
    img {
        width: 27rem;
        height: auto;
    }
}
</style>