<template>
  <div class="users">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'UsersView',
}
</script>

<style lang="scss" scoped>
.users {
  height: 100%;
}
</style>