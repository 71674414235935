<template>
	<div class="su-view su-admin">
		<SubTitle :title="'Контроль СБ'" :backBtn="true" />
		<div class="duser__search-block">
			<Search @search='onSearch' />
			<span class="sort-block">
				<img src="@/assets/images/icons/filter.svg" alt="filter" class="js-tooltip-icon"
					data-id="tooltip-id-supervisor">
			</span>
			<Filtration :style="tooltipElemCoord" :filters="{'filtersName': 'supervisor'}" />
		</div>
		{{isLoading}}
		<div class="users-body" v-if="loading">
			<Loader />
		</div>
		<div class="users-body" v-else>
			<div class="cards-list c-cat__list js-masonry">
				<AddCard />
				<Card v-for="(security, idx) in getSecurity" :key="idx" :security="security"
					@toggleActivity="toggleActivity" />
			</div>
		</div>
	</div>
</template>

<script>
	import API from "@/services/API";
	import Loader from '@/components/Loader.vue'
	import Button from '@/components/UI/Button'
	import Search from '@/components/Controlls/Search.vue'
	import Filtration from '@/components/Controlls/Filtration.vue'
	import SubTitle from '@/components/UI/SubTitle.vue'
	import AddCard from '@/components/Cards/AddSecurity.vue'
	import Card from '@/components/Cards/SecurityCard.vue'

	export default {
		name: 'SecurityList',
		components: {
			Loader,
			Search,
			Filtration,
			SubTitle,
			Button,
			AddCard,
			Card,
		},
		data() {
			return {
				isSend: false,
				loading: true,
				showPopupDel: false,
				securityForDelId: null,
				queryOption: {
					searchTxt: ''
				},
				tooltipElemCoord: {
					top: '5rem',
					left: '',
					right: ''
				},
			}
		},
		created() {
			this.$store.dispatch('uploadSecurity', this.queryOption)
		},
		mounted() {
			API.resizeAllMasonryItems()
		},
		updated() {
			this.$nextTick(function () {
				API.resizeAllMasonryItems()
			})
		},
		computed: {
			isLoading() {
				this.getSecurity
			},
			getSecurity() {
				let allSecurity = this.$store.getters.getAllSecurity
				if (allSecurity) {
					this.loading = false
				}
				return allSecurity
			},
		},
		methods: {
			onSearch(searchTxt) {
				this.queryOption.searchTxt = searchTxt
				this.$store.dispatch('uploadSecurity', this.queryOption)
			},
			toggleActivity(data) {
				this.$store.dispatch('toggleSecurityActivity', data)
			},
		}
	}
</script>

<style lang="scss">

</style>