import store from "./index";
import router from '@/router/index'
import axios from 'axios'

export default {
    state: {
        docsSignature: null,
        docsLegal: null,
        docsInstr: null,
        docsTypes: null,
        docsTags: null,
        queryOption: {
            searchTxt: '',
            types: [],
            tags: []
        },
        singleDoc: null,
    },
    getters: {
        getDocsSignature(state) {
            return state.docsSignature
        },
        getDocsLegal(state) {
            return state.docsLegal
        },
        getDocsInstr(state) {
            return state.docsInstr
        },
        getDocsTypes(state) {
            return state.docsTypes
        },
        getDocsTags(state) {
            return state.docsTags
        },
        getSingleDoc(state) {
            return state.singleDoc
        },
    },
    mutations: {
        setDocsSignature(state, docsSignature) {
            state.docsSignature = docsSignature
        },
        setDocsLegal(state, docsLegal) {
            state.docsLegal = docsLegal
        },
        setDocsInstr(state, docsInstr) {
            state.docsInstr = docsInstr
        },
        setDocsTypes(state, docsTypes) {
            state.docsTypes = docsTypes
        },
        setDocsTags(state, docsTags) {
            state.docsTags = docsTags
        },
        setSingleDoc(state, singleDoc) {
            state.singleDoc = singleDoc
        },

    },
    actions: {
        uploadDocsSignature({
            commit,
            state
        }, queryOption) {
            state.queryObject = queryOption
            let typesString = queryOption.types.join('&document_type=')
            let tagsString = queryOption.tags.join('&tag=')
            let url

            if (typesString.length != 0 && tagsString.length != 0) {
                url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/documents/for_signature?search=${queryOption.searchTxt}&document_type=${typesString}&tag=${tagsString}&`
            } else if (typesString.length != 0 && tagsString.length == 0) {
                url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/documents/for_signature?search=${queryOption.searchTxt}&document_type=${typesString}&`
            } else if (typesString.length == 0 && tagsString.length != 0) {
                url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/documents/for_signature?search=${queryOption.searchTxt}&tag=${tagsString}&`
            } else {
                url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/documents/for_signature?search=${queryOption.searchTxt}&`
            }

            if (queryOption.ordering) url += `ordering=${queryOption.ordering}`
            if (queryOption.offset) url += queryOption.offset

            return axios.get(url, {
                    headers: {
                        Authorization: `JWT ${store.state.API_TOKEN}`
                    }
                }).then(response => {
                    let _allDocs = state.docsSignature
                    if (queryOption.offset && _allDocs) {
                        response.data.forEach(elem => {
                            _allDocs.push(elem)
                        })
                        commit('setDocsSignature', _allDocs)
                    } else {
                        commit('setDocsSignature', response.data)
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status == 401) {
                        this.dispatch('userLogout')
                    } else {
                        commit('setErrorText', 'Ошибка соединения с сервером')
                    }
                })
        },
        deleteDocsSignature({
            commit,
            state
        }, queryOption) {

            return axios.delete(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/documents/for_signature/${queryOption.delDocId}`, {
                    headers: {
                        Authorization: `JWT ${store.state.API_TOKEN}`
                    }
                }).then(response => {
                    this.dispatch('uploadDocsSignature', queryOption)
                })
                .catch(error => console.log(error))
        },
        uploadDocsInfo({
            commit,
            state
        }, queryOption) {
            state.queryObject = queryOption
            let url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/documents/info?limit=500&search=${queryOption.searchTxt}&document_type=${queryOption.types}` // TODO добавить пагинацию

            if (queryOption.ordering) url += `&ordering=${queryOption.ordering}`
            if (queryOption.offset) url += queryOption.offset

            return axios.get(url, {
                    headers: {
                        Authorization: `JWT ${store.state.API_TOKEN}`
                    }
                }).then(response => {
                    if (queryOption.types == 0) {
                        let _allDocs = state.docsLegal
                        if (queryOption.offset && _allDocs) {
                            response.data.forEach(elem => {
                                _allDocs.push(elem)
                            })
                            commit('setDocsLegal', _allDocs)
                        } else {
                            commit('setDocsLegal', response.data)
                        }
                    } else {
                        let _allDocs = state.docsInstr
                        if (queryOption.offset && _allDocs) {
                            response.data.forEach(elem => {
                                _allDocs.push(elem)
                            })
                            commit('setDocsInstr', _allDocs)
                        } else {
                            commit('setDocsInstr', response.data)
                        }
                    }
                })
                .catch(error => console.log(error))
        },
        deleteDocsInfo({
            commit,
            state
        }, queryOption) {
            return axios.delete(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/documents/info/${queryOption.delDocId}`, {
                    headers: {
                        Authorization: `JWT ${store.state.API_TOKEN}`
                    }
                }).then(response => {
                    this.dispatch('uploadDocsInfo', queryOption)
                })
                .catch(error => console.log(error))
        },
        uploadDocsTags({
            commit,
            state
        }) {
            return axios.get(`${store.state.API_HOST}/api/documents/signature/tags`, {
                    headers: {
                        Authorization: `JWT ${store.state.API_TOKEN}`
                    }
                }).then(response => {
                    commit('setDocsTags', response.data)
                })
                .catch(error => console.log(error))
        },
        uploadDocsTypes({
            commit,
            state
        }) {
            return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/documents/for_signature/types`, {
                    headers: {
                        Authorization: `JWT ${store.state.API_TOKEN}`
                    }
                }).then(response => {
                    commit('setDocsTypes', response.data)
                })
                .catch(error => console.log(error))
        },
        uploadSingleDoc({
            commit,
            state
        }, singleDocInfo) {
            let url

            if (singleDocInfo.docKind == 'signature') {
                url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/documents/for_signature/${singleDocInfo.docId}`
            } else {
                url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/documents/info/${singleDocInfo.docId}`
            }

            return axios.get(url, {
                    headers: {
                        Authorization: `JWT ${store.state.API_TOKEN}`
                    }
                }).then(response => {
                    commit('setSingleDoc', response.data)
                })
                .catch(error => console.log(error))
        },
        addDocTemplate({
            commit,
            state
        }, newDocInfo) {
            let formData = new FormData();
            formData.append('title', newDocInfo.title)
            formData.append('file', newDocInfo.file)
            formData.append('document_type', newDocInfo.docTypeId)
            newDocInfo.tags.forEach(elem => {
                formData.append('tag', elem)
            })
            return axios.post(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/documents/for_signature/`, formData, {
                    headers: {
                        Authorization: `JWT ${store.state.API_TOKEN}`
                    }
                }).then(response => {
                    router.go(-1)
                })
                .catch(error => {
                    if (error.response.status == 400) {
                        commit('setErrorText', 'Загрузите файл в разрешенном формате: doc, xls, xlx, xlsx, pdf.')
                    }
                })
        },
        addDocInfo({
            commit,
            state
        }, newDocInfo) {
            let formData = new FormData();
            formData.append('title', newDocInfo.title)
            formData.append('file', newDocInfo.file)
            formData.append('document_type', newDocInfo.docTypeId)

            return axios.post(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/documents/info/`, formData, {
                    headers: {
                        Authorization: `JWT ${store.state.API_TOKEN}`
                    }
                }).then(response => {
                    // this.dispatch(uploadDocsTags)
                    router.go(-1)
                })
                .catch(error => {
                    if (error.response.status == 400) {
                        commit('setErrorText', 'Загрузите файл в разрешенном формате: doc, xls, xlx, xlsx, pdf.')
                    }
                })
        },
        addDocType({
            commit,
            state
        }, newDocType) {
            let body = {
                "title": newDocType
            }

            return axios.post(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/documents/for_signature/types`, body, {
                    headers: {
                        Authorization: `JWT ${store.state.API_TOKEN}`
                    }
                }).then(response => {
                    this.dispatch('uploadDocsTypes')
                })
                .catch(error => console.log(error))
        },
        deleteDocType({
            commit,
            state
        }, docTypeId) {
            return axios.delete(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/documents/for_signature/types/${docTypeId}`, {
                    headers: {
                        Authorization: `JWT ${store.state.API_TOKEN}`
                    }
                }).then(response => {
                    this.dispatch('uploadDocsTypes')
                })
                .catch(error => console.log(error))
        },
        editingSingleDoc({
            commit,
            state
        }, editDocInfo) {
            let url
            let formData = new FormData();
            formData.append('title', editDocInfo.title)
            formData.append('document_type', editDocInfo.docTypeId)
            if (editDocInfo.file) formData.append('file', editDocInfo.file)

            if (editDocInfo.docKind == 'signature') {
                let tagsData = {
                    "tags": editDocInfo.tags
                }

                return axios.put(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/documents/for_signature/${editDocInfo.docId}/tags`, tagsData, {
                    headers: {
                        Authorization: `JWT ${store.state.API_TOKEN}`
                    }
                }).then(response => {
                    axios.patch(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/documents/for_signature/${editDocInfo.docId}`, formData, {
                            headers: {
                                Authorization: `JWT ${store.state.API_TOKEN}`
                            }
                        }).then(response => {
                            router.go(-1)
                        })
                        .catch(error => console.log(error))
                })
            } else {
                return axios.patch(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/documents/info/${editDocInfo.docId}`, formData, {
                    headers: {
                            Authorization: `JWT ${store.state.API_TOKEN}`
                    }
                }).then(response => {
                        router.go(-1)
                })
                .catch(error => console.log(error))
            } 
        }
    },
}