<template>
   <div class="su-view su-chats">
    <div class="duser__search-block">
        <Search @search='onSearch' />
        <span class="sort-block">
          <img src="@/assets/images/icons/sort.svg" alt="sort" data-id="tooltip-id-chat" class="js-tooltip-icon">
        </span>
        <Sorting :style="tooltipElemCoord" :sorting="chatSorting" @addOrdering="addOrdering"/>
    </div>
    <Tabs :tabs="tabs"/>
    <router-view></router-view>
  </div>
</template>

<script>

import chatSorting from '@/data/ChatSorting'
import SubTitle from '@/components/UI/SubTitle.vue'
import Search from '@/components/Controlls/Search.vue'
import Tabs from '@/components/Controlls/Tabs.vue'
import Sorting from '@/components/Controlls/Sorting.vue'

export default {
  name: 'ChatsView',
  components: {
    SubTitle,
    Search,
    Tabs,
    Sorting,
  },
   data() {
    return {
      tabs: [
        { title: 'Активные', url: 'UserChatsActive' },
        { title: 'Закрытые', url: 'UserChatsClosed' },
      ],
       tooltipElemCoord: {
          top: '5rem',
          left: '',
          right: ''
        },
        queryOption: {
          userId: this.$route.params.id ? this.$route.params.id : null,
          searchTxt: '',
          ordering: '',
          chatStatus: ''
        }
    }
  },
  mounted() {
      this.queryOption.chatStatus = this.$route.name == 'UserChatsActive' ? 0 : 1
      this.$store.dispatch('uploadChats', this.queryOption)
    },
    updated() {
      this.$nextTick(function () {
        this.queryOption.chatStatus = this.$route.name == 'UserChatsActive' ? 0 : 1
        this.$store.dispatch('uploadChats', this.queryOption)
      })
    },
  computed: {
     chatSorting() {
        return chatSorting
      },
  },
  methods:{
    onSearch(searchTxt) {
      this.queryOption.searchTxt = searchTxt
      this.$store.dispatch('uploadChats', this.queryOption)
    },
    addOrdering(data) {
      this.queryOption.ordering = data.query
      this.$store.dispatch('uploadChats', this.queryOption)
    },
  }
}
</script>

<style lang="scss" scoped>

</style>