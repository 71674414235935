<template>

  <div class="su-view">
    <SubTitle :title="'Редактировать: ' + editingTitle" :backBtn="true" />
    <div class="f-block__wrap" v-if="getSubcatForEdit">
      <div class="f-block">
        <div class="f-block__inner">
          <div class="f-block__body">
            <form class="form-category" @submit.prevent="addProduct">
              <div class="form__item-wrap">
                <div class="form__input-title">
                  <p class="title" :class="{'active': getSubcatForEdit.cat.active}">
                    <span>
                      {{getSubcatForEdit.cat.item.title}}
                    </span>
                    <span>
                      {{getSubcatForEdit.cat.item.volumeMeasurement.title}}
                    </span>
                  </p>
                  <div class="f-block__delete" @click="openPopup(getSubcatForEdit.cat.item)">
                    Удалить всю категорию
                  </div>
                </div>
                <div class="c-cat__select-list" v-if="getSubcatForEdit.subcat_1">
                  <ul>
                    <li class="form__input-title">
                      <p class="title" :class="{'active': getSubcatForEdit.subcat_1.active}">
                        <span>
                          {{getSubcatForEdit.subcat_1.item.title}}
                        </span>
                      </p>
                      <div class="f-block__delete" @click="openPopup(getSubcatForEdit.subcat_1.item)">
                        Удалить всю подкатегорию
                      </div>
                    </li>
                    <li v-if="getSubcatForEdit.subcat_2">
                      <ul>
                        <li class="form__input-title">
                          <p class="title" :class="{'active': getSubcatForEdit.subcat_2.active}">
                            <span>
                              {{getSubcatForEdit.subcat_2.item.title}}
                            </span>
                          </p>
                          <div class="f-block__delete" @click="openPopup(getSubcatForEdit.subcat_2.item)">
                            Удалить всю подкатегорию
                          </div>
                        </li>
                        <li v-if="getSubcatForEdit.subcat_3">
                          <ul>
                            <li class="form__input-title">
                              <p class="title" :class="{'active': getSubcatForEdit.subcat_3.active}">
                                <span>
                                  {{getSubcatForEdit.subcat_3.item.title}}
                                </span>
                              </p>
                              <div class="f-block__delete" @click="openPopup(getSubcatForEdit.subcat_3.item)">
                                Удалить всю подкатегорию
                              </div>
                            </li>
                            <li v-if="productItem">
                              <ul>
                                <li class="f-block__row" v-for="item, idx in productItem" :key="idx">
                                  <p>
                                    <span @click="deleteSubcat(item.id)"> &times; </span> {{ item.title }}
                                  </p>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li v-else>
                          <ul v-if="productItem">
                            <li class="f-block__row" v-for="item, idx in productItem" :key="idx">
                              <p>
                                <span @click="deleteSubcat(item.id)"> &times; </span> {{ item.title }}
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li v-else>
                      <ul v-if="productItem">
                        <li class="f-block__row" v-for="item, idx in productItem" :key="idx">
                          <p>
                            <span @click="deleteSubcat(item.id)"> &times; </span> {{ item.title }}
                          </p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div class="c-cat__select-list" v-else>
                  <ul v-if="productItem">
                    <li class="f-block__row" v-for="item, idx in productItem" :key="idx">
                      <p>
                        <span @click="deleteSubcat(item.id)"> &times; </span> {{ item.title }}
                      </p>
                    </li>
                  </ul>
                </div>

              </div>
              <div class="form__item-wrap">
                <div class="form__item" :class="{ 'form__item--error': $v.newSubcatTitle.$error}">
                  <input class="form__input" id="newSubcatTitle" v-model.lazy="newSubcatTitle"
                    :class="{error: $v.newSubcatTitle.$error}" type="text" @change="$v.newSubcatTitle.$touch()"
                    placeholder="Новая подкатегория">
                  <label for="newSubcatTitle" class="form__label"><span>Новая подкатегория</span></label>
                  <p class="form__item-error" v-if="!$v.newSubcatTitle.required">
                    Обязательное поле
                  </p>
                </div>
              </div>

              <div class="form__controlls" :class="{'form-send': isSend}">
                <Button>
                  Добавить
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <transition name="fadeIn">
      <div class="popup" v-if="showPopup" @click="closePopup">
        <div class="popup__window" @click.stop="">
          <!-- <span class="popup__close" @click="closePopup">
            <img src="@/assets/images/icons/close.svg" alt="close">
          </span> -->
           <div class="popup__inner">
          <div class="popup__title">
            Вы уверены что хотите удалить "{{deleteSubcatTitle}}"?
          </div>
          <div class="popup__controlls">
            <Button :class="'btn--red'" @click="deleteSubcat(deleteSubcatGroup)">Удалить</Button>
            <Button :class="'btn--grey'" @click="closePopup">Отмена</Button>
          </div>
          </div>

        </div>
      </div>
    </transition>
  </div>


</template>

<script>
  import {
    required
  } from 'vuelidate/lib/validators'

  import SubTitle from '@/components/UI/SubTitle.vue'
  import Button from '@/components/UI/Button.vue'

  export default {
    components: {
      SubTitle,
      Button,
    },
    data() {
      return {
        newSubcatTitle: '',
        isSend: false,
        productItem: null,
        showPopup: false,
        deleteSubcatGroup: '',
        editingTitle: '',
        deleteSubcatTitle: ''
      }
    },
    validations: {
      newSubcatTitle: {
        required,
      },
    },
    created() {
      let catId = this.$route.params.catId
      let subcat_1_id = this.$route.params.subcat_1_id
      let subcat_2_id = this.$route.params.subcat_2_id
      let subcat_3_id = this.$route.params.subcat_3_id
      this.$store.dispatch('uploadSingleSubcat', {
        catId,
        subcat_1_id,
        subcat_2_id,
        subcat_3_id
      })
    },
    computed: {
      getSubcatForEdit() {
        let singleProduct = this.$store.getters.getSingleCat
        
        if (singleProduct != null && singleProduct.subcat_1) {
          if (singleProduct.subcat_3) {
            this.productItem = singleProduct.subcat_3.item.subCategories
            this.editingTitle = singleProduct.subcat_3.item.title
            return singleProduct
          }
          else if (singleProduct.subcat_2) {
            this.productItem = singleProduct.subcat_2.item.subCategories
            this.editingTitle = singleProduct.subcat_2.item.title
            return singleProduct
          } else if (singleProduct.subcat_1) {
            this.productItem = singleProduct.subcat_1.item.subCategories
            this.editingTitle = singleProduct.subcat_1.item.title
            return singleProduct
          } 
        }

      },
    },
    methods: {
      deleteSubcat(itemId) {
        let singleSubcat = this.$store.getters.getSingleCat
        const subcatInfo = {
          itemId: itemId,
          catId: singleSubcat.cat ? singleSubcat.cat.item.id : null,
          subcat_1_id: singleSubcat.subcat_1 ? singleSubcat.subcat_1.item.id : null,
          subcat_2_id: singleSubcat.subcat_2 ? singleSubcat.subcat_2.item.id : null,
          subcat_3_id: singleSubcat.subcat_3 ? singleSubcat.subcat_3.item.id : null,
          subcat_4_id: singleSubcat.subcat_4 ? singleSubcat.subcat_4.item.id : null,
        }

        this.$store.dispatch('deleteSubcat', subcatInfo)
        
      },
      async addProduct() {
        let singleSubcat = this.$store.getters.getSingleCat
        this.$v.newSubcatTitle.$touch()
        if (!this.$v.newSubcatTitle.$invalid) {
          const subcatInfo = {
            catId: singleSubcat.cat ? singleSubcat.cat.item.id : null,
            subcat: true,
            subcat_1_id: singleSubcat.subcat_1 ? singleSubcat.subcat_1.item.id : null,
            subcat_2_id: singleSubcat.subcat_2 ? singleSubcat.subcat_2.item.id : null,
            subcat_3_id: singleSubcat.subcat_3 ? singleSubcat.subcat_3.item.id : null,
            subcat_4_id: singleSubcat.subcat_4 ? singleSubcat.subcat_4.item.id : null,
            subcatTitle: this.newSubcatTitle
          }

          this.isSend = true
          await this.$store.dispatch('addSubcat', subcatInfo)
          this.isSend = false
          this.newSubcatTitle = ''
          this.$v.newSubcatTitle.$reset()
        }
      },
      openPopup(item) {
        this.deleteSubcatGroup = item.id
        this.deleteSubcatTitle = item.title
        this.showPopup = true
      },
      closePopup(event) {
        this.showPopup = false
      },
    }
  }
</script>

<style lang="scss" scoped>
  .title.active {
    color: #191B1D;
  }

  .f-block__header ul.with-border {
    padding-left: 1.6rem;
    border-left: .1rem solid #DBDCDE;
  }

  .c-cat__select-list ul {
    margin-top: 2.6rem;
  }

  .f-block__row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1.6rem;
    font-size: 1.6rem;
    font-weight: 700;
    color: #008069;

    span {
      font-size: 2rem;
      font-weight: 400;
      cursor: pointer;
    }
  }

</style>