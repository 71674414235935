<template>
  <div class="table__th">
    <span class="table__th-sort" v-if="tHeader.isSorting">
      <img src="@/assets/images/icons/sort.svg" alt="sort" class="js-tooltip-icon"
        :data-id="'tooltip-id-' + tHeader.sorting.sortingTabs[0].sortingName">
    </span>
    <span class="table__th-sort" v-if="tHeader.isFilter">
      <img src="@/assets/images/icons/filter.svg" alt="sort" class="js-tooltip-icon"
        :data-id="'tooltip-id-' + tHeader.filters.filtersName">
    </span>
    <span v-html="tHeader.title"></span>
    <Sorting :style="tooltipElemCoord" :sorting="tHeader.sorting" v-if="tHeader.isSorting" @addOrdering="addOrdering" />
    <Filtration :style="tooltipElemCoord" :filters="tHeader.filters" v-if="tHeader.isFilter"
      @addFiltration="addFiltration" />
  </div>
</template>


<script>
  import Sorting from '@/components/Controlls/Sorting.vue'
  import Filtration from '@/components/Controlls/Filtration.vue'

  export default {
    components: {
      Sorting,
      Filtration
    },
    props: {
      tHeader: {
        type: Object,
        require: true
      },
      index: {
        type: Number,
        require: false
      },
    },
    data() {
      return {
        showSorting: false,
        showSortingId: -1,
        tooltipElemCoord: {
          top: '5rem',
          left: '',
          right: ''
        },
      }
    },
    methods: {
      addOrdering(data) {
        let queryOption = this.$store.state.counterparties.queryObject
        queryOption.ordering[data.queryGroup] = data.query
        queryOption.offset = null
        if (this.$route.params.employeeId) {
          this.$store.dispatch('uploadEmployeesCounterparties', queryOption)
        } else {
          this.$store.dispatch('uploadCounterparties', queryOption)
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .table__th {
    width: 22.5rem;
    padding: 4.2rem 3.2rem 3.2rem;
  }
</style>