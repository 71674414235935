import store from "./index";
import axios from 'axios'

export default {
    state: {
        allAdmins: null,
        file: null,
        isLoading: false
    },
    getters: {
        getAllAdmins(state) {
            return state.allAdmins
        },
        getIsLoading(state) {
            return state.isLoading
        },
        getFile(state) {
            return state.file
        },

    },
    mutations: {
        setAllAdmins(state, allAdmins) {
            state.allAdmins = allAdmins
        },
        setFile(state, file) {
            state.file = file;
        },
        setLoading(state, value) {
            state.isLoading = value;
        },

    },
    actions: {
        fetchAdmins({commit, state}) {
            return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/moderators`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                commit('setAllAdmins', response.data)
            }).catch(error => {
                if (error.response.status == 403) {
                    commit('setErrorText', 'Нет прав для просмотра данного раздела')
                    setTimeout(() => {
                        window.location.href = '/'
                    }, 1500)
                } else {
                    console.error(error)
                }
            })
        },
        addAdmin({commit, state}, adminInfo) {
            let _adminInfo = {
                "email": adminInfo.email,
                "password": adminInfo.password,
                "name": adminInfo.name
            }

            return axios.post(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/moderators`, _adminInfo, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                this.dispatch('fetchAdmins')
            }).catch(error => console.log(error))
        },
        importAdmins({commit, state}) {
            if (!state.file) {
                alert('Файл не выбран');
                return;
            }

            const formData = new FormData();
            formData.append('file', state.file);

            return axios.post(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/moderators/import`, formData, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                commit('setFile', null)
                commit('setLoading', false)
                this.dispatch('fetchAdmins')
            }).catch(error => {
                console.error(error)
                commit('setLoading', false)
            })
        },
        deleteAdmin({commit, state}, adminInfo) {
            return axios.delete(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/moderators/${adminInfo.adminId}`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                this.dispatch('fetchAdmins')
            }).catch(error => console.log(error))
        },
    }
}