export const documentTypesEnum = {
    'PROXY': 'Доверенность',
    'LEGAL_ENTITY_CREATION': 'Решение о создании юридического лица',
    'PASSPORT': 'Паспорт',
    'INN': 'ИНН',
    'EGRUL': 'ЕГРЮЛ',
    'EGRIP': 'ЕГРИП',
    'CHARTER': 'Устав',
    'CEO_POSITION_ORDER': 'Приказ о вступлении Генерального Директора в должность',
    'CEO_ELECTION_DECISION': 'Решение об избрании Генерального Директора',
    'CEO_PASSPORT': 'Паспорт Генерального Директора',
    'CEO_CHECK_PAYMENT': 'Оплата с расчётного счёта руководителя',  // SES_PAYMENT_APPROVAL = 6
    'OFFER_PAYMENT': 'Оплата счёта-оферты',  // Со счёта юр. лица
}

export const updateStatusesEnum = {
    'INITIAL': 0,
    'UPDATE_INTENDED': 1,
    'APPROVED': 2,
    'DECLINED': 3,
}

export const userStatusesEnum = {
    'INITIAL': 0,
    'APPROVED': 1,
    'DECLINED': 2,
}

export const userDeletionStatusesEnum = {
    'NO_INTENTION': 0,
    'DELETION_INTENDED': 1,
    'DELETION_DECLINED': 2,
    'DELETED': 3
}

export const organizationTypesEnum = {
    'ООО': 0,
    'ИП': 1,
    'АО': 2,
    'Физлицо': 3
}

