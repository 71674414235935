import Vue from 'vue'
import Vuelidate from 'vuelidate'
import Moment from 'vue-moment'
import Lottie from 'vue-lottie';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueMask from 'v-mask'
import App from './App.vue'
import router from './router'
import store from './store'
import {getToken, onMessage} from 'firebase/messaging'
import {messaging} from './services/firebase';
import './assets/style/fonts.css'
import './assets/style/base.css'
import 'swiper/css/swiper.css'

Vue.use(Vuelidate)
Vue.use(Moment);
Vue.use(Lottie);
Vue.use(VueAwesomeSwiper);
Vue.use(VueMask)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')


// Request notification permission
function requestNotificationPermission() {
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');
            return registerServiceWorker();
        } else {
            console.log('Unable to get permission to notify.');
        }
    }).catch((err) => {
        console.log('Error requesting notification permission.', err);
    });
}

// Register service worker
function registerServiceWorker() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/firebase-messaging-sw.js', {scope: "/firebase-cloud-messaging-push-scope"})
            .then((registration) => {
                console.log('Service Worker registered with scope:', registration.scope);
                return new Promise((resolve, reject) => {
                    if (registration.active) {
                        resolve(registration);
                    } else {
                        registration.addEventListener('updatefound', () => {
                            const installingWorker = registration.installing;
                            if (installingWorker == null) {
                                reject(new Error('Service worker installation failed'));
                            } else {
                                installingWorker.addEventListener('statechange', () => {
                                    if (installingWorker.state === 'activated') {
                                        resolve(registration);
                                    } else if (installingWorker.state === 'redundant') {
                                        reject(new Error('Service worker installation failed'));
                                    }
                                });
                            }
                        });
                    }
                });
            })
            .then((registration) => {
                console.log('Service Worker ready');
                return getToken(messaging, {
                    vapidKey: process.env.VUE_APP_VAPID_KEY,
                    serviceWorkerRegistration: registration
                });
            })
            .then((token) => {
                if (token) {
                    console.log('FCM Token:', token);
                    // Send this token to your server to send notifications
                }
            })
            .catch((err) => {
                console.log('Service Worker registration failed:', err);
            });
    }
}

requestNotificationPermission();

// Handle foreground notifications
onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // Custom logic to handle foreground notifications
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
        body: payload.notification.body,
        icon: '/notification-icon.png',
        badge: '/notification-badge.png',
        sound: '/sound.mp3'
    };

    // Show notification
    // new Notification(notificationTitle, notificationOptions);
});