<template>
  <div class="table__tr">
    <div class="table__td">
      <p class="font-small">
        {{ cpRelation.target.uniqueNumber }}
      </p>
    </div>
    <div class="table__td">
      <router-link class="text-green" :to="({ name: 'User', params: { id: cpRelation.target.id } })">
        {{ cpRelation.target.name }}
      </router-link>
    </div>
    <div class="table__td">
      <div>
        <p>
          Дата регистрации
        </p>
        <p class="text-grey">
          {{ Math.floor(new Date(+cpRelation.target.createdAt).getTime() / 1000.0) | moment("DD.MM.YYYY") }}
        </p>
      </div>
    </div>
    <div class="table__td">
      <p class="text-grey">
        {{ cpRelation.target.ogrn }}
      </p>
    </div>
    <div class="table__td">
      <p class="font-small">
        {{ cpRelation.target.inn }}
      </p>
    </div>
    <div class="table__td">
      <p class="font-small">
        {{ cpRelation.target.promocode }}
      </p>
    </div>
    <!--        <div class="table__td">-->
    <!--            <div>-->
    <!--            <p class="font-small">-->
    <!--                {{user.region.title}}-->
    <!--            </p>-->
    <!--            <p class="font-small">-->
    <!--                {{user.city.title}}-->
    <!--            </p>-->
    <!--            </div>-->
    <!--        </div>-->
    <div class="table__td">
      <div class="">
        <!--                <p>-->
        <!--                    Дата добавления-->
        <!--                </p>-->
        <p class="text-grey">
          {{ Math.floor(new Date(+cpRelation.createdAt).getTime() / 1000.0) | moment("DD.MM.YYYY") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cpRelation: {
      type: Object,
      require: true
    },
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
.table__td {
  justify-content: center;
  align-items: center;
  width: 22.5rem;
  text-align: center;
  padding: 2.4rem 3.2rem;
}

.text-grey {
  margin-top: 1rem;
}

.font-small {
  font-size: 1.4rem;
}
</style>