import store from "./index";
import axios from 'axios'

export default {
    state: {
        allUsers: null,
        totalCount: 0,
        forDeleteUsers: null,
        usersToDeleteExist: false,
        usersCSV: null,
        loading: false,
        queryObject: {
            searchTxt: '',
            ordering: {},
            filters: {},
            limit: 10
        },
    },
    getters: {
        getAllUsers(state) {
            return state.allUsers
        },
        getLoading(state) {
            return state.loading
        },
        getForDeleteUsers(state) {
            return state.forDeleteUsers
        },
        getUsersCSV(state) {
            return state.usersCSV
        },
        getTotalCount(state) {
            return state.totalCount
        },
        getUsersToDeleteExist(state) {
            return state.usersToDeleteExist
        },
    },
    mutations: {
        setAllUsers(state, users) {
            state.allUsers = users
        },
        setLoading(state, loading) {
            state.loading = !!loading
        },
        setForDeleteUsers(state, users) {
            state.forDeleteUsers = users
        },
        setUsersCSV(state, usersCSV) {
            state.usersCSV = usersCSV
        },
        setTotalCount(state, totalCount) {
            state.totalCount = totalCount
        },
        setUsersToDeleteExist(state, exist) {
            state.usersToDeleteExist = exist
        },
    },
    actions: {
        fetchAllUsers({commit, state}, queryOption) {
            state.queryObject = queryOption

            let orderingArray = Object.values(queryOption.ordering)
            let orderingString = orderingArray.join('&ordering=')
            let filterArray = Object.values(queryOption.filters)
            let filterString = filterArray.join('&')
            let url

            if (Object.keys(queryOption.ordering).length !== 0 && Object.keys(queryOption.filters).length !== 0) {
                url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users?limit=10&search=${queryOption.searchTxt}&ordering=${orderingString}&${filterString}`
            } else if (Object.keys(queryOption.ordering).length !== 0 && Object.keys(queryOption.filters).length === 0) {
                url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users?limit=10&search=${queryOption.searchTxt}&ordering=${orderingString}`
            } else if (Object.keys(queryOption.ordering).length === 0 && Object.keys(queryOption.filters).length !== 0) {
                url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users?limit=10&search=${queryOption.searchTxt}&${filterString}`
            } else {
                url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users?limit=10&search=${queryOption.searchTxt}`
            }

            if (queryOption.promocode) url += queryOption.promocode
            if (queryOption.uniqueNumber) url += queryOption.uniqueNumber
            if (queryOption.offset) url += queryOption.offset

            commit('setLoading', true)
            return axios.get(url, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                let _allUsers = state.allUsers
                if (queryOption.offset && _allUsers) {
                    response.data.forEach(elem => {
                        _allUsers.push(elem)
                    })
                    commit('setAllUsers', _allUsers)
                } else {
                    commit('setAllUsers', response.data)
                }
                commit('setTotalCount', response.headers['total-count'])
                commit('setLoading', false)
            }).catch(error => {
                console.log(error)
                commit('setLoading', false)
            })
        },
        checkUsersToDelete({commit, state}) {
            return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/deletion_intended/exists`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
            }).then(response => {
                commit('setUsersToDeleteExist', response.data.exists)
            }).catch(error => {
                console.log(error)
            })
        },
        fetchUsersToDelete({commit, state}, queryOption) {
            state.queryObject = queryOption
            let orderingArray = Object.values(queryOption.ordering)
            let orderingString = orderingArray.join('&ordering=')
            let filterArray = Object.values(queryOption.filters)
            let filterString = filterArray.join('&')

            let url

            if (Object.keys(queryOption.ordering).length != 0 && Object.keys(queryOption.filters).length != 0) {
                url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users?search=${queryOption.searchTxt}&ordering=${orderingString}&${filterString}`
            } else if (Object.keys(queryOption.ordering).length != 0 && Object.keys(queryOption.filters).length == 0) {
                url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users?search=${queryOption.searchTxt}&ordering=${orderingString}&deletion_status=1&deletion_status=2&deletion_status=3`
            } else if (Object.keys(queryOption.ordering).length == 0 && Object.keys(queryOption.filters).length != 0) {
                url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users?search=${queryOption.searchTxt}&${filterString}`
            } else {
                url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users?search=${queryOption.searchTxt}&deletion_status=1&deletion_status=2&deletion_status=3`
            }

            if (queryOption.offset) url += queryOption.offset

            return axios.get(url, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                let _forDeleteUsers = state.forDeleteUsers
                if (queryOption.offset && _forDeleteUsers) {
                    state.queryObject = null
                    response.data.forEach(elem => {
                        _forDeleteUsers.push(elem)
                    })
                    commit('setForDeleteUsers', _forDeleteUsers)
                } else {
                    commit('setForDeleteUsers', response.data)
                }
            }).catch(error => console.log(error))
        },
        canceledDeleteUser({commit, state}, canceledObj) {
            let body = {
                "moderationMessage": canceledObj.moderationMessage,
                "deletionStatus": 2
            }
            return axios.put(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/deletion_intended/${canceledObj.id}`, body, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
            }).then(response => {
                this.dispatch('checkUsersToDelete')
                this.dispatch('fetchUsersToDelete', state.queryObject)
                commit('setErrorText', 'Информация пользователя обновлена')
            }).catch(error => console.log(error))
        },
        deleteUser({commit, state}, userId) {
            // console.log( state.queryObject);
            return axios.delete(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/${userId}`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
            }).then(response => {
                this.dispatch('checkUsersToDelete')
                this.dispatch('fetchUsersToDelete', state.queryObject)
                commit('setErrorText', 'Пользователь удален')
            }).catch(error => console.log(error))
        },
        uploadUsersCSV({commit, state}) {
            return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/reports/csv`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
            }).then(response => {
                let uriContent = "data:application/csv," + encodeURIComponent(response.data);
                commit('setUsersCSV', uriContent)
            }).catch(error => console.log(error))
        },
    },
}