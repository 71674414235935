<template>
  <div class="su-chats">
    {{isLoading}}
    <div class="users-body" v-if="loading">
      <Loader />
    </div>
    <div class="users-body" v-else>
      <div class="cards-list c-company__list js-masonry" v-if="getСhats.length">
        <Card v-for="(chat, idx) in getСhats" :key="idx" :chat="chat" />
      </div>
      <div class="su-empty su-about-empty" v-else>
        <EmptyBlock />
      </div>
    </div>
  </div>
</template>

<script>
  import Loader from '@/components/Loader.vue'
  import EmptyBlock from '@/components/Empty.vue'
  import Card from '@/components/Cards/ChatCard.vue'

  export default {
    components: {
      Loader,
      EmptyBlock,
      Card,
    },
    data() {
      return {
        loading: true,
        
      }
    },
    computed: {
      isLoading() {
        this.getСhats
      },
      getСhats() {
        let chats = this.$store.getters.getActiveChats
        this.$store.dispatch('pageTitle', 'Чаты')
        if (chats) {
          this.loading = false
        }
        return chats
      }
    },
  }
</script>

<style lang="scss" scoped>
  .cards-list {
    grid-template-columns: repeat(2, 1fr)
  }


</style>