<template>
    <div
        class="card-item c-chat js-masonry-item"
        :class="[
            getStatus, 
            !chat.isReadByAdmin ? 'c-chat--newmsg' : '',
            // !chat.deal.lot ? 'c-chat--contragent' : ''
        ]"
    >

        <div class="js-masonry-content card-item__content">
            <div class="c-chat__header">
                <div class="c-chat__number">
                    <span class="decor"></span>
                    <p class="c-chat__title">
                        <!-- <router-link :to="({ name: 'ChatSingle', params: { chatId: chat.id, chatNumber: chat.deal.number } })"> -->
                        <!-- {{chat.deal.number}}-->
                            {{chat.title}}
                        <!-- </router-link> -->
                        <span>
                            Номер чата
                        </span>
                    </p>
                </div>
                <div class="c-chat__theme" v-if="chat.theme">
                   {{chat.theme}}
                </div>
                <div class="c-chat__time">
                    <p>
                        {{ Math.floor(new Date(+chat.lastMessage?.createdAt).getTime()/1000.0) | moment("DD.MM.YYYY") }}
                    </p>
                </div>
            </div>
            <div class="c-chat__body">
                <div class="c-chat__profile">
                    <router-link :to="({ name: 'User', params: { id: getOwner?.id } })"
                        class="c-chat__profile-avatar">
                        <img :src="getOwnerAvatar.file" alt="avatar" class="avatar" v-if="getOwnerAvatar">
                        <img src="@/assets/images/no_avatar.png" alt="avatar" class="avatar" v-else>
                    </router-link>
                    <div class="c-chat__profile-info">
                        <router-link :to="({ name: 'User', params: { id: getOwner?.id } })"
                            class="c-chat__profile-name">
                            <p>
                                {{getOwner?.name}}
                            </p>
                        </router-link>
                        <div class="c-chat__profile-role">
                            <p>
                                Покупатель
                            </p>
                        </div>
                    </div>
                </div>
                <div class="c-chat__profile">
                    <router-link :to="({ name: 'User', params: { id: getOwner?.id } })"
                        class="c-chat__profile-avatar">
                        <img :src="getCounterpartyAvatar?.file" alt="avatar" class="avatar" v-if="getCounterpartyAvatar">
                        <img src="@/assets/images/no_avatar.png" alt="avatar" class="avatar" v-else>
                    </router-link>
                    <div class="c-chat__profile-info">
                        <router-link :to="({ name: 'User', params: { id: getCounterparty?.id } })"
                            class="c-chat__profile-name">
                            <p>
                                {{getCounterparty?.name}}
                            </p>
                        </router-link>
                        <div class="c-chat__profile-role">
                            <p>
                                Продавец
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="c-chat__footer">
                <div class="num-msg" v-if="chat.unreadMessagesCount">
                    {{chat.unreadMessagesCount}}
                </div>
                <div class="contragent">
                    Создано из раздела контрагенты
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import API from "@/services/API";

    export default {
        props: {
            chat: {
                type: Object,
                require: true
            }
        },
        mounted() {
            API.resizeAllMasonryItems()
        },
        updated() {
            this.$nextTick(function () {
                API.resizeAllMasonryItems()
            })
        },
        computed: {
            getAvatar() {
                let buyerAvatar = this.chat.deal.buyer.profile.avatar ? this.chat.deal.buyer.profile.avatar.file : false
                let sellerAvatar = this.chat.deal.seller.profile.avatar ? this.chat.deal.seller.profile.avatar.file :
                    false

                return {
                    buyerAvatar,
                    sellerAvatar
                }
            },
            getOwner() {
              return this.chat.users[0]
            },
            getOwnerAvatar() {
              return this.chat.users[0]?.avatar
            },
            getCounterparty() {
              return this.chat.users[1]
            },
            getCounterpartyAvatar() {
              return this.chat.users[1]?.avatar
            },
            getStatus() {
                let chatStatus = this.chat.status
                if (chatStatus == 1) {
                    return 'c-chat--closed'
                } else {
                    
                    let addingClass
                    if (this.chat.deal == null) return ''
                    switch (this.chat.deal.status) {
                        case 0:
                            addingClass = 'c-chat--waiting'
                            break
                        case 1:
                            addingClass = 'c-chat--waiting'
                            break
                        case 2:
                            addingClass = 'c-chat--success'
                            break
                        case 3:
                            addingClass = 'c-chat--waiting'
                            break
                        case 4:
                            addingClass = 'c-chat--success'
                            break
                        case 5:
                            addingClass = 'c-chat--success'
                            break
                        default:
                            addingClass = 'c-chat--abort'
                    }
                    return addingClass
                }
            }
        }

    }
</script>

<style lang="scss">

</style>