<template>
  <div class="table__tr">
    <div class="table__td">
      <div class="user-number">
        <router-link class="text-green" :to="({ name: 'User', params: { id: user.id } })">
          <p class="text-green">
            {{ user.uniqueNumber }}
          </p>
        </router-link>
      </div>
    </div>
    <div class="table__td">
      <div class="user-info">
        <router-link class="text-green" :to="({ name: 'User', params: { id: user.id } })">
          {{ user.name }}
        </router-link>


        <p class="text-grey">
          ОГРН {{ user.ogrn }} <br>
          ИНН {{ user.inn }} <br>
          Дата регистрации <br>
          {{ Math.floor(new Date(+user.createdAt).getTime() / 1000.0) | moment("DD.MM.YYYY") }}
        </p>
      </div>
    </div>
    <div class="table__td">
      <div class="user-status">
        <p class="text-black">
          {{ getOrganizationType.text }}
        </p>
      </div>
    </div>
    <div class="table__td">
      <div class="user-counterparties">
        <p class="text-grey">
          Количество
        </p>
        <router-link :to="({ name: 'UserCounterparties', params: { id: user.id } })" class="text-green">
          {{ user.counterpartiesCount }}
        </router-link>
      </div>
    </div>
    <div class="table__td">
      <div class="user-counterparties">
        <p class="text-grey">
          Количество
        </p>
        <router-link :to="({ name: 'UserEmployees', params: { id: user.id } })" class="text-green">
          {{ user.employeesCount }}
        </router-link>
      </div>
    </div>
    <!--        <div class="table__td">-->
    <!--            <div class="user-lots">-->
    <!--                <p class="text-grey">-->
    <!--                    Закрытые-->
    <!--                </p>-->
    <!--                <router-link :to="({ name: 'UserLotsBuyClosed', params: { id: user.id } })" class="text-green">-->
    <!--&lt;!&ndash;                    {{ user.closedLotsCount + user.closedAuctionLotsCount }}&ndash;&gt;-->
    <!--                </router-link>-->
    <!--                <p class="text-grey">-->
    <!--                    Активные-->
    <!--                </p>-->
    <!--                <router-link :to="({ name: 'UserLotsBuyOpened', params: { id: user.id } })" class="text-green">-->
    <!--&lt;!&ndash;                    {{ user.activeLotsCount + user.activeAuctionLotsCount }}&ndash;&gt;-->
    <!--                </router-link>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--        <div class="table__td">-->
    <!--            <div class="user-chats">-->
    <!--                <p class="text-grey">-->
    <!--                    Закрытые-->
    <!--                </p>-->
    <!--                <router-link :to="({ name: 'UserChatsClosed', params: { id: user.id } })" class="text-green">-->
    <!--&lt;!&ndash;                    {{ user.closedChatsCount}}&ndash;&gt;-->
    <!--                </router-link>-->
    <!--                <p class="text-grey">-->
    <!--                    Активные-->
    <!--                </p>-->
    <!--                <router-link :to="({ name: 'UserChatsActive', params: { id: user.id } })" class="text-green">-->
    <!--&lt;!&ndash;                    {{ user.activeChatsCount}}&ndash;&gt;-->
    <!--                </router-link>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--        <div class="table__td">-->
    <!--            <div class="user-payments">-->
    <!--                <p class="text-grey">-->
    <!--                    Ожидающие-->
    <!--                </p>-->
    <!--                <router-link :to="({ name: 'UserPayments', params: { id: user.id } })" class="text-green">-->
    <!--&lt;!&ndash;                    {{ user.pendingPaymentsCount}}&ndash;&gt;-->
    <!--                </router-link>-->
    <!--                <p class="text-grey">-->
    <!--                    Исполненные-->
    <!--                </p>-->
    <!--                <router-link :to="({ name: 'UserPayments', params: { id: user.id } })" class="text-green">-->
    <!--&lt;!&ndash;                    {{ user.executedPaymentsCount }}&ndash;&gt;-->
    <!--                </router-link>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--        <div class="table__td">-->
    <!--            <div class="user-goods">-->
    <!--                <router-link :to="({ name: 'UserGoods', params: { id: user.id } })" class="text-green">-->
    <!--                    Закупленные-->
    <!--                </router-link>-->
    <!--                <router-link :to="({ name: 'UserGoods', params: { id: user.id } })" class="text-green">-->
    <!--                    Проданные-->
    <!--                </router-link>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--        <div class="table__td">-->
    <!--            <div class="user-deal">-->
    <!--                <p class="text-grey">-->
    <!--                    Закупки-->
    <!--                </p>-->
    <!--                <p class="text-black">-->
    <!--&lt;!&ndash;                    {{ user.ownLotsPurchaseDealsCount }}&ndash;&gt;-->
    <!--                </p>-->
    <!--                <p class="text-grey">-->
    <!--                    Продажи-->
    <!--                </p>-->
    <!--                <p class="text-black">-->
    <!--&lt;!&ndash;                    {{ user.ownLotsSaleDealsCount }}&ndash;&gt;-->
    <!--                </p>-->
    <!--            </div>-->
    <!--        </div>-->
    <div class="table__td">
      <div class="user-status">
        <p class="text-black">
          {{ getStatus.text }}
        </p>
      </div>
    </div>
    <div class="table__td">
      <div class="user-status">
        <p class="text-black">
          {{ user.promocode }}
        </p>
      </div>
    </div>
    <!--        <div class="table__td">-->
    <!--            <div class="user-paystatus" v-if="user.servicePayment">-->
    <!--                <p class="text-black">-->
    <!--                    Оплачен-->
    <!--                </p>-->
    <!--                <p class="text-grey">-->
    <!--                    {{ Math.floor(new Date(+user.servicePayment.payedUntil).getTime()/1000.0) | moment("DD.MM.YYYY") }}-->
    <!--                </p>-->
    <!--            </div>-->
    <!--            <div class="user-paystatus" v-else>-->
    <!--                <p class="text-black">-->
    <!--                    Не оплачен-->
    <!--                </p>-->
    <!--            </div>-->
    <!--        </div>-->
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      require: true
    },
  },
  computed: {
    getStatus() {
      let userIsActive = this.user.isActive
      let userStatus = this.user.status
      let userUpdateStatus = this.user.updateStatus
      let userDeletionStatus = this.user.deletionStatus

      let status = {
        0: {
          text: 'На проверке',
          class: 'su-about__status--new'
        },
        1: {
          text: 'Подтверждённый',
          class: 'su-about__status--active'
        },
        2: {
          text: 'Отклоненный',
          class: 'su-about__status--canceled'
        },
        3: {
          text: 'Заблокированный',
          class: 'su-about__status--closed'
        },
        4: {
          text: 'Удален',
          class: 'su-about__status--canceled'
        },
        5: {
          text: 'Ограниченный',
          class: 'su-about__status--restricted'
        },
      }


      if (!userIsActive) {
        return status[3]
      }

      if (userDeletionStatus === 3) {
        return status[4]
      }

      if (userStatus == 1) {

      }

      if (userStatus == 1) {
        if (userUpdateStatus == 0 || userUpdateStatus == 2) {
          return status[1]
        }
        if (userUpdateStatus == 1) {
          return status[0]

        }
        if (userUpdateStatus == 3) {
          return status[2]
        }
      }

      if (userStatus == 2) {
        return status[5]
      }

      return status[userStatus];
    },
    getOrganizationType() {

      let organizationType = this.user.organizationType;

      const organizationTypes = {
        0: {
          text: 'ООО',
          class: 'su-about__organization_type--company'
        },
        1: {
          text: 'ИП',
          class: 'su-about__organization_type--individual_entrepreneur'
        },
        2: {
          text: 'АО',
          class: 'su-about__organization_type--ao'
        },
        3: {
          text: 'Физическое лицо',
          class: 'su-about__organization_type--individual'
        },
      }

      return organizationTypes[organizationType];

    },
  }
}
</script>

<style lang="scss" scoped>
.table__td {
  justify-content: center;
  align-items: center;
  padding: 3rem 2.2rem;
  width: 15rem;

  &:nth-of-type(1) {
    width: 7rem;
    padding: 0;
  }

  &:nth-of-type(2) {
    justify-content: flex-start;
    width: 23rem;
  }

  &:nth-of-type(7) {
    width: 18rem;
  }

  &:nth-of-type(8) {
    width: 17rem;
  }

  &:nth-of-type(9) {
    width: 17rem;
  }

  &:nth-of-type(10) {
    width: 18rem;
  }

  &:last-child {
    width: 18rem;
  }
}
</style>