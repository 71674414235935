import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import axios from 'axios'

import allUsers from './allUsers'
import singleUser from './singleUser'
import lots from './lots'
import cats from './cats'
import docs from './docs'
import chats from './chats'
import rating from './rating'
import payments from './payments'
import admins from "./admins";
import analytics from "./analytics";
import registry from "./registry";
import counterparties from "./counterparties";
import promocode from "./promocode";
import employee from "./employee";
import security from "./security";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        API_HOST: process.env.VUE_APP_API_HOST,
        API_VERSION: "v1",
        API_TOKEN: '',
        isAuth: false,
        isSuperuser: localStorage.getItem('isSuperuser') === 'true',
        showSorting: false,
        pageTitle: '',
        errorText: null,
        notification: [],
        noticeInfo: null,
        isNotReadNotification: false,
    },
    getters: {
        getPageTitle(state) {
            return state.pageTitle
        },
        getErrorText(state) {
            return state.errorText
        },
        getNotification(state) {
            return state.notification
        },
        getNotReadNotification(state) {
            return state.isNotReadNotification
        },
        getFullAccess(state) {
            return state.isSuperuser
        }
    },
    mutations: {
        setPageTitle(state, title) {
            state.pageTitle = title
        },
        setErrorText(state, errorText) {
            state.errorText = errorText
        },
        setNotification(state, notification) {
            state.notification = notification
        },
        setNotReadNotification(state, bool) {
            state.isNotReadNotification = bool
        }
    },
    actions: {
        userLogin({commit, state}, userObj) {
            return axios.post(`${state.API_HOST}/api/${state.API_VERSION}/admin/auth/email`, userObj)
                .then(function (response) {
                    state.isAuth = true;
                    state.API_TOKEN = response.data.accessToken;
                    localStorage.setItem('jwt', response.data.accessToken);
                    localStorage.setItem('isSuperuser', response.data.isSuperuser);
                    state.isSuperuser = response.data.isSuperuser;
                    router.push({name: "Home"});
                }).catch(error => {
                    if (error.response && error.response.status === 401) {
                        commit('setErrorText', 'Неверно введенные данные')
                    } else {
                        commit('setErrorText', 'Ошибка соединения с сервером')
                    }
                })
        },
        registrationDevice({commit, state}, currentToken) {
            axios.post(`${state.API_HOST}/api/notifications/fcmdevices/admin`,
                {'registration_id': currentToken,},
                {
                    headers: {Authorization: `JWT ${state.API_TOKEN}`}
                }).then(
                localStorage.setItem('currentToken', currentToken)
            ).catch(error => console.log(error))
        },
        userLogout({state}) {
            let currentToken = localStorage.getItem('currentToken')
            return axios.delete(`${store.state.API_HOST}/api/notifications/fcmdevices/${currentToken}`, {
                data: {},
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
            }).then(response => {
                state.isAuth = false;
                localStorage.removeItem('jwt')
                localStorage.removeItem('isSuperuser')
                localStorage.removeItem('currentToken')

                router.push({
                    name: "Login"
                });
            }).catch(error => {
                console.log(error)
            })
        },
        userIsLogin(state) {
            this.state.isAuth = true;
            this.state.API_TOKEN = localStorage.getItem("jwt");
        },
        pageTitle({commit, state}, title) {
            commit('setPageTitle', title)
        },
        fetchNotifications({commit, state}, noticeInfo) {
            state.noticeInfo = noticeInfo

            let url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/notifications`
            if (noticeInfo.offset) url += `?offset=${noticeInfo.offset}`

            return axios.get(url, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
            }).then(response => {
                let _allNotice = state.notification
                let newNoticeArray = response.data
                newNoticeArray.forEach(item => {
                    if (!item.seen) {
                        this.state.newNotice = true
                    }
                })


                if (noticeInfo.offset && _allNotice) {
                    newNoticeArray.forEach(elem => {
                        _allNotice.push(elem)
                    })
                    commit('setNotification', _allNotice)
                } else {
                    commit('setNotification', newNoticeArray)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        readNotification({commit, state}, noticeId) {
            return axios.put(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/notifications`, {
                "values": [noticeId]
            }, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
            }).then(response => {
                this.dispatch('fetchNotifications', {offset: 0})
                this.dispatch('checkUnreadNotifications')
            }).catch(error => {
                console.log(error)
            })
        },
        readAllNotifications({commit, state}) {
            return axios.post(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/notifications/read_all`, {}, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
            }).then(response => {
                this.dispatch('fetchNotifications', {offset: 0})
                this.dispatch('checkUnreadNotifications')
            }).catch(error => {
                console.log(error)
            })
        },
        deleteAllNotifications({commit, state}) {
            return axios.post(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/notifications/delete_all`, {}, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
            }).then(response => {
                this.dispatch('fetchNotifications', {offset: 0})
                this.dispatch('checkUnreadNotifications')
            }).catch(error => {
                console.log(error)
            })
        },
        checkUnreadNotifications({commit, state}) {
            return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/notifications/exists/unread`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
            }).then(response => {
                this.commit('setNotReadNotification', response.data.exists)
            }).catch(error => {
                console.log(error)
            })
        },
        deleteReadNotifications({commit, state}) {
            let allNotification = this.state.notification
            let notificationsToDelete = []

            allNotification.filter(notification => {
                if (notification.seen) {
                    notificationsToDelete.push(notification.id)
                }
            })

            return axios.delete(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/notifications`, {
                data: {
                    "values": notificationsToDelete
                },
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
            }).then(response => {
                this.dispatch('fetchNotifications', {offset: 0})
                this.dispatch('checkUnreadNotifications')
            }).catch(error => {
                console.log(error)
            })
        }
    },
    modules: {
        allUsers,
        singleUser,
        lots,
        cats,
        docs,
        chats,
        rating,
        payments,
        admins,
        analytics,
        registry,
        counterparties,
        promocode,
        employee,
        security,
    }
});

export default store;