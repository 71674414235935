<template>
  <div class="users-body" v-if="loading">
    {{ isLoading }}
    <Loader/>
  </div>
  <div class="su-content su-about-content" v-else>
    <div class="su-about-info" v-if="getEmployee">
      <div class="su-body">
        <div class="su-body__block-wrap">
          <div class="su-body__block">
            <!-- ФИО -->
            <Row :isUpdate="fullName.isUpdate">
              <template v-slot:label>
                <p class="row__label">
                  ФИО
                </p>
              </template>
              <template v-slot:current-text>
                <p class="row__text">
                  {{ fullName.isUpdate ? fullName.textUpdated : fullName.textCurrent }}
                </p>
              </template>
              <template v-slot:old-text v-if="fullName.isUpdate">
                <p class="row__text">
                  {{ fullName.textCurrent }}
                </p>
              </template>
            </Row>
            <!-- Email -->
            <Row v-if="getEmployee.publicEmail" :isUpdate="getDataToUpdate.publicEmail">
              <template v-slot:label>
                <p class="row__label">
                  Email
                </p>
              </template>
              <template v-slot:current-text>
                <p class="row__text">
                  {{
                    getDataToUpdate.publicEmail ? getDataToUpdate.publicEmail : getEmployee.publicEmail
                  }}
                </p>
              </template>
              <template v-slot:old-text v-if="getDataToUpdate.publicEmail">
                <p class="row__text">
                  {{ getEmployee.publicEmail }}
                </p>
              </template>
            </Row>
            <!-- Должность -->
            <Row v-if="position" :isUpdate="position.isUpdate">
              <template v-slot:label>
                <p class="row__label">
                  Должность
                </p>
              </template>
              <template v-slot:current-text>
                <p class="row__text">
                  {{ position.isUpdate ? position.textUpdated : position.textCurrent }}
                </p>
              </template>
              <template v-slot:old-text v-if="position.isUpdate">
                <p class="row__text">
                  {{ position.textCurrent }}
                </p>
              </template>
            </Row>
            <!-- Дата добавления -->
            <Row>
              <template v-slot:label>
                <p class="row__label">
                  Дата добавления в сервис
                </p>
              </template>
              <template v-slot:current-text>
                <p class="row__text">
                  {{ Math.floor(new Date(+getEmployee.createdAt).getTime() / 1000.0) | moment("DD.MM.YYYY") }}
                </p>
              </template>
            </Row>
            <!-- Дата блокировки -->
            <Row v-if="getEmployee.isBlockedAt">
              <template v-slot:label>
                <p class="row__label">
                  Дата блокировки
                </p>
              </template>
              <template v-slot:current-text>
                <p class="row__text">
                  {{ Math.floor(new Date(+getEmployee.isBlockedAt).getTime() / 1000.0) | moment("DD.MM.YYYY") }}
                </p>
              </template>
            </Row>
          </div>

          <!-- ПРАВА СОТРУДНИКА -->
          <div class="su-body__block">
            <div class="row ai-center">
              <div class="row__string">
                <span class="c-employee__permission-span"
                      :class="{'active': getEmployee.employmentPermissions?.canSignDocs}">
                  Право подписи документов
                </span>
              </div>
            </div>
            <div class="row ai-center">
              <div class="row__string">
                <span class="c-employee__permission-span"
                      :class="{'active': getEmployee.employmentPermissions?.employeesAccess}">
                  Доступ к карточкам сотрудников
                </span>
              </div>
            </div>
            <div class="row ai-center" v-if="hasProxy">
              <div class="row__string">
                <div class="c-employee__counterparties">
                  <router-link :to="({ name: 'UserEmployeesProxy', params: { employeeId: getEmployee.id } })">
                    Доверенности
                  </router-link>
                </div>
              </div>
            </div>
            <div class="row ai-center">
              <div class="row__string">
                <span class="c-employee__permission-span"
                      :class="{'active': getEmployee.employmentPermissions?.documentsAccess}">
                  Доступ к документам должной осмотрительности
                </span>
              </div>
            </div>


            <!-- Другие документы -->
            <template v-for="(doc, idx) in getEmployeeDocuments">
              <Row :class="[0,1].includes(doc.updateStatus) ? 'ai-center jc-between green' : 'ai-center jc-between'"
                   :key="idx">
                <template v-slot:current-text>
                  <div class="row__text">
                    <a :href="doc.file" v-if="doc.isPublic"
                       target="_blank">
                      {{ documentTypesEnum[doc.documentType] }}
                    </a>
                    <div class="privateDoc" v-else @click="getPrivateDoc(getEmployee.id, doc.id)">
                      {{ documentTypesEnum[doc.documentType] }}
                    </div>
                    <span class="row__update" v-if="[0,1].includes(doc.updateStatus)">
                        Обновлено
                      </span>
                  </div>
                </template>
              </Row>
            </template>
          </div>

          <!-- КОНТРАГЕНТЫ -->
          <div class="su-body__block">
            <div class="employee__counterparties">
              <div class="employee__counterparties-info">
                <p>
                  Назначенные контрагенты
                </p>
                <span> {{ counterpartiesCount }} </span>
              </div>
              <router-link :to="({ name: 'UserEmployeesCounterparties', params: { employeeId: getEmployee.id } })"
                           class="btn employee__counterparties-btn">
                Просмотр
              </router-link>
            </div>
          </div>

        </div>
      </div>
      <template v-if="(getEmployee.isActive === false)">
        <div class="su-footer">
          <div class="su-footer__controlls">
            <Button @click="toUnblockEmployee(getEmployee.id)">Разблокировать</Button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="su-footer" v-if="getEmployee.deletionStatus === 3"></div>
        <template v-else>
          <div class="su-footer"
               v-if="(getEmployee.status === 0 || getEmployee.updateStatus === 1) && getEmployee.status !== 2">
            <div class="su-footer__block js-reason-block">
              <Row :class="'textarea'">
                <template v-slot:label>
                  <p class="row__label">
                    Причина отклонения
                  </p>
                </template>
                <template v-slot:current-text>
                  <p class="row__text js-reason-parag" contenteditable></p>
                </template>
              </Row>
              <span class="error-text">Укажите причину отказа</span>
            </div>
            <div class="su-footer__controlls" :class="{'form-send': isSend}">
              <Button @click="toPublishEmployee(getEmployee.id)">Опубликовать</Button>
              <Button @click="toCancelEmployee(getEmployee.id)" :class="'btn--red'">Отклонить</Button>
              <Button @click="toBlockEmployee(getEmployee.id)" :class="'btn--black'">Заблокировать</Button>
            </div>
          </div>

          <div class="su-footer" v-else-if="getEmployee.status === 1 && getEmployee.updateStatus !== 1">
            <div class="su-footer__controlls">
              <Button @click="toBlockEmployee(getEmployee.id)" :class="'btn--black'">Заблокировать</Button>
            </div>
          </div>

          <div class="su-footer" v-else-if="getEmployee.status === 2">
            <div class="su-footer__block">
              <Row :class="'textarea'">
                <template v-slot:label>
                  <p class="row__label">
                    Причина отклонения
                  </p>
                </template>
                <template v-slot:current-text>
                  <p class="row__text" v-html="getEmployee.moderationMessage"></p>
                </template>
              </Row>
            </div>
            <div class="su-footer__controlls">
              <Button @click="toBlockEmployee(getEmployee.id)" :class="'btn--black'">Заблокировать</Button>
            </div>
          </div>
        </template>
      </template>
    </div>
    <div class="su-empty su-about-empty" v-else>
      <EmptyBlock/>
    </div>
  </div>
</template>

<script>
import API from "@/services/API";
import axios from 'axios'
import Loader from '@/components/Loader.vue'
import EmptyBlock from '@/components/Empty.vue'
import Row from '@/components/UI/SingleDescrRow.vue'
import Button from '@/components/UI/Button.vue'
import {documentTypesEnum, updateStatusesEnum, userStatusesEnum} from "@/enums";

export default {
  name: 'UserEmployeesListView',
  components: {
    Loader,
    EmptyBlock,
    Row,
    Button,
  },
  data() {
    return {
      loading: true,
      isSend: false,
      fullName: null,
      position: null,
      counterpartiesCount: null,
    }
  },
  created() {
    let employeeId = this.$route.params.employeeId
    this.$store.dispatch('fetchEmployee', employeeId)
    this.$store.dispatch('fetchEmployeeDocuments', employeeId)
  },
  computed: {
    documentTypesEnum() {
      return documentTypesEnum
    },
    isLoading() {
      this.getEmployee
    },
    getEmployeeDocuments() {
      let docs = this.$store.getters.getEmployeeDocuments
      if (!docs) return []
      return docs.filter(doc => doc.documentType !== 'PROXY')
    },
    getEmployee() {
      let employee = this.$store.getters.getSingleEmployee
      if (employee) {
        this.loading = false
        this.getFullName(employee)
        this.getPosition(employee)
        this.getCounterparties(employee)
        this.$store.dispatch('pageTitle', `${employee.supervisor?.name} - ${this.fullName.textCurrent} - ${this.position.textCurrent}`)
      }
      return employee
    },
    getDataToUpdate() {
      return this.$store.getters.getSingleEmployee.dataToUpdate || {}
    },
    documentsList() {
      return this.$store.getters.getEmployeeDocuments
    },
    hasProxy() {
      if (!this.getEmployee) return
      let docs = this.$store.getters.getEmployeeDocuments
      if (!docs) return false
      let proxies = docs.filter(doc => doc.documentType === 'PROXY')
      return proxies && !!proxies.length
    }
  },
  methods: {
    getFullName(employee) {
      let firstName = employee.firstName
      let middleName = employee.middleName
      let lastName = employee.lastName

      if (this.getDataToUpdate.firstName || this.getDataToUpdate.middleName || this.getDataToUpdate.lastName) {
        this.fullName = {
          isUpdate: true,
          textUpdated: `${this.getDataToUpdate.lastName || lastName} ${this.getDataToUpdate.firstName || firstName} ${this.getDataToUpdate.middleName || middleName}`,
          textCurrent: `${lastName} ${firstName} ${middleName}`
        }
      } else {
        this.fullName = {
          isUpdate: false,
          textUpdated: '',
          textCurrent: `${lastName} ${firstName} ${middleName}`
        }
      }
    },
    getPosition(employee) {
      if (this.getDataToUpdate.position) {
        this.position = {
          isUpdate: true,
          textUpdated: this.getDataToUpdate.position,
          textCurrent: employee.position
        }
      } else {
        this.position = {
          isUpdate: false,
          textUpdated: '',
          textCurrent: employee.position
        }
      }
    },
    getCounterparties(employee) {
      let counterpartiesText = API.numWords(
          employee.counterpartiesCount,
          [' контрагент', ' контрагента', ' контрагентов']
      )
      this.counterpartiesCount = `${employee.counterpartiesCount} ${counterpartiesText}`
    },
    async toPublishEmployee(id) {
      let employeeUpdateStatus = this.getEmployee.updateStatus
      let employeeInfo = {
        id: id,
        moderationMessage: null
      }

      if (employeeUpdateStatus === updateStatusesEnum.UPDATE_INTENDED) {
        employeeInfo.updateStatus = updateStatusesEnum.APPROVED
      } else {
        employeeInfo.status = userStatusesEnum.APPROVED
      }

      this.isSend = true
      await this.$store.dispatch('editEmployee', employeeInfo)
      this.isSend = false
    },
    async toCancelEmployee(id) {
      const reasonElem = document.querySelector(`.js-reason-parag`)
      const parentBlock = reasonElem.closest('.js-reason-block')
      let reasonTxt = reasonElem.innerHTML.trim().replaceAll('&nbsp;', '');

      if (!reasonTxt.length) {
        parentBlock.classList.add('show-error')
        return
      }

      let employeeUpdateStatus = this.getEmployee.updateStatus
      let employeeInfo = {
        id: id,
        moderationMessage: reasonTxt
      }

      if (!reasonTxt.length) return parentBlock.classList.add('show-error')

      if (employeeUpdateStatus === 1) {
        employeeInfo.updateStatus = 3
      } else {
        employeeInfo.status = 2
      }

      parentBlock.classList.remove('show-error')
      this.isSend = true
      this.$store.dispatch('editEmployee', employeeInfo)
      this.isSend = false
    },
    toBlockEmployee(id) {
      this.$store.dispatch('blockEmployee', id)
    },
    toUnblockEmployee(id) {
      this.$store.dispatch('unblockEmployee', id)
    },
    async getPrivateDoc(userId, docId) {
      await axios.get(
          `${this.$store.state.API_HOST}/api/${this.$store.state.API_VERSION}/admin/employees/${userId}/documents/${docId}`, {
            headers: {
              Authorization: `JWT ${this.$store.state.API_TOKEN}`
            },
          }).then(response => {
        let url = response.data.url
        window.open(url, '_blank');
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.su-body__block {
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem 2.4rem;
  padding: 2.6rem;

  + .su-body__block {
    border-top: 1px solid #F7F7FA;
  }

  > .row {
    flex: 1 0 100%;
    max-width: calc(50% - 1.2rem);
  }

  .row + .row,
  .row__string + .row__string {
    margin-top: 0;
  }
}

.su-about-info {
  max-width: 120rem;
  margin: 3.2rem auto;
}

.su-about-info .su-body > .su-body__block-wrap {
  max-width: 100%;
}

.c-employee__permission-span {
  font-size: 1.6rem;
  color: #191B1D;
}

.c-employee__counterparties {
  padding: 0;
  border: 0;
}

.privateDoc {
  cursor: pointer;
}

.employee {
  &__counterparties {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem 2.4rem;
    background-color: #f7f7fa;
    border-radius: 3rem;
  }

  &__counterparties-info {
    font-size: 1.6rem;

    span {
      font-size: 1.3rem;
      color: #8A8C96;
    }
  }

  &__counterparties-btn {
    height: 4.4rem;
    max-width: calc(50% - 1.2rem);
  }

}
</style>