<template>
	<div class="table__th">
		<span class="table__th-sort" v-if="tHeader.isSorting">
			<img src="@/assets/images/icons/sort.svg" alt="sort" class="js-tooltip-icon"
				:data-id="'tooltip-id-' + tHeader.sorting.sortingTabs[0].sortingName">
		</span>
		<span class="table__th-sort" v-if="tHeader.isFilter">
			<img src="@/assets/images/icons/filter.svg" alt="filter" class="js-tooltip-icon"
				:data-id="'tooltip-id-' + tHeader.filters.filtersName">
		</span>
		{{tHeader.title}}
		<Sorting :style="tooltipElemCoord" :sorting="tHeader.sorting" v-if="tHeader.isSorting"
			@addOrdering="addOrdering" />
		<Filtration :style="tooltipElemCoord" :filters="tHeader.filters" v-if="tHeader.isFilter"
			@addFiltration="addFiltration" />

	</div>
</template>

<script>
	import Sorting from '@/components/Controlls/Sorting.vue'
	import Filtration from '@/components/Controlls/Filtration.vue'

	export default {
		components: {
			Sorting,
			Filtration
		},
		props: {
			tHeader: {
				type: Object,
				require: true
			},
		},
		data() {
			return {
				showSorting: false,
				tooltipElemCoord: {
					top: '5rem',
					left: '',
					right: ''
				},
			}
		},
		methods: {
			addOrdering(data) {
				let queryOption = this.$store.state.registry.queryObject
				queryOption.offset = 0
				queryOption.ordering = data.query
				this.$store.dispatch('getMediaProxies', queryOption)
			},
			addFiltration(data) {
				let queryOption = this.$store.state.registry.queryObject
				queryOption.offset = 0
				queryOption.filters = data.query
				this.$store.dispatch('getMediaProxies', queryOption)
			}
		}
	}
</script>