<template>
    <div class="su-view su-counterparties">
        {{isLoading}}
        <div class="users-body" v-if="loading">
            <Loader />
        </div>
        <div class="users-body" v-else>
            <Search @search='onSearch' />
            <p class="counterparties__count">
                Всего: {{getEmployeeCounterparties.length}}
            </p>
            <CounterpartiesTable :userList="getEmployeeCounterparties" v-if="getEmployeeCounterparties" />
        </div>
    </div>
</template>

<script>
    import Loader from '@/components/Loader.vue'
    import EmptyBlock from '@/components/Empty.vue'
    import SubTitle from '@/components/UI/SubTitle.vue'
    import Search from '@/components/Controlls/Search.vue'
    import CounterpartiesTable from '@/components/CounterpartiesTable/Table.vue'

    export default {
        name: 'GoodsView',
        components: {
            Loader,
            EmptyBlock,
            SubTitle,
            Search,
            CounterpartiesTable,
        },
        data() {
            return {
                isSend: false,
                loading: true,
                queryOption: {
                    employeeId: this.$route.params.employeeId,
                    searchTxt: '',
                    ordering: {},
                    filter: ''
                }
            }
        },
        mounted() {
            this.$store.dispatch('uploadEmployeesCounterparties', this.queryOption)
		},
       
        computed: {
            isLoading() {
                this.getEmployeeCounterparties
            },
            getEmployeeCounterparties() {
                let employeeCounterparties = this.$store.getters.getEmployeeCounterparties
                if (employeeCounterparties) {
                    this.loading = false
                    // this.$store.dispatch('pageTitle', 'Контрагенты #123')
                }
                return employeeCounterparties
            },
        },
        methods: {
            onSearch(searchTxt) {
                this.queryOption.searchTxt = searchTxt
                this.queryOption.offset = 0
                this.$store.dispatch('uploadEmployeesCounterparties', this.queryOption)
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>