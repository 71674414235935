<template>
  <div class="wrapper wrapper--guest">
    <Header :userType="'guest'" />
    <div class="content ">
      <router-view />
    </div>

    <transition name="fadeIn">
      <div class="popup" v-if="getError" @click="closeError">
        <div class="popup__window" @click.stop="">
          <span class="popup__close" @click="closeError">
            <img src="@/assets/images/icons/close.svg" alt="close">
          </span>
          <div class="popup__inner">
            <div class="popup__title">
              {{getError.errorText}}
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

</template>

<script>
  import Header from '@/components/Header.vue'

  export default {
    components: {
      Header,
    },
    computed: {
      getError() {
        if (this.$store.state.errorText) {
          let errorInfo = {
            showError: this.$store.state.errorText ? true : false,
            errorText: this.$store.getters.getErrorText
          }
          return errorInfo
        }
      }
    },
    methods: {
      closeError() {
        this.$store.state.errorText = null
        let f = document.querySelector('.form-send')
        f.classList.remove('form-send')
      }
    }
  };
</script>

