<template>

  <div class="su-view" v-if="getSingleCat">
    <SubTitle :title="getSingleCat.title" :backBtn="true"/>
    <div class="f-block__wrap">
      <div class="f-block">
        <div class="f-block__inner">
          <div class="f-block__header">
            <div class="f-block__row">
              <div class="f-block__category">
                <p>
                  {{ getSingleCat.title }}  <span class="f-block__volume">{{ getSingleCat.volumeMeasurement.title }}</span>
                </p>
               
              </div>
              <div class="f-block__delete" @click="openPopup">
                Удалить всю категорию
              </div>
            </div>
            <ul>
              <li class="f-block__row" v-for="subcat, idx in getSingleCat.subCategories" :key="idx">
                <div class="f-block__product">
                  <p>
                    <span @click="deleteSubcat(subcat.id)"> &times; </span> {{ subcat.title }}
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <div class="f-block__body">
            <form class="form-subcategory" @submit.prevent="addSubcat">
              <p class="form__input-title">
                Новая подкатегория
              </p>
              <div class="form__item" :class="{ 'form__item--error': $v.subcategory.$error}">

                <input class="form__input" id="subcategory" v-model.lazy="subcategory"
                  :class="{error: $v.subcategory.$error}" type="text" @change="$v.subcategory.$touch()"
                  placeholder="Новая подкатегория">
                <label for="subcategory" class="form__label"><span>Новая подкатегория</span></label>
                <p class="form__item-error" v-if="!$v.subcategory.required">
                  Обязательное поле
                </p>
              </div>
              <div class="form__controlls" :class="{'form-send': isSend}">
                <Button>
                  Добавить
                </Button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>

    <transition name="fadeIn">
      <div class="popup" v-if="showPopup" @click="closePopup">
        <div class="popup__window" @click.stop="">
          <!-- <span class="popup__close" @click="closePopup">
            <img src="@/assets/images/icons/close.svg" alt="close">
          </span> -->
           <div class="popup__inner">
          <div class="popup__title">
            Вы уверены что хотите удалить категорию?
          </div>
          <div class="popup__controlls">
            <Button :class="'btn--red'" @click="deleteCat">Удалить</Button>
            <Button :class="'btn--grey'" @click="closePopup">Отмена</Button>
          </div>
          </div>
        </div>
      </div>
    </transition>
  </div>


</template>

<script>
  import {
    required
  } from 'vuelidate/lib/validators'
  import SubTitle from '@/components/UI/SubTitle.vue'
  import Input from '@/components/UI/Input.vue'
  import Button from '@/components/UI/Button.vue'

  export default {
    components: {
      SubTitle,
      Input,
      Button,
    },
    data() {
      return {
        subcategory: '',
        isSend: false,
        showPopup: false,

      }
    },
    validations: {
      subcategory: {
        required,
      },
    },
    created() {
      let catId = this.$route.params.catId
      this.$store.dispatch('uploadSingleCat', {catId})
    },
    computed: {
      getSingleCat() {
        let singleCat = this.$store.getters.getSingleCat
        if (singleCat) {
          return singleCat
        }
      },
    },
    methods: {
      async addSubcat() {
        this.$v.subcategory.$touch()
        if (!this.$v.subcategory.$invalid) {
          let catInfo = {
            catId: this.$route.params.catId,
            subcatTitle: this.subcategory
          }
          await this.$store.dispatch('addSubcat', catInfo)
          this.isSend = false
          this.subcategory = ''
          this.$v.subcategory.$reset()
        }
      },
      deleteCat() {
        let catId = this.$route.params.catId
        this.$store.dispatch('deleteCat', catId)
      },
      async deleteSubcat(subcatId) {
        let subcatInfo = { catId: this.$route.params.catId, subcatId: subcatId }
        await this.$store.dispatch('deleteSubcat', subcatInfo)
      },
        openPopup(event) {
        this.showPopup = true
      },
      closePopup(event) {
        this.showPopup = false
      },
    }

  }
</script>

<style lang="scss" scoped>

</style>