<template>
         <router-link  class="notice-card" :class="notice.seen ? 'notice-card--seen' : ''"
            :to="{name: getNoticeStatus.link, params: getNoticeStatus.params }"
        > 
        <span class="notice-card__title">
            <span>
                {{ getNoticeStatus.text }}
            </span>
            <img src="@/assets/images/icons/arrow-right.svg" alt="arrow-right">
        </span>
        <span class="notice-card__text">
            {{notice.title}}
        </span>
    </router-link>
</template>

<script>
    export default {
        props: {
            notice: {
                type: Object,
                require: true
            }
        },
        computed: {
            getNoticeStatus() {
                let notice = this.notice.data

                switch (notice.type) {
                    case '8':
                          return {
                            text: 'Новый пользователь на модерацию',
                            link: notice.isEmployee ? 'UserEmployeesItem' : 'User',
                            params: notice.isEmployee ? { id: notice.supervisor, employeeId: notice.id } : { id: notice.id }
                        }
                    case '9':
                          return {
                            text: 'Новый отзыв на модерацию',
                            link: 'Ratings',
                            params: {}
                        }
                    case '10':
                          return {
                            text: 'Возникновение спорного платежа',
                            link: 'Payments',
                            params: {}
                        }
                    case '11':
                          return {
                            text: 'Новый запрос на удаление',
                            link: 'UsersForDelete',
                            params: {}
                        }
                    case '12':
                        return {
                            text: `Новый лот на модерацию`,
                            link: 'LotSingle',
                            params: { lot: notice.id }
                        }
                    default: 
                        return {
                            text: notice.type,
                            link: 'Users',
                            params: {}
                        }
                }
            }
        },
    }
</script>

