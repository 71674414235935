<template>
  <div class="table">
    <div class="table__header">
      <TableHeaderCell v-for="(tHeader, idx) in tableHeaders" :key="idx" :tHeader="tHeader" :index="idx" />
    </div>
    <div class="table__body" v-if="proxyList.length">
      <TableBodyRow v-for="(item, idx) in proxyList" :key="idx" :item="item" />
    </div>
    <EmptyBlock v-else />
  </div>
</template>


<script>
  import EmptyBlock from '@/components/Empty.vue'
  import tableHeaders from '@/data/EmployeeProxyTabs'
  import TableHeaderCell from '@/components/EmployeeProxyTable/TableHeaderCell.vue'
  import TableBodyRow from '@/components/EmployeeProxyTable/TableBodyRow.vue'

  export default {
    components: {
      EmptyBlock,
      TableHeaderCell,
      TableBodyRow,
    },
    props: {
      proxyList: {
        type: Array,
        require: true
      }
    },
    computed: {
      tableHeaders() {
        return tableHeaders
      }
    },
  }
</script>

<style lang="scss" scoped>

  .table__th {
    align-items: center;
    padding: 7rem 3.2rem;

    &:nth-of-type(1) {
      justify-content: flex-start;
      width: 18rem;
    }

    &:nth-of-type(2) {
      width: 16rem;
    }

    &:nth-of-type(3) {
      width: 28rem;
    }

    &:nth-of-type(4),
    &:nth-of-type(5) {
      width: 26rem;
    }

    &:nth-of-type(6) {
      width: 42rem;
    }
  }
</style>