const UserDeleteTabs = [{
    title: 'Пользователь',
    isSorting: false,
    isFilter: false,
    sorting: {
      sortingTabs: [{
        sortingName: 'users',
        sortingAsc: 'name',
        sortingDesc: '-name',
        sortingAscTitle: 'Сортировка “От А до Я”',
        sortingDescTitle: 'Сортировка “От Я до А”'
      }, ],
    }
  },
  {
    title: 'Статус',
    isSorting: false,
    isFilter: true,
    filters: {
      filtersName: 'status',
      filtersTabs: [
        { 
          filterTabTitle: 'Все пользователи',
          filterTabRequest: ''
        },
        { 
          filterTabTitle: 'На проверке',
          filterTabRequest: 'deletion_status=1'
        },
        { 
          filterTabTitle: 'Отклонен',
          filterTabRequest: 'deletion_status=2'
        },
        { 
          filterTabTitle: 'Удален',
          filterTabRequest: 'deletion_status=3'
        },
      ]
    }
  },
]

export default UserDeleteTabs