<template>
    <div class="subtitle">
        <router-link :to="backLinkUrl" class="subtitle__link" v-if="backLink">
            <img src="./../../assets/images/icons/arrow-left.svg" alt="arrow-left">
        </router-link>
        <button class="subtitle__link" v-if="backBtn" @click="$router.back()">
            <img src="./../../assets/images/icons/arrow-left.svg" alt="arrow-left">
        </button>
        <p class="subtitle__text" v-html="title"></p>
        <span v-if="getStatus" class="su-about__status" :class="getStatus.class">
            {{getStatus.text}}
        </span>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                require: true
            },
            backLink: {
                type: Boolean,
                default: false
            },
            backLinkUrl: {
                type: Object,
                require: false
            },
            backBtn: {
                type: Boolean,
                default: false
            },
        },

        updated() {
            this.$nextTick(function () {
                this.getStatus
            })
        },
        computed: {
            getStatus() {
                if (this.$route.name != 'UserAbout' && this.$route.name != 'UserEmployeesItem') {
                    return
                }

                let itemIsActive, itemStatus, itemUpdateStatus, itemDeletionStatus

                if (this.$route.name == 'UserAbout' && this.$store.state.singleUser.user != null) {
                    itemIsActive = this.$store.state.singleUser.user.isActive
                    itemStatus = this.$store.state.singleUser.user.status
                    itemUpdateStatus = this.$store.state.singleUser.user.updateStatus
                    itemDeletionStatus = this.$store.state.singleUser.user.deletionStatus
                }

                if (this.$route.name == 'UserEmployeesItem' && this.$store.state.employee.singleEmployee != null) {
                    itemIsActive = this.$store.state.employee.singleEmployee.isActive
                    itemStatus = this.$store.state.employee.singleEmployee.status
                    itemUpdateStatus = this.$store.state.employee.singleEmployee.updateStatus
                    itemDeletionStatus = this.$store.state.employee.singleEmployee.deletionStatus
                }
               

                let status = {
                    0: {
                        text: 'На проверке',
                        class: 'su-about__status--new'
                    },
                    1: {
                        text: 'Подтвержденный',
                        class: 'su-about__status--active'
                    },
                    2: {
                        text: 'Отклоненный',
                        class: 'su-about__status--canceled'
                    },
                    3: {
                        text: 'Заблокированный',
                        class: 'su-about__status--closed'
                    },
                    4: {
                        text: 'Удален',
                        class: 'su-about__status--canceled'
                    },
                    5: {
                        text: 'Ограниченный',
                        class: 'su-about__status--restricted'
                    },
                }

                if (!itemIsActive) {
                    return status[3]
                }

                if (itemDeletionStatus == 3) {
                    return status[4]
                }

                if (itemStatus == 1) {
                    if (itemUpdateStatus == 0 || itemUpdateStatus == 2) {
                        return status[1]
                    }
                    if (itemUpdateStatus == 1) {
                        return status[0]

                    }
                    if (itemUpdateStatus == 3) {
                        return status[2]
                    }
                }
                if (itemStatus == 2) {
                        return status[5]
                }

                return status[itemStatus];
            }
        },
    }
</script>

<style lang="scss">
.subtitle__text {
    text-align: center;
    span {
        font-size: 1.4rem;
        font-weight: 400;
        color: #8A8C96;
    }
}
</style>