const employeeSorting = {
    sortingTabs: [
        {
            sortingName: 'employee',
            sortingAsc: 'created_at',
            sortingDesc: '-created_at',
            sortingAscTitle: 'По дате в прямом порядке',
            sortingDescTitle: 'По дате в обратном порядке'
        },
        {
            sortingName: 'employee',
            sortingAsc: 'fio',
            sortingDesc: '-fio',
            sortingAscTitle: 'ФИО от А до Я',
            sortingDescTitle: 'ФИО от Я до А'
        },
    ],
}

export default employeeSorting