<template>
    <div class="card-item c-payment js-masonry-item">
        <div class="js-masonry-content card-item__content">
            <div class="c-payment__header">
                <span class="c-payment__status" :class="getStatus.class">
                    {{getStatus.text}}
                </span>
                <div class="c-payment__descr">
                    <div class="c-payment__descr-price">
                        <p>
                            {{ getAmount }} ₽
                        </p>
                    </div>
                    <div class="c-payment__descr-time">
                        <p>
                            {{ Math.floor(new Date(+payment.createdAt).getTime()/1000.0) | moment("DD.MM.YYYY HH:mm") }}
                        </p>
                    </div>
                </div>
                <div class="c-payment__number">
                    <p>
                        Сделка № {{ payment.chat.number }}
                    </p>
                </div>
                <div class="c-payment__company">
                    <div class="c-payment__profile">
                        <router-link :to="({ name: 'User', params: { id: payment.payer.id } })"
                            class="c-payment__profile-avatar">
                            <img :src="getAvatar.payerAvatar" alt="avatar" class="avatar" v-if="getAvatar.payerAvatar">
                            <img src="@/assets/images/no_avatar.png" alt="avatar" class="avatar" v-else>
                        </router-link>
                        <div class="c-payment__info">
                            <div class="c-payment__profile-title">
                                <router-link :to="({ name: 'User', params: { id: payment.payer.id } })">
                                    {{ payment.payer.profile.name }}
                                </router-link>
                            </div>
                            <div class="c-payment__profile-role">
                                <p>
                                    Отправитель
                                </p>
                            </div>
                        </div>
                    </div>
                    <img src="@/assets/images/icons/way-arrow.svg" alt="way" class="c-payment__arrow">
                    <div class="c-payment__profile">
                        <router-link :to="({ name: 'User', params: { id: payment.payee.id } })"
                            class="c-payment__profile-avatar">
                            <img :src="getAvatar.payerAvatar" alt="avatar" class="avatar" v-if="getAvatar.payerAvatar">
                            <img src="@/assets/images/no_avatar.png" alt="avatar" class="avatar" v-else>
                        </router-link>
                        <div class="c-payment__info">
                            <div class="c-payment__profile-title">
                                <router-link :to="({ name: 'User', params: { id: payment.payee.id } })">
                                    {{ payment.payee.profile.name }}
                                </router-link>
                            </div>
                            <div class="c-payment__profile-role">
                                <p>
                                    Получатель
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="c-payment__body">
                <div class="c-payment__body-title" :class="{'opened': showInfo}" @click="showInfo = !showInfo">
                    <p>
                        Условия оплаты
                    </p>
                    <img src="@/assets/images/icons/arrow-down.svg" alt="arrow">

                </div>
                <div class="c-payment__terms" v-show="showInfo">
                    <div class="c-payment__terms-label">
                        <p>
                            Сумма оплаты
                        </p>
                    </div>
                    <div class="c-payment__terms-text">
                        <p>
                            {{ getAmount }} ₽
                        </p>
                    </div>
                    <div class="c-payment__terms-label">
                        <p>
                            Условия перечисления платежа получателю
                        </p>
                    </div>
                    <div class="c-payment__terms-text">
                        <p>
                            {{payment.transferConditions}}
                        </p>
                    </div>
                    <div class="c-payment__terms-label">
                        <p>
                            Способ подтверждения выполнения этих условий
                        </p>
                    </div>
                    <div class="c-payment__terms-text">
                        <p>
                            {{ payment.approvalMethod}}
                        </p>
                    </div>
                    <div class="c-payment__terms-label">
                        <p>
                            Дата и время выполнения условий
                        </p>
                    </div>
                    <div class="c-payment__terms-text">
                        <p>
                            {{ Math.floor(new Date(+payment.conditionsPerformanceDatetime).getTime()/1000.0) | moment("DD.MM.YYYY HH:mm") }}
                        </p>
                    </div>
                </div>

            </div>
            <div class="c-payment__footer">
                <div class="c-payment__controlls" v-if="payment.status == 4">
                    <Button @click="onExecutePayment(payment.id)" >Перечислить</Button>
                    <Button @click="onRefundPayment(payment.id)">Вернуть</Button>
                    <router-link :to="({ name: 'ChatSingle', params: { chatId: payment.chat.id, chatNumber: payment.chat.number } })" class="btn btn--grey">
                        Перейти в чат</router-link>
                </div>
                <div class="c-payment__controlls" v-else>
                    <router-link :to="({ name: 'ChatSingle', params: { chatId: payment.chat.id, chatNumber: payment.chat.number } })" class="btn btn--grey">
                        Перейти в чат</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import API from "@/services/API";
    import Button from "@/components/UI/Button.vue";

    export default {
        components: {
            Button
        },
        props: {
            payment: {
                type: Object,
                require: true
            }
        },
        data() {
            return {
                showInfo: false
            }
        },
        mounted() {
            API.resizeAllMasonryItems()
        },
        updated() {
            this.$nextTick(function () {
                API.resizeAllMasonryItems()
            })
        },
        computed: {
            getAvatar() {
                let payerAvatar = this.payment.payer.profile.avatar ? this.payment.payer.profile.avatar.file : false
                let payeeAvatar = this.payment.payee.profile.avatar ? this.payment.payee.profile.avatar.file : false

                return {
                    payerAvatar,
                    payeeAvatar
                }
            },
            getAmount() {
                return API.breakNumString(this.payment.amount)
            },
            getStatus() {
                let status = {
                    0: {
                        text: 'Ожидает подтверждения',
                        class: 'c-payment__status--waiting'
                    },
                    1: {
                        text: 'Ожидает принятия условий',
                        class: 'c-payment__status--waiting'
                    },
                    2: {
                        text: 'Ожидает выполнения условий',
                        class: 'c-lots__status--completed'
                    },
                    3: {
                        text: 'Условия выполнены',
                        class: 'c-payment__status--completed'
                    },
                    4: {
                        text: 'Спорный платеж',
                        class: 'c-payment__status--canceled'
                    },
                    5: {
                        text: 'Исполнен',
                        class: 'c-payment__status--completed'
                    },
                    6: {
                        text: 'Платеж возвращен',
                        class: 'c-payment__status--closed'
                    },
                }

                return status[this.payment.status];

            }
        },
        methods: {
            onExecutePayment(paymentId) {
                let _paymentsInfo = this.$store.state.payments.paymentsInfo
                _paymentsInfo.paymentId = paymentId

                this.$store.dispatch('executePayment', _paymentsInfo)
            },
            onRefundPayment(paymentId) {
                let _paymentsInfo = this.$store.state.payments.paymentsInfo
                _paymentsInfo.paymentId = paymentId
               
                this.$store.dispatch('refundPayment', _paymentsInfo)
            }
        },
    }
</script>

