import store from "./index";
import axios from 'axios'

export default {
    state: {
        userEmployees: null,
        userEmployeesPosition: null,
        singleEmployee: null,
        employeeProxies: null,
        employeeDocuments: null,
        queryObject: {
            searchTxt: '',
            ordering: '',
            filters: '',
            'employeeId': ''
        }
    },
    getters: {
        getUserEmployees(state) {
            return state.userEmployees
        },
        getUserEmployeesPosition(state) {
            return state.userEmployeesPosition
        },
        getSingleEmployee(state) {
            return state.singleEmployee
        },
        getEmployeeProxies(state) {
            return state.employeeProxies
        },
        getEmployeeDocuments(state) {
            return state.employeeDocuments
        },
    },
    mutations: {
        setEmployees(state, userEmployees) {
            state.userEmployees = userEmployees
        },
        setUserEmployeesPosition(state, userEmployeesPosition) {
            state.userEmployeesPosition = userEmployeesPosition
        },
        setSingleEmployee(state, employee) {
            state.singleEmployee = employee
        },
        setEmployeeProxies(state, proxies) {
            state.employeeProxies = proxies
        },
        setEmployeeDocuments(state, documents) {
            state.employeeDocuments = documents
        },
    },
    actions: {
        fetchUserEmployees({commit, state}, queryOption) {
            state.queryObject = queryOption
            let options = ''
            if (queryOption.can_sign_docs) options += `&can_sign_docs=true`
            if (queryOption.documents_access) options += `&documents_access=true`
            if (queryOption.employees_access) options += `&employees_access=true`
            if (queryOption.position) options += `&position=${queryOption.position}`
            if (queryOption.offset) options += queryOption.offset

            return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/${queryOption.userId}/employees?search=${queryOption.searchTxt}${options}`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                let _allEmployees = state.userEmployees
                if (queryOption.offset && _allEmployees) {
                    response.data.forEach(elem => {
                        _allEmployees.push(elem)
                    })
                    commit('setEmployees', _allEmployees)
                } else {
                    commit('setEmployees', response.data)
                }
            }).catch(error => console.log(error))
        },
        fetchUserEmployeesPosition({commit, state}, {userId}) {
            return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/employees/positions?organization=${userId}`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                commit('setUserEmployeesPosition', response.data)
            }).catch(error => console.log(error))
        },
        fetchEmployee({commit, state}, employeeId) {
            return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/employees/${employeeId}`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                commit('setSingleEmployee', response.data)
            }).catch(error => console.log(error))
        },
        fetchEmployeeDocuments({commit, state}, employeeId) {
            return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/employees/${employeeId}/documents`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                commit('setEmployeeDocuments', response.data)
            }).catch(error => console.log(error))
        },
        editEmployee({commit, state}, employeeInfo) {
            let body;
            let url;

            if (employeeInfo.status) {
                body = {
                    status: employeeInfo.status,
                    moderationMessage: employeeInfo.moderationMessage
                }
                url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/employees/${employeeInfo.id}`
            } else {
                body = {
                    updateStatus: employeeInfo.updateStatus,
                    moderationMessage: employeeInfo.moderationMessage
                }
                url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/employees/${employeeInfo.id}/updates`
            }

            return axios.put(url, body, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
            }).then(response => {
                this.dispatch('fetchEmployee', employeeInfo.id)
                this.dispatch('fetchEmployeeDocuments', employeeInfo.id)
            }).catch(error => {
                console.log(error)
            })
        },
        blockEmployee({commit, state}, employeeId) {
            let formData = new FormData();
            formData.append('isActive', false)

            return axios.post(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/employees/${employeeId}/block`, formData, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
            }).then(response => {
                this.dispatch('fetchEmployee', employeeId)
            }).catch(error => {
                console.log(error)
            })
        },
        unblockEmployee({commit, state}, employeeId) {
            let formData = new FormData();
            formData.append('isActive', true)

            return axios.post(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/employees/${employeeId}/unblock`, formData, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
            }).then(response => {
                this.dispatch('fetchEmployee', employeeId)
            }).catch(error => {
                console.log(error)
            })
        },
        fetchEmployeeProxies({commit, state}, queryOption) {
            state.queryObject = queryOption

            let url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/employees/${queryOption.employeeId}/documents/owned?search=${queryOption.searchTxt}&document_type=PROXY`
            if (queryOption.ordering) url += `&ordering=${queryOption.ordering}`
            if (queryOption.filters) url += `&${queryOption.filters}`
            if (queryOption.offset) url += queryOption.offset

            return axios.get(url, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                let _allProxies = state.employeeProxies
                if (queryOption.offset && _allProxies) {
                    response.data.forEach(elem => {
                        _allProxies.push(elem)
                    })
                    commit('setEmployeeProxies', _allProxies)
                } else {
                    commit('setEmployeeProxies', response.data)
                }
            }).catch(error => console.log(error))
        },
    }
}