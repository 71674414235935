<template>
  <div class="su-view su-documents">
    <template v-if="!isNewEditPages.isNew && !isNewEditPages.isEditing">
      <div class="add-template-wrap">
        <SubTitle :title="isNewEditPages.title" />
        <div class="add-template-block">
          <img src="@/assets/images/icons/dots.svg" alt="dots" class="js-tooltip-icon"
            :data-id="'tooltip-id-add-doctype'">
          <div class="add-template-tooltip" :style="tooltipElemCoord" id="tooltip-id-add-doctype">
            <Button @click="openAddDocType = true">Создать тип</Button>
          </div>
        </div>
      </div>
      <div class="duser__search-block">
        <Search @search='onSearch' />
        <span class="sort-block">
          <img src="@/assets/images/icons/sort.svg" alt="sort" data-id="tooltip-id-doc" class="js-tooltip-icon">
        </span>
        <Sorting :style="tooltipElemCoord" :sorting="docSorting" @addOrdering="addOrdering"/>
      </div>
      <Tabs :tabs="tabs" />
    </template>
    <template v-else>
      <SubTitle :title="isNewEditPages.title" :backBtn="true" />
    </template>

    <transition name="fadeIn">
      <div class="popup" v-if="openAddDocType" @click="openAddDocType = false">
        <div class="popup__window" @click.stop="">
          <span class="popup__close" @click="openAddDocType = false">
            <img src="@/assets/images/icons/close.svg" alt="close">
          </span>
          <div class="f-block__header">
            <div class="f-block__row">
              <div class="f-block__title">
                <p>
                  Создание типа шаблона
                </p>
              </div>
            </div>
            <ul>
              <li class="f-block__row" v-for="docType, idx in getDocTypes" :key="idx">
                <p>
                  <span @click="deleteDocType(docType.id)"> &times; </span> {{ docType.title }}
                </p>
              </li>
            </ul>
          </div>
          <form class="form-template" @submit.prevent="addDocType">
            <p class="form__input-title">
              Новый тип
            </p>
            <div class="form__item" :class="{ 'form__item--error': $v.docType.$error}">
              <input class="form__input" id="docType" v-model.lazy="docType" :class="{error: $v.docType.$error}"
                type="text" @change="$v.docType.$touch()" placeholder="Новый тип шаблона">
              <label for="docType" class="form__label"><span>Новый тип шаблона</span></label>
              <p class="form__item-error" v-if="!$v.docType.required">
                Обязательное поле
              </p>
            </div>
            <div class="form__controlls" :class="{'form-send': isSend}">
              <Button>
                Добавить
              </Button>
            </div>
          </form>
        </div>
      </div>
    </transition>

    <router-view></router-view>
  </div>
</template>

<script>
  import docSorting from '@/data/DocSorting'

  import {required} from 'vuelidate/lib/validators'
  import SubTitle from '@/components/UI/SubTitle.vue'
  import Search from '@/components/Controlls/Search.vue'
  import Tabs from '@/components/Controlls/Tabs.vue'
  import Button from '@/components/UI/Button.vue'
  import Sorting from '@/components/Controlls/Sorting.vue'

  export default {
    name: 'DocumentsView',
    components: {
      SubTitle,
      Search,
      Tabs,
      Button,
      Sorting,
    },
    data() {
      return {
        tabs: [{
            title: 'Шаблоны документов',
            url: 'DocsSignature'
          },
          {
            title: 'Юридическая информация',
            url: 'DocsInformation'
          },
          {
            title: 'Инструкции',
            url: 'DocsInstruction'
          },
        ],
        openAddDocType: false,
        docType: '',
        isSend: false,
        tooltipElemCoord: {
          top: '5rem',
          left: '',
          right: ''
        },
      }
    },
    validations: {
      docType: {
        required,
      },
    },
    created() {
      this.$store.dispatch('uploadDocsTags')
    },
    computed: {
      docSorting() {
        return docSorting
      },
      isNewEditPages() {
        if (this.$route.name == 'DocsNew') {
          return {
            isNew: true,
            title: 'Создание документа'
          }
        }

        if (this.$route.name == 'DocsEdit') {
          return {
            isEditing: true,
            title: 'Редактирование документа'
          }
        }

        return {
          isNew: false,
          isEditing: false,
          title: 'Документы'
        }
      },
      getDocTypes() {
        return this.$store.getters.getDocsTypes
      },
    },
    methods: {
      onSearch(searchTxt) {
        let _docsInfo = this.$store.state.docs.queryObject
        _docsInfo.searchTxt = searchTxt
        if (this.$route.name == 'DocsSignature') {
          this.$store.dispatch('uploadDocsSignature', _docsInfo)
        } else {
          this.$store.dispatch('uploadDocsInfo', _docsInfo)
        }
      },
      async deleteDocType(id) {
          await this.$store.dispatch('deleteDocType', id)
      },
      async addDocType() {
        this.$v.docType.$touch()
        if (!this.$v.docType.$invalid) {
          this.isSend = true
          await this.$store.dispatch('addDocType', this.docType)
          this.isSend = false
          this.docType = ''
          this.$v.docType.$reset()
        }
      },
      addOrdering(data) {
        let _docsInfo = this.$store.state.docs.queryObject

        _docsInfo.ordering = data.query
        if (this.$route.name == 'DocsSignature') {
          this.$store.dispatch('uploadDocsSignature', _docsInfo)
        } else {
          this.$store.dispatch('uploadDocsInfo', _docsInfo)
        }
      },
    }
  }
</script>
