<template>
  <div class="su-view su-ratings">
    <div class="users-body" v-if="loading">
      {{isLoading}}
      <Loader />
    </div>
    <div class="users-body" v-else>
      <div class="su-rating-info">
        <div class="cards-list c-company__list js-masonry">

          <div class="card-item c-rating js-masonry-item" v-if="getUser">
            <div class="card-item__content js-masonry-content">
              <div class="user-rating__title">
                <p>
                  Средний рейтинг пользователя
                </p>
              </div>
              <div class="user-rating__info">
                <div class="c-rating__row">
                  <div class="c-rating__stars" v-if="getUser.rating.avgDocumentProviding">
                    <i v-for="star, idx in 5" :key="idx"
                      :class="idx < getUser.rating.avgDocumentProviding ? 'active' : ''"></i>
                  </div>
                  <div class="c-rating__stars" v-else>
                    <i v-for="star, idx in 5" :key="idx"></i>
                  </div>
                  <div class="c-rating__stars-label">
                    Предоставление «закрывающих» документов
                  </div>
                </div>
                <div class="c-rating__row">
                  <div class="c-rating__stars" v-if="getUser.rating.avgDeliveryDeadlinesRespect">
                    <i v-for="star, idx in 5" :key="idx"
                      :class="idx < getUser.rating.avgDeliveryDeadlinesRespect ? 'active' : ''"></i>
                  </div>
                  <div class="c-rating__stars" v-else>
                    <i v-for="star, idx in 5" :key="idx"></i>
                  </div>
                  <div class="c-rating__stars-label">
                    Соблюдение сроков поставки товара/услуги
                  </div>
                </div>
                <div class="c-rating__row">
                  <div class="c-rating__stars" v-if="getUser.rating.avgProductQuality">
                    <i v-for="star, idx in 5" :key="idx"
                      :class="idx < getUser.rating.avgProductQuality ? 'active' : ''"></i>
                  </div>
                  <div class="c-rating__stars" v-else>
                    <i v-for="star, idx in 5" :key="idx"></i>
                  </div>
                  <div class="c-rating__stars-label">
                    Качество товара/услуги
                  </div>
                </div>
                <div class="c-rating__row">
                  <div class="c-rating__stars" v-if="getUser.rating.avgPaymentDeadlinesRespect">
                    <i v-for="star, idx in 5" :key="idx"
                      :class="idx < getUser.rating.avgPaymentDeadlinesRespect ? 'active' : ''"></i>
                  </div>
                  <div class="c-rating__stars" v-else>
                    <i v-for="star, idx in 5" :key="idx"></i>
                  </div>
                <div class="c-rating__stars-label">
                  Соблюдение сроков оплаты
                </div>
              </div>
            </div>
          </div>
        </div>

        <Card v-for="rating, idx in getUserRating" :key="idx" :rating="rating" />
      </div>
    </div>
    <!-- <div class="su-empty su-about-empty" v-else>
        <EmptyBlock />
      </div> -->
  </div>
  </div>

</template>

<script>
    import API from "@/services/API";
  import Search from '@/components/Controlls/Search.vue'
  import Loader from '@/components/Loader.vue'
  import EmptyBlock from '@/components/Empty.vue'
  import Card from '@/components/Cards/RatingCard.vue'

  export default {
    name: 'UserRatingsView',
    components: {
      Search,
      Loader,
      EmptyBlock,
      Card,
    },
    data() {
      return {
        loading: true,
      }
    },
    created() {
      let userId = this.$route.params.id
      this.$store.dispatch('pageTitle', 'Рейтинги и отзывы')
      this.$store.dispatch('uploadUserRatings', userId)
      this.$store.dispatch('fetchUser', userId)
    },
     mounted() {
            API.resizeAllMasonryItems()
        },
        updated() {
            this.$nextTick(function () {
                API.resizeAllMasonryItems()
            })
        },
    computed: {
      isLoading() {
        this.getUserRating
        this.getUser
      },
      getUserRating() {
        let ratingList = this.$store.getters.getUserRatings
        if (ratingList != null) {
          this.loading = false
        }
        return ratingList
      },
      getUser() {
        let getUser = this.$store.getters.getUser
        if (getUser != null) {
          this.loading = false
        }
        return getUser
      },
    }

  }
</script>

<style lang="scss" scoped>
  .cards-list {
    grid-template-columns: repeat(2, 1fr)
  }
  .user-rating {
    &__title {
      margin-bottom: 2.8rem;
      font-size: 1.6rem;
      font-weight: 500;
    }

  }
</style>