import store from "./index";
import axios from 'axios'

export default {
    state: {
        lots: null,
        lotsCount: null,
        lotsInfo: null,
        lotSingle: {},
    },
    getters: {
        getLots(state) {
            return state.lots
        },
        getLotsCount(state) {
            return state.lotsCount
        },
        getLotSingle(state) {
            return state.lotSingle
        },
    },
    mutations: {
        setLots(state, lots) {
            state.lots = lots
        },
        setLotsCount(state, lotsCount) {
            state.lotsCount = lotsCount
        },
        setLotsInfo(state, lotsInfo) {
            state.lotsInfo = lotsInfo
        },
        setLotSingle(state, lotSingle) {
            state.lotSingle = lotSingle
        },        
    },
    actions: {
        uploadLots({ commit, state }, lotsInfo) {
            state.lotsInfo = lotsInfo
            let options = lotsInfo.userId 
                ? `user=${lotsInfo.userId}&${lotsInfo.type}&${lotsInfo.status}&${lotsInfo.showOnlyAuct}`
                : `${lotsInfo.type}&${lotsInfo.status}&${lotsInfo.showOnlyAuct}`

            if (lotsInfo.offset) options += `${lotsInfo.offset}`

            return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/lots?search=${lotsInfo.search}&${options}`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                let _allLots = state.lots
                    if (lotsInfo.offset && _allLots) {
                        response.data.forEach(elem => {
                            _allLots.push(elem)
                        })
                        commit('setLots', _allLots)
                    } else {
                        commit('setLots', response.data)
                    }
            })
            .catch(error => {
                if (error.response && error.response.status == 401) {
                    this.dispatch('userLogout')
                } else {
                    commit('setErrorText', 'Ошибка соединения с сервером')
                }
            })
        },
        uploadLotsCount({ commit, state }, countInfo) {
            let url

            if (countInfo.userId) { 
                url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/lots/count?user=${countInfo.userId}&${countInfo.type}`
            } else {
                url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/lots/count?${countInfo.type}`
            }

            if(countInfo.showOnlyAuct) {
                url += `&${countInfo.showOnlyAuct}`
            }

            return axios.get( url , {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
                }).then(response => {
                    commit('setLotsCount', response.data)
                })
                .catch(error => console.log(error))
        },
        uploadLotSingle({ commit, state }, lotId ) {
            return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/lots/${lotId}`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                if( response.data.status == 1 && response.data.updateStatus == 1) {
                    axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/lots/update_intended/${lotId}`, {
                        headers: {
                            Authorization: `JWT ${store.state.API_TOKEN}`
                        }
                    }).then(response => {
                        commit('setLotSingle', response.data)
                    })
                } else {
                    commit('setLotSingle', response.data)
                }
            })
            .catch(error => console.log(error))
        },
        editLot({ commit, state }, lotInfo) {
            let body;
            let url;
            if (lotInfo.status) {
                body = {
                    status: lotInfo.status,
                    moderationMessage: lotInfo.moderationMessage
                }
                url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/lots/${lotInfo.id}`
            } else {
                body = {
                    updateStatus: lotInfo.updateStatus,
                    moderationMessage: lotInfo.moderationMessage
                }
                url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/lots/update_intended/${lotInfo.id}`

            }

            return axios.put(url, body, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
                }).then(response => {
                    this.dispatch('uploadLotSingle', lotInfo.id)
                })
                .catch(error => {
                    if ( error.response.status == 400 ) {
                        commit('setErrorText', 'Пользователь не активирован')
                    }
                })
        },
    },
}