import store from "./index";
import axios from 'axios'

export default {
    state: {
        allPromocode: null
    },
    getters: {
        getAllPromocode(state) {
            return state.allPromocode
        },
    },
    mutations: {
        setAllPromocode(state, allPromocode) {
            state.allPromocode = allPromocode
        },
    },
    actions: {
        uploadPromocode({commit,state}, queryOption = {searchTxt: ''}) {
            return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/promo/codes?search=${queryOption.searchTxt}`, {
                    headers: {
                        Authorization: `JWT ${store.state.API_TOKEN}`
                    }
                }).then(response => {
                    commit('setAllPromocode', response.data)
                })
                .catch(error => {
                    if (error.response && error.response.status == 401) {
                        this.dispatch('userLogout')
                    } else {
                        commit('setErrorText', 'Ошибка соединения с сервером')
                    }
                })
        },
        addPromocode({commit,state}, promocodeInfo) {
            return axios.post(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/promo/codes`, promocodeInfo, {
                    headers: {
                        Authorization: `JWT ${store.state.API_TOKEN}`
                    }
                }).then(response => {
                    this.dispatch('uploadPromocode')
                })
                .catch(error => {
                    if (error.response) {
                        commit('setErrorText', error.response.request.responseText)
                    }
                })
        },
        deletePromocode({commit,state}, promocodeId) {
            return axios.delete(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/promo/codes/${promocodeId}`, {
                    headers: {
                        Authorization: `JWT ${store.state.API_TOKEN}`
                    }
                }).then(response => {
                    this.dispatch('uploadPromocode')
                })
                .catch(error => console.log(error))
        },
        autoPromocode() {
            return axios.post(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/promo/codes/auto`, {}, {
                    headers: {
                        Authorization: `JWT ${store.state.API_TOKEN}`
                    }
                }).then(response => {
                    return response.data.value
                })
                .catch(error => console.log(error))
        }

    }

}