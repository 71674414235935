<template>
  <div class="dusers">
    <div class="dusers-header">
      <SubTitle :title="'Запросы на удаление'" :backLink="true" :backLinkUrl="{ name: 'UsersList' }"/>
      <div class="duser__search-block">
        <Search @search='onSearch'/>
        <span class="sort-block">
          <img src="@/assets/images/icons/sort.svg" alt="sort" class="js-tooltip-icon"
               :data-id="'tooltip-id-' + forDelSorting.sortingTabs[0].sortingName">
        </span>

        <Sorting :style="tooltipElemCoord" :sorting="forDelSorting" @addOrdering="addOrdering"/>
      </div>
    </div>
    {{ isLoading }}
    <div class="dusers-body" v-if="loading">
      <Loader/>
    </div>

    <div class="dusers-body" v-else>
      <DeleteTable :userList="userForDelete" v-if="userForDelete"/>
      <EmptyBlock v-else/>
    </div>
  </div>
</template>

<script>
import EmptyBlock from '@/components/Empty.vue'
import Loader from '@/components/Loader.vue'
import ForDelSorting from '@/data/DelSorting'

import SubTitle from '@/components/UI/SubTitle.vue'
import Search from '@/components/Controlls/Search.vue'
import Button from '@/components/UI/Button.vue'
import DeleteTable from '@/components/DeleteTable/Table.vue'
import Sorting from '@/components/Controlls/Sorting.vue'

export default {
  name: 'UserForDelete',
  components: {
    EmptyBlock,
    Loader,
    SubTitle,
    Search,
    Button,
    DeleteTable,
    Sorting
  },
  data() {
    return {
      loading: true,
      tooltipElemCoord: {
        top: '5rem',
        left: '',
        right: ''
      },
    }
  },
  created() {
    let queryOption = {searchTxt: '', ordering: {}, filters: {}}
    this.$store.dispatch('fetchUsersToDelete', queryOption)
  },
  mounted() {
    this.$store.state.allUsers.queryObject = {searchTxt: '', ordering: {}, filters: {}}
  },
  computed: {
    isLoading() {
      this.userForDelete
    },
    forDelSorting() {
      return ForDelSorting
    },
    userForDelete() {
      let userList = this.$store.getters.getForDeleteUsers
      if (userList && userList.length) {
        this.loading = false
      }
      return userList
    },

  },
  methods: {
    onSearch(searchTxt) {
      let queryOption = this.$store.state.allUsers.queryObject
      queryOption.searchTxt = searchTxt
      queryOption.offset = null
      this.$store.dispatch('fetchUsersToDelete', queryOption)
    },
    addOrdering(data) {
      let queryOption = this.$store.state.allUsers.queryObject
      queryOption.ordering[data.queryGroup] = data.query
      this.$store.dispatch('fetchUsersToDelete', queryOption)
    },
  }

}
</script>

<style lang="scss">
.dusers-header {
  margin-bottom: 3.2rem;

  .search__btn {
    right: 7rem;
  }
}

.duser {
  &__search-block {
    position: relative;

    .search__input {
      padding-right: 6rem;
    }

    .sort-block {
      position: absolute;
      right: 1rem;
      top: 1rem;
      width: 5.2rem;
      height: 5.2rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-left: .1rem solid #B8BABE;

      img {
        width: 2.4rem;
        cursor: pointer;
      }
    }
  }

}
</style>