<template>
  <div class="navbar">
    <div class="navbar__logo">
      <img src="@/assets/images/Logo.png" alt="">
    </div>
    <nav class="nav">
      <ul class="nav__list">
        <li class="nav__item">
          <router-link :to="{ name: 'UsersList' }" class="nav__link">
            <img src="@/assets/images/icons/arrow-down.svg" alt="arrow-down">
            Пользователи
          </router-link>
          <router-link :to="{ name: 'UsersForDelete' }" class="nav__btn">
            Запросы на удаление
          </router-link>
        </li>
<!--        <li v-if="isFullAccess" class="nav__item">-->
<!--          &lt;!&ndash; SUPERADMIN&ndash;&gt;-->
<!--          <router-link :to="{ name: 'Category' }" class="nav__link">-->
<!--            Товары и услуги сервиса-->
<!--          </router-link>-->
<!--        </li>-->
        <!-- SUPERADMIN-->
<!--        <li v-if="isFullAccess" class="nav__item">-->
<!--          <router-link :to="{ name: 'Lots' }" class="nav__link">-->
<!--            Лоты пользователей-->
<!--          </router-link>-->
<!--        </li>-->
        <!-- SUPERADMIN-->
        <li v-if="isFullAccess" class="nav__item">
          <router-link :to="{ name: 'Chats' }" class="nav__link">
            Чаты
          </router-link>
        </li>
        <!--                <li class="nav__item">-->
        <!--                    <router-link :to="{ name: 'Payments' }" class="nav__link">-->
        <!--                        Платежи-->
        <!--                    </router-link>-->
        <!--                </li>-->
        <!--                <li class="nav__item">-->
        <!--                    <router-link :to="{ name: 'Analytics' }" class="nav__link">-->
        <!--                        Аналитика-->
        <!--                    </router-link>-->
        <!--                </li>-->

        <!-- SUPERADMIN-->
        <li v-if="isFullAccess" class="nav__item">
          <router-link :to="{ name: 'Documents' }" class="nav__link">
            Документы
          </router-link>
        </li>

        <!--                <li class="nav__item">-->
        <!--                    <router-link :to="{ name: 'Ratings' }" class="nav__link">-->
        <!--                        Рейтинги и отзывы-->
        <!--                    </router-link>-->
        <!--                </li>-->
        <!--                <li class="nav__item">-->
        <!--                    <router-link :to="{ name: 'Goods' }" class="nav__link">-->
        <!--                        Товары из лотов-->
        <!--                    </router-link>-->
        <!--                </li>-->
        <li class="nav__item">
          <router-link :to="{ name: 'Registry' }" class="nav__link">
            <img src="@/assets/images/icons/arrow-down.svg" alt="arrow-down">
            Реестр подписанных файлов
          </router-link>
          <ul class="nav__submenu">
            <li class="nav__item">
              <router-link :to="{ name: 'RegistryProxy' }" class="nav__btn">
                Доверенности
              </router-link>
            </li>
            <li v-if="isFullAccess" class="nav__item">
              <router-link :to="{ name: 'RegistryPhoto' }" class="nav__btn">
                Фото
              </router-link>
            </li>
            <li v-if="isFullAccess" class="nav__item">
              <router-link :to="{ name: 'RegistryVideo' }" class="nav__btn">
                Видео
              </router-link>
            </li>
            <li v-if="isFullAccess" class="nav__item">
              <router-link :to="{ name: 'RegistryDocs' }" class="nav__btn">
                Документы
              </router-link>
            </li>
            <!-- <li class="nav__item">
               <router-link :to="{ name: 'RegistryPayment' }" class="nav__btn">
                   Условия оплаты
               </router-link>
           </li> -->
            <!--                         <li class="nav__item">-->
            <!--                            <router-link :to="{ name: 'RegistryDeals' }" class="nav__btn">-->
            <!--                                Условия сделки-->
            <!--                            </router-link>-->
            <!--                        </li>-->
          </ul>
        </li>
        <!-- SUPERADMIN-->
        <li v-if="isFullAccess" class="nav__item">
          <router-link :to="{ name: 'Promocode' }" class="nav__link">
            Промокод
          </router-link>
        </li>
        <!-- SUPERADMIN-->
        <li v-if="isFullAccess" class="nav__item">
          <router-link :to="{ name: 'Security' }" class="nav__link">
            Контроль СБ
          </router-link>
        </li>
      </ul>
    </nav>
    <!--        <div class="help-block">-->
    <!--            <a href="https://app.intercom.com/a/apps/xqts1e7b/home" class="help__link" target="_blank">-->
    <!--                <img src="@/assets/images/icons/help-chat.svg" alt="help-chat">-->
    <!--                Чат поддержки-->
    <!--            </a>-->
    <!--        </div>-->
  </div>
</template>

<script>
export default {
  computed: {
    isFullAccess() {
      return this.$store.getters.getFullAccess
    }
  },
  methods: {
    goToDeleteUsers() {
      this.$router.push({
        name: 'UsersForDelete'
      });
    }
  }
}
</script>