import store from "./index";
import axios from 'axios'

export default {
    state: {
        registryFiles: null,
		proxies: null,
        queryObject: { searchTxt: '', ordering: '', filters: '', fileType:''},
    },
    getters: {
       getRigistryFiles(state) {
        return state.registryFiles
       },
       getProxies(state) {
        return state.proxies
    },
    },
    mutations: {
        setRigistryFiles(state, files) {
            state.registryFiles = files
        },
        setProxies(state, proxies) {
			state.proxies = proxies
		},
    },
    actions: {
        getRegistryFiles({ commit, state }, queryOption ) {
            state.queryObject = queryOption
            
            let url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/documents/signed?exclude_document_type=PROXY&is_document=${queryOption.is_document}&is_video=${queryOption.is_video}&is_image=${queryOption.is_image}&search=${queryOption.searchTxt}`
            if (queryOption.ordering) url += `&ordering=${queryOption.ordering}`
            if (queryOption.offset) url += queryOption.offset

            return axios.get(url, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                let _allRegistryFiles = state.registryFiles
                if (queryOption.offset && _allRegistryFiles) {
                    response.data.forEach(elem => {
                        _allRegistryFiles.push(elem)
                    })
                    commit('setRigistryFiles', _allRegistryFiles)
                } else {
                    commit('setRigistryFiles', response.data)
                }
            })
            .catch(error => {
                if (error.response.status === 403) {
                    commit('setErrorText', 'Нет прав для просмотра данного раздела')
                    setTimeout(() => {
                        window.location.href = '/'
                    }, 1500)
                }else{
                    console.error(error)
                }     
            })
        },
        getMediaProxies({ commit, state }, queryOption) {
			state.queryObject = queryOption
			
			let url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/documents/signed?document_type=PROXY&search=${queryOption.searchTxt}`
			if (queryOption.ordering) url += `&ordering=${queryOption.ordering}`
			if (queryOption.filters) url += `&${queryOption.filters}`
			if (queryOption.offset) url += queryOption.offset
            
			return axios.get(url, {
					headers: {
						Authorization: `JWT ${store.state.API_TOKEN}`
					}
				}).then(response => {
					let _allProxies = state.proxies
					if (queryOption.offset && _allProxies) {
						response.data.forEach(elem => {
							_allProxies.push(elem)
						})
						commit('setProxies', _allProxies)
					} else {
						commit('setProxies', response.data)
					}
				})
				.catch(error => console.log(error))
		},
    },
}