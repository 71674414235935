<template>
    <router-view></router-view>
</template>

<script>
    export default {
        name: 'SecurityView',
        created() {
            this.$store.dispatch('fetchSupervisors')
        },
    }
</script>

<style lang="scss">

</style>