<template>
    <div class="card-item c-cat js-masonry-item">
        <div class=" card-item__content js-masonry-content">
           
            <div class="c-cat__header">
                <div class="before" @click="toggleBodyCard(cat.id)"></div>
                <div class="c-cat__volume">
                    <p>
                        {{cat.volumeMeasurement.title}}
                    </p>
                </div>
                <div class="c-cat__title">
                    <p>
                        {{ cat.title }}
                    </p>
                    <img src="@/assets/images/icons/arrow-down.svg" alt="arrow" @click="toggleBodyCard(cat.id)">
                </div>
            </div>

            <div class="c-cat__body">
                <div class="c-cat__select-list" v-if="cardContent.subCategories && cardContent.subCategories.length">
                    <ul v-for="subcat, idx in cardContent.subCategories" :key="`s-${idx}`">
                        <li>
                            <input class="c-cat__select-input" name="subcat" type="radio" v-model="editNameProduct" 
                                :id="subcat.id" :value="subcat.title" :data-subcat-first="subcat.id" @input="showSubcat(subcat.id)">
                            <label :for="subcat.id" class="js-upload-subcat">
                                {{ subcat.title }}
                            </label>
                        </li>
                        <li v-if="subcat.subCategories">
                            <ul v-for="subcat2, idx2 in subcat.subCategories" :key="`s-${idx2}`">
                                <li>
                                    <input class="c-cat__select-input" name="subcat" type="radio" v-model="editNameProduct" 
                                        :id="subcat2.id" :value="subcat2.title" :data-subcat-second="subcat.id" @input="showSubcat(subcat2.id)">
                                    <label :for="subcat2.id" class="js-upload-subcat">
                                        {{ subcat2.title }}
                                    </label>
                                </li>
                                <li v-if="subcat2.subCategories">
                                    <ul v-for="subcat3, idx3 in subcat2.subCategories" :key="`s-${idx3}`">
                                        <li>
                                            <input class="c-cat__select-input" name="subcat" type="radio" v-model="editNameProduct" 
                                                :id="subcat3.id" :value="subcat3.title" :data-subcat-third="subcat2.id" @input="showSubcat(subcat3.id)">
                                            <label :for="subcat3.id" class="js-upload-subcat">
                                                {{ subcat3.title }}
                                            </label>
                                        </li>
                                        <li v-if="subcat3.subCategories">
                                            <ul v-for="subcat4, idx4 in subcat3.subCategories" :key="`s-${idx4}`">
                                                <li>
                                                    <input class="c-cat__select-input" name="subcat" type="radio" v-model="editNameProduct" 
                                                        :id="subcat4.id" :value="subcat4.title" :data-subcat-fourth="subcat3.id" @input="showSubcat(subcat4.id)">
                                                    <label :for="subcat4.id" class="js-upload-subcat">
                                                        {{ subcat4.title }}
                                                    </label>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <div class="c-cat__controlls">
                        <div class="c-cat__controlls">
                        <router-link :to="({ name: 'EditSubCat', params: { catId: cat.id, ...editSubcatParams }})" class="btn btn--black" :class="{'btn--disabled': !editNameProduct}">
                            Редактировать: {{ editNameProduct }}
                            <img src="@/assets/images/icons/pencil--wh.svg" alt="pencil"
                                class="c-cat__controlls-image">
                        </router-link>
                    </div>
                    </div>
                    <div class="c-cat__controlls">
                        <router-link :to="({ name: 'EditCategory', params: { catId: cat.id }})" class="btn">
                            Редактировать {{ cat.title }}  <img src="@/assets/images/icons/pencil--wh.svg" alt="pencil"
                                class="c-cat__controlls-image">
                        </router-link>
                    </div>
                    <div class="c-cat__controlls">
                        <router-link :to="({ name: 'NewSubCat', params: { catId: cat.id }})" class="btn">
                            Добавить подкатегорию
                        </router-link>
                    </div>
                </div>

                <div class="c-cat__select-list" v-else-if="cardContent.subCategories && !cardContent.subCategories.length">
                    <div class="c-cat__controlls">
                    <router-link :to="({ name: 'EditCategory', params: { catId: cat.id }})" class="btn">
                        Редактировать: {{ cat.title }}  <img src="@/assets/images/icons/pencil--wh.svg" alt="pencil"
                                class="c-cat__controlls-image">
                        <img src="@/assets/images/icons/pencil--wh.svg" alt="pencil"
                            class="c-cat__controlls-image">
                    </router-link>
                    </div>
                    <div class="c-cat__controlls">
                        <router-link :to="({ name: 'NewSubCat', params: { catId: cat.id }})" class="btn">
                            Добавить подкатегорию
                        </router-link>
                    </div> 
                </div>
            </div>
        </div>
    </div>

    
</template>

<script>
    import API from "@/services/API";
    import Button from '@/components/UI/Button.vue'

    export default {
        components: {
            Button,
        },
        props: {
            cat: {
                type: Object,
                require: true
            },
            activeInputId: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                showCardBody: false,
                editNameProduct: '',
                cardContent: '',
                editSubcatParams: {},
            }
        },
        mounted() {
            API.resizeAllMasonryItems()
        },
        updated() {
            this.$nextTick(function () {
               API.resizeAllMasonryItems()
            })
        },

        methods: {
            async toggleBodyCard(catId) {
                let elem = event.target.closest('.js-masonry-item')
                let openedElem = document.querySelector('.js-masonry-item.opened') 

                if (openedElem && !elem.classList.contains('opened')) {
                    let _clickElem = openedElem.querySelector('.before')
                    _clickElem.click()
                }

                if (elem.classList.contains('opened')) {
                    elem.classList.remove('opened')
                    this.cardContent = ''
                    return
                } 
                elem.classList.add('opened')
                await this.$store.dispatch('uploadSingleCat', {catId, subcat: false})
                this.cardContent = this.$store.getters.getSingleCat
            },
            async showSubcat(catId) {
                let subcatInfo = {catId: catId,subcat: true }
                if (event.target.dataset.subcatFirst) {
                    this.editSubcatParams.subcat_2_id = null
                    this.editSubcatParams.subcat_3_id = null
                    this.editSubcatParams.subcat_4_id = null

                    this.editSubcatParams.subcat_1_id = catId
                    subcatInfo.subcat1 = catId
                }
                if (event.target.dataset.subcatSecond) {
                    this.editSubcatParams.subcat_3_id = null
                    this.editSubcatParams.subcat_4_id = null

                    subcatInfo.subcat1 = this.editSubcatParams.subcat_1_id
                    this.editSubcatParams.subcat_2_id = catId
                    subcatInfo.subcat2 = catId
                }
                if (event.target.dataset.subcatThird){
                    this.editSubcatParams.subcat_4_id = null

                    subcatInfo.subcat1 = this.editSubcatParams.subcat_1_id
                    subcatInfo.subcat2 = this.editSubcatParams.subcat_2_id
                    subcatInfo.subcat3 = catId
                    this.editSubcatParams.subcat_3_id = catId
                }
                if (event.target.dataset.subcatFourth){
                    subcatInfo.subcat1 = this.editSubcatParams.subcat_1_id
                    subcatInfo.subcat2 = this.editSubcatParams.subcat_2_id
                    subcatInfo.subcat3 = this.editSubcatParams.subcat_3_id
                    subcatInfo.subcat4 = catId
                    this.editSubcatParams.subcat_4_id = catId
                } 
                await this.$store.dispatch('uploadSingleCat', subcatInfo)
                this.cardContent = this.$store.getters.getSingleCat
                this.$forceUpdate();
               
            }
        },
    }
</script>

<style lang="scss">


</style>