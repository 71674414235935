<template>
    <div :class="[getMsgClass, getSender]">
        <div class="cs-chat__body-date" v-if="showDate">
            <p>
                {{ Math.floor(new Date(+msg.createdAt).getTime()/1000.0) | moment("DD.MM.YYYY") }}
            </p>
        </div>
        <div class="cs-chat__msg" :class="getSender">
            <div class="cs-chat__msg-inner">
                <div class="cs-chat__msg-header"
                    v-if="msg.sender && msg.messageType == 0 || msg.messageType == 3 || msg.messageType == 4">
                    <p>
                        {{msg.sender.name}}
                    </p>
                    <p>
                        <router-link :to="msg.sender.isEmployee 
                            ? { name: 'UserEmployeesItem', params: { id: msg.sender.supervisor, employeeId: msg.sender.id } } 
                            : { name: 'User', params: { id: msg.sender.id } }">
                            {{msg.sender.isEmployee ? `${msg.sender.lastName} ${msg.sender.firstName} ${msg.sender.middleName}` : msg.sender.ceoFullName}}
                        </router-link>
                    </p>
                    <p>
                        {{msg.sender.isEmployee ? msg.sender.position : 'Руководитель'}}
                    </p>
                    <p v-if="!isRender">
                        ИНН {{msg.sender.inn}}
                    </p>
                </div>

                <div class="cs-chat__msg-body"
                    :class="msg.messageType == 3 || msg.messageType == 4 ? 'cs-chat__msg-body--flex' : ''">
                    <template v-if="msg.images.length">
                        <div class="cs-chat__msg-images" v-for="image, idx in msg.images" :key="image.id"
                            @click="openPopup">
                            <p v-if="image.inChatId">
                                № {{image.inChatId}}
                            </p>
                            <br>
                            <img :src="imageLink[idx]">
                        </div>
                    </template>

                    <template v-else-if="msg.videos.length">
                        <div class="cs-chat__msg-videos" v-for="video, idx in msg.videos" :key="video.id">
                            <p v-if="video.inChatId">
                                № {{video.inChatId}}
                            </p>
                            <br>
                            <video controls>
                                <source :src="videoLink[idx]" type="video/mp4" :poster="video.thumbnailUrl"
                                    v-if="videoLink[idx]">
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </template>

                    <template v-else-if="msg.documents.length">
                        <div class="cs-chat__msg-docs cs-chat__msg-body--flex" v-for="doc, idx in msg.documents" :key="doc.id">
                            <div class="leftside">
                                <p v-if="doc.inChatId">
                                    № {{doc.inChatId}}
                                </p>
                                <a :href="docLink[idx]" target="_blank">
                                    <img :src="doc.thumbnailUrl" alt="">
                                </a>
                            </div>
                            <div class="rightside">
                                <strong>
                                    Документ
                                </strong>
                                <p>
                                    {{doc.fileInitialName}}
                                </p>
                            </div>
                        </div>
                    </template>

                    <div v-else-if="msg.messageType == 7 && msg.body.paymentStatus == 3" v-show="false"></div>

                    <template v-else-if="msg.messageType == 9"> {{msg.text}} </template>

                    <template v-else>
                        <div class="cs-chat__msg-text" v-if="msg.messageType == 0 || msg.messageType == 1">
                            <p>
                                {{msg.text}}
                            </p>
                        </div>

                        <div class="cs-chat__msg-text cs-chat__msg-status" v-if="msg.messageType == 2">
                            <p>
                                {{getChatStatus}}
                            </p>
                        </div>

                        <template v-if="msg.messageType == 3 && paymentDocInfo">
                            <div class="leftside">
                                <p v-if="paymentDocInfo.inChatId">
                                    № {{paymentDocInfo.inChatId}}
                                </p>
                                <a :href="paymentDocInfo.url" target="_blank">
                                    <img :src="paymentDocInfo.thumbnailUrl" alt="">
                                </a>
                            </div>
                            <div class="rightside" :key="idx">
                                <strong>
                                    Условия оплаты
                                </strong>
                                <p>
                                    {{paymentDocInfo.fileInitialName}}
                                </p>
                            </div>
                        </template>

                        <template v-if="msg.messageType == 4 && conditionDocInfo">
                            <div class="leftside">
                                <p v-if="conditionDocInfo.inChatId">
                                    № {{conditionDocInfo.inChatId}}
                                </p>
                                <a :href="conditionDocInfo.url" target="_blank">
                                    <img :src="conditionDocInfo.thumbnailUrl" alt="">
                                </a>
                            </div>
                            <div class="rightside">
                                <strong>
                                    Условия сделки
                                </strong>
                                <p>
                                    {{conditionDocInfo.fileInitialName}}
                                </p>
                            </div>
                        </template>

                        <div class="cs-chat__msg-text" v-if="msg.messageType == 5">
                            <p>
                                Пользователь {{getUserName}} изменил информацию по Лоту. Изменения будут опубликованы
                                после проверки администратором
                            </p>
                        </div>

                        <div class="cs-chat__msg-text cs-chat__msg-status" v-if="msg.messageType == 6">
                            <p>
                                Чат закрыт
                            </p>
                        </div>

                        <div class="cs-chat__msg-text" v-if="msg.messageType == 7">
                            Статус платежа изменился на
                            <strong>
                                "{{getPayStatus}}"
                            </strong>
                        </div>

                        <div class="cs-chat__msg-text" v-if="msg.messageType == 8">
                            <p>
                                Информация о стоимости лота обновлена
                            </p>
                        </div>
                    </template>
                </div>

                <div class="cs-chat__msg-footer" v-if="msg.messageType == 0 || msg.messageType == 3 || msg.messageType == 4">
                    <div class="cs-chat__msg-time">
                        <!-- <template v-if="(msg.messageType == 3 || msg.messageType == 4) && msg.approvalStatus"> -->
                        <template v-if="msg.approvalStatus">
                            {{ getApprovalStatus }}
                            {{ Math.floor(new Date(+msg.approvedAt).getTime()/1000.0) | moment("DD.MM.YYYY") }}
                            {{ Math.floor(new Date(+msg.approvedAt).getTime()/1000.0) | moment("HH:mm") }}
                        </template>
                        <template v-else>
                            {{ Math.floor(new Date(+msg.createdAt).getTime()/1000.0) | moment("HH:mm") }}
                        </template>
                    </div>
                    <div class="cs-chat__msg-readed" v-if="msg.isRead">
                        <img src="@/assets/images/icons/readed.svg" alt="readed">
                    </div>
                </div>
            </div>
            <div class="cs-chat__msg-avatar">
                <template v-if="msg.sender">
                    <img :src="msg.sender.avatar" alt="avatar" v-if="msg.sender.avatar">
                    <img src="@/assets/images/avatar.svg" alt="avatar" v-else>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        props: {
            msg: {
                type: Object,
                require: true
            },
            idx: {
                type: Number,
                require: true
            },
            isRender: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                docLink: [],
                imageLink: [],
                posterLink: [],
                videoLink: [],
                paymentDocInfo: null,
                conditionDocInfo: null,
                showDate: false
            }
        },
        created() {
            this.getDoc
            this.getImage
            this.getVideo

            if (this.msg.messageType == 3) {
                this.getPaymentsDocInfo(this.msg.body.entityId)
            }

            if (this.msg.messageType == 4) {
                this.getConditionsDocInfo(this.msg.body.entityId)
            }

            window.onbeforeunload = () => {
                localStorage.removeItem('msgDate')
            }

            if (this.isRender) {
                if (localStorage.getItem('msgDate')) {
                    let storageDateTimestamp = new Date(+localStorage.getItem('msgDate'))
                    let msgDateTimestamp = new Date(+this.msg.createdAt)
                    let storageDate = storageDateTimestamp.toDateString()
                    let msgDate = msgDateTimestamp.toDateString()

                    if (msgDate != storageDate) {
                        this.showDate = true
                        localStorage.setItem('msgDate', this.msg.createdAt)
                    } else {
                        this.showDate = false
                    }
                } else {
                    localStorage.setItem('msgDate', this.msg.createdAt)
                    this.showDate = true
                }
            }
        },
        destroyed() {
            localStorage.removeItem('msgDate')
        },
        computed: {
            getImage() {
                let msgId = this.msg.id
                let msgImages = this.msg.images

                msgImages.forEach(elem => {
                    let imageId = elem.id
                    this.getImageSrc({
                        msgId,
                        imageId
                    })
                })
            },
            getVideo() {
                let msgId = this.msg.id
                let msgVideos = this.msg.videos

                msgVideos.forEach(elem => {
                    let videoId = elem.id
                    this.getVideoSrc({
                        msgId,
                        videoId
                    })
                })

            },
            getDoc() {
                let msgId = this.msg.id
                let msgDocs = this.msg.documents

                msgDocs.forEach((elem, idx) => {
                    let docId = elem.id
                    this.getDocumentSrc({
                        msgId,
                        docId
                    })
                })
            },
            getMsgClass() {
                let classes = {
                    1: 'msg--white',
                    2: 'msg--white',
                    3: 'msg--doc',
                    4: 'msg--doc',
                    5: 'msg--white',
                    6: 'msg--white',
                    7: 'msg--white',
                    8: 'msg--white',
                    9: 'msg--white'
                }

                return classes[this.msg.messageType]
            },
            getChatStatus() {
                if ((this.msg.body.status == 1 || this.msg.body.status == 3) && !this.msg.body.userRole) {
                    return `Не указана роль пользователя`
                }

                let status = {
                    0: 'Чат создан',
                    1: `${this.msg.body.userRole == 'seller' ? 'Продавец' : 'Покупатель'} предложил сделку`,
                    2: 'Сделка заключена',
                    3: `${this.msg.body.userRole == 'seller' ? 'Продавец' : 'Покупатель'} не принял сделку`,
                    4: 'Продавец предложил закрыть сделку',
                    5: 'Покупатель предложил закрыть сделку',
                    6: 'Сделка закрыта',
                    7: 'Чат закрыт'
                }

                return status[this.msg.body.status]
            },
            getUserName() {
                if (!this.msg.body.lotUserName) return 'Добавьте значение!!!'
                return this.msg.body.lotUserName
            },
            getPayStatus() {
                let payStatus = {
                    0: 'Ожидает подверждения',
                    1: 'Ожидает принятия условий',
                    2: 'Ожидает выполнения условий',
                    3: '',
                    4: 'Спорный платеж',
                    5: 'Исполнен',
                    6: 'Платеж возвращен',
                }

                return payStatus[this.msg.body.paymentStatus]
            },
            getSender() {
                if (!this.msg.sender) return ''
                let role = this.msg.sender.role == 1 ? 'cs-chat__msg--my' : ''
                return role
            },
            getApprovalStatus() {
                let status = {
                    0: 'INITIAL ',
                    1: 'Подписано',
                    2: 'Контрагент готов подписать',
                    3: 'Подписано обоюдно',
                    4: 'Подпись отклонена',
                }

                return status[this.msg.approvalStatus]
            },
        },

        methods: {
            async getImageSrc(data) {
                await axios.get(
                    `${this.$store.state.API_HOST}/api/${this.$store.state.API_VERSION}/admin/chats/messages/${data.msgId}/media/images/${data.imageId}`, {
                        headers: {
                            Authorization: `JWT ${this.$store.state.API_TOKEN}`
                        },
                        // responseType: 'arraybuffer',
                    }).then(response => {
                    this.imageLink.push(response.data.url)
                    // let contentType = response.headers['content-type']
                    // let image = (
                    //     `data:${contentType};base64,${btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''))}`
                    // );
                    // this.imageLink.push(image)
                })
            },
            async getVideoSrc(data) {
                await axios.get(
                    `${this.$store.state.API_HOST}/api/${this.$store.state.API_VERSION}/admin/chats/messages/${data.msgId}/media/videos/${data.videoId}`, {
                        headers: {
                            Authorization: `JWT ${this.$store.state.API_TOKEN}`
                        },
                        // responseType: 'blob'
                    }).then(response => {
                    this.videoLink.push(response.data.url)
                    // let contentType = response.headers['content-type']
                    // const video = new Blob([response.data], {
                    //     type: contentType
                    // })
                    // const fileURL = URL.createObjectURL(video)
                    // this.videoLink.push(fileURL)
                })

            },
            async getDocumentSrc(data) {
                await axios.get(
                    `${this.$store.state.API_HOST}/api/${this.$store.state.API_VERSION}/admin/chats/messages/${data.msgId}/media/documents/${data.docId}`, {
                        headers: {
                            Authorization: `JWT ${this.$store.state.API_TOKEN}`
                        },
                        // responseType: 'blob'
                    }).then(response => {
                    this.docLink.push(response.data.url)
                    // let contentType = response.headers['content-type']
                    // const file = new Blob([response.data], {
                    //     type: contentType
                    // })
                    // const fileURL = URL.createObjectURL(file)
                    // this.docLink.push(fileURL)
                })
            },
            async getPaymentsDocInfo(paymentId) {
                await axios.get(
                    `${this.$store.state.API_HOST}/api/${this.$store.state.API_VERSION}/admin/deals/payments/${paymentId}`, {
                        headers: {
                            Authorization: `JWT ${this.$store.state.API_TOKEN}`
                        },
                    }).then(response => {
                    return this.paymentDocInfo = response.data.document
                })
            },
            async getConditionsDocInfo(conditionId) {
                await axios.get(
                    `${this.$store.state.API_HOST}/api/${this.$store.state.API_VERSION}/admin/deals/conditions/${conditionId}`, {
                        headers: {
                            Authorization: `JWT ${this.$store.state.API_TOKEN}`
                        },
                    }).then(response => {
                    return this.conditionDocInfo = response.data.document
                })
            },
            openPopup(event) {
                this.showPopup = true
                let element = event.target
                this.$emit('openPopup', element.src)
            },
        }
    }
</script>