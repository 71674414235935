<template>
  <button class="btn" :class="btnClass"  v-on="$listeners"> 
    <Miniloader/>
    <span class="btn-text">
      <slot />
    </span>
  </button>
</template>

<script>
import Miniloader from '@/components/UI/MiniLoader.vue'

export default {
  components: {
    Miniloader
  },
  props: {
    btnClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 6.2rem;
  font-size: 1.6rem;
  font-weight: 500;
  text-decoration: none;
  color: #ffffff;
  background-color: #008069;
  border: 0;
  border-radius: 10rem;
  transition: all .2s ease;
  .btn-text {
    display: inline-flex;
    align-items: center;
  }
  img {
    width: 2.4rem;
    height: 2.4rem;
  }
  &:active {
    background-color: #449889;
    transform: translateY(3px);
  }
  &.btn--grey {
    color: #191B1D;
    background-color: #F7F7FA;
    &:active {
      background-color: #8f8f92;
    }
  }
  &.btn--red {
    background-color: #FF5757;
    &:active {
    background-color: #914646;
    }
  }
  &.btn--black {
    background-color: #3C4853;
    &:active {
    background-color: #29333C;
    }
  }
  &.btn--white {
    color: #191B1D;
    background-color: #ffffff;
    &:active {
    background-color: #eeeeee;
    }
  }
  &.btn--disabled {
    opacity: .85;
    pointer-events: none;
  }
  &.btn--add {
    flex-shrink: 0;
    width: 4.5rem;
    height: 4.5rem;
    margin-right: 1.4rem;
  }

}



.btn .miniloader {
  display: none;
}

.form-send .btn {
  pointer-events: none;
}

.form-send .btn .miniloader {
    display: flex;
}

.form-send .btn .btn-text {
  display: none;
}
</style>