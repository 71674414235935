<template>
  <div class="wrapper wrapper--admin">
    <div class="leftside">
      <Navbar/>
    </div>
    <div class="rightside">
      <Header @showNotice="showNotice = true"/>
      <div class="content" @scroll="addScroll">
        <router-view/>
      </div>
    </div>
    <transition name="fadeIn">
      <div class="popup" v-if="showPopup" @click="closePopup">
        <div class="popup__window" @click.stop="">
          <span class="popup__close" @click="closePopup">
            <img src="@/assets/images/icons/close.svg" alt="close">
          </span>
          <div class="popup__title">
            Добавление админа
          </div>
          <div class="popup__inner">
            <form class="form-add-user" @submit.prevent="addAdmin">
              <div class="form__inner">
                <div class="form__item" :class="{ 'form__item--error': $v.email.$error}">
                  <input class="form__input" id="email" v-model.lazy="email" :class="{error: $v.email.$error}"
                         @change="$v.email.$touch()" placeholder="Логин (email)">
                  <label for="email" class="form__label"><span>Логин</span></label>
                  <p class="form__item-error" v-if="!$v.email.required">
                    Обязательное поле
                  </p>
                  <p class="form__item-error" v-if="!$v.email.email">
                    Введите email
                  </p>
                </div>
                <div class="form__item" :class="{ 'form__item--error': $v.password.$error }">
                  <input class="form__input" id="password" v-model.lazy="password" :class="{error: $v.password.$error}"
                         type="password" @change="$v.password.$touch()" placeholder="Пароль">
                  <label for="password" class="form__label"><span>Пароль</span></label>
                  <p class="form__item-error" v-if="!$v.password.required">
                    Обязательное поле
                  </p>
                </div>
                <div class="form__controlls" :class="{'form-send': isSend}">
                  <Button>
                    Добавить
                  </Button>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>

      <div class="popup" v-if="getError" @click="closeError">
        <div class="popup__window" @click.stop="">
          <span class="popup__close" @click="closeError">
            <img src="@/assets/images/icons/close.svg" alt="close">
          </span>
          <div class="popup__inner">
            <div class="popup__title">
              {{ getError.errorText }}
            </div>
          </div>
        </div>
      </div>

      <div class="popup" v-if="showNotice" @scroll="popupScroll">
        <div class="popup__window popup__window--notice" @click.stop="">
          <span class="popup__close" @click="showNotice = false">
            <img src="@/assets/images/icons/close.svg" alt="close">
          </span>
          <div class="popup__inner">
            <div class="popup__title">
              Уведомления
            </div>
            <div class="popup__grid" v-if="getNotification.length">
              <NoticeCard v-for="notice in getNotification" :key="notice.id" :notice="notice"
                          @click.native="readNotification(notice)"/>
            </div>
            <div class="su-empty su-about-empty" v-else>
              <EmptyBlock/>
            </div>
            <div class="notifications-clear" v-if="getNotification.length">
              <button class="btn" @click="readAllNotifications">
                Прочитать все
              </button>
              <button class="btn btn--red" @click="deleteAllNotifications">
                Удалить все
              </button>
            </div>
          </div>
        </div>
      </div>

    </transition>

  </div>
</template>

<script>
import {email, required} from 'vuelidate/lib/validators'
import API from '@/services/API'
import Header from '@/components/Header.vue'
import Navbar from '@/components/Navbar.vue'
import Sorting from '@/components/Controlls/Sorting.vue'
import Button from '@/components/UI/Button.vue'
import EmptyBlock from '@/components/Empty.vue'
import NoticeCard from '@/components/Cards/NoticeCard.vue'

export default {
  components: {
    Sorting,
    Header,
    Navbar,
    Button,
    EmptyBlock,
    NoticeCard,
  },
  data() {
    return {
      showPopup: false,
      showSorting: false,
      showSortingId: -1,
      sortingBy: '',
      email: '',
      password: '',
      isSend: false,
      upload: false,
      showNotice: false
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
    },
  },
  created() {
    document.addEventListener('click', this.onShowSorting)
    this.$store.dispatch('checkUnreadNotifications')
  },
  destroyed() {
    document.removeEventListener('click', this.onShowSorting)
  },
  computed: {
    getError() {
      if (this.$store.state.errorText) {
        let errorInfo = {
          showError: this.$store.state.errorText ? true : false,
          errorText: this.$store.getters.getErrorText
        }
        return errorInfo
      }
    },
    getNotification() {
      let notification = this.$store.getters.getNotification
      return notification
    },
  },
  methods: {
    async addScroll(event) {
      let scrollBlock = event.target;
      let routeName;

      if (this.$route.name == 'UsersList') routeName = 'UsersList'
      if (this.$route.name == 'UsersForDelete') routeName = 'UsersList'
      if (this.$route.name == 'DocsSignature') routeName = 'docs-signature'
      if (this.$route.name == 'DocsInformation') routeName = 'docs-information'
      if (this.$route.name == 'DocsInstruction') routeName = 'docs-instruction'
      if (this.$route.name == 'UserEmployeesList') routeName = 'employees'
      if (this.$route.name == 'UserEmployeesProxy') routeName = 'proxy'

      if (this.$route.name.includes('Counterparties')) routeName = 'counterparties'
      if (this.$route.name.includes('Chats')) routeName = 'chats'
      if (this.$route.name.includes('Ratings')) routeName = 'rating'
      if (this.$route.name.includes('Lots')) routeName = 'lots'
      if (this.$route.name.includes('Payments')) routeName = 'payments'
      if (this.$route.name.includes('Registry')) routeName = 'registry'
      API.uploadByScroll(this, scrollBlock, routeName)

    },
    async popupScroll(event) {
      let scrollBlock = event.target
      API.uploadByScroll(this, scrollBlock, 'notice')

    },
    onShowSorting(event) {
      const windowBody = document.body;
      const elemOnClick = event.target;
      let coord = {
        left: '',
        right: ''
      }
      let eventElemCoord;
      let eventElemId

      if (windowBody.classList.contains('tooltip-is-open')) {
        if (elemOnClick.classList.contains('open-tooltip')) return

        if (elemOnClick.classList.contains('js-tooltip-icon')) {
          eventElemId = elemOnClick.dataset.id
          eventElemCoord = elemOnClick.getBoundingClientRect()

          if (this.showSortingId == eventElemId) {
            API.toCloseSorting(this, windowBody)
            return
          }

          API.toCloseSorting(this, windowBody)
          API.toShowSorting(this, windowBody, eventElemId, coord, eventElemCoord)

        } else {
          if (event.target.closest('.js-custom-filters')) {
            return
          }

          API.toCloseSorting(this, windowBody)
        }
      } else {
        if (!elemOnClick.classList.contains('js-tooltip-icon')) return
        eventElemId = elemOnClick.dataset.id
        eventElemCoord = elemOnClick.getBoundingClientRect()

        API.toShowSorting(this, windowBody, eventElemId, coord, eventElemCoord)
      }
    },
    closePopup(event) {
      this.showPopup = false
    },
    closeError() {
      this.$store.state.errorText = null
    },
    addAdmin() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const user = {
          email: this.email,
          password: this.password,
        }

        this.isSend = true
      }
    },
    readNotification(notice) {
      this.showNotice = false
      this.$store.dispatch('readNotification', notice.id)
      this.$router.go(this.$router.currentRoute)
    },
    readAllNotifications() {
      this.$store.dispatch('readAllNotifications')
    },
    deleteAllNotifications() {
      this.$store.dispatch('deleteAllNotifications')
    }
  },
}
</script>

