<template>

  <div class="su-view">
    <SubTitle :title="'Добавление меры объема'" :backBtn="true" />
    <div class="f-block__wrap">
      <div class="f-block">
        <div class="f-block__inner">
          <div class="f-block__header">
            <div class="f-block__row">
              <div class="f-block__title">
                <p>
                  Меры объема
                </p>
              </div>
            </div>
            <ul>
              <li class="f-block__row" v-for="volume, idx in getVolumes" :key="idx">
                  <p>
                    <span @click="deleteVolume(volume.id)"> &times; </span> {{ volume.title }}
                  </p>
              </li>
            </ul>
          </div>

          <div class="f-block__body">
            <form class="form-volume" @submit.prevent="addVolume">
              <p class="form__input-title">
                Новая мера объема
              </p>
              <div class="form__item" :class="{ 'form__item--error': $v.volume.$error}">

                <input class="form__input" id="volume" v-model.lazy="volume"
                  :class="{error: $v.volume.$error}" type="text" @change="$v.volume.$touch()"
                  placeholder="Новая мера объема">
                <label for="volume" class="form__label"><span>Новая мера объема</span></label>
                <p class="form__item-error" v-if="!$v.volume.required">
                  Обязательное поле
                </p>
              </div>
              <div class="form__controlls" :class="{'form-send': isSend}">
                <Button>
                  Добавить
                </Button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  </div>


</template>

<script>
  import {required} from 'vuelidate/lib/validators'
  import SubTitle from '@/components/UI/SubTitle.vue'
  import Input from '@/components/UI/Input.vue'
  import Button from '@/components/UI/Button.vue'

  export default {
    components: {
      SubTitle,
      Input,
      Button,
    },
    data() {
      return {
        volume: '',
        isSend: false
      }
    },
    validations: {
      volume: {
        required,
      },
    },
    created() {
      this.$store.dispatch('uploadVolumes')
    },
    computed: {
      getVolumes() {
        return this.$store.getters.getVolumes
      },
    },
    methods: {
      async addVolume() {
        this.$v.volume.$touch()
        if (!this.$v.volume.$invalid) {
          this.isSend = true
          await this.$store.dispatch('addVolume', this.volume)
          this.isSend = false
          this.volume = ''
          this.$v.volume.$reset()
        }
      },
      async deleteVolume(id) {
          await this.$store.dispatch('deleteVolume', id)
      }
    }

  }
</script>

<style lang="scss" scoped>
  .f-block {
    &__row {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 1.6rem;
      font-size: 1.6rem;
      font-weight: 700;
      color: #008069;
       span {
        font-size: 2rem;
        font-weight: 400;
        cursor: pointer;
      }
    }

    &__title {
      margin-right: 1rem;
      font-size: 1.6rem;
      font-weight: 500;
      color: #8A8C96;
     
    }
  }

  .form__input-title {
    margin-bottom: 1.6rem;
    font-size: 1.6rem;
    color: #B8BABE;
  }
</style>