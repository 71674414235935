const UserListTabs = [
    {
        title: '№',
        isSorting: false,
        isFilter: true,
        filters: {
            filtersName: 'uniqueNumber',
        }
    },
    {
        title: 'Пользователь',
        isSorting: true,
        isFilter: false,
        sorting: {
            sortingTabs: [{
                sortingName: 'users',
                sortingAsc: 'name',
                sortingDesc: '-name',
                sortingAscTitle: 'Сортировка “От А до Я”',
                sortingDescTitle: 'Сортировка “От Я до А”'
            },],
        }
    },
    {
        title: 'Организационно-правовой статус',
        isSorting: false,
        isFilter: true,
        filters: {
            filtersName: 'user-organization-type',
            filtersTabs: [
                {
                    filterTabTitle: 'Все пользователи',
                    filterTabRequest: ''
                },
                {
                    filterTabTitle: 'ООО',
                    filterTabRequest: 'organization_type=0'
                },
                {
                    filterTabTitle: 'АО',
                    filterTabRequest: 'organization_type=2'
                },
                {
                    filterTabTitle: 'ИП',
                    filterTabRequest: 'organization_type=1'
                },
                {
                    filterTabTitle: 'Физическое лицо',
                    filterTabRequest: 'organization_type=3'
                },
            ]
        }
    },
    {
        title: 'Контрагенты',
        isSorting: true,
        isFilter: false,
        sorting: {
            sortingTabs: [{
                sortingName: 'counterparties',
                sortingAsc: 'counterparties_count',
                sortingDesc: '-counterparties_count',
                sortingAscTitle: 'По возрастанию количества контрагентов',
                sortingDescTitle: 'По убыванию количества контрагентов'
            }],
        }
    },
    {
        title: 'Сотрудники',
        isSorting: true,
        isFilter: false,
        sorting: {
            sortingTabs: [{
                sortingName: 'employees',
                sortingAsc: 'employees_count',
                sortingDesc: '-employees_count',
                sortingAscTitle: 'По возрастанию количества сотрудников',
                sortingDescTitle: 'По убыванию количества сотрудников'
            }],
        }
    },
    // {
    //   title: 'Лоты кол-во',
    //   isSorting: true,
    //   isFilter: false,
    //   sorting: {
    //     sortingTabs: [{
    //       sortingName: 'lots',
    //       sortingAsc: 'active_lots_count',
    //       sortingDesc: '-active_lots_count',
    //       sortingAscTitle: 'По возрастанию количества активных лотов',
    //       sortingDescTitle: 'По убыванию количества активных лотов'
    //     }, {
    //       sortingName: 'lots',
    //       sortingAsc: 'closed_lots_count',
    //       sortingDesc: '-closed_lots_count',
    //       sortingAscTitle: 'По возрастанию количества закрытых лотов',
    //       sortingDescTitle: 'По убыванию количества закрытых лотов'
    //     }, ],
    //   }
    // },
    // {
    //   title: 'Чаты',
    //   isSorting: true,
    //   isFilter: false,
    //   sorting: {
    //     sortingTabs: [{
    //         sortingName: 'chats',
    //         sortingAsc: 'active_chats_count',
    //         sortingDesc: '-active_chats_count',
    //         sortingAscTitle: 'По возрастанию количества активных чатов',
    //         sortingDescTitle: 'По убыванию количества активных чатов'
    //       },
    //       {
    //         sortingName: 'chats',
    //         sortingAsc: 'closed_chats_count',
    //         sortingDesc: '-closed_chats_count',
    //         sortingAscTitle: 'По возрастанию количества закрытых чатов',
    //         sortingDescTitle: 'По убыванию количества закрытых чатов'
    //       },
    //     ],
    //   }
    // },
    // {
    //   title: 'Платежи',
    //   isSorting: true,
    //   isFilter: false,
    //   sorting: {
    //     sortingTabs: [{
    //       sortingName: 'pays',
    //       sortingAsc: 'aggregations__executed_payments_count',
    //       sortingDesc: '-aggregations__executed_payments_count',
    //       sortingAscTitle: 'По возрастанию количества исполненных платежей',
    //       sortingDescTitle: 'По убыванию количества исполненных платежей'
    //     }, {
    //       sortingName: 'pays',
    //       sortingAsc: 'aggregations__pending_payments_count',
    //       sortingDesc: '-aggregations__pending_payments_count',
    //       sortingAscTitle: 'По возрастанию количества ожидающих платежей',
    //       sortingDescTitle: 'По убыванию количества ожидающих платежей'
    //     }, ],
    //   }
    // },
    // {
    //   title: 'Товары из лотов',
    //   isSorting: false,
    //   isFilter: false,
    // },
    // {
    //   title: 'Сделки из лотов',
    //   isSorting: true,
    //   isFilter: false,
    //   sorting: {
    //     sortingTabs: [
    //       {
    //         sortingName: 'deal',
    //         sortingAsc: 'own_lots_sale_deals_count',
    //         sortingDesc: '-own_lots_sale_deals_count',
    //         sortingAscTitle: 'По возрастанию количества продаж',
    //         sortingDescTitle: 'По убыванию количества продаж'
    //       },
    //       {
    //         sortingName: 'deal',
    //         sortingAsc: 'own_lots_purchase_deals_count',
    //         sortingDesc: '-own_lots_purchase_deals_count',
    //         sortingAscTitle: 'По возрастанию количества закупок',
    //         sortingDescTitle: 'По убыванию количества закупок'
    //       }, ],
    //   }
    // },
    {
        title: 'Статус пользователя',
        isSorting: false,
        isFilter: true,
        filters: {
            filtersName: 'user-status',
            filtersTabs: [
                {
                    filterTabTitle: 'Все пользователи',
                    filterTabRequest: ''
                },
                {
                    filterTabTitle: 'На проверке',
                    filterTabRequest: 'status=0&update_status=0&status=1&update_status=1&is_active=true'
                },
                {
                    filterTabTitle: 'Подтвержденный',
                    filterTabRequest: 'status=1&update_status=0&update_status=2&is_active=true'
                },
                {
                    filterTabTitle: 'Заблокированный',
                    filterTabRequest: 'is_active=false'
                },
                {
                    filterTabTitle: 'Отклоненный',
                    filterTabRequest: 'status=2&update_status=0&status=1&update_status=3&is_active=true'
                },
            ]
        }
    },
    {
        title: 'Промокод',
        isSorting: false,
        isFilter: true,
        filters: {
            filtersName: 'promocode',
            filtersTabs: [
                {
                    filterTabTitle: 'Все записи',
                    filterTabRequest: 'has_promocode='
                },
                {
                    filterTabTitle: 'По наличию промокода',
                    filterTabRequest: 'has_promocode=true'
                },
            ]
        }
    },
    // {
    //   title: 'Даты оплаты услуг сервиса за отчетный период',
    //   isSorting: false,
    //   isFilter: true,
    //   filters: {
    //     filtersName: 'pay-status',
    //     filtersTabs: [
    //       {
    //         filterTabTitle: 'Все пользователи',
    //         filterTabRequest: `payed_for_services=`
    //       },
    //       {
    //         filterTabTitle: 'Оплачен',
    //         filterTabRequest: 'service_payment=true'
    //       },
    //       {
    //         filterTabTitle: 'Не оплачен',
    //         filterTabRequest: 'service_payment=false'
    //       },
    //     ]
    //   }
    // },
]

export default UserListTabs