<template>
    <div class="su-view su-admin">
        <SubTitle :title="'Промокоды'" :backBtn="true" />
        <Search @search='onSearch' />
        {{isLoading}}
        <div class="users-body" v-if="loading">
            <Loader />
        </div>
        <div class="users-body" v-else>
            <div class="cards-list c-cat__list js-masonry">
                <AddPromocode />
                <Card v-for="promocode, idx in getPromocode" :key="idx" :promocode="promocode" @getPromocodeId="openDelPopup" />
            </div>

            <transition name="fadeIn">
                <div class="popup" v-if="showPopupDel" @click="closePopup">
                    <div class="popup__window" @click.stop="">
                        <div class="popup__inner">
                            <div class="popup__title">
                                Удалить промокод?
                            </div>
                            <div class="popup__controlls">
                                <Button :class="'btn--red'" @click="deletePromocode">Удалить</Button>
                                <Button :class="'btn--grey'" @click="closePopup">Отмена</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import API from "@/services/API";
    import Loader from '@/components/Loader.vue'
    import Button from '@/components/UI/Button'
    import Search from '@/components/Controlls/Search.vue'
    import SubTitle from '@/components/UI/SubTitle.vue'
    import AddPromocode from '@/components/Cards/AddPromocode.vue'
    import Card from '@/components/Cards/PromocodeCard.vue'

    export default {
        name: 'AdminView',
        components: {
            Loader,
            Search,
            SubTitle,
            Button,
            AddPromocode,
            Card
        },
        data() {
            return {
                loading: true,
                showPopupDel: false,
                promocodeForDelId: null,
                queryOption: { searchTxt: '' }
            }
        },
        created() {
            this.$store.dispatch('uploadPromocode', this.queryOption)
        },
        mounted() {
            API.resizeAllMasonryItems()
        },
        updated() {
            this.$nextTick(function () {
                API.resizeAllMasonryItems()
            })
        },
        computed: {
            isLoading() {
                this.getPromocode
            },
            getPromocode() {
                let allPromocode = this.$store.getters.getAllPromocode
                if (allPromocode) {
                    this.loading = false
                }
                return allPromocode
            },
        },
        methods: {
            onSearch(searchTxt) {
                this.queryOption.searchTxt = searchTxt
                this.$store.dispatch('uploadPromocode', this.queryOption)
            },
            openDelPopup(data) {
                this.showPopupDel = true
                this.promocodeForDelId = data
            },
            closePopup(event) {
                this.showPopupDel = false
            },
            async deletePromocode() {
                let promocodeId = this.promocodeForDelId

                this.isSend = true
                await this.$store.dispatch('deletePromocode', promocodeId)
                this.isSend = false
                this.showPopupDel = false
            }
        }
    }
</script>

<style lang="scss">
    .form__row {
        display: flex;
        gap: 1.6rem;
        margin-top: 1.6rem;

        .form__item+.form__item {
            margin-top: 0;
        }
    }

    .promocode__auto {
        position: absolute;
        right: 2rem;
        top: 2rem;
        cursor: pointer;
    }
</style>