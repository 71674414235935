<template>
  <div class="su-view su-registry">
    <div class="dusers-header">
      <SubTitle :title="'Реестр подписанных файлов'" />
      <div class="duser__search-block">
        <Search @search='onSearch' />
        <span class="sort-block">
          <img src="@/assets/images/icons/sort.svg" alt="sort" class="js-tooltip-icon"
            :data-id="'tooltip-id-' + forRegistrySorting.sortingTabs[0].sortingName">
        </span>

        <Sorting :style="tooltipElemCoord" :sorting="forRegistrySorting" @addOrdering="addOrdering" />
      </div>
    </div>
    {{isLoading}}
    <div class="users-body" v-if="loading">
      <Loader />
    </div>
    <div class="users-body" v-else>
      <template>
       
      </template>
      <RegistryTable :registryList="getRegitryFiles"  v-if="getRegitryFiles.length" />
      <div class="su-empty su-about-empty" v-else>
          <EmptyBlock />
      </div>
    </div>
  </div>
</template>

<script>
  import EmptyBlock from '@/components/Empty.vue'
  import Loader from '@/components/Loader.vue'
  import ForRegistrySorting from '@/data/RegistrySorting'

  import SubTitle from '@/components/UI/SubTitle.vue'
  import Search from '@/components/Controlls/Search.vue'
  import Button from '@/components/UI/Button.vue'
  import RegistryTable from '@/components/RegistryTable/Table.vue'
  import Sorting from '@/components/Controlls/Sorting.vue'

  export default {
    name: 'Registry',
    components: {
      EmptyBlock,
      Loader,
      SubTitle,
      Search,
      Button,
      RegistryTable,
      Sorting
    },
    data() {
      return {
        loading: true,
        tooltipElemCoord: {
          top: '5rem',
          left: '',
          right: ''
        },
        queryOption: {
          searchTxt: '',
          ordering: '-signed_at',
          is_image: !!this.is_image,
          is_video: !!this.is_video,
          is_document: !!this.is_document,
        }
      }
    },
    created() {
      this.getRegistryType(this.$route.name)
      this.$watch(() => this.$route.name, 
      (to, from) => { 
        this.loading = true
        this.getRegistryType(to)
      })
    },
    computed: {
      forRegistrySorting() {
        return ForRegistrySorting
      },
      isLoading() {
        this.getRegitryFiles
      },
      getRegitryFiles() {
        let regitryFiles = this.$store.getters.getRigistryFiles
        if (regitryFiles) {
          this.loading = false
        }
        return regitryFiles
      },
     
    },
    methods: {
      onSearch(searchTxt) {
        this.queryOption.searchTxt = searchTxt
        this.queryOption.offset = 0
        this.$store.dispatch('getRegistryFiles', this.queryOption)
      },
      addOrdering(data) {
        this.queryOption.ordering = data.query
        this.queryOption.offset = 0
        this.$store.dispatch('getRegistryFiles', this.queryOption)
      },
      getRegistryType(urlName) {
        switch (urlName) {
          case 'RegistryDeals':
            this.queryOption.fileType = 'conditions';
            break;
          case 'RegistryPayment':
            this.queryOption.fileType = 'payments';
            break;
          case 'RegistryDocs':
            this.queryOption.is_document = true;
            this.queryOption.is_image = false;
            this.queryOption.is_video = false;
            break;
          case 'RegistryVideo':
            this.queryOption.is_video = true;
            this.queryOption.is_image = false;
            this.queryOption.is_document = false;
            break;
          default:
            this.queryOption.is_image = true;
            this.queryOption.is_document = false;
            this.queryOption.is_video = false;
        }

        this.queryOption.offset = 0
        this.$store.dispatch('getRegistryFiles', this.queryOption)
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>