<template>
  <div class="su-view su-subscribes">
    <Tabs :tabs="tabs"/>
    <router-view></router-view>
  </div>
</template>

<script>
import Tabs from '@/components/Controlls/Tabs.vue'

export default {
  name: 'UserSubscribesView',
  components: {
    Tabs
  },
  data() {
    return {
      tabs: [
        { title: 'Товары / услуги', url: 'UserSubscribesProducts' },
        { title: 'Пользователи', url: 'UserSubscribesCompany' },
      ]
    }
  }, 
}
</script>