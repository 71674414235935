
import store from "./index";
import axios from 'axios'

export default {
    state: {
        allUsersLight: null,
        regions: null,
        cities: null,
        towns: null,
        districts: null,
        analytics: null,
    },
    getters: {
        getAllUsersLight(state) {
            return state.allUsersLight
        },
        getRegions(state) {
            return state.regions
        },
        getCities(state) {
            return state.cities
        },
        getTowns(state) {
            return state.towns
        },
        getDistricts(state) {
            return state.districts
        },
        getAnalytics(state) {
            return state.analytics
        },
    },
    mutations: {
        setAllUsersLight(state, users) {
            state.allUsersLight = users
        },
        setRegions(state, regions) {
            state.regions = regions
        },
        setCities(state, cities) {
            state.cities = cities
        },
        setTowns(state, towns) {
            state.towns = towns
        },
        setDistricts(state, districts) {
            state.districts = districts
        },
        setAnalytics(state, analytics) {
            state.analytics = analytics
        },
    },
    actions: {
        clearUsers({ commit, state }) {
            commit('setAllUsersLight', [])
        },
        getAllUsersLight({ commit, state }, payload) {
            let {offset, search} = payload || {};
            let params = {}
            let url =`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/light`

            if(search){
                params['search'] = search
            }
            if(offset){
                params['offset'] = offset
            }


            return axios.get(url, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }, 
                params
            }).then(response => {
                let _allUsers = state.allUsersLight
                
                if (JSON.stringify(_allUsers)===JSON.stringify(response.data)) return

                if (_allUsers) {
                    response.data.forEach(elem => {
                        _allUsers = _allUsers.filter(e=>e.id!==elem.id)
                        _allUsers.push(elem)
                    })
                    commit('setAllUsersLight', _allUsers)
                } else {
                    commit('setAllUsersLight', response.data)
                }
            })
            .catch(error => console.log(error))
        },
        uploadRegions({ commit, state }) {
            return axios.get(`${store.state.API_HOST}/api/geo/regions`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
                }).then(response => {
                    commit('setRegions', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        uploadCities({ commit, state }, regionId) {
            return axios.get(`${store.state.API_HOST}/api/geo/regions/${regionId}/cities`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
                }).then(response => {
                    commit('setCities', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        uploadTowns({ commit, state }, cityId) {
            return axios.get(`${store.state.API_HOST}/api/geo/cities/${cityId}/towns`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
                }).then(response => {
                    commit('setTowns', response.data)
                })
                .catch(error => {
                    if (error.response.status == 404) {
                        commit('setTowns', null)
                    }
                })
        },
        uploadDistricts({ commit, state }, townId) {
            return axios.get(`${store.state.API_HOST}/api/geo/towns/${townId}/districts`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
                }).then(response => {
                    commit('setDistricts', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        uploadAnalytics({ commit, state }, analyticInfo) {
            let option = `product=${analyticInfo.product}&type=${analyticInfo.type}&avg_prices_range=${analyticInfo.avg_prices_range}`

            if (analyticInfo.city) option += `&city=${analyticInfo.city}`
            if (analyticInfo.user) option += `&user=${analyticInfo.user}`

            return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/analytics?${option}`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
                }).then(response => {
                    commit('setAnalytics', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
    },
}