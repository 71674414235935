<template>
  <div class="su-view su-employes">
    {{ isLoading }}
    <div class="users-body" v-if="loading">
      <Loader/>
    </div>
    <div class="users-body" v-else>
      <div class="duser__search-block">
        <Search @search='onSearch'/>
        <span class="sort-block" @click="showPopup = true">
          <img src="@/assets/images/icons/filter.svg" alt="filter">
        </span>
        <span class="sort-block">
          <img src="@/assets/images/icons/sort.svg" alt="sort" data-id="tooltip-id-employee" class="js-tooltip-icon">
        </span>
        <Sorting :style="tooltipElemCoord" :sorting="employeeSorting" @addOrdering="addOrdering"/>
      </div>
      <div class="su-employee-info" v-if="getEmployees.length">
        <div class="cards-list c-company__list js-masonry">
          <Card v-for="(employee, idx) in getEmployees" :key="idx" :employee="employee"/>
        </div>
      </div>
      <div class="su-empty su-about-empty" v-else>
        <EmptyBlock/>
      </div>
      <transition name="fadeIn">
        <div class="popup" v-if="showPopup" @click="closePopup">
          <div class="popup__window" @click.stop="">
            <span class="popup__close" @click="closePopup">
              <img src="@/assets/images/icons/close.svg" alt="close">
            </span>
            <div class="popup__inner">
              <div class="popup__title">
                Фильтрация
              </div>
              <div class="filter__wrap">
                <div class="filter__block">
                  <div class="filter__block-name">
                    По метке
                  </div>
                  <div class="filter__row">
                    <input class="sorting__input" type="checkbox" id="can_sign_docs"
                           v-model="queryOption.can_sign_docs">
                    <label for="can_sign_docs">
                      Есть право подписи
                    </label>
                  </div>
                  <div class="filter__row">
                    <input class="sorting__input" type="checkbox" id="documents_access"
                           v-model="queryOption.documents_access">
                    <label :for="`documents_access`">
                      Есть доступ к документам
                    </label>
                  </div>
                  <div class="filter__row">
                    <input class="sorting__input" type="checkbox" id="employees_access"
                           v-model="queryOption.employees_access">
                    <label for="employees_access">
                      Есть доступ к сотрудникам
                    </label>
                  </div>
                </div>
                <div class="filter__block">
                  <div class="filter__block-name">
                    По должности
                  </div>
                  <div class="filter__row su-goods__select-block">
                    <div class="su-goods__select">
                      <div class="su-goods__select-input" :class="{'opened': showListPosition}" tabindex="0"
                           @click="showListPosition = true" @blur="showListPosition = false">
                        <template v-if="queryOption.position">
                          {{ queryOption.position }}
                        </template>
                        <template v-else>
                          Выберите должность
                        </template>
                      </div>
                    </div>
                    <transition name="fade">
                      <div class="select-list" v-show="showListPosition">
                        <ul>
                          <li v-for="(position, idx) in getPosition" :key="idx">
                            <input type="radio" name="doc-type" :value="position.name"
                                   class="select__input js-select-input" :id="'position-' + idx"
                                   v-model="queryOption.position">
                            <label :for="'position-' + idx">
                              {{ position.name }}
                            </label>
                          </li>
                        </ul>
                      </div>
                    </transition>
                  </div>
                </div>

                <div class="filter__controlls">
                  <Button @click="setEmployeeFilter">
                    Применить
                  </Button>
                  <Button :class="'btn--grey'" @click="clearFilters">
                    Сбросить
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import employeeSorting from '@/data/EmployeeSorting'
import Loader from '@/components/Loader.vue'
import Search from '@/components/Controlls/Search.vue'
import Input from '@/components/UI/Input.vue'
import Button from '@/components/UI/Button.vue'
import Sorting from '@/components/Controlls/Sorting.vue'
import SubTitle from '@/components/UI/SubTitle.vue'
import EmptyBlock from '@/components/Empty.vue'
import Card from '@/components/Cards/EmployeeCard.vue'

export default {
  name: 'UserEmployeesListView',
  components: {
    Loader,
    SubTitle,
    Search,
    Input,
    Button,
    Sorting,
    EmptyBlock,
    Card,
  },
  data() {
    return {
      loading: true,
      showPopup: false,
      showListPosition: false,
      tooltipElemCoord: {
        top: '5rem',
        left: '',
        right: ''
      },
      queryOption: {
        userId: this.$route.params.id ? this.$route.params.id : null,
        searchTxt: '',
        ordering: '',
        position: '',
        can_sign_docs: false,
        documents_access: false,
        employees_access: false,
      }
    }
  },
  created() {
    this.$store.dispatch('fetchUserEmployees', this.queryOption)
    this.$store.dispatch('fetchUserEmployeesPosition', this.queryOption)
  },
  computed: {
    employeeSorting() {
      return employeeSorting
    },
    isLoading() {
      this.getEmployees
    },
    getEmployees() {
      let employeesList = this.$store.getters.getUserEmployees
      this.$store.dispatch('pageTitle', 'Сотрудники')
      if (employeesList) {
        this.loading = false
      }
      return employeesList
    },
    getPosition() {
      return this.$store.getters.getUserEmployeesPosition
    }
  },
  methods: {
    onSearch(searchTxt) {
      this.queryOption.searchTxt = searchTxt
      this.$store.dispatch('fetchUserEmployees', this.queryOption)
    },
    addOrdering(data) {
      this.queryOption.ordering = data.query
      this.$store.dispatch('fetchUserEmployees', this.queryOption)
    },
    closePopup(event) {
      this.showPopup = false
    },
    async setEmployeeFilter() {
      await this.$store.dispatch('fetchUserEmployees', this.queryOption)
      this.closePopup()
    },
    async clearFilters() {
      this.queryOption.position = ''
      this.queryOption.can_sign_docs = false
      this.queryOption.documents_access = false
      this.queryOption.employees_access = false
      await this.$store.dispatch('fetchUserEmployees', this.queryOption)
      this.closePopup()
    }
  }
}
</script>

<style lang="scss" scoped>
.sort-block + .sort-block {
  right: 6rem;
}

.wrapper {
  .popup__window {
    max-width: 120rem;
  }

  .popup__inner {
    margin: 0 auto;
    width: 100%;
    max-width: 55rem;
  }

  .filter {
    &__block {
      margin-top: 2.6rem;
    }

    &__block-name {
      font-size: 1.8rem;
      color: #B8BABE;
    }

    &__row {
      margin-top: 1.6rem;
    }

    &__controlls {
      margin-top: 4rem;

      .btn + .btn {
        margin-top: 1.6rem;
      }
    }
  }
}
</style>