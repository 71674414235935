<template>
    <div class="card-item c-product js-masonry-item">
        <div class="js-masonry-content card-item__content">
            <div class="c-product__header">
                <div class="c-product__type">
                    <p>
                        {{ product.type == 0 ? 'Закупка' : 'Продажа'}}
                    </p>
                </div>
                <div class="c-product__time">
                    <p>
                    {{ Math.floor(new Date(+product.createdAt).getTime()/1000.0) | moment("DD.MM.YYYY HH:mm") }}
                    </p>
                </div>
            </div>
            <div class="c-product__body">
                <div class="c-product__info">
                    <p class="c-product__info-title">
                        Товар
                    </p>
                    <p class="c-product__info-descr">
                        {{product.product ? product.product.title : ''}}
                    </p>
                </div>
                <div class="c-product__info">
                    <p class="c-product__info-title">
                        Локация
                    </p>
                    <p class="c-product__info-descr">
                        {{product.region ? product.region.title : ''}},
                        {{product.city ? product.city.title : ''}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import API from "@/services/API";

export default {
    props: {
        product: {
            type: Object,
            require: true
        }
    },
    mounted() {
        API.resizeAllMasonryItems()
    },
    updated() {
            this.$nextTick(function () {
                API.resizeAllMasonryItems()
            })
        },

}
</script>

<style lang="scss">

</style>