<template>
  <div class="table">
    <div class="table__header">
      <TableHeaderCell v-for="(tHeader, idx) in tableHeaders" :key="idx" :tHeader="tHeader" :index="idx" />
    </div>
    <div class="table__loading" v-if="loading"><Loader/></div>
    <div class="table__body" :class="{ 'table__body--loading': loading}" v-if="userList.length">
      <TableBodyRow v-for="(user, idx) in userList" :key="idx" :user="user" />
    </div>
    <EmptyBlock v-else />
  </div>
</template>


<script>
  import Loader from '@/components/Loader.vue'
  import EmptyBlock from '@/components/Empty.vue'
  import tableHeaders from '@/data/UserListTabs'
  import TableHeaderCell from '@/components/HomeTable/TableHeaderCell.vue'
  import TableBodyRow from '@/components/HomeTable/TableBodyRow.vue'

  export default {
    components: {
      EmptyBlock,
      TableHeaderCell,
      TableBodyRow,
      Loader,
    },
    props: {
      userList: {
        type: Array,
        require: true
      },
      loading: {
        type: Boolean,
        require: true
      }
    },
    computed: {
      tableHeaders() {
        return tableHeaders
      }
    },
  }
</script>

<style lang="scss" scoped>

  .table__loading{
    position: absolute;
    bottom: 0;
  }

  .table__body--loading{
    filter: opacity(0.3);
  }
  .table__th {
    width: 15rem;

    &:nth-of-type(1) {
      width: 7rem;
      text-align: left;
    }

    &:nth-of-type(2) {
      justify-content: flex-start;
      width: 23rem;
    }

    &:nth-of-type(7) {
      width: 18rem;
    }

    &:nth-of-type(8) {
      width: 17rem;
    }
    &:nth-of-type(9) {
      width: 17rem;
    }

    &:nth-of-type(10) {
      width: 18rem;
    }

    &:last-child {
      width: 18rem;
      text-align: left;
    }
  }
</style>