<template>
    <div class="custom__filters js-custom-filters">
        <div class="custom__select" :class="{'opened': showList}" tabindex="0" @click="getPromocode">
            <div class="text">
                {{promocodeFormating}}
            </div>
            <img src="@/assets/images/icons/arrow-down.svg" alt="arrow">
        </div>
        <transition name="fade">
            <div class="select-list custom__select-list" v-show="showList">
                <ul>
                    <li v-for="promocode, idx in allPromocode" :key="idx">
                        <input
                            type="checkbox"
                            class="select__input js-select-input"
                            :value="promocode.code"
                            :id="promocode.id"
                            v-model="promocodeArray">
                        <label :for="promocode.id">
                            {{promocode.code}}
                        </label>
                    </li>
                </ul>
                <Button @click="promocodeFiltration">Показать</Button>
            </div>
        </transition>
    </div>
</template>


<script>
    import Button from '@/components/UI/Button.vue'
    import API from '@/services/API'
    export default {
        components: {
            Button,
        },
        data() {
            return {
                showList: false,
                allPromocode: [],
                promocodeArray: [],
            }
        },
        computed: {
            promocodeFormating() {
                let promocodeString = this.promocodeArray.length <= 1 ?
                    this.promocodeArray[0] :
                    this.promocodeArray.join(', ')
                return promocodeString
            }
        },
        methods: {
            getPromocode(event) {
                if (event.target.closest('.custom__select').classList.contains('opened')) {
                    this.showList = false
                    return
                }
                this.showList = true
                this.allPromocode = this.$store.getters.getAllPromocode
            },
            promocodeFiltration() {
                const windowBody = document.body;
                let queryOption = this.$store.state.allUsers.queryObject
                queryOption.promocode = this.promocodeArray.map(elem => '&promocode=' + elem).join('')
                queryOption.offset = null
                this.showList = false
                API.toCloseSorting(this, windowBody)
                this.$store.dispatch('fetchAllUsers', queryOption)
            }
        }
    }
</script>

<style lang="scss">
    .custom__select {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 31rem;
        min-height: 6.2rem;
        padding: 2rem 2.4rem;
        font-size: 1.6rem;
        background-color: #F7F7FA;
        border-radius: 10rem;

    }

    .custom__select .text {
        text-align: left;
    }

    .custom__select img {
        width: 2.4rem;
        height: 2.4rem;
    }

    .custom__select.opened img {
        transform: scale(1, -1);
    }

    .custom__filters {
        position: relative;
    }

    .custom__select-list {
        top: 100%;
    }
</style>