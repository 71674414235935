<template>
  <div class="card-item add-admin js-masonry-item">
    <div class="add-admin__content js-masonry-content">
      <form class="form-add-admin" @submit.prevent="onSubmit">
        <div class="add-admin__title">
          <p v-if="securityItem">
            Редактирование сотрудника СБ
          </p>
          <p v-else>
            Создание сотрудника СБ
          </p>
        </div>
        <!-- <div class="form__item" :class="{ 'form__item--error': $v.name.$error}">
            <input class="form__input" id="name" v-model.lazy="name" :class="{error: $v.name.$error}"
                @change="$v.name.$touch()" placeholder="Наименование Контроль СБ">
            <label for="name" class="form__label">
                <span>Наименование Контроль СБ</span>
            </label>
            <p class="form__item-error" v-if="!$v.name.required">Обязательное поле</p>
        </div> -->
        <div class="form__item" :class="{ 'form__item--error': $v.supervisor.$error}">
          <input
              class="form__input form__input--arrow"
              :class="{error: $v.supervisor.$error}"
              type="text"
              id="supervisor"
              placeholder="Руководитель"
              v-model.lazy="supervisorName"
              @change="$v.supervisor.$touch()"
              @focus="toggleList('show')"
              @blur="toggleList('hide')"
              @input="searchSupervisors"
          >
          <label for="type" class="form__label">
            <span>Пользователь</span>
            <img src="@/assets/images/icons/arrow-down.svg" alt="down">
          </label>
          <p class="form__item-error" v-if="!$v.supervisor.required">
            Обязательное поле
          </p>
          <transition name="fade">
            <div class="select-list" v-show="showListSupervisor">
              <ul @scroll="uploadInfoList">
                <li class="su-goods__select-item js-filter-item" v-for="(user, idx) in getSupervisors"
                    :key="idx">
                  <input type="radio" name="user" class="select__input js-select-input"
                         :id="'user-' + user.id"
                         :checked="securityItem && securityItem.supervisor.id === user.id"
                         ref="user"
                         @change="userChosen(user)"
                  >
                  <label :for="'user-' + user.id"> {{ user.name }}
                    {{ `(${user.uniqueNumber}), ИНН ${user.inn}` }} </label>
                </li>
              </ul>
            </div>
          </transition>
        </div>
        <div class="form__item" :class="{ 'form__item--error': $v.email.$error}" v-if="!securityItem">
          <input class="form__input" id="email" v-model.lazy="email" :class="{error: $v.email.$error}"
                 @change="$v.email.$touch()" placeholder="Логин (email)" autocomplete="new-email">
          <label for="email" class="form__label"><span>Логин (email)</span></label>
          <p class="form__item-error" v-if="!$v.email.required">
            Обязательное поле
          </p>
          <p class="form__item-error" v-if="!$v.email.email">
            Введите email
          </p>
        </div>
        <div class="form__item" :class="{ 'form__item--error': $v.password.$error }" v-if="!securityItem">
          <input class="form__input" id="password" v-model.lazy="password"
                 :class="{error: $v.password.$error}" type="password" @change="$v.password.$touch()"
                 placeholder="Пароль" autocomplete="new-password">
          <label for="password" class="form__label"><span>Пароль</span></label>
          <p class="form__item-error" v-if="!$v.password.required">
            Обязательное поле
          </p>
          <p class="form__item-error"
             v-if="!$v.password.minLength || !$v.password.maxLength || !$v.password.alpha">
            Код должен быть длиной от {{ $v.password.$params.minLength.min }} до
            {{ $v.password.$params.maxLength.max }} символов и состоять из латинских букв любого регистра или
            цифр
          </p>
        </div>
        <div class="form__controlls" :class="{'form-send': isSend}">
          <Button>
            Сохранить
          </Button>
          <Button type="button" class="btn--grey" @click="$router.back()" v-if="securityItem">
            Отмена
          </Button>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import {email, maxLength, minLength, required, requiredIf} from 'vuelidate/lib/validators'
import Button from '@/components/UI/Button.vue'

export default {
  props: {
    securityItem: {
      type: Object,
      default: null
    }
  },
  components: {
    Button,
  },
  data() {
    return {
      debounce: null,
      search: '',
      isSend: false,
      name: this.securityItem ? this.securityItem.name : '',
      email: '',
      password: '',
      supervisor: this.securityItem ? this.securityItem.supervisor.id : '',
      supervisorName: this.securityItem ? this.securityItem.supervisor.name : '',
      showListSupervisor: false,
      upload: false,
      scrollTop: 0,
    }
  },
  validations: {
    email: {
      required: requiredIf(function () {
        return !this.securityItem;
      }),
      email
    },
    password: {
      required: requiredIf(function () {
        return !this.securityItem;
      }),
      minLength: minLength(8),
      maxLength: maxLength(14),
      alpha: val => /^[a-zA-Z0-9]*$/i.test(val),
    },
    supervisor: {
      required,
    },
  },
  computed: {
    getLoading() {
      return this.$store.getters.getSecLoading
    },
    getTotalCount() {
      return this.$store.getters.getSecTotalCount
    },
    getSupervisors() {
      return this.$store.getters.getAllSupervisors
    },
  },
  methods: {
    async onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.isSend = true
        if (this.securityItem) {
          const securityInfo = {
            "supervisor": this.supervisor,
          }
          await this.$store.dispatch('editSecurity', {
            securityId: this.securityItem.id,
            securityInfo
          })
          this.$router.back()
        } else {
          const securityInfo = {
            "email": this.email,
            "password": this.password,
            "supervisor": this.supervisor,
          }
          await this.$store.dispatch('addSecurity', securityInfo)
          this.email = ''
          this.password = ''
          this.supervisorName = ''
          this.$refs.user.forEach(input => input.checked = false)
          this.isSend = false
          this.search = ''
          this.$v.$reset()
        }
      }
    },

    async uploadInfoList(elem) {
      let list = elem.target
      const isLoading = this.$store.getters.getSecLoading;
      const hasMore = this.$store.getters.getSecTotalCount > this.$store.getters.getAllSupervisors.length;

      console.log('scrolled' + !!(list.scrollTop + list.clientHeight) > (list.scrollHeight - 5))
      console.log(!isLoading)
      console.log(hasMore)
      console.log(!isLoading && hasMore)

      if (!isLoading && hasMore && (list.scrollTop + list.clientHeight) > (list.scrollHeight - 5)) {
        let offset = this.$store.getters.getAllSupervisors.length
        await this.$store.dispatch('fetchSupervisors', {
              offset: offset,
              search: this.search
            }
        )
      }
    },

    async searchSupervisors(event) {
      this.supervisorName = event.target.value
      this.search = event.target.value
      clearTimeout(this.debounce);
      this.debounce = setTimeout(
          async () => {
            await this.$store.dispatch('clearSupervisors')
            await this.$store.dispatch('fetchSupervisors', {
              search: event.target.value
            })
          },
          300
      )
    },

    toggleList(action) {
      if (action === 'show') {
        this.showListSupervisor = true
        this.scrollTop = document.querySelector('.content').scrollTop
      } else {
        this.showListSupervisor = false
      }
    },

    userChosen(user) {
      this.supervisor = user.id
      this.supervisorName = user.name
      document.querySelector('.content').scrollTo(0, this.scrollTop);
    },
  }
}
</script>

<style lang="scss" scoped>

</style>