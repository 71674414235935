<template>
  <div class="users-body" v-if="loading">
   {{isLoading}}
      <Loader />
  </div>
  <div class="users-body" v-else>
    <div class="su-rating-info" v-if="Object.keys(getRating).length">
      <div class="cards-list c-company__list js-masonry"> 
        <Card v-for="rating, idx in getRating" :key="idx" :rating="rating" />
      </div>
    </div>
    <div class="su-empty su-about-empty" v-else>
      <EmptyBlock />
    </div>
  </div>
</template>

<script>
  import Loader from '@/components/Loader.vue'
  import EmptyBlock from '@/components/Empty.vue'
  import Card from '@/components/Cards/RatingCard.vue'

  export default {
    components: {
      Loader,
      EmptyBlock,
      Card,
    },
     data() {
      return {
        loading: true,
      }
    },
    created() {
      let ratingInfo = {
        userId: this.$route.params.id ? this.$route.params.id : null,
        status: 'status=1',
        search: '',
      }
      this.$store.dispatch('uploadRatings', ratingInfo)
    },
    computed: {
      isLoading() {
        this.getRating
      },
      getRating() {
        this.$store.dispatch('pageTitle', 'Рейтинг и отзывы')
        let ratingList = this.$store.getters.getRatings
        if (ratingList != null) {
          this.loading = false
        } 
        return ratingList
      }
    },
  }
</script>

<style lang="scss" scoped>
   .cards-list {
        grid-template-columns: repeat(2, 1fr)
    }
</style>