<template>
    <div class="card-item add-admin js-masonry-item">
        <div class="add-admin__content js-masonry-content">
            <form class="form-add-admin" @submit.prevent="onSubmit">
                <div class="add-admin__title">
                    <p>
                        Промокод
                    </p>
                </div>
                <div class="form__item" :class="{ 'form__item--error': $v.code.$error}">
                    <span class="promocode__auto" @click="promocodeAuto">
                        <img src="@/assets/images/icons/update.svg" alt="update">
                    </span>
                    <input class="form__input" id="code" v-model.lazy="code"
                        :class="{error: $v.code.$error}" @change="$v.code.$touch()" placeholder="Промокод" autocomplete="new-code">
                    <label for="code" class="form__label">
                        <span>Промокод</span>
                    </label>
                    <p class="form__item-error" v-if="!$v.code.required">
                        Обязательное поле
                    </p>
                    <p class="form__item-error"
                        v-if="!$v.code.minLength || !$v.code.maxLength || !$v.code.alpha">
                        Код должен быть длиной в 6 символов и состоять из латинских букв любого регистра или
                        цифр
                    </p>
                </div>
                <div class="form__row">
                    <div class="form__item" :class="{ 'form__item--error': $v.dateStart.$error}">
                        <input class="form__input" id="dateStart" v-model="dateStart"
                            :class="{error: $v.dateStart.$error}" @change="$v.dateStart.$touch()"
                            placeholder="Дата начала" v-mask="'##.##.####'" autocomplete="new-date-start">
                        <label for="dateStart" class="form__label">
                            <span>Дата начала </span>
                        </label>
                        <p class="form__item-error" v-if="!$v.dateStart.required">
                            Обязательное поле
                        </p>
                        <p class="form__item-error" v-if="!$v.dateStart.minLength || !$v.code.maxLength">
                            Введите правильную дату
                        </p>
                        <p class="form__item-error" v-if="!$v.dateStart.minValue">
                            Дата не должна быть меньше сегодняшней
                        </p>
                        <p class="form__item-error" v-if="!$v.dateStart.maxValue">
                            Дата не должна первышать 100 лет
                        </p>
                    </div>
                    <div class="form__item" :class="{ 'form__item--error': $v.timeStart.$error}">
                        <input class="form__input" id="timeStart" v-model="timeStart"
                            :class="{error: $v.timeStart.$error}" @change="$v.timeStart.$touch()"
                            placeholder="Время начала " v-mask="'##:##'" autocomplete="new-time-start">
                        <label for="timeStart" class="form__label">
                            <span>Время начала</span>
                        </label>
                        <p class="form__item-error" v-if="!$v.timeStart.required">
                            Обязательное поле
                        </p>
                        <p class="form__item-error" v-if="!$v.timeStart.minLength || !$v.timeStart.maxLength || !$v.timeStart.timeType">
                            Введите правильное время
                        </p>
                    </div>
                </div>
                <div class="form__row">
                    <div class="form__item" :class="{ 'form__item--error': $v.dateEnd.$error}">
                        <input class="form__input" id="dateEnd" v-model="dateEnd"
                            :class="{error: $v.dateEnd.$error}" @change="$v.dateEnd.$touch()"
                            placeholder="Дата окончания" v-mask="'##.##.####'" autocomplete="new-date-end">
                        <label for="dateEnd" class="form__label">
                            <span>Дата окончания</span>
                        </label>
                        <p class="form__item-error" v-if="!$v.dateEnd.required">
                            Обязательное поле
                        </p>
                        <p class="form__item-error" v-if="!$v.dateEnd.minLength || !$v.code.maxLength">
                            Введите правильную дату
                        </p>
                            <p class="form__item-error" v-if="!$v.dateEnd.minValue">
                            Дата не должна быть меньше даты начала
                        </p>
                        <p class="form__item-error" v-if="!$v.dateEnd.maxValue">
                            Дата не должна первышать 100 лет
                        </p>
                    </div>
                    <div class="form__item" :class="{ 'form__item--error': $v.timeEnd.$error}">
                        <input class="form__input" id="timeEnd" v-model="timeEnd"
                            :class="{error: $v.timeEnd.$error}" @change="$v.timeEnd.$touch()"
                            placeholder="Время окончания" v-mask="'##:##'" autocomplete="new-time-end">
                        <label for="timeEnd" class="form__label">
                            <span>Время окончания</span>
                        </label>
                        <p class="form__item-error" v-if="!$v.timeEnd.required">
                            Обязательное поле
                        </p>
                        <p class="form__item-error" v-if="!$v.timeEnd.minLength || !$v.timeEnd.maxLength || !$v.timeEnd.timeType">
                            Введите правильное время
                        </p>
                    </div>
                </div>

                <div class="form__controlls" :class="{'form-send': isSend}">
                    <Button>
                        Сохранить
                    </Button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import API from "@/services/API";
    import {
        required,
        minLength,
        maxLength
    } from 'vuelidate/lib/validators'
    import Loader from '@/components/Loader.vue'
    import Button from '@/components/UI/Button'
    import Search from '@/components/Controlls/Search.vue'
    import SubTitle from '@/components/UI/SubTitle.vue'
    import Card from '@/components/Cards/PromocodeCard.vue'

    export default {
        name: 'AdminView',
        components: {
            Loader,
            Search,
            SubTitle,
            Button,
            Card
        },
        data() {
            return {
                code: '',
                dateStart: '',
                dateEnd: '',
                timeStart: '',
                timeEnd: '',
                isSend: false,
            }
        },
        validations: {
            code: {
                required,
                minLength: minLength(6),
                maxLength: maxLength(6),
                alpha: val => /^[a-zA-Z0-9]*$/i.test(val),
            },
            dateStart: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(10),
                minValue(val) {
                    let dateArray = val.split('.')
                    let date = new Date(`${dateArray[2]}, ${dateArray[1]}, ${+dateArray[0] + 1}`)
                    return date.getTime() >= Date.now()
                },
                maxValue(val) {
                    let dateArray = val.split('.')
                    let date = new Date(`${dateArray[2]}, ${dateArray[1]}, ${dateArray[0]}`)
                    return date.getTime() < Date.now() + (100*365*24*60*60*1000) 
                }
            },
            dateEnd: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(10),
                minValue(val) {
                    let startDateArray = this.dateStart.split('.')
                    let endDateArray = val.split('.')
                    let startDate = new Date(`${startDateArray[2]}, ${startDateArray[1]}, ${startDateArray[0]}`)
                    let endDate = new Date(`${endDateArray[2]}, ${endDateArray[1]}, ${endDateArray[0]}`)

                    return this.dateStart 
                        ? endDate > startDate
                        : endDate.getTime() > Date.now()
                },
                maxValue(val) {
                    let dateArray = val.split('.')
                    let date = new Date(`${dateArray[2]}, ${dateArray[1]}, ${dateArray[0]}`)
                    return date.getTime() < Date.now() + (100*365*24*60*60*1000) 
                }
            },
            timeStart: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(5),
                timeType(val) {
                    let timeArray = val.split(':')
                    return timeArray[0] < 24 && timeArray[1] < 60
                },
            },
            timeEnd: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(5),
                timeType(val) {
                    let timeArray = val.split(':')
                    return timeArray[0] < 24 && timeArray[1] < 60
                },
            }
        },
        methods: {
            async onSubmit() {
                this.$v.$touch()
                if (!this.$v.$invalid) {
                    const promocodeInfo = {
                        code: this.code,
                        startDt: this.fomatedDate(this.dateStart, this.timeStart),
                        endDt: this.fomatedDate(this.dateEnd, this.timeEnd),
                    }

                    this.isSend = true
                    await this.$store.dispatch('addPromocode', promocodeInfo)
                    this.isSend = false
                    this.code = '',
                    this.dateStart = '',
                    this.dateEnd = '',
                    this.timeStart = '',
                    this.timeEnd = '',
                    this.$v.$reset()
                }
            },
            fomatedDate(date, time) {
                let dateArray = date.split('.')
                let dateString = `${dateArray[1]} ${dateArray[0]}, ${dateArray[2]} ${time}:00`;
                return new Date(dateString).getTime()
            },
            async promocodeAuto() {
                this.isSend = true
                this.code = await this.$store.dispatch('autoPromocode')
                this.isSend = false
            },
        }
    }
</script>

<style lang="scss">
    .promocode__auto {
        position: absolute;
        right: 2rem;
        top: 2rem;
        cursor: pointer;
    }
</style>