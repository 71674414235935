import store from "./index";
import axios from 'axios'

export default {
    state: {
        ratings: null,
        queryObject: {},
    },
    getters: {
        getRatings(state) {
            return state.ratings
        },
    },
    mutations: {
        setRatings(state, ratings) {
            state.ratings = ratings
        },
        
    },
    actions: {
        uploadRatings({ commit, state }, ratingInfo) {
            state.queryObject = ratingInfo
            let options = `&${ratingInfo.status}`
            if (ratingInfo.offset) { options += `&${ratingInfo.offset}` }
            
            return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/ratings?search=${ratingInfo.search}${options}`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
                }).then(response => {
                    let _allRatings = state.ratings
                    if (ratingInfo.offset && _allRatings) {
                        response.data.forEach(elem => {
                            _allRatings.push(elem)
                        })
                        commit('setRatings', _allRatings)
                    } else {
                        commit('setRatings', response.data)
                    }
                })
                .catch(error => console.log(error))
        },
        editRating({ commit, state }, changeInfo) {
            let _ratingInfo = state.queryObject
            let body = {
                "status": changeInfo.status
            }
            
            return axios.put(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/ratings/${changeInfo.id}`, body, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
                }).then(response => {
                    this.dispatch('uploadRatings', _ratingInfo)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        // deleteRating({ commit, state }, ratingId) {
        // let _ratingInfo = state.queryObject
        //     return axios.delete(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/ratings/${ratingId}`, {
        //         headers: {
        //             Authorization: `JWT ${store.state.API_TOKEN}`
        //         },
        //         }).then(response => {
        //             this.dispatch('uploadRatings', ratingInfo)
        //         })
        //         .catch(error => {
        //             console.log(error)
        //         })
        // },
    },
}