<template>
  <div class="su-view su-payments">
    <Search @search='onSearch' />
    <Tabs :tabs="tabs" />
    <router-view></router-view>
  </div>
</template>

<script>
  import Search from '@/components/Controlls/Search.vue'
  import Tabs from '@/components/Controlls/Tabs.vue'

  export default {
    name: 'PaymentsView',
    components: {
      Search,
      Tabs
    },
    data() {
      return {
        tabs: [{
            title: 'Входящие', url: 'UserPaymentsIncoming'
          },
          {
            title: 'Исходящие', url: 'UserPaymentsOutgoing'
          },
        ]
      }
    },
    methods: {
      onSearch(searchTxt) {
        let _paymentsInfo = this.$store.state.payments.paymentsInfo
        _paymentsInfo.offset = null
        _paymentsInfo.search = searchTxt
        this.$store.dispatch('uploadPayments', _paymentsInfo)
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>