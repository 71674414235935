<template>
  <div class="sorting" :id="'tooltip-id-' + filters.filtersName">
    <div class="sorting__block">
      <UniqueNumberFilters v-if="filters.filtersName === 'uniqueNumber'"/>
      <PromocodeFilters v-if="filters.filtersName === 'promocode'"/>
      <SupervisorFilters v-if="filters.filtersName === 'supervisor'"/>
      <Input v-for="(filter, idx) in filters.filtersTabs" :key="idx" :inputClass="'sorting__input js-sorting-input'"
             :name="filters.filtersName" :type="'radio'" :label="true" :id="filters.filtersName + '_' + idx">
        <label :data-group="filters.filtersName" :for="filters.filtersName + '_' + idx"
               @click="onFiltrationBy(filter.filterTabRequest, $event)">
          {{ filter.filterTabTitle }}
        </label>
      </Input>

    </div>
  </div>
</template>


<script>
import Input from '@/components/UI/Input.vue'
import Button from '@/components/UI/Button.vue'
import UniqueNumberFilters from '@/components/Controlls/CustomFilters/UniqueNumber.vue'
import PromocodeFilters from '@/components/Controlls/CustomFilters/Promocode.vue'
import SupervisorFilters from '@/components/Controlls/CustomFilters/Supervisor.vue'

export default {
  props: {
    filters: {
      type: Object,
      require: false
    }
  },
  components: {
    PromocodeFilters,
    UniqueNumberFilters,
    SupervisorFilters,
    Input,
    Button,
  },
  data() {
    return {}
  },
  methods: {
    onFiltrationBy(query) {
      let label = event.target
      let queryGroup = label.dataset.group
      this.$emit('addFiltration', {
        queryGroup: queryGroup,
        query: query
      })
    },
  }
}
</script>

<style lang="scss">
.sorting {
  .input-controlls + .input-controlls {
    margin-top: 1.6rem;
  }
}

</style>