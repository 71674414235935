const goodsSorting = {
    sortingTabs: [{
            sortingName: 'goods',
            sortingAsc: 'sales_count',
            sortingDesc: '-sales_count',
            sortingAscTitle: 'По возрастанию закупленных товаров',
            sortingDescTitle: 'По убыванию закупленных товаров'
        },
        {
            sortingName: 'goods',
            sortingAsc: 'purchases_count',
            sortingDesc: '-purchases_count',
            sortingAscTitle: 'По возрастанию проданных товаров',
            sortingDescTitle: 'По убыванию проданных товаров'
        },
    ],
}

export default goodsSorting