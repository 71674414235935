<template>
  <form class="form-login" @submit.prevent="onSubmit">
    <div class="form__inner">
      <div class="form__title">
        <h2>
          Вход
        </h2>
      </div>
      <div class="form__item" :class="{ 'form__item--error': $v.email.$error}">
        <input class="form__input" id="email" v-model.lazy="email" :class="{error: $v.email.$error}"
               @change="$v.email.$touch()" placeholder="Логин (email)">
        <label for="email" class="form__label"><span>Логин</span></label>

        <p class="form__item-error" v-if="!$v.email.required">
          Обязательное поле
        </p>
        <p class="form__item-error" v-if="!$v.email.email">
          Введите email
        </p>
      </div>
      <div class="form__item" :class="{ 'form__item--error': $v.password.$error }">
        <input class="form__input" id="password" v-model.lazy="password" :class="{error: $v.password.$error}"
               type="password" @change="$v.password.$touch()" placeholder="Пароль">
        <label for="password" class="form__label"><span>Пароль</span></label>

        <p class="form__item-error" v-if="!$v.password.required">
          Обязательное поле
        </p>
      </div>
      <div class="form__controlls" :class="{'form-send': isSend}">
        <Button>
          Войти
        </Button>
      </div>
    </div>

  </form>
</template>

<script>
import {
  required,
  email
} from 'vuelidate/lib/validators'
import Button from '@/components/UI/Button'

import axios from 'axios'
import {getToken} from 'firebase/messaging'
import {messaging} from '@/services/firebase'

export default {
  components: {
    Button
  },
  data() {
    return {
      email: '',
      password: '',
      isSend: false
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
    },
  },
  methods: {
    async onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const user = {
          email: this.email,
          password: this.password,
        }

        this.isSend = true
        await this.$store.dispatch('userLogin', user)

        if (this.$store.state.errorText != null) {
          this.email = ''
          this.password = ''
          return
        } else {
          getToken(messaging, {
            vapidKey: process.env.VUE_APP_VAPID_KEY
          }).then((currentToken) => {
            this.$store.dispatch('registrationDevice', currentToken)
          }).catch(e => alert(e))
        }
        this.$v.$reset()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.form-login {
  width: 100%;
  max-width: 71rem;
  padding: 10rem;
  background-color: #ffffff;
  backdrop-filter: blur(12px);
  border-radius: 3rem;

  .form__title {
    margin-bottom: 5rem;
    font-size: 2.6rem;
    font-weight: 600;
  }
}

@media screen and (max-width: 1000px) {
  .form-login {
    padding: 5rem;
  }
}

@media screen and (max-width: 450px) {
  .form-login {
    padding: 5rem 2.5rem;
  }
}
</style>