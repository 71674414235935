<template>
    <div class="card-item c-good js-masonry-item">
        <div class="js-masonry-content card-item__content">
            <div class="c-good__header">
                <div class="c-good__title">
                    <p>
                        {{ deal.title }}
                    </p>
                </div>
            </div>
            <div class="c-good__body">
                <div class="c-good__info">
                    <p>
                        Закупка {{ deal.purchasesCount }}
                    </p>
                    <p>
                        Продажа {{ deal.salesCount }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import API from "@/services/API";

    export default {
        props: {
            deal: {
                type: Object,
                require: true
            }
        },
        mounted() {
            API.resizeAllMasonryItems()
        },
        updated() {
            this.$nextTick(function () {
                API.resizeAllMasonryItems()
            })
        },

    }
</script>

