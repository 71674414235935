<template>
  <div class="card-item add-admin js-masonry-item">
    <div class="add-admin__content js-masonry-content">
      <div class="form-add-admin">
        <div class="add-admin__title">
          <p>
            Импорт модераторов из файла
          </p>
        </div>
        <input type="file" ref="fileInput" @change="handleFileChange" accept=".csv, .xls, .xlsx"
               style="display: none;"/>
        <div v-if="file" class="form__input" style="position: relative">
          {{ file.name }}
          <div style="position: absolute; right: 0; top: 0.7rem; cursor: pointer" class="btn btn--grey btn--add"
               @click="clearFileInput">
            <img src="@/assets/images/icons/close.svg" alt="close">
          </div>
        </div>
        <button v-else class="btn btn--grey btn--add" @click="triggerFileInput">
          <img src="@/assets/images/icons/plus.svg" alt="add">
        </button>
        <div class="form__controlls form__controlls--decor">
          <button class="btn"  :class="{'btn--disabled': isLoading}" @click="uploadFile" :disabled="!file">Загрузить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/UI/Input.vue";

export default {
  components: {Input},
  data() {
    return {};
  },
  computed: {
    file() {
      return this.$store.getters.getFile
    },
    isLoading() {
      return this.$store.getters.getIsLoading
    }
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    clearFileInput() {
      this.$store.commit('setFile', null);
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.$store.commit('setFile', file);
    },
    uploadFile() {
      this.$store.commit('setLoading', true);
      this.$store.dispatch('importAdmins');
    },
  },
};
</script>