<template>
   <div class="table__th">
        <span class="table__th-sort" v-if="tHeader.isSorting">
           <img src="@/assets/images/icons/sort.svg" alt="sort" 
            class="js-tooltip-icon" 
            :data-id="'tooltip-id-' + tHeader.sorting.sortingTabs[0].sortingName">
       </span>
       <span class="table__th-sort" v-if="tHeader.isFilter">
           <img src="@/assets/images/icons/sort.svg" alt="sort" 
            class="js-tooltip-icon" :data-id="'tooltip-id-' + tHeader.filters.filtersName" >
       </span>
       {{tHeader.title}}
       <!-- <Sorting :style="tooltipElemCoord" :sorting="tHeader.sorting" v-if="tHeader.isSorting"/> -->
       <Filtration :style="tooltipElemCoord" :filters="tHeader.filters" v-if="tHeader.isFilter" @addFiltration="addFiltration"/>
   </div>
</template>

<script>
  import Sorting from '@/components/Controlls/Sorting.vue'
  import Filtration from '@/components/Controlls/Filtration.vue'

export default {
  components: {
    Sorting,
    Filtration
  },
  props: {
    tHeader: {
      type: Object,
      require: true
    },
    index: {
      type: Number,
      require: false
    },
  },
  data() {
    return {
      showSorting: false,
      showSortingId: -1,
      tooltipElemCoord: {
        top: '5rem',
        left: '',
        right: ''
      },
    }
  },
  methods: {
      addFiltration(data) {
        console.log(data);
        let queryOption = this.$store.state.allUsers.queryObject
        queryOption.filters[data.queryGroup] = data.query
        this.$store.dispatch('fetchUsersToDelete', queryOption)
    }
  }
}
</script>

<style lang="scss" scoped>
.table{
  &__th {
    max-width: 50%;
    padding: 4.4rem 6.3rem;

    &:nth-child(1) {
      justify-content: flex-start;
    }
    &:nth-child(2){
      text-align: center;
    }
  }
}
</style>