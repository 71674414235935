<template>
    <router-link :to="({ name: 'User', params: { id: company.target.id } })" class="card-item c-company js-masonry-item">
        <div class="js-masonry-content card-item__content">
            <div class="c-company__header">
                <div class="c-company__image">
                    <img :src="getImage" alt="avatar" v-if="getImage">
                    <img src="@/assets/images/no_avatar.png" alt="avatar" v-else>
                </div>
                <div class="c-company__name">
                    <p>
                        {{company.target.profile.name}}
                    </p>
                </div>
            </div>
            <div class="c-company__body">
                <div class="c-company__time">
                    <p>
                    {{ Math.floor(new Date(+company.createdAt).getTime()/1000.0) | moment("DD.MM.YYYY HH:mm") }}
                    </p>
                </div>
            </div>
            <div class="c-company__arrow">
                <img src="@/assets/images/icons/arrow-right.svg" alt="arrow-right">
            </div>
        </div>
    </router-link>
</template>

<script>
import API from "@/services/API";

export default {
    props: {
        company: {
            type: Object,
            require: true
        }
    },
    mounted() {
        API.resizeAllMasonryItems()
    },
    updated() {
            this.$nextTick(function () {
                API.resizeAllMasonryItems()
            })
        },
    computed: {
        getImage() {
            let companyImage = this.company.target.profile.avatar.file.length 
                ? this.company.target.profile.avatar.file
                : false

            return companyImage
        }
    }

}
</script>

<style lang="scss">

</style>