<template>
   <form class="search" @submit.prevent="onSearch">
       <span class="search__loupe">
           <img src="@/assets/images/icons/loupe.svg" alt="loupe">
       </span>
       <input type="text" class="search__input" name="search" placeholder="Что вы ищете?" v-model="searchText">
        <button 
            class="search__btn" 
            type="button" 
            v-show="searchText.length" 
            @click="clearSearch">
            Отмена
        </button>
   </form>
</template>

<script>
export default {
    data() {
        return {
            searchText: ''
        }
    },
   
    methods: {
        onSearch() {
            this.$emit('search', this.searchText)
        },

        clearSearch() {
            this.searchText = ''
            this.onSearch()
        }
    }
}
</script>

<style lang="scss">
 .su-employee .duser__search-block .search__btn {
    right: 12rem;
  }
</style>
