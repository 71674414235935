<template>
  <div class="users-list">
    <div class="users-header">
      <Search @search='onSearch'/>

      <a :href="usersCSV" class="btn btn--white users-header__download" download="users-csv.csv">
        <img src="@/assets/images/icons/download.svg" alt="download">
      </a>
      <Button :class="'users-header__delete'" @click="goToDeleteUsers">
        <span :class="{'have-for-delete': hasForDelete}"> Запросы на удаление</span>
        <img src="@/assets/images/icons/trash.svg" alt="trash">
      </Button>
    </div>
    {{ isLoading }}
    <div class="users-body" v-if="loading">
      <Loader/>
    </div>
    <div class="users-body" v-else>
      <div class="total-count">
        Всего: {{ getTotalCount || 0 }}
      </div>
      <HomeTable :userList="allUserLoaded" :loading="getLoading" v-if="allUserLoaded"/>
      <EmptyBlock v-else/>
    </div>
  </div>
</template>

<script>
import EmptyBlock from '@/components/Empty.vue'
import Loader from '@/components/Loader.vue'

import Search from '@/components/Controlls/Search.vue'
import Button from '@/components/UI/Button.vue'
import HomeTable from '@/components/HomeTable/Table.vue'

export default {
  name: 'UsersList',
  components: {
    EmptyBlock,
    Loader,
    Search,
    Button,
    HomeTable,
  },
  data() {
    return {
      hasForDelete: false,
      loading: true,
      queryOption: {
        searchTxt: '',
        ordering: {},
        filters: {}
      }
    }
  },
  mounted() {
    this.queryOption = {searchTxt: '', ordering: {}, filters: {}}
    this.$store.dispatch('fetchAllUsers', this.queryOption)
    this.$store.dispatch('checkUsersToDelete')
    // this.$store.dispatch('uploadUsersCSV')
    this.$store.dispatch('uploadPromocode', {searchTxt: ''})
  },
  computed: {
    getLoading() {
      return this.$store.getters.getLoading
    },
    isLoading() {
      this.allUserLoaded
      this.usersCSV
    },
    allUserLoaded() {
      let userList = this.$store.getters.getAllUsers
      let userDeleteList = this.$store.getters.getForDeleteUsers

      if (userList) this.loading = false
      // if (userDeleteList && userDeleteList.length) this.hasForDelete = true
      this.hasForDelete = this.$store.getters.getUsersToDeleteExist

      return userList
    },
    usersCSV() {
      return this.$store.getters.getUsersCSV
    },
    getTotalCount() {
      return this.$store.getters.getTotalCount
    },
  },
  methods: {
    goToDeleteUsers() {
      this.$router.push({
        name: 'UsersForDelete'
      });
    },
    onSearch(searchTxt) {
      let queryOption = this.$store.state.allUsers.queryObject
      queryOption.searchTxt = searchTxt
      queryOption.offset = null

      this.$store.dispatch('fetchAllUsers', queryOption)
    },
  }
}
</script>

<style lang="scss" scoped>
.users-list {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.users-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3.2rem;

  .search {
    margin-bottom: 0;
    flex: 1 0 auto;
  }

  &__download {
    flex-shrink: 0;
    width: 7.2rem;
    height: 7.2rem;
    margin-left: 1.6rem;
    border-radius: 3rem;

    img {
      max-width: 100%;
    }
  }

  &__delete {
    max-width: 34rem;
    height: 7.2rem;
    margin-left: 1.6rem;

    img {
      display: none;
    }

    .have-for-delete {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -1.8rem;
        display: inline-block;
        width: 1rem;
        height: 1rem;
        background-color: #FF5757;
        border: .1rem solid #fff;
        border-radius: 3rem;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .users-header {
    &__download {
      width: 5.2rem;
      height: 5.2rem;
    }

    &__delete {
      width: 5.2rem;
      height: 5.2rem;

      span {
        display: none;
      }

      img {
        display: inline-block;
      }
    }
  }
}
</style>