import store from "./index";
import router from '@/router/index'
import axios from 'axios'

export default {
  state: {
    cats: null,
    singleCat: null,
    volumes: null,
    subcatForEdit: null
  },
  getters: {
    getCats(state) {
      return state.cats
    },
    getSingleCat(state) {
      return state.singleCat
    },
    getVolumes(state) {
      return state.volumes
    },
  },
  mutations: {
    setCats(state, cats) {
      state.cats = cats
    },
    setSingleCat(state, singleCat) {
      state.singleCat = singleCat
    },
    setVolumes(state, volumes) {
      state.volumes = volumes
    },
  },
  actions: {
    uploadCats({commit,state}) {
      return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/lots/categories`, {
          headers: {
            Authorization: `JWT ${store.state.API_TOKEN}`
          }
        }).then(response => {
          commit('setCats', response.data)
        })
        .catch(error => console.log(error))
    },
    uploadSingleCat({commit,state}, catInfo) {
      return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/lots/categories/${catInfo.catId}`, {
          headers: {
            Authorization: `JWT ${store.state.API_TOKEN}`
          }
        }).then(response => {
          if (catInfo.subcat1) {
            let singleCat = store.state.cats.singleCat
            let singleCatSubcat = singleCat.subCategories
            let subcatForAppend = singleCatSubcat.find(elem => elem.id == catInfo.catId)
            if (subcatForAppend) {
              subcatForAppend.subCategories = response.data.subCategories
            } else {
              if (catInfo.subcat1) {
                let parentSubcat = singleCatSubcat.find(elem => elem.id == catInfo.subcat1)
                let subcatForAppend2 = parentSubcat.subCategories.find(elem => elem.id == catInfo.catId)
                if (subcatForAppend2) {
                  subcatForAppend2.subCategories = response.data.subCategories
                } else {
                  let parentSubcat2 = parentSubcat.subCategories.find(elem => elem.id == catInfo.subcat2)
                  let subcatForAppend3 = parentSubcat2.subCategories.find(elem => elem.id == catInfo.catId)
                  if (subcatForAppend3) {
                    subcatForAppend3.subCategories = response.data.subCategories
                  } 
                }
              }
            }
            commit('setSingleCat', singleCat)
          } else {
            commit('setSingleCat', response.data)
          }
        })
        .catch(error => console.log(error))
    },
    uploadVolumes({commit,state}) {
      return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/lots/volume_measurements`, {
          headers: {
            Authorization: `JWT ${store.state.API_TOKEN}`
          }
        }).then(response => {
          commit('setVolumes', response.data)
        })
        .catch(error => console.log(error))
    },
    uploadSingleSubcat({commit,state}, subcatInfo) {
      let catObject = {}

      return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/lots/categories/${subcatInfo.catId}`, {
          headers: {
            Authorization: `JWT ${store.state.API_TOKEN}`
          }
        }).then(response => {
          catObject.cat = {
            item: response.data,
            active: true
          }
          if (subcatInfo.subcat_1_id) {
            catObject.cat.active = false
            let subcat_1 = catObject.cat.item.subCategories.find(elem => elem.id == subcatInfo.subcat_1_id)
            if (!subcat_1) {
              return router.go(-1)
            }

            axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/lots/categories/${subcat_1.id}`, {
              headers: {
                Authorization: `JWT ${store.state.API_TOKEN}`
              }
            }).then(response => {
              subcat_1 = response.data
              catObject.subcat_1 = {
                item: subcat_1,
                active: true
              }

              if (subcatInfo.subcat_2_id) {
                catObject.subcat_1.active = false
                let subcat_2 = catObject.subcat_1.item.subCategories.find(elem => elem.id == subcatInfo.subcat_2_id)
                if (!subcat_2) {
                  return router.go(-1)
                }
                axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/lots/categories/${subcat_2.id}`, {
                  headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                  }
                }).then(response => {
                  subcat_2 = response.data
                  catObject.subcat_2 = {
                    item: subcat_2,
                    active: true
                  }
                  if (subcatInfo.subcat_3_id) {
                    catObject.subcat_2.active = false
                    let subcat_3 = catObject.subcat_2.item.subCategories.find(elem => elem.id == subcatInfo.subcat_3_id)
                    if (!subcat_3) {
                      return router.go(-1)
                    }
                    axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/lots/categories/${subcat_3.id}`, {
                      headers: {
                        Authorization: `JWT ${store.state.API_TOKEN}`
                      }
                    }).then(response => {
                      subcat_3 = response.data
                      catObject.subcat_3 = {
                        item: subcat_3,
                        active: true
                      }
                      commit('setSingleCat', catObject)
                    })
                  } else {
                    commit('setSingleCat', catObject)

                  }
                })
              } else {
                commit('setSingleCat', catObject)
              }
            })
          } else {
            commit('setSingleCat', catObject.cat.item)
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    addCategory({commit,state}, catInfo) {
      let body = {
        "title": catInfo.category,
        "volume_measurement": catInfo.volume
      }

      return axios.post(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/lots/categories`, body, {
          headers: {
            Authorization: `JWT ${store.state.API_TOKEN}`
          }
        }).then(response => {
          commit('setErrorText', 'Категория создана')
          this.dispatch('uploadCats')
        })
        .catch(error => console.log(error))
    },
    addSubcat({commit,state}, catInfo) {
      let body = [{"title": catInfo.subcatTitle}]
      let itemId
      switch (catInfo.subcat) {
        case catInfo.subcat_4_id != null:
          itemId = catInfo.subcat_4_id
          break
        case catInfo.subcat_3_id != null:
          itemId = catInfo.subcat_3_id
          break
        case catInfo.subcat_2_id != null:
          itemId = catInfo.subcat_2_id
          break
        case catInfo.subcat_1_id != null:
          itemId = catInfo.subcat_1_id
          break
        default:
          itemId = catInfo.catId
      }

      return axios.post(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/lots/categories/${itemId}/sub_categories`, body, {
          headers: {
            Authorization: `JWT ${store.state.API_TOKEN}`
          }
        }).then(response => {
          if (catInfo.subcat) {
            this.dispatch('uploadSingleSubcat', catInfo)
          } else {
            this.dispatch('uploadSingleCat', catInfo)
          }
        })
        .catch(error => {
          if (error.response.status == 400) {
            commit('setErrorText', 'Такая категория уже существует')
          }
        })
    },
    addProduct({commit,state}, catInfo) {
      let body = [{
        "title": catInfo.subcatTitle
      }]
      return axios.post(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/lots/categories/${catInfo.catId}/sub_categories`, body, {
          headers: {
            Authorization: `JWT ${store.state.API_TOKEN}`
          }
        }).then(response => {
          this.dispatch('uploadSingleCat', catInfo.catId)
        })
        .catch(error => {
          if (error.response.status == 400) {
            commit('setErrorText', 'Такая категория уже существует')
          }
        })
    },
    addVolume({commit,state}, newVol) {
      let body = {
        "title": newVol
      }
      return axios.post(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/lots/volume_measurements`, body, {
          headers: {
            Authorization: `JWT ${store.state.API_TOKEN}`
          }
        }).then(response => {
          this.dispatch('uploadVolumes')
        })
        .catch(error => console.log(error))
    },
    editCategory({commit, state}, catInfo) {
      let body = {"title": catInfo.category,"volume_measurement": catInfo.volume}

      return axios.patch(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/lots/categories/${catInfo.id}`, body, {
          headers: {
            Authorization: `JWT ${store.state.API_TOKEN}`
          }
        }).then(response => {
          commit('setErrorText', 'Категория изменена')
          this.dispatch('uploadSingleCat', catInfo.id)
        })
        .catch(error => console.log(error))
    },
    deleteCat({commit,state}, catId) {
      return axios.delete(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/lots/categories/${catId}`, {
          headers: {
            Authorization: `JWT ${store.state.API_TOKEN}`
          }
        }).then(response => {
          router.go(-1)
        })
        .catch(error => {
          if (error.response.status == 532) {
            commit('setErrorText', 'К данной категории привязаны подкатегории, удалите все данные по ним')
          }
        })
    },
    deleteSubcat({commit,state}, subcatInfo) {
      let subcatId = subcatInfo.itemId ? subcatInfo.itemId : subcatInfo.subcatId
      return axios.delete(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/lots/categories/${subcatId}`, {
          headers: {
            Authorization: `JWT ${store.state.API_TOKEN}`
          }
        }).then(response => {
          this.dispatch('uploadSingleSubcat', subcatInfo)
        })
        .catch(error => {
          if (error.response.status == 532) {
            commit('setErrorText', 'К данной подкатегории привязаны подкатегории, удалите все данные по ним')
          }
        })
    },
    deleteVolume({commit,state}, volId) {
      return axios.delete(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/lots/volume_measurements/${volId}`, {
          headers: {
            Authorization: `JWT ${store.state.API_TOKEN}`
          }
        }).then(response => {
          this.dispatch('uploadVolumes')
        })
        .catch(error => console.log(error))
    }

  }
}