<template>
  <div class="subheader">
    <div class="duser__search-block">
      <SubTitle :title="getTitle" :backLink="true" :backLinkUrl="{ name: getBackLink }"/>
      <span class="sort-block" v-show="isGoods">
            <img src="@/assets/images/icons/sort.svg" alt="sort" class="js-tooltip-icon"
                 :data-id="'tooltip-id-' + goodsSorting.sortingTabs[0].sortingName">
        </span>

      <Sorting :style="tooltipElemCoord" :sorting="goodsSorting" @addOrdering="addOrdering"/>
    </div>

    <SubTabs :tabs="tabs"/>
  </div>
</template>

<script>
import SubTitle from '@/components/UI/SubTitle.vue'
import SubTabs from '@/components/Controlls/SubTabs.vue'
import Sorting from '@/components/Controlls/Sorting.vue'
import GoodsSorting from '@/data/GoodsSorting'

export default {
  components: {
    SubTitle,
    SubTabs,
    Sorting
  },
  data() {
    let tabsList = [
      {title: 'Сведения о пользователе', url: 'About'},
      {title: 'Контрагенты', url: 'Counterparties'},
      {title: 'Сотрудники', url: 'Employees'},
      // {title: 'Подписки', url: 'Subscribes' },
      // {title: 'Рейтинг и отзывы', url: 'Ratings' },
      // {title: 'Лоты', url: 'Lots' },


      // {title: 'Платежи', url: 'Payments' },
      // {title: 'Товары из чатов', url: 'Goods' },
      // {title: 'Обороты', url: 'Turnover' },
    ];
    if (this.$store.getters.getFullAccess) {
      // SUPERADMIN
      tabsList.push({title: 'Чаты', url: 'Chats'})
    }
    return {
      tabs: tabsList,
      tooltipElemCoord: {
        top: '5rem',
        left: '',
        right: ''
      },
    }
  },
  computed: {
    isFullAccess() {
      return this.$store.getters.getFullAccess
    },
    goodsSorting() {
      return GoodsSorting
    },
    getTitle() {
      return this.$store.getters.getPageTitle
    },
    isGoods() {
      if (this.$route.name === 'UserGoods') return true
    },
    getBackLink() {
      let backLink;
      switch (this.$route.name) {
        case 'UserEmployeesItem':
          backLink = 'UserEmployeesList'
          break
        case 'UserEmployeesProxy':
          backLink = 'UserEmployeesItem'
          break
        default:
          backLink = 'UsersList'
      }
      return backLink
    }
  },
  methods: {
    addOrdering(data) {
      let queryOption = {}
      queryOption.userId = this.$route.params.id
      queryOption.ordering = data.query

      this.$store.dispatch('uploadLotsDeal', queryOption)
    },
  }
}
</script>