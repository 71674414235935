<template>
    <div class="loader-block">
        <div class="loader-svg-block">
            <lottie :options="defaultOptions"/>
        </div>
    </div>
</template>

<script>
import Lottie from '/node_modules/vue-lottie';
import * as animationData from '@/assets/loader/loader.json';

export default {
 components: {
     Lottie
 },
 data() {
     return {
         defaultOptions: {animationData: animationData},
     }
 }
}
</script>

<style lang="scss" scoped>
    .loader-block {
        display: flex;
        align-items: center;
        justify-content: center;
        .loader-svg-block {
            width: 40%;
            height: 40%;
        }
    }
</style>