<template>
  <div class="table">
    <div class="table__header">
      <TableHeaderCell v-for="(tHeader, idx) in tableHeaders" :key="idx" :tHeader="tHeader" :index="idx"/>
    </div>
    <div class="table__body" v-if="userList.length">
      <TableBodyRow v-for="(user, idx) in userList" :key="idx" :user="user"/>
    </div>
    <EmptyBlock v-else/>
  </div>
</template>


<script>
import EmptyBlock from '@/components/Empty.vue'
import tableHeaders from '@/data/UserDeleteTabs'
import TableHeaderCell from '@/components/DeleteTable/TableHeaderCell.vue'
import TableBodyRow from '@/components/DeleteTable/TableBodyRow.vue'

export default {
  components: {
    EmptyBlock,
    TableHeaderCell,
    TableBodyRow,
  },
  props: {
    userList: {
      type: Array,
      require: true
    }
  },
  computed: {
    tableHeaders() {
      return tableHeaders
    }
  }
}
</script>
