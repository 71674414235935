<template>
  <div class="table">
    <div class="table__header">
      <TableHeaderCell v-for="(tHeader, idx) in tableHeaders" :key="idx" :tHeader="tHeader" :index="idx" />
    </div>
    <div class="table__body" v-if="true">
      <TableBodyRow v-for="(file, idx) in registryList" :key="idx" :item="file"/>
    </div>
      <EmptyBlock v-else/>
  </div>
</template>


<script>
  import EmptyBlock from '@/components/Empty.vue'
  import tableHeaders from '@/data/RegistryTabs'
  import TableHeaderCell from '@/components/RegistryTable/TableHeaderCell.vue'
  import TableBodyRow from '@/components/RegistryTable/TableBodyRow.vue'

  export default {
    components: {
      EmptyBlock,
      TableHeaderCell,
      TableBodyRow,
    },
    props: {
      registryList: {
        type: Array,
        require: true
      }
    },
    computed: {
      tableHeaders() {
        return tableHeaders
      }
    },
  }
</script>

<style lang="scss" scoped>
.table__th {
  justify-content: flex-start;
  padding: 7rem 3.6rem;
  &:nth-of-type(1) {
    width: 26.1rem;
  }
  &:nth-of-type(2) {
    width: 36.7rem;
  }
  &:nth-of-type(3) {
    width: 35.4rem;
  }
  &:last-child {
    width: 58.7rem;
  }
 
}

</style>