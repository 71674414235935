<template>

  <div class="su-view">
    <SubTitle :title="'Новая категория'" :backBtn="true" />
    <div class="f-block__wrap">
      <div class="f-block">
        <div class="f-block__inner">
          <div class="f-block__body">
            <form class="form-category" @submit.prevent="addCategory">
              <div class="form__item-wrap">
                <div class="form__input-title">
                  <p class="title">
                    Новая категория
                  </p>
                </div>
                <div class="form__item" :class="{ 'form__item--error': $v.category.$error}">

                  <input class="form__input" id="category" v-model.lazy="category" :class="{error: $v.category.$error}"
                    type="text" @change="$v.category.$touch()" placeholder="Новая категория">
                  <label for="category" class="form__label"><span>Новая категория</span></label>
                  <p class="form__item-error" v-if="!$v.category.required">
                    Обязательное поле
                  </p>
                </div>
              </div>
              <div class="form__item-wrap">
                <div class="form__input-title">
                  <p class="title">
                    Мера объема
                  </p>
                  <router-link :to="({name: 'NewVolume'})" class="link">
                    Добавить меру объема
                  </router-link>
                </div>
                <div class="form__item" :class="{ 'form__item--error': $v.volume.$error }">
                  <input 
                    readonly
                    class="form__input form__input--arrow" 
                    id="volume" 
                    v-model.lazy="volume" 
                    :class="{error: $v.volume.$error}" 
                    type="text" 
                    placeholder="Новая мера"
                    @change="$v.volume.$touch()" 
                    @focus="showListType = true" @blur="showListType = false">
                  <label for="volume" class="form__label">
                    <span>Новая мера</span>
                    <img src="@/assets/images/icons/arrow-down.svg" alt="down">
                    </label>
                  <p class="form__item-error" v-if="!$v.volume.required">
                    Обязательное поле
                  </p>
                  <transition name="fade">
                    <div class="select-list" v-show="showListType">
                        <ul>
                            <li v-for="volume, idx in getVolumes" :key="idx">
                              <div class="input-controlls">
                                <input class="select__input js-select-input" 
                                  :value="volume.title" 
                                  name="volume" type="radio" 
                                  :id="volume.id"
                                  @input="isChecked">
                                <label :for="volume.id">
                                    {{volume.title}}
                                </label>
                              </div>
                            </li>
                        </ul>
                    </div>
                </transition>
                </div>
              </div>
              <div class="form__controlls" :class="{'form-send': isSend}">
                <Button>
                  Добавить
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
  import { required  } from 'vuelidate/lib/validators'
  import SubTitle from '@/components/UI/SubTitle.vue'
  import Button from '@/components/UI/Button.vue'

  export default {
    components: {
      SubTitle,
      Button,
    },
    data() {
      return {
        category: '',
        volume: '',
        volumeId: '',
        isSend: false,
        showListType: false
      }
    },
    validations: {
      category: {
        required,
      },
      volume: {
        required,
      },
    },
    created() {
      this.$store.dispatch('uploadVolumes')
    },

    computed: {
      getVolumes() {
        return this.$store.getters.getVolumes
      },
    },
    methods: {
      async addCategory() {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          const catInfo = {
            category: this.category,
            volume: this.volumeId,
          }
          this.isSend = true
          await this.$store.dispatch('addCategory', catInfo)
          this.isSend = false
          this.category = ''
          this.volume = ''
          this.$v.$reset()
        }
      },
      isChecked(event) {
        this.volume = event.target.value.trim()
        this.volumeId = event.target.id.trim()
      }
    }

  }
</script>

<style lang="scss">
 
</style>