<template>
  <div class="su-view su-cat">
    <SubTitle :title="'Товары и услуги сервиса'" />

    <div class="cards-list c-cat__list js-masonry">
      <router-link :to="({ name: 'NewCategory'})"  class="card-item add-cat js-masonry-item">
          <div class="add-cat__content js-masonry-content">
              <img src="@/assets/images/icons/plus--white.svg" alt="plus">
              <p>
                Новая категория
              </p>
        </div>
      </router-link>

      <Card v-for="cat, idx in getCats" :key="idx" :cat="cat"/>
    </div>
  </div>
</template>

<script>
  import SubTitle from '@/components/UI/SubTitle.vue'
  import Card from '@/components/Cards/CategoryCard.vue'

  export default {
    name: 'CategoryView',
    components: {
      SubTitle,
      Card,
    },
    created() {
      this.$store.dispatch('uploadCats')
    },
 
    computed: {
      getCats() {
        return this.$store.getters.getCats
      },
    },

  }
</script>

<style lang="scss" scoped>
  .add-cat {
    font-size: 1.8rem;
    font-weight: 500;
    color: #ffffff;
    text-decoration: none;
    background-color: #008069;
    transition: all .2s ease;
     &:active {
      background-color: #449889;
      transform: translateY(3px);
    }
  }
  .add-cat__content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
      width: 2.4rem;
      height: 2.4rem;
      object-fit: cover;
      object-position: center;
      margin-right: 1.6rem;
    }
  }
</style>