<template>
    <div class="users-body" v-if="loading">
        {{isLoading}}
        <Loader />
    </div>
    <div class="su-content su-lots-content" v-else>
        <Tabs :tabs="tabs" :lotsCount="getLotsCount" />
        <router-view :showOnlyAuct="showOnlyAuct"></router-view>
    </div>
</template>

<script>
    import Tabs from '@/components/Controlls/Tabs.vue'
    import Loader from '@/components/Loader.vue'

    export default {
        props: {
            showOnlyAuct: {
                type: Boolean,
                default: false
            }
        },
        components: {
            Tabs,
            Loader,
        },
        data() {
            return {
                tabs: [{
                        title: 'Новые',
                        url: 'UserLotsSellNew',
                        lotStatus: 0
                    },
                    {
                        title: 'Открытые',
                        url: 'UserLotsSellOpened',
                        lotStatus: 1
                    },
                    {
                        title: 'Закрытые',
                        url: 'UserLotsSellClosed',
                        lotStatus: 2
                    },
                    {
                        title: 'Отклонен',
                        url: 'UserLotsSellCanceled',
                        lotStatus: 3
                    },
                ],
                loading: true,
            }
        },
        mounted() {
            this.$store.state.lots.lots = null
            this.$store.state.lots.lotsCount = null
            let userId = this.$route.params.id ? this.$route.params.id : null
            this.$store.dispatch('uploadLotsCount', {userId: userId, type: 'type=0', showOnlyAuct: this.showOnlyAuct ? 'is_auction=true' : ''})
        },
        computed: {
            isLoading() {
                this.getLotsCount
            },
            getLotsCount() {
                let lotsCount = this.$store.getters.getLotsCount
                this.$store.dispatch('pageTitle', 'Лоты')
                if (lotsCount) {
                    this.loading = false
                }
                return lotsCount
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>