<template>
  <div class="su-view su-admin">
    <SubTitle :title="'Администраторы'" :backBtn="true" />
    <Search @search='onSearch' />
    {{isLoading}}
    <div class="users-body" v-if="loading">
      <Loader />
    </div>
    <div class="users-body" v-else>
      <div class="cards-list c-cat__list js-masonry">
        <div class="card-item add-admin js-masonry-item">
          <div class="add-admin__content js-masonry-content">
            <form class="form-add-admin" @submit.prevent="onSubmit">
              <div class="add-admin__title">
                <p>
                  Добавление админа
                </p>
              </div>
              <div class="form__item" :class="{ 'form__item--error': $v.name.$error}">
                <input class="form__input" id="name" v-model.lazy="name" :class="{error: $v.name.$error}"
                  @change="$v.name.$touch()" placeholder="Наименование пользователя">
                <label for="name" class="form__label">
                  <span>Наименование пользователя</span>
                </label>

                <p class="form__item-error" v-if="!$v.name.required">
                  Обязательное поле
                </p>
              </div>
              <div class="form__item" :class="{ 'form__item--error': $v.email.$error}">
                <input class="form__input" id="email" v-model.lazy="email" :class="{error: $v.email.$error}"
                  @change="$v.email.$touch()" placeholder="Логин (email)">
                <label for="email" class="form__label">
                  <span>Логин</span>
                </label>

                <p class="form__item-error" v-if="!$v.email.required">
                  Обязательное поле
                </p>
                <p class="form__item-error" v-if="!$v.email.email">
                  Введите email
                </p>
              </div>
              <div class="form__item" :class="{ 'form__item--error': $v.password.$error }">
                <input class="form__input" id="password" v-model.lazy="password" :class="{error: $v.password.$error}"
                  type="password" @change="$v.password.$touch()" placeholder="Пароль">
                <label for="password" class="form__label">
                  <span>Пароль</span>
                </label>

                <p class="form__item-error" v-if="!$v.password.required">
                  Обязательное поле
                </p>
              </div>
              <div class="form__controlls" :class="{'form-send': isSend}">
                <Button>
                  Создать
                </Button>
              </div>
            </form>
          </div>
        </div>
        <FileUpload />
        <Card v-for="(admin, idx) in getAllAdmin" :key="idx" :admin="admin" @getAdminId="openDelPopup" />
      </div>

      <transition name="fadeIn">
        <div class="popup" v-if="showPopupDelAdmin" @click="closePopup">
          <div class="popup__window" @click.stop="">
            <div class="popup__inner">
              <div class="popup__title">
                Вы уверены что хотите удалить администратора?
              </div>
              <div class="popup__controlls">
                <Button :class="'btn--red'" @click="deleteAdmin">Удалить</Button>
                <Button :class="'btn--grey'" @click="closePopup">Отмена</Button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  import API from "@/services/API";
  import {
    required,
    email
  } from 'vuelidate/lib/validators'
  import Loader from '@/components/Loader.vue'
  import Button from '@/components/UI/Button'
  import Search from '@/components/Controlls/Search.vue'
  import SubTitle from '@/components/UI/SubTitle.vue'
  import FileUpload from '@/components/UI/FileUpload.vue'
  import Card from '@/components/Cards/AdminCard.vue'

  export default {
    name: 'AdminView',
    components: {
      Loader,
      Search,
      SubTitle,
      Button,
      Card,
      FileUpload
    },
    data() {
      return {
        name: '',
        email: '',
        password: '',
        isSend: false,
        loading: true,
        showPopupDelAdmin: false,
        adminForDelId: null
      }
    },
    validations: {
      email: {
        required,
        email
      },
      password: {
        required,
      },
      name: {
        required,
      },
    },
    created() {
      let queryOption = {
        searchTxt: ''
      }
      this.$store.dispatch('fetchAdmins', queryOption)
    },
    mounted() {
      API.resizeAllMasonryItems()
    },
    updated() {
      this.$nextTick(function () {
        API.resizeAllMasonryItems()
      })
    },
    computed: {
      isLoading() {
        this.getAllAdmin
      },
      getAllAdmin() {
        let adminList = this.$store.getters.getAllAdmins
        if (adminList) {
          this.loading = false
        }
        return adminList
      },
    },
    methods: {
      onSearch(searchTxt) {
        //   let _lotsInfo = this.$store.state.lotsInfo
        //   _lotsInfo.search = searchTxt
        //   this.$store.dispatch('uploadLots', _lotsInfo)
      },
      onSubmit() {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          const adminInfo = {
            name: this.name,
            email: this.email,
            password: this.password,
          }

          this.isSend = true
          this.$store.dispatch('addAdmin', adminInfo)
          this.isSend = false
          this.$v.$reset()
        }
      },
      openDelPopup(data) {
        this.showPopupDelAdmin = true
        this.adminForDelId = data
      },
      closePopup(event) {
        this.showPopupDelAdmin = false
      },
      async deleteAdmin() {
        let adminInfo = {
          adminId: this.adminForDelId
        }

        this.isSend = true
        await this.$store.dispatch('deleteAdmin', adminInfo)
        this.isSend = false
        this.showPopupDelAdmin = false
      }

    }
  }
</script>

<style lang="scss">
  .add-admin {
    &__content {
      width: 100%;
    }

    &__title {
      margin-bottom: 2.6rem;
      font-size: 1.8rem;
      font-weight: 500;
    }
  }
</style>