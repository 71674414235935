const delSorting = {
    sortingTabs: [{
            sortingName: 'user',
            sortingAsc: 'deletion_intended_at',
            sortingDesc: '-deletion_intended_at',
            sortingAscTitle: 'Сначала новые',
            sortingDescTitle: 'Сначала старые'
        },
    ],
}

export default delSorting