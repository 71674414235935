<template>
    <div class="su-view su-admin">
        <SubTitle :title="'Редактирование Контроль СБ'" :backBtn="true" />
        {{isLoading}}
        <div class="users-body" v-if="loading">
            <Loader />
        </div>
        <div class="users-body" v-else>
            <div class="f-block">
                <AddCard :securityItem="getSecurity"/>
            </div>
        </div>
    </div>
</template>

<script>
    import SubTitle from '@/components/UI/SubTitle.vue'
    import AddCard from '@/components/Cards/AddSecurity.vue'
    import Loader from '@/components/Loader.vue'

    export default {
        components: {
            SubTitle,
            Loader,
            AddCard,
        },
        data() {
            return {
                loading: true,
            }
        },
        mounted() {
            let securityId = this.$route.params.id
            this.$store.dispatch('uploadSingleSecurity', securityId)
        },
        beforeDestroy() {
            this.$store.state.security.singleSecurity = null
        },
        computed: {
            isLoading() {
                this.getSecurity
            },
            getSecurity() {
                let singleSecurity = this.$store.getters.getSingleSecurity
                if (singleSecurity) {
                    this.loading = false
                }
                return singleSecurity
            },
        },
    }
</script>

<style lang="scss" scoped>
    
</style>