<template>
    <div class="card-item c-docs js-masonry-item">
        <div class="js-masonry-content card-item__content">
            <div class="c-docs__body">
                 <div class="c-docs__edit">
                    <img src="@/assets/images/icons/pencil.svg" alt="edit"
                    class="js-tooltip-icon"
                    :data-id="'tooltip-id-' + doc.id">
                    <div class="editing" :id="'tooltip-id-' + doc.id">
                        <router-link :to="{ name: 'DocsEdit', params: { docId: doc.id, docKind: kind }  }" class="btn">Редактировать</router-link>
                        <Button :btnClass="'btn--grey'" @click="getDelDocId(doc.id)">Удалить</Button>
                    </div>
                </div>
                <div class="c-docs__image">
                    <a :href="doc.file" target="_balnk">
                    <img src="@/assets/images/icons/docs.svg" alt="docs">
                    </a>
                </div>
                <div class="c-docs__info">
                    <div class="c-docs__title">
                      {{doc.title.length > 50 ? doc.title.substring(0, 50) + '...' : doc.title}}
                    </div>
                    <div class="c-docs__type" v-if="doc.documentType.id">
                        Тип: {{doc.documentType.title}}
                    </div>
                </div>
            </div>
            <div class="c-docs__footer" v-if="doc.tags">
                <div class="c-docs__tag-list">
                    <div class="c-docs__tag-item" v-for="tag, idx in doc.tags" :key="idx">
                        <p>
                            {{ tag }}
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import API from "@/services/API";
    import Button from '@/components/UI/Button.vue'
    export default {
        props: {
            doc: {
                type: Object,
                require: true
            },
            kind: {
                type: String,
                require: true

            }
        },
        components: {
            Button
        },
        data() {
            return {
                // showEditing: false,
                tooltipElemCoord: {
                    top: '5rem',
                    left: '',
                    right: ''
                },
            }
        },
        mounted() {
            API.resizeAllMasonryItems()
        },
        updated() {
            this.$nextTick(function () {
                API.resizeAllMasonryItems()
            })
        },
        methods: {
            getDelDocId(docId) {
                this.$emit('getDelDocId', docId)
            }
        }
    }
</script>
