<template>
    <div class="card-item c-rating js-masonry-item">
        <div class="card-item__content js-masonry-content">
            <div class="c-rating__header">
                <div class="c-rating__recipient">
                    <router-link :to="({ name: 'User', params: { id: rating.target.id } })"
                        class="c-rating__recipient-avatar">
                        <img :src="getTargetAvatar" alt="avatar" v-if="getTargetAvatar">
                        <img src="@/assets/images/no_avatar.png" alt="avatar" v-else>
                    </router-link>
                    <div class="c-rating__recipient-info">
                        <p class="c-rating__recipient-label">
                            Получатель отзыва
                        </p>
                        <router-link :to="({ name: 'User', params: { id: rating.target.id } })"
                            class="c-rating__recipient-name">
                            {{ rating.target.profile.name }}
                        </router-link>
                    </div>
                </div>
                <div class="c-rating__header-rightside">
                    <div class="c-rating__status" :class="getStatus.class">
                        <p>
                            {{ getStatus.text }}
                        </p>
                    </div>
                    <router-link
                        :to="({ name: 'ChatSingle', params: { chatId: rating.chat.id, chatNumber: rating.chat.number } })"
                        class="btn">
                        <span>Просмотр сделки</span>
                        <img src="@/assets/images/icons/arrow-right--wh.svg" alt="arrow">
                    </router-link>
                </div>
            </div>
            <div class="c-rating__body">
                <div class="c-rating__showinfo" :class="{'opened': showInfo}" @click="showInfo = !showInfo">
                    <p>
                        Рейтинг отзыва
                    </p>
                    <img src="@/assets/images/icons/arrow-down.svg" alt="arrow">
                </div>
                <div class="c-rating__info" v-show="showInfo">
                    <div class="c-rating__row">
                        <div class="c-rating__stars">
                            <i v-for="star, idx in 5" :key="idx"
                                :class="idx < rating.documentProviding ? 'active' : ''"></i>
                        </div>
                        <div class="c-rating__stars-label">
                            Предоставление «закрывающих» документов
                        </div>
                    </div>
                    <div class="c-rating__row">
                        <div class="c-rating__stars">
                            <i v-for="star, idx in 5" :key="idx"
                                :class="idx < rating.deliveryDeadlinesRespect ? 'active' : ''"></i>
                        </div>
                        <div class="c-rating__stars-label">
                            Соблюдение сроков поставки товара/услуги
                        </div>
                    </div>
                    <div class="c-rating__row">
                        <div class="c-rating__stars">
                            <i v-for="star, idx in 5" :key="idx"
                                :class="idx < rating.productQuality ? 'active' : ''"></i>
                        </div>
                        <div class="c-rating__stars-label">
                            Качество товара/услуги
                        </div>
                    </div>
                    <div class="c-rating__row">
                        <div class="c-rating__stars">
                            <i v-for="star, idx in 5" :key="idx"
                                :class="idx < rating.paymentDeadlinesRespect ? 'active' : ''"></i>
                        </div>
                        <div class="c-rating__stars-label">
                            Соблюдение сроков оплаты
                        </div>
                    </div>
                </div>
                <div class="c-rating__profile">
                    <div class="c-rating__profile-info">
                        <router-link :to="({ name: 'User', params: { id: rating.user.id } })"
                            class="c-rating__profile-avatar">
                            <img :src="getUserAvatar" alt="avatar" v-if="getUserAvatar">
                            <img src="@/assets/images/no_avatar.png" alt="avatar" v-else>
                        </router-link>
                        <router-link :to="({ name: 'User', params: { id: rating.user.id } })"
                            class="c-rating__profile-name">
                            {{ rating.user.profile.name }}
                        </router-link>
                    </div>
                    <div class="c-rating__profile-descr">
                        <p>
                            {{ rating.review }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="c-rating__footer" v-if="rating.status == 0">
                <div class="c-rating__controlls">
                    <Button @click="toChangeRating(rating.id, 1)">Опубликовать</Button>
                    <Button :class="'btn--red'" @click="toChangeRating(rating.id, 2)">Отклонить</Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import API from "@/services/API";
    import Button from '@/components/UI/Button.vue'

    export default {
        components: {
            Button,
        },
        props: {
            rating: {
                type: Object,
                require: true
            }
        },
        data() {
            return {
                showInfo: false
            }
        },
        mounted() {
            API.resizeAllMasonryItems()
        },
        updated() {
            this.$nextTick(function () {
                API.resizeAllMasonryItems()
            })
        },
        computed: {
            getTargetAvatar() {
                let targetAvatar = this.rating.target.profile.avatar 
                    ? this.rating.target.profile.avatar.file 
                    : false
                return targetAvatar
            },
            getUserAvatar() {
                let userAvatar = this.rating.user.profile.avatar
                    ? this.rating.user.profile.avatar.file 
                    : false
                return userAvatar
            },
            getStatus() {
                let status = {
                    0: {
                        text: 'Отзыв на проверке',
                        class: 'c-rating__status--new'
                    },
                    1: {
                        text: 'Отзыв активен',
                        class: 'c-rating__status--opened'
                    },
                    2: {
                        text: 'Отзыв отклонен',
                        class: 'c-rating__status--canceled'
                    },
                }
                return status[this.rating.status];
            },
        },
        methods: {
            toChangeRating(id, status) {
                let changeInfo = {
                    id: id,
                    status: status
                }
                this.$store.dispatch('editRating', changeInfo)
            },
            // toDeleteRating(id) {
            //     this.$store.dispatch('deleteRating', id)
            // }
        },


    }
</script>

<style lang="scss" scoped>
    .c-rating__header-rightside {
        display: flex;
        align-items: center;
        gap: 1.6rem;

        .btn {
            width: 20rem;
            height: 5rem;
        }
    }

    @media screen and (max-width: 800px) {
        .c-rating__header-rightside {
            .btn {
                flex-shrink: 0;
                width: 4rem;
                height: 4rem;
                span {
                    display: none;
                }

            }
        }
    }
</style>