const registrySorting = {
    sortingTabs: [{
            sortingName: 'registry',
            sortingAsc: '-signed_at',
            sortingDesc: 'signed_at',
            sortingAscTitle: 'Сортировка по дате подписания "Сначала новые"',
            sortingDescTitle: 'Сортировка по дате подписания "Сначала старые"'
        },
    ],
}

export default registrySorting