<template>
    <div class="tabs">
        <ul class="tabs__list">
            <li class="tabs__item" v-for="tab, idx in tabs" :key="idx">
                <router-link :to="{ name: tab.url }" class="tabs__link" >
                    <template v-if="lotsCount">
                        <span v-if="tab.lotStatus == 0"> {{ tab.title }} ({{lotsCount.newLotsCount}}) </span>
                        <span v-else-if="tab.lotStatus == 1"> {{ tab.title }} ({{lotsCount.activeLotsCount}}) </span>
                        <span v-else-if="tab.lotStatus == 2"> {{ tab.title }} ({{lotsCount.closedLotsCount}}) </span>
                        <span v-else-if="tab.lotStatus == 3"> {{ tab.title }} ({{lotsCount.declinedLotsCount}}) </span>
                    </template>
                    <span v-else> {{ tab.title }} </span>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    props: {
        tabs: {
            type: Array,
            require: true
        },
        lotsCount: {
            require: false
        }
    },
    computed: {
        
    }
}
</script>

<style lang="scss" scoped>
.tabs {
    margin-bottom: 3.6rem;
    &__list {
        display: flex;
        width: 100%;
        padding: .4rem;
        list-style: none;
        background-color: #ffffff;
        border-radius: 3rem;
    }
    &__item {
        flex: 1 0 auto;
    }
    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 6.4rem;
        padding: 0 3.2rem;
        font-size: 1.8rem;
        font-weight: 500;
        color: #191B1D;
        background-color: #ffffff;
        border-radius: 3rem;
        text-decoration: none;
        transition: all .2s ease;
        &:hover,
        &.router-link-active {
            background-color: #008069;
            color: #ffffff;
        }
    }
}

@media screen and (max-width: 800px) {
    .tabs {
        &__list {
            flex-direction: column;
        }
    }
    
}
</style>