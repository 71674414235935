<template>

  <div class="su-view">
    <SubTitle :title="'Редактирование категории'" :backBtn="true" />
    <div class="f-block__wrap" v-if="getSingleCat">
      <div class="f-block">
        <div class="f-block__inner">
          <div class="f-block__body">
            <form class="form-category" @submit.prevent="editCategory">
              
              <div class="form__item-wrap">
                <div class="form__input-title">
                  <p class="title">
                    Редактируемая категория
                  </p>
                  <div class="f-block__delete" @click="openPopup">
                      Удалить всю категорию
                  </div>
                </div>
                <div class="form__item" :class="{ 'form__item--error': $v.category.$error}">
                  <input class="form__input" id="category" v-model.lazy="category" :class="{error: $v.category.$error}"
                    type="text" @change="$v.category.$touch()" placeholder="Новая категория">
                  <label for="category" class="form__label"><span>Новая категория</span></label>
                  <p class="form__item-error" v-if="!$v.category.required">
                    Обязательное поле
                  </p>
                </div>
              </div>

              <div class="form__item-wrap">
                <div class="form__input-title">
                  <p class="title">
                    Мера объема
                  </p>
                  <router-link :to="({name: 'NewVolume'})" class="link">
                    Добавить меру объема
                  </router-link>
                </div>
                <div class="form__item" :class="{ 'form__item--error': $v.volume.$error }">
                  <input 
                    readonly
                    class="form__input form__input--arrow" 
                    id="volume" 
                    v-model.lazy="volume" 
                    :class="{error: $v.volume.$error}" 
                    type="text" 
                    placeholder="Новая мера"
                    @change="$v.volume.$touch()" 
                    @focus="showListType = true" @blur="showListType = false">
                  <label for="volume" class="form__label">
                    <span>Новая мера</span>
                    <img src="@/assets/images/icons/arrow-down.svg" alt="down">
                    </label>
                  <p class="form__item-error" v-if="!$v.volume.required">
                    Обязательное поле
                  </p>
                  <transition name="fade">
                    <div class="select-list" v-show="showListType">
                        <ul>
                            <li v-for="volume, idx in getVolumes" :key="idx">
                              <div class="input-controlls">
                                <input class="select__input js-select-input" 
                                  :value="volume.title" 
                                  name="volume" type="radio" 
                                  :id="volume.id"
                                  @input="isChecked">
                                <label :for="volume.id">
                                    {{volume.title}}
                                </label>
                              </div>
                            </li>
                        </ul>
                    </div>
                </transition>
                </div>
              </div>

              <div class="form__controlls" :class="{'form-send': isSend}">
                <Button>
                  Сохранить
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>    
     <transition name="fadeIn">
      <div class="popup" v-if="showPopup" @click="closePopup">
        <div class="popup__window" @click.stop="">
          <!-- <span class="popup__close" @click="closePopup">
            <img src="@/assets/images/icons/close.svg" alt="close">
          </span> -->
          <div class="popup__inner">
            <div class="popup__title">
              Вы уверены что хотите удалить "{{category}}"?
            </div>
            <div class="popup__controlls">
              <Button :class="'btn--red'" @click="deleteCat">Удалить</Button>
              <Button :class="'btn--grey'" @click="closePopup">Отмена</Button>
            </div>
          </div>
          
        </div>
      </div>
    </transition>
  </div>


</template>

<script>
  import { required  } from 'vuelidate/lib/validators'
  import SubTitle from '@/components/UI/SubTitle.vue'
  import Button from '@/components/UI/Button.vue'

  export default {
    components: {
      SubTitle,
      Button,
    },
    data() {
      return {
        category: '',
        volume: '',
        volumeId: '',
        isSend: false,
        showListType: false,
        showPopup: false,
      }
    },
    validations: {
      category: {
        required,
      },
      volume: {
        required,
      },
    },
    created() {
      let catId = this.$route.params.catId
      this.$store.dispatch('uploadSingleCat', {catId})
      this.$store.dispatch('uploadVolumes')
    },

    computed: {
      getSingleCat() {
        let singleCat = this.$store.getters.getSingleCat
        if (singleCat) {
          this.category = singleCat.title
          this.volume = singleCat.volumeMeasurement.title
          this.volumeId = singleCat.volumeMeasurement.id
          return singleCat
        }
      },
      getVolumes() {
        return this.$store.getters.getVolumes
      },
    },
    methods: {
      async editCategory() {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          const catInfo = {
            id: this.$route.params.catId,
            category: this.category,
            volume: this.volumeId,
          }

          this.isSend = true
          await this.$store.dispatch('editCategory', catInfo)
          this.isSend = false
        }
      },
      isChecked(event) {
        this.volume = event.target.value.trim()
        this.volumeId = event.target.id.trim()
      },
      openPopup(event) {
        this.showPopup = true
      },
      closePopup(event) {
        this.showPopup = false
      },
      deleteCat() {
        let catId = this.$route.params.catId
        this.$store.dispatch('deleteCat', catId)
      },
    }

  }
</script>

<style lang="scss" scoped>
 
</style>