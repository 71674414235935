<template>
   <div class="su-view su-goods">
    {{isLoading}}
    <div class="users-body" v-if="loading">
      <Loader />
    </div>
    <div class="users-body" v-else>
      <div class="su-goods-info" v-if="getDeals.length">
        <div class="cards-list c-company__list js-masonry"> 
          <Card v-for="deal, idx in getDeals" :key="idx" :deal="deal" />
        </div>
      </div>
      <div class="su-empty su-about-empty" v-else>
        <EmptyBlock />
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import EmptyBlock from '@/components/Empty.vue'
import SubTitle from '@/components/UI/SubTitle.vue'
import Card from '@/components/Cards/GoodCard.vue'

export default {
  name: 'UserGoodsView',
  components: {
    Loader,
    EmptyBlock,
    SubTitle,
    Card,
  },
   data() {
      return {
        loading: true,
      }
   },
  created() {
    let userId = this.$route.params.id
    this.$store.dispatch('uploadLotsDeal', {userId})
  },
  computed: {
    isLoading() {
        this.getDeals
    },
    getDeals() {
      let dealsList = this.$store.getters.getDeals
      this.$store.dispatch('pageTitle', 'Товары из лотов')
        if (dealsList) {
          this.loading = false
        }
      return dealsList
    }
  }
}
</script>

<style lang="scss" scoped>
.cards-list {
  grid-template-columns: repeat(2, 1fr)
}
</style>