import store from "./index";
import axios from 'axios'

export default {
    state: {
        activeChats: null,
        closedChats: null,
        chatSingle: null,
        chatSingleMsgs: null,
        queryObject: null,
        chatDownloadFile: null,
    },
    getters: {
        getActiveChats(state) {
            return state.activeChats
        },
        getClosedChats(state) {
            return state.closedChats
        },
        getChatsCount(state) {
            return state.chatsCount
        },
        getChatSingle(state) {
            return state.chatSingle
        },
        getChatSingleMsgs(state) {
            return state.chatSingleMsgs
        },
        getChatDownloadFile(state) {
            return state.chatDownloadFile
        },

    },
    mutations: {
        setActiveChats(state, activeChats) {
            state.activeChats = activeChats
        },
        setClosedChats(state, closedChats) {
            state.closedChats = closedChats
        },
        setChatsCount(state, chatsCount) {
            state.chatsCount = chatsCount
        },
        setChatSingle(state, chatSingle) {
            state.chatSingle = chatSingle
        },
        setChatSingleMsgs(state, chatSingleMsgs) {
            state.chatSingleMsgs = chatSingleMsgs
        },
        setChatDownloadFile(state, chatDownloadFile) {
            state.chatDownloadFile = chatDownloadFile
        },
    },
    actions: {
        uploadChats({ commit, state }, chatsInfo) {
            state.queryObject = chatsInfo
            let options = `&status=${chatsInfo.chatStatus}`
            if (chatsInfo.userId) options += `&user=${chatsInfo.userId}`
            if (chatsInfo.ordering) options += `&ordering=${chatsInfo.ordering}`

            if (chatsInfo.offset) options += `&${chatsInfo.offset}`

            return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/chats?search=${chatsInfo.searchTxt}${options}`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {

                if (chatsInfo.chatStatus == 0) {
                    let _allChats = state.activeChats
                    if (chatsInfo.offset && _allChats) {
                        response.data.forEach(elem => {
                            _allChats.push(elem)
                        })
                        commit('setActiveChats', _allChats)
                    } else {
                        commit('setActiveChats', response.data)
                    }
                } else {
                    let _allChats = state.closedChats
                    if (chatsInfo.offset && _allChats) {
                        response.data.forEach(elem => {
                            _allChats.push(elem)
                        })
                        commit('setClosedChats', _allChats)
                    } else {
                        commit('setClosedChats', response.data)
                    }
                }
            })
            .catch(error => {
                if (error.response && error.response.status == 401) {
                    this.dispatch('userLogout')
                }
                else if (error.response.status == 403) {
                    commit('setErrorText', 'Нет прав для просмотра данного раздела')
                    setTimeout(() => {
                        window.location.href = '/'
                    }, 1500)
                }else {
                    commit('setErrorText', 'Ошибка соединения с сервером')
                }
            })
        },
        uploadChatsCount({ commit, state }, userId) {
            let url

            if (userId) {
                url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/chats/${userId}/count`
            } else {
                url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/chats/count`
            }

            return axios.get( url , {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
                }).then(response => {
                    commit('setChatsCount', response.data)
                })
                .catch(error => {
                    if (error.response.status == 403) {
                        commit('setErrorText', 'Нет прав для просмотра данного раздела')
                        setTimeout(() => {
                            window.location.href = '/'
                        }, 1500)
                    }else{
                        console.error(error)
                    }     
                  }
                )
        },
        uploadChatSingle({ commit, state }, chatId) {
            return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/chats/${chatId}`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                commit('setChatSingle', response.data)
            })
            .catch(error => {
                if (error.response.status == 403) {
                    commit('setErrorText', 'Нет прав для просмотра данного раздела')
                    setTimeout(() => {
                        window.location.href = '/'
                    }, 1500)
                }else{
                    console.error(error)
                }     
              }
            )
        },
        uploadChatSingleMsgs({ commit, state }, chatId) {
            return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/chats/${chatId}/messages`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
                params: { // TODO добавить пагинацию для сообщений в чате
                    limit: 500
                }
            }).then(response => {
                commit('setChatSingleMsgs', response.data)
            })
            .catch(
                error => {
                    if (error.response.status == 403) {
                        commit('setErrorText', 'Нет прав для просмотра данного раздела')
                        setTimeout(() => {
                            window.location.href = '/'
                        }, 1500)
                    }else{
                        console.error(error)
                    }     
                  }
            )
        },
        readedByAdmin({ commit, state }, chatId) {
            return axios.post(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/chats/${chatId}/read`, {}, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`,
                },
                }).then(response => {
                //    console.log(response);
                })
                .catch(error => {
                    if (error.response.status == 403) {
                        commit('setErrorText', 'Нет прав для просмотра данного раздела')
                        setTimeout(() => {
                            window.location.href = '/'
                        }, 1500)
                    }else{
                        console.error(error)
                    }     
                  }
                )
        }
    }

}