<template>
    <div class="form-container">
      <LoginForm />
    </div>
</template>

<script>
import LoginForm from '@/components/Forms/LoginForm.vue'

export default {
  name: 'LoginView',
  components: {
    LoginForm,
  }
}
</script>

<style lang="scss" scoped>
.form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>