<template>
    <div class="row" v-if="isUpdate">
        <div class="row__string green">
            <p class="row__update">
                Обновлено
            </p>
            <slot name="label"></slot>
            <slot name="current-attent"></slot>
            <slot name="current-text"></slot>
        </div>
    </div>
    <div class="row" v-else-if="isCanceled">
        <div class="row__string red">
            <slot name="label"></slot>
            <slot name="current-attent"></slot>
            <slot name="current-text"></slot>
        </div>
    </div>
    <div class="row" v-else>
        <div class="row__string">
            <slot name="label"></slot>
            <slot name="current-attent"></slot>
            <slot name="current-text"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            isUpdate: {
                Type: Boolean,
                require: false
            },
            isCanceled: {
                Type: Boolean,
                require: false
            },
        }
    }
</script>

<style lang="scss">

</style>