const docSorting = {
    sortingTabs: [{
            sortingName: 'doc',
            sortingAsc: 'title',
            sortingDesc: '-title',
            sortingAscTitle: 'От А до Я',
            sortingDescTitle: 'от Я до А'
        },
    ],
}

export default docSorting