<template>
  <header id="header" class="header" :class="'header--' + userType">
    <button class="nav-open-btn" @click="openNav" v-if="this.$route.name != 'Login'">
      <img src="@/assets/images/icons/open.svg" alt="" class="nav-hidden">
      <img src="@/assets/images/icons/close.svg" alt="" class="nav-showed">
    </button>
    <div v-if="userType != 'admin'" class="header__logo">
      <img src="@/assets/images/icons/logo.svg" alt="">
    </div>
    <div v-if="userType == 'admin'" class="header__controller">
      <button class="btn btn--grey btn--add" :class="{ 'have-new-notice': isNotReadNotification}" @click="showNotice">
        <img src="@/assets/images/icons/bell.svg" alt="bell">
      </button>
      <template v-if="isFullAccess">
        <router-link :to="({ name: 'AddAdmin'})" class="btn btn--grey btn--add">
          <img src="@/assets/images/icons/plus.svg" alt="add">
        </router-link>
      </template>

      <span class="header__username">
        {{ isFullAccess ? 'Администратор' : 'Модератор' }}
      </span>
      <Button :class="'btn--grey header__btn'" @click="logout">Выйти</Button>
    </div>
  </header>
</template>

<script>
import Button from '@/components/UI/Button'

export default {
  props: {
    userType: {
      type: String,
      default: 'admin'
    }
  },
  components: {
    Button
  },
  data() {
    return {
      opened: false
    }
  },
  computed: {
    isNotReadNotification() {
      return this.$store.getters.getNotReadNotification
    },
    isFullAccess() {
      return this.$store.getters.getFullAccess
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('userLogout')
    },
    openNav() {
      let navbarBlock = document.querySelector('.wrapper.wrapper--admin')
      if (navbarBlock.classList.contains('opened')) {
        navbarBlock.classList.remove('opened')
      } else {
        navbarBlock.classList.add('opened')
      }
    },
    showNotice() {
      this.$store.dispatch('fetchNotifications', {offset: 0})
      this.$emit('showNotice', true)
    }
  }
}
</script>

<style lang="scss">
.nav-open-btn {
  display: none;
  width: 4.5rem;
  height: 4.5rem;
  background: transparent;
  border: 0;

  img {
    max-width: 100%;
  }
}

.have-new-notice {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
    display: inline-block;
    width: .7rem;
    height: .7rem;
    background-color: #FF5757;
    border: .1rem solid #fff;
    border-radius: 3rem;
  }
}

@media screen and (max-width: 1000px) {
  .header {
    min-width: 100vw;
  }

  .nav-open-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>