<template>
  <div class="table">
    <div class="table__header">
      <TableHeaderCell v-for="(tHeader, idx) in tableHeaders" :key="idx" :tHeader="tHeader" :index="idx" />
    </div>
    <div class="table__body" v-if="userList.length">
      <TableBodyRow v-for="(cpRelation, idx) in userList" :key="idx" :cpRelation="cpRelation" />
    </div>
    <div class="table__body" v-else>
      <EmptyBlock />
    </div>
  </div>
</template>


<script>
  import EmptyBlock from '@/components/Empty.vue'
  import tableHeaders from '@/data/CounterpartiesTabs'
  import TableHeaderCell from '@/components/CounterpartiesTable/TableHeaderCell.vue'
  import TableBodyRow from '@/components/CounterpartiesTable/TableBodyRow.vue'

  export default {
    components: {
      EmptyBlock,
      TableHeaderCell,
      TableBodyRow,
    },
    props: {
      userList: {
        type: Array,
        require: true
      }
    },
    computed: {
      tableHeaders() {
        return tableHeaders
      }
    },
  }
</script>

<style lang="scss" scoped>
  .empty-block {
    padding: 5rem 0;
  }
</style>