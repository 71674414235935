<template>
  <div class="custom__filters js-custom-filters">
    <div class="custom__input-group">
      <input
          type="number"
          class="custom__input"
          v-model="uniqueNumber"
          placeholder="Уникальный номер"
      />
      <Button class=" mb-1rem" @click="applyUniqueNumber">Показать</Button>
      <Button class="btn--grey" @click="clearUniqueNumber">Очистить</Button>
    </div>
  </div>
</template>


<script>
import Button from '@/components/UI/Button.vue'
import API from '@/services/API'

export default {
  components: {
    Button,
  },
  data() {
    return {
      uniqueNumber: null,
    }
  },
  methods: {
    clearUniqueNumber() {
      this.uniqueNumber = null;
      const windowBody = document.body;
      let queryOption = this.$store.state.allUsers.queryObject;
      queryOption.uniqueNumber = undefined;
      queryOption.offset = null;

      API.toCloseSorting(this, windowBody);
      this.$store.dispatch('fetchAllUsers', queryOption);

    },
    applyUniqueNumber() {
      const windowBody = document.body;
      let queryOption = this.$store.state.allUsers.queryObject;
      queryOption.uniqueNumber = '&unique_number=' + this.uniqueNumber;
      queryOption.offset = null;

      API.toCloseSorting(this, windowBody);
      this.$store.dispatch('fetchAllUsers', queryOption);
    }
  }
}
</script>

<style lang="scss">
.custom__input-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 32rem;
}

.custom__input {
  flex: 1;
  padding: 2rem;
  width: 100%;
  font-size: 1.5rem;
  border-radius: 10rem;
  margin: 1rem auto;
}

.custom__filters {
  position: relative;
}
</style>