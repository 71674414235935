<template>
    <div class="card-item c-security js-masonry-item" :class="{'c-security--disabled': security.isBlocked}">
        <div class="card-item__content js-masonry-content">
            <div class="add-admin__header">
                <!-- <div class="add-admin__title">
                    <p>
                        {{security.name}}
                    </p>
                </div> -->
                <div class="add-admin__status" v-if="security.isBlocked">
                    Заблокированный
                </div>
                <div class="add-admin__controls" v-else>
                    <button class="add-admin__btn js-tooltip-icon" :data-id="'tooltip-id-' + security.id">
                        <i></i><i></i><i></i>
                    </button>
                    <div class="editing" :id="'tooltip-id-' + security.id">
                        <router-link :to="{ name: 'SecurityEdit', params: { id: security.id } }" class="btn">Редактировать</router-link>
                        <Button :class="'btn--grey'" @click="toggleActivity({id: security.id, action: 'block'})"> Заблокировать </Button>
                    </div>
                </div>
            </div>
            <div class="form__row">
                <div class="form__item">
                    <input disabled class="form__input form__input--focused" :id="'securityLogin-' + security.id"
                        :value="security.securityUniqueNumber">
                    <label :for="'dateStart-' + security.id" class="form__label"><span>№</span></label>
                </div>
            </div>
            <div class="form__row">
                <div class="form__item">
                    <input class="form__input form__input--focused" :id="'securityLogin-' + security.id"
                        @input="returnEmail"
                        v-model="email"
                    >
                    <label :for="'dateStart-' + security.id" class="form__label"><span>Логин</span></label>
                </div>
            </div>
            <div class="form__row">
                <div class="form__item">
                    <input class="form__input form__input--focused" :id="'securityUser-' + security.id"
                        @input="returnName"
                        v-model="name"
                    >
                    <label :for="'securityUser-' + security.id" class="form__label"><span>Пользователь</span></label>
                </div>
            </div>
            <div class="form__row">
                <div class="form__item">
                    <input 
                        @input="returnInn"
                        v-model="inn"
                        class="form__input form__input--focused" :id="'securityUser-' + security.id"
                    >
                    <label :for="'securityUser-' + security.id" class="form__label"><span>ИНН</span></label>
                </div>
            </div>
            <div class="form__controlls" v-if="security.isBlocked">
                <Button @click="toggleActivity({id: security.id, action: 'unblock'})">
                    Разблокировать
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
    import API from "@/services/API";
    import Button from '@/components/UI/Button.vue'

    export default {
        components: {
            Button,
        },
        props: {
            security: {
                type: Object,
                require: true
            },
        },
        data() {
            return {
                inn: this.security?.supervisor.inn ?? '',
                name: this.security?.supervisor.name ?? '',
                email: this.security?.email ?? '',
                tooltipOpen: false,
                tooltipElemCoord: {
                    top: '5rem',
                    left: '',
                    right: ''
                },
            }
        },
        mounted() {
            API.resizeAllMasonryItems()
        },
        updated() {
            this.$nextTick(function () {
                API.resizeAllMasonryItems()
            })
        },
        computed: {
            returnInn(){ // заказчику нужен input без disabled 
                this.inn = this.security?.supervisor.inn ?? ''
            },
            returnName(){ // заказчику нужен input без disabled 
                this.name = this.security?.supervisor.name ?? ''
            },
            returnEmail(){ // заказчику нужен input без disabled 
                this.email = this.security?.email ?? ''
            },
        },
        methods:{
            toggleActivity(data) {
                this.$emit('toggleActivity', data)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .add-admin__header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .add-admin__title {
        margin: 0 auto 0 0;
    }

    .add-admin__controls {
        position: relative;
    }

    .add-admin__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .4rem;
        padding: 1rem;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        i {
            width: .3rem;
            height: .3rem;
            border-radius: 100%;
            background-color: #191B1D;
        }
    }

    .add-admin__tooltip {
        position: absolute;
        width: 95rem;
        max-width: 38rem;
        padding: 2.6rem;
        background-color: #ffffff;
        border-radius: 3rem;
        box-shadow: 0px -0.2rem 2rem rgba(113, 112, 128, 0.1);
        z-index: 5;
    }

    .add-admin__status {
        padding: 1rem;
        font-size: 1.4rem;
        background-color: #F7F7FA;
        border-radius: 3rem;
        margin-right: 1rem;
    }

    .form__input:disabled {
        color: #191B1D;
    }

    .c-security--disabled {
        color: #8A8C96;

        .form__label span,
        .form__input:disabled {
            color: #8A8C96;
        }

        .add-admin__status {
            color: #191B1D;
        }
    }
</style>