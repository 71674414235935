<template>
    <div class="users-body" v-if="loading">
      {{isLoading}}
      <Loader />
    </div>
    <div class="users-body su-payments-info" v-else>
          <div class="cards-list c-company__list js-masonry" v-if="getPayments.length">
            <Card v-for="payment, idx in getPayments" :key="idx" :payment="payment" />
          </div>
          <div class="su-empty su-about-empty" v-else>
          <EmptyBlock />
        </div>
    </div>
</template>

<script>
  import Loader from '@/components/Loader.vue'
  import EmptyBlock from '@/components/Empty.vue'
  import SubTitle from '@/components/UI/SubTitle.vue'
  import Card from '@/components/Cards/PaymentCard.vue'

  export default {
    name: 'PaymentsView',
    components: {
      Loader,
      EmptyBlock,
      SubTitle,
      Card,
    },
    data() {
      return {
        loading: true,
      }
    },
    created() {
      this.$store.state.payments.payments = null
      let paymentsInfo = {
        userId: this.$route.params.id ? this.$route.params.id : null,
        status: '',
        search: '',
        route: this.$route.name
      }
      this.$store.dispatch('pageTitle', 'Платежи')
      this.$store.dispatch('uploadPayments', paymentsInfo)
    },
    computed: {
      isLoading() {
        this.getPayments
      },
      getPayments() {
        let paymentsList = this.$store.getters.getPayments
        if (paymentsList != null) {
          this.loading = false
        }
        return paymentsList
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>