<template>
  <div class="su-lots-info" v-if="getLots && getLots.length">
    <div class="cards-list c-company__list js-masonry">
      <Card v-for="lot, idx in getLots" :key="idx" :lot="lot" />
    </div>
  </div>
  <div class="su-empty su-about-empty" v-else>
    <EmptyBlock />
  </div>
</template>

<script>
  import EmptyBlock from '@/components/Empty.vue'
  import Card from '@/components/Cards/LotCard.vue'

  export default {
     props: {
      showOnlyAuct: {
        type: Boolean,
      }
    },
    components: {
      EmptyBlock,
      Card,
    },
    created() {
      let _pathArray = this.$route.fullPath.split('/')
      let lotsInfo = {
        userId: this.$route.params.id ? this.$route.params.id : null,
        type: _pathArray.includes('buy') ? 'type=1' : 'type=0',
        status: 'status=1&update_status=0&update_status=2',
        search: '',
        showOnlyAuct: this.showOnlyAuct ? 'is_auction=true' : ''
      }
      this.$store.dispatch('uploadLots', lotsInfo)
    },
    computed: {
      getLots() {
        return this.$store.getters.getLots
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>