<template>
  <div class="user-single">
    <template>
      <SubHeader/>
      <router-view></router-view>
    </template>
  </div>
</template>

<script>
import SubHeader from '@/components/SubHeader.vue'
import Loader from '@/components/Loader.vue'
export default {

  name: 'UserSingleView',
  components: {
      SubHeader,
      Loader,
  },
   data() {
      return {
      }
   },

  computed: {
  }
}
</script>

<style lang="scss" scoped>
.user-single {
  display: flex;
  flex-direction: column;
  height: 100%;
}
  
</style>