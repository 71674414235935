<template>
  <div class="su-view su-registry">
    <Search @search='onSearch'/>
    {{ isLoading }}
    <div class="users-body" v-if="loading">
      <Loader/>
    </div>
    <div class="users-body" v-else>
      <ProxyTable :proxyList="getProxies" v-if="getProxies"/>
      <div class="su-empty su-about-empty" v-else>
        <EmptyBlock/>
      </div>
    </div>
  </div>
</template>

<script>
import EmptyBlock from '@/components/Empty.vue'
import Loader from '@/components/Loader.vue'

import SubTitle from '@/components/UI/SubTitle.vue'
import Search from '@/components/Controlls/Search.vue'
import Button from '@/components/UI/Button.vue'
import ProxyTable from '@/components/EmployeeProxyTable/Table.vue'

export default {
  name: 'EmployeeProxy',
  components: {
    EmptyBlock,
    Loader,
    SubTitle,
    Search,
    Button,
    ProxyTable,
  },
  data() {
    return {
      loading: true,
      fullName: null,
      queryOption: {searchTxt: '', ordering: '', filters: '', 'employeeId': this.$route.params.employeeId}
    }
  },
  mounted() {
    this.queryOption = {searchTxt: '', ordering: '', filters: '', 'employeeId': this.$route.params.employeeId}
    this.$store.dispatch('fetchEmployeeProxies', this.queryOption)
  },
  computed: {
    isLoading() {
      this.getProxies
    },
    getProxies() {
      let proxies = this.$store.getters.getEmployeeProxies
      if (proxies) {
        this.loading = false
        if (proxies.length) {
          const proxy = proxies[0]
          const employeeData = `${proxies[0].owner.supervisor.name} - ${this.getFio(proxy.owner)} - ${proxy.owner.position}`
          this.$store.dispatch('pageTitle', `${employeeData} <br><span>Доверенности сотрудника</span>`)
        }
      }
      return proxies
    },
  },
  methods: {
    onSearch(searchTxt) {
      this.queryOption.searchTxt = searchTxt
      this.queryOption.offset = 0
      this.$store.dispatch('fetchEmployeeProxies', this.queryOption)
    },
    getFio(user) {
      let _parts = [];
      _parts.push(user.lastName)
      _parts.push(user.firstName)
      _parts.push(user.middleName)

      return _parts.join(' ')
    },
  }
}
</script>

<style lang="scss" scoped>
.users-body {
  padding-right: 2.4rem;
  padding-bottom: 2.4rem;
}
</style>