import store from "./index";
import axios from 'axios'

export default {
  state: {
    userLightInfo: null,
    userCounterparties: null,
    employeeCounterparties: null,
    queryObject: {searchTxt: '', ordering: {}, filter: ''},
  },
  getters: {
    getUserLightInfo(state) {
      return state.userLightInfo
    },
    getUserCounterparties(state) {
      return state.userCounterparties
    },
    getEmployeeCounterparties(state) {
      return state.employeeCounterparties
    }
  },
  mutations: {
    setUserLightInfo(state, userLightInfo) {
      state.userLightInfo = userLightInfo
    },
    setUserCounterparties(state, counterparties) {
      state.userCounterparties = counterparties
    },
    setEmployeeCounterparties(state, counterparties) {
      state.employeeCounterparties = counterparties
    }
  },
  actions: {
    uploadUserLightInfo({ commit, state }, userLightId) {
      localStorage.setItem('userLightId', userLightId)
      return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/${userLightId}`, {
          headers: {
            Authorization: `JWT ${store.state.API_TOKEN}`
          }
        }).then(response => {
          commit('setUserLightInfo', response.data)
        })
        .catch(error => console.log(error))
    },
    uploadCounterparties({ commit, state }, queryOption) {
      state.queryObject = queryOption
      let url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/${queryOption.userId}/counterparties?search=${queryOption.searchTxt}`
      let orderingArray = Object.values(queryOption.ordering)
      let orderingString = orderingArray.join('&ordering=')

      if (Object.keys(queryOption.ordering).length != 0) url += `&ordering=${orderingString}`
      if (queryOption.filter) url += `&${queryOption.filter}`
      if (queryOption.offset) url += queryOption.offset

      return axios.get(url, {
          headers: {
            Authorization: `JWT ${store.state.API_TOKEN}`
          }
        }).then(response => {
          let _allCounterparties = state.userCounterparties
          if (queryOption.offset && _allCounterparties) {
              response.data.forEach(elem => {
                  _allCounterparties.push(elem)
              })
              commit('setUserCounterparties', _allCounterparties)
          } else {
              commit('setUserCounterparties', response.data)
          }
        })
        .catch(error => console.log(error))
    },
    uploadEmployeesCounterparties({commit,state}, queryOption) {
      state.queryObject = queryOption

      let url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/employees/${queryOption.employeeId}/counterparties?search=${queryOption.searchTxt}`
      let orderingArray = Object.values(queryOption.ordering)
      let orderingString = orderingArray.join('&ordering=')

      if (Object.keys(queryOption.ordering).length != 0) url += `&ordering=${orderingString}`
      if (queryOption.filter) url += `&${queryOption.filter}`
      if (queryOption.offset) url += queryOption.offset

      return axios.get(url, {
          headers: {
            Authorization: `JWT ${store.state.API_TOKEN}`
          }
        }).then(response => {
          let _allCounterparties = state.employeeCounterparties
          if (queryOption.offset && _allCounterparties) {
              response.data.forEach(elem => {
                  _allCounterparties.push(elem)
              })
              commit('setEmployeeCounterparties', _allCounterparties)
          } else {
              commit('setEmployeeCounterparties', response.data)
          }
        })
        .catch(error => console.log(error))
    },
  }
}