<template>
  <div class="su-view su-registry">
    <SubTitle :title="'Доверенности'"/>
    <Search @search='onSearch'/>
    {{ isLoading }}
    <div class="users-body" v-if="loading">
      <Loader/>
    </div>
    <div class="users-body" v-else>
      <ProxyTable :proxyList="getProxies" v-if="getProxies.length"/>
      <div class="su-empty su-about-empty" v-else>
        <EmptyBlock/>
      </div>
    </div>
  </div>
</template>

<script>
import EmptyBlock from '@/components/Empty.vue'
import Loader from '@/components/Loader.vue'

import SubTitle from '@/components/UI/SubTitle.vue'
import Search from '@/components/Controlls/Search.vue'
import Button from '@/components/UI/Button.vue'
import ProxyTable from '@/components/ProxyTable/Table.vue'

export default {
  name: 'Proxy',
  components: {
    EmptyBlock,
    Loader,
    SubTitle,
    Search,
    Button,
    ProxyTable,
  },
  data() {
    return {
      loading: true,
      queryOption: {searchTxt: '', ordering: '-created_at', filters: ''}
    }
  },
  mounted() {
    this.queryOption = {searchTxt: '', ordering: '-created_at', filters: ''}
    this.$store.dispatch('getMediaProxies', this.queryOption)
  },
  computed: {
    proxySorting() {
      return ProxySorting
    },
    isLoading() {
      this.getProxies
    },
    getProxies() {
      let proxies = this.$store.getters.getProxies
      if (proxies) {
        this.loading = false
      }
      return proxies
    },
  },
  methods: {
    onSearch(searchTxt) {
      this.queryOption.searchTxt = searchTxt
      this.queryOption.offset = 0
      this.$store.dispatch('getMediaProxies', this.queryOption)
    },
  }
}
</script>

<style lang="scss" scoped>
.users-body {
  padding-right: 2.4rem;
  padding-bottom: 2.4rem;
}
</style>