<template>
  <div class="table__th">
    {{tHeader.title}}
  </div>
</template>

<script>
export default {
  props: {
    tHeader: {
      type: Object,
      require: true
    },
  },
}
</script>