import { render, staticRenderFns } from "./AddSecurity.vue?vue&type=template&id=f8aa2f46&scoped=true"
import script from "./AddSecurity.vue?vue&type=script&lang=js"
export * from "./AddSecurity.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8aa2f46",
  null
  
)

export default component.exports