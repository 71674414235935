<template>
   <div class="sorting" :id="'tooltip-id-' + sorting.sortingTabs[0].sortingName">
      <div v-for="sort, idx in sorting.sortingTabs" :key="`${sort.sortingName}-${idx}`" class="sorting__block">
         <Input :inputClass="'sorting__input js-sorting-input'" :name="sort.sortingName" :type="'radio'" :label="true"
            :id="`${sort.sortingName}-${idx}-asc`">
         <label :data-group="sort.sortingName" :for="`${sort.sortingName}-${idx}-asc`"
            @click="onSortingBy(sort.sortingAsc, $event)">
            {{ sort.sortingAscTitle }}
         </label>
         </Input>
         <Input :inputClass="'sorting__input js-sorting-input'" :name="sort.sortingName" :type="'radio'" :label="true"
            :id="`${sort.sortingName}-${idx}-desc`">
         <label :data-group="sort.sortingName" :for="`${sort.sortingName}-${idx}-desc`"
            @click="onSortingBy(sort.sortingDesc, $event)">
            {{ sort.sortingDescTitle}}
         </label>
         </Input>
      </div>
   </div>
</template>


<script>
   import Input from '@/components/UI/Input.vue'
   export default {
      props: {
         sorting: {
            type: Object,
            require: false
         }
      },
      components: {
         Input,
      },
      methods: {
         onSortingBy(query, event) {
            let label = event.target
            let queryGroup = label.dataset.group

            this.$emit('addOrdering', {
               queryGroup: queryGroup,
               query: query
            })
         }
      }
   }
</script>

<style lang="scss">
   .sorting {
      display: none;
      position: absolute;
      width: max-content;
      max-width: 95vw;
      padding: 3.2rem;
      background-color: #ffffff;
      border-radius: 3rem;
      box-shadow: 0px -2px 20px rgba(113, 112, 128, 0.1);
      z-index: 5;

      &.open-tooltip {
         display: block;
      }

      &__block {
         &:not(:first-child) {
            margin-top: 1.6rem;
         }

         display: flex;
         flex-direction: column;
         gap: 1.6rem;
      }

   }
</style>