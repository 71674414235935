import axios from "axios";
import store from "@/store";
import cookie from 'js-cookie'

// API METHODS
const API = {};

API.hasLocalToken = function () {
  return localStorage.getItem('jwt')
};


if (API.hasLocalToken() && !store.state.isAuth) {
  store.dispatch("userIsLogin");
}

function resizeMasonryItem(item) {
  var grid = document.getElementsByClassName('js-masonry')[0];
  if (grid) {
    var rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap')),
      rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows')),
      paddingCard = parseInt(window.getComputedStyle(item).getPropertyValue('padding-top'))


    var rowSpan = Math.ceil((item.querySelector('.js-masonry-content').getBoundingClientRect().height + rowGap + paddingCard * 2) / (rowHeight + rowGap));

    item.style.gridRowEnd = 'span ' + rowSpan;
  }
}

API.resizeAllMasonryItems = function () {
  var allItems = document.querySelectorAll('.js-masonry-item');
  if (allItems) {
    for (var i = 0; i < allItems.length; i++) {
      resizeMasonryItem(allItems[i]);
    }
  }
}

API.toShowSorting = function (_this, windowBody, eventElemId, coord, eventElemCoord) {
  let sortingElem = document.querySelector(`#${eventElemId}`)
  sortingElem.classList.add('open-tooltip')
  windowBody.classList.add('tooltip-is-open')
  _this.showSortingId = eventElemId

  if (window.innerWidth / 2 < eventElemCoord.left) {
    sortingElem.style.right = '0'
    sortingElem.style.left = 'auto'
  } else {
    sortingElem.style.left = '0'
    sortingElem.style.right = 'auto'
  }
}

API.toCloseSorting = function (_this, windowBody) {
  let sortingElem = document.querySelector(`.open-tooltip`)
  if (sortingElem) sortingElem.classList.remove('open-tooltip')
  windowBody.classList.remove('tooltip-is-open')
  _this.showSortingId = ''
  return
}

API.numWords = function (value, words) {
  value = Math.abs(value) % 100;
  var num = value % 10;
  if (value > 10 && value < 50) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num == 1) return words[0];
  return words[2];
}

API.calcActiveDate = function (endDate) {
  let nowDate = new Date().getTime();
  let diff = endDate - nowDate

  let years = Math.floor(diff / 1000 / 60 / 60 / 24 / 365)
  let month = Math.floor(diff / 1000 / 60 / 60 / 24 / 30)
  let days = Math.floor(diff / 1000 / 60 / 60 / 24)
  let hours = Math.floor(diff / 1000 / 60 / 60)
  let minutes = Math.floor(diff / 1000 / 60 )

  if (month > 12) {
    return years + ' года'
  } else if (days > 30) {
    return month + API.numWords(days, [' месяц', ' месяц', ' месяцев'])
  } else if (hours > 24 ) {
    return days + API.numWords(days, [' день', ' дня', ' дней'])
  } else if (minutes > 60) {
    return hours + API.numWords(hours, [' час', ' часа', ' часов'])
  } else {
    return minutes + API.numWords(minutes, [' минут', ' минуты', ' минут'])
  }
}

API.breakNumString = function (num) {
  return num.toString().split(/(?=(?:\d{3})+(?!\d))/).join(' ')
}

API.uploadByScroll = async function (_this, scrollBlock, routeName) {

  if (routeName === 'UsersList' || routeName === 'UsersForDelete') {
    let users = scrollBlock.querySelectorAll('.table__tr')
    let usersLength = users.length

    if (usersLength < 10) return

    const isLoading =_this.$store.state.allUsers.loading;
    const hasMore =_this.$store.state.allUsers.totalCount > usersLength;

    if (!isLoading && hasMore && scrollBlock.scrollTop + scrollBlock.clientHeight > (scrollBlock.scrollHeight - 50) ) {
        let queryOption = _this.$store.state.allUsers.queryObject
        queryOption.offset = `&offset=${usersLength}`
        _this.upload = true

        if (routeName === 'UsersList') await _this.$store.dispatch('fetchAllUsers', queryOption)
        if (routeName === 'UsersForDelete') await _this.$store.dispatch('fetchUsersToDelete', queryOption)
    }
  }

  if (routeName == 'chats' || routeName == 'rating' || routeName == 'lots' || routeName == 'payments' ||
      routeName == 'docs-signature' || routeName == 'docs-information' || routeName == 'docs-instruction' ||
      routeName == 'employees') {

    let cards = scrollBlock.querySelectorAll('.card-item')
    let cardsLength = cards.length

    if (cardsLength < 50) return
    if (_this.upload == false && scrollBlock.scrollHeight - window.innerHeight * 1.25 < scrollBlock.scrollTop) {
      let queryOption
      if (routeName == 'chats') queryOption = _this.$store.state.chats.queryObject
      if (routeName == 'rating') queryOption = _this.$store.state.rating.queryObject
      if (routeName == 'lots') queryOption = _this.$store.state.lots.lotsInfo
      if (routeName == 'payments') queryOption = _this.$store.state.payments.paymentsInfo
      if (routeName == 'docs-signature' || routeName == 'docs-information' || routeName == 'docs-instruction') queryOption = _this.$store.state.docs.queryOption
      if (routeName == 'employees') queryOption = _this.$store.state.employee.queryObject

      queryOption.offset = `&offset=${cardsLength}`
      _this.upload = true

      if (routeName == 'chats') await _this.$store.dispatch('uploadChats', queryOption)
      if (routeName == 'rating') await _this.$store.dispatch('uploadRatings', queryOption)
      if (routeName == 'lots') await _this.$store.dispatch('uploadLots', queryOption)
      if (routeName == 'payments') await _this.$store.dispatch('uploadPayments', queryOption)
      if (routeName == 'docs-signature' ) await _this.$store.dispatch('uploadDocsSignature', queryOption)
      if (routeName == 'docs-information' || routeName == 'docs-instruction') await _this.$store.dispatch('uploadDocsInfo', queryOption)
      if (routeName == 'employees' ) await _this.$store.dispatch('fetchUserEmployees', queryOption)

      setTimeout(() => {
        _this.upload = false
      }, 1200)
    }
  }

  if (routeName == 'registry' || routeName == 'counterparties' || routeName == 'proxy') {
    let tableRow = scrollBlock.querySelectorAll('.table__tr')
    let tableRowLength = tableRow.length

    if (tableRowLength < 50) return

    if (_this.upload == false && scrollBlock.scrollHeight - window.innerHeight * 1.25 < scrollBlock.scrollTop) {
      let queryOption

      if (routeName == 'registry') queryOption = _this.$store.state.registry.queryObject
      if (routeName == 'proxy') queryOption = _this.$store.state.employee.queryObject
      if (routeName == 'counterparties') queryOption = _this.$store.state.counterparties.queryObject

      queryOption.offset = `&offset=${tableRowLength}`
      _this.upload = true

      if (routeName == 'registry') {
        if (_this.$router.currentRoute.name == 'RegistryProxy') {
            await _this.$store.dispatch('getMediaProxies', queryOption)
        } else {
          await _this.$store.dispatch('getRegistryFiles', queryOption)
        }
      }

      if (routeName == 'counterparties') {
        if (_this.$router.currentRoute.name == 'UserEmployeesCounterparties') {
          console.log(queryOption);
          await _this.$store.dispatch('uploadEmployeesCounterparties', queryOption)
        } else {
          await _this.$store.dispatch('uploadCounterparties', queryOption)
        }
      }

      if (routeName == 'proxy') await _this.$store.dispatch('fetchEmployeeProxies', queryOption)

      setTimeout(() => {
        _this.upload = false
      }, 1200)
    }
  }

  if (routeName == 'notice') {
    let cards = scrollBlock.querySelectorAll('.notice-card')
    let cardsLength = cards.length

    if (cardsLength < 50) return

    if (_this.upload == false && scrollBlock.scrollHeight - window.innerHeight * 1.25 < scrollBlock.scrollTop) {
      _this.upload = true
      await _this.$store.dispatch('fetchNotifications', {offset: cardsLength})

      setTimeout(() => {
        _this.upload = false
      }, 1200)
    }
  }
}

API.incommingMsg = function () {
  try {
    const context = new AudioContext();
    window
      .fetch('./sound.wav')
      .then(response => response.arrayBuffer())
      .then(arrayBuffer => context.decodeAudioData(arrayBuffer))
      .then(audioBuffer => {
        const source = context.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(context.destination);
        source.start();
      });
  } catch (error) {
    console.log(error);
  }
}

export default API;