const EmployeeProxyTabs = [
  {
    title: '№ документа',
    isSorting: false,
    isFilter: false,
  },
  {
    title: 'Статус',
    isSorting: false,
    isFilter: true,
    filters: {
      filtersName: 'status',
      filtersTabs: [
        { 
          filterTabTitle: 'Все документы',
          filterTabRequest: ''
        },
        { 
          filterTabTitle: 'Отозванные документы',
          filterTabRequest: 'is_revoked=true'
        },
        { 
          filterTabTitle: 'Активыне документы',
          filterTabRequest: 'is_revoked=false'
        },
      ]
    }
  },
  {
    title: 'Имя файла',
    isSorting: false,
    isFilter: false,
  },
  {
    title: 'Дата подписания',
    isSorting: true,
    isFilter: false,
    sorting: {
      sortingTabs: [
        {
          sortingName: 'signed',
          sortingAsc: '-signed_at',
          sortingDesc: 'signed_at',
          sortingAscTitle: 'Сортировка по дате подписания "Сначала новые"',
          sortingDescTitle: 'Сортировка по дате подписания "Сначала старые"'
      },
      ]
    }
  },
  {
    title: 'Дата отмены документа',
    isSorting: true,
    isFilter: false,
    sorting: {
      sortingTabs: [
        {
          sortingName: 'revoked',
          sortingAsc: '-revoked_at',
          sortingDesc: 'revoked_at',
          sortingAscTitle: 'Сортировка по дате отмены "Сначала новые"',
          sortingDescTitle: 'Сортировка по дате отмены "Сначала старые"'
      },
      ]
    }
  },
  {
    title: 'Кем подписано',
    isSorting: false,
    isFilter: false,
  }, 

  
]

export default EmployeeProxyTabs