<template>
  <div class="users-body" v-if="loading">
    {{isLoading}}
    <Loader />
  </div>
  <div class="su-content su-subscribes-content" v-else>
    <div class="cards-list c-company__list js-masonry" v-if="getUserSubscribes.length">
      <Card v-for="company, idx in getUserSubscribes" :key="idx" :company="company" />
    </div>
    <div class="su-empty su-about-empty" v-else>
      <EmptyBlock />
    </div>
  </div>
</template>

<script>
  import Card from '@/components/Cards/CompanyCard.vue'
  import EmptyBlock from '@/components/Empty.vue'
  import Loader from '@/components/Loader.vue'

  export default {
    components: {
      Card,
      EmptyBlock,
      Loader,
    },
    data() {
      return {
        loading: true,
      }
    },
    created() {
      let subscrInfo = {
        userId: this.$route.params.id,
        type: 'company',
        search: '',
      }
      this.$store.dispatch('uploadUserSubscribes', subscrInfo)
    },
    computed: {
      isLoading() {
        this.getUserSubscribes
      },
      getUserSubscribes() {
        let subscribes = this.$store.getters.getUserSubscribeCompany
        this.$store.dispatch('pageTitle', 'Подписки')
        if (subscribes) {
          this.loading = false
        }
        return subscribes
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>