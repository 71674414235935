<template>
  <div class="home">
    <Table />
  </div>
</template>

<script>


export default {
  name: 'HomeView',
}
</script>
