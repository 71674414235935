import store from "./index";
import axios from 'axios'

export default {
    state: {
        user: null,
        documents: null,
        media: null,
        // userSubscribeProduct: null,
        // userSubscribeCompany: null,
        // subscrInfo: null,
        // userDeals: null,
        // userRatings: null,
        // lotsTurnover: null,
        // agentTurnover: null,
        notPublicFile: null,
        // subscriptionsDisabled: false,
        // subscriptions: [],
    },
    getters: {
        getUser(state) {
            return state.user
        },
        getDocuments(state) {
            return state.documents
        },
        getMedia(state) {
            return state.media
        },
        // getUserSubscribeProduct(state) {
        //   return state.userSubscribeProduct
        // },
        // getUserSubscribeCompany(state) {
        //   return state.userSubscribeCompany
        // },
        // getDeals(state) {
        //   return state.userDeals
        // },
        // getUserRatings(state) {
        //   return state.userRatings
        // },
        // getLotsTurnover(state) {
        //   return state.lotsTurnover
        // },
        // getAgentTurnover(state) {
        //   return state.agentTurnover
        // },
        getNotPublicFile(state) {
            return state.notPublicFile
        },
        // getSubscriptionsDisabled(state) {
        //     return state.subscriptionsDisabled
        // },
        getSubscriptions(state) {
            return state.subscriptions
        },
    },
    mutations: {
        setUser(state, user) {
            state.user = user
        },
        setDocuments(state, documents) {
            state.documents = documents
        },
        setMedia(state, media) {
            state.media = media
        },
        setUpdateStatus(state, updateStatus) {
            state.user.updateStatus = updateStatus
        },
        // setUserSubscribesProduct(state, product) {
        //   state.userSubscribeProduct = product
        // },
        // setUserSubscribesCompany(state, company) {
        //   state.userSubscribeCompany = company
        // },
        setUserSubscriptionsDisabled(state, subscriptionsDisabled) {
            state.subscriptionsDisabled = subscriptionsDisabled
        },
        // setDeals(state, userDeals) {
        //   state.userDeals = userDeals
        // },
        // setUserRatings(state, userRatings) {
        //   state.userRatings = userRatings
        // },
        // setLotsTurnover(state, lotsTurnover) {
        //   state.lotsTurnover = lotsTurnover
        // },
        // setAgentTurnover(state, agentTurnover) {
        //   state.agentTurnover = agentTurnover
        // },
        setNotPublicFile(state, notPublicFile) {
            state.notPublicFile = notPublicFile
        },
    },
    actions: {
        fetchUser({commit, state}, userId) {
            localStorage.setItem('userId', userId)
            return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/${userId}`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                commit('setUser', response.data)
                commit('setUserSubscriptionsDisabled', response.data.subscriptionsDisabled)
            }).catch(error => console.log(error))
        },
        fetchUserDocuments({commit, state}, userId) {
            return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/${userId}/documents?is_for_job=true`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                commit('setDocuments', response.data)
            }).catch(error => console.log(error))
        },
        fetchUserMedia({commit, state}, userId) {
            return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/${userId}/documents?is_for_job=false&is_document=false`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                commit('setMedia', response.data)
            }).catch(error => console.log(error))
        },

        moderateUser({commit, state}, user) {
            let body;
            let url;
            body = {
                status: user.status,
                moderationMessage: user.moderationMessage
            }
            url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/${user.id}`
            return axios.put(url, body, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
            }).then(response => {
                this.dispatch('fetchUser', user.id)
                this.dispatch('fetchUserDocuments', user.id)
            }).catch(error => {
                console.log(error)
            })
        },
        moderateUserUpdates({commit, state}, user) {
            let body;
            let url;
            body = {
                updateStatus: user.updateStatus,
                moderationMessage: user.moderationMessage
            }
            url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/${user.id}/updates`

            return axios.put(url, body, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
            }).then(response => {
                commit('setUpdateStatus', response.data.updateStatus)
                this.dispatch('fetchUser', user.id)
                this.dispatch('fetchUserDocuments', user.id)
            }).catch(error => {
                console.log(error)
            })
        },

        blockUser({commit, state}, userId) {
            let formData = new FormData();
            formData.append('isActive', false)

            return axios.post(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/${userId}/block`, formData, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
            }).then(response => {
                this.dispatch('fetchUser', userId)
                this.dispatch('fetchUserDocuments', userId)
            }).catch(error => {
                console.log(error)
            })
        },
        unblockUser({commit, state}, userId) {
            let formData = new FormData();
            formData.append('isActive', true)

            return axios.post(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/${userId}/unblock`, formData, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                },
            }).then(response => {
                this.dispatch('fetchUser', userId)
                this.dispatch('fetchUserDocuments', userId)
            }).catch(error => {
                console.log(error)
            })
        },

        userSubscriptionsReverse({commit, state}, userId) {
            return axios.post(
                `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/${userId}/subscriptions`,
                {
                    subscriptionsDisabled: !state.subscriptionsDisabled
                },
                {
                    headers: {
                        Authorization: `JWT ${store.state.API_TOKEN}`
                    }
                }).then(() => {
                commit('setUserSubscriptionsDisabled', !state.subscriptionsDisabled)
            }).catch(error => console.error(error))
        },

        // TODO DEPRECATED
        uploadUserSubscribes({commit, state}, subscrInfo) {
            state.subscrInfo = subscrInfo
            return axios.get(
                `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/${subscrInfo.userId}/subscriptions/on_${subscrInfo.type}`,
                {
                    headers: {
                        Authorization: `JWT ${store.state.API_TOKEN}`
                    }
                }).then(response => {
                subscrInfo.type == 'product' ?
                    commit('setUserSubscribesProduct', response.data) :
                    commit('setUserSubscribesCompany', response.data)
            })
                .catch(error => console.log(error))
        },
        uploadLotsDeal({commit, state}, lotsDealInfo) {
            let url

            if (lotsDealInfo.ordering) {
                url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/lots/${lotsDealInfo.userId}/deal_products?ordering=${lotsDealInfo.ordering}`
            } else {
                url = `${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/lots/${lotsDealInfo.userId}/deal_products`
            }

            return axios.get(url, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                commit('setDeals', response.data)
            })
                .catch(error => console.log(error))
        },
        uploadUserRatings({commit, state}, userId) {
            return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/ratings?target=${userId}`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                commit('setUserRatings', response.data)
            })
                .catch(error => console.log(error));
        },
        uploadUserTurnover({commit, state}, userId) {
            return axios.get(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/financial_turnover?user=${userId}`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                let allTurnover = response.data
                let _lotsTurnover = allTurnover.filter(elem => elem.type == 0)
                let _agentTurnover = allTurnover.filter(elem => elem.type == 1)
                commit('setLotsTurnover', _lotsTurnover)
                commit('setAgentTurnover', _agentTurnover)
            })
                .catch(error => {
                    if (error.response.status == 403) {
                        commit('setErrorText', 'Нет прав для просмотра данного раздела')
                        setTimeout(() => {
                            window.location.href = '/'
                        }, 1500)
                    } else {
                        console.error(error)
                    }
                });
        },
        sendTurnover({commit, state}, turnoverInfo) {
            return axios.post(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/financial_turnover`, turnoverInfo, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                this.dispatch('uploadUserTurnover', turnoverInfo.user)
            })
                .catch(error => {
                        if (error.response.status == 403) {
                            commit('setErrorText', 'Нет прав для просмотра данного раздела')
                            setTimeout(() => {
                                window.location.href = '/'
                            }, 1500)
                        } else {
                            console.error(error)
                        }
                    }
                );
        },
        changeUserDoc({commit, state}, userDocInfo) {
            let formData = new FormData();
            formData.append('file', userDocInfo.file)
            formData.append('documentType', userDocInfo.docType)
            return axios.post(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/${userDocInfo.userId}/documents`, formData, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                this.dispatch('fetchUser', userDocInfo.userId)
            })
                .catch(error => {
                    if (error.response.status === 400) {
                        commit('setErrorText', 'Загрузите pdf-документ')
                    }
                })
        },
        deleteUserDoc({commit, state}, userDocInfo) {
            return axios.delete(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/users/documents/${userDocInfo.docId}`, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                this.dispatch('fetchUser', userDocInfo.userId)
            })
                .catch(error => {
                        if (error.response.status === 403) {
                            commit('setErrorText', 'Нет прав для просмотра данного раздела')
                            setTimeout(() => {
                                window.location.href = '/'
                            }, 1500)
                        } else {
                            console.error(error)
                        }
                    }
                );
        },
        updatePaymentDate({commit, state}, {paymentId, newDate}) {
            let body = {
                payedUntil: newDate
            }
            return axios.put(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/payments/service_payments/${paymentId}`, body, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
            })
                .catch(error => {
                    if (error.response.status == 403) {
                        commit('setErrorText', 'Нет прав для просмотра данного раздела')
                        setTimeout(() => {
                            window.location.href = '/'
                        }, 1500)
                    } else {
                        console.error(error)
                    }
                });
        },
        editMonetaAccount({commit, state}, monetaruInfo) {
            return axios.post(`${store.state.API_HOST}/api/${store.state.API_VERSION}/admin/payments/monetu_ru_account`, monetaruInfo, {
                headers: {
                    Authorization: `JWT ${store.state.API_TOKEN}`
                }
            }).then(response => {
                commit('setErrorText', 'Данные изменены')
            })
                .catch(error => {
                    if (error.response.status == 403) {
                        commit('setErrorText', 'Нет прав для просмотра данного раздела')
                        setTimeout(() => {
                            window.location.href = '/'
                        }, 1500)
                    } else {
                        console.error(error)
                    }
                });
        },
    }
}