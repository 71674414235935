const chatSorting = {
    sortingTabs: [{
            sortingName: 'chat',
            sortingAsc: 'created_at',
            sortingDesc: '-created_at',
            sortingAscTitle: 'По дате в прямом порядке',
            sortingDescTitle: 'По дате в обратном порядке'
        },
    ],
}

export default chatSorting