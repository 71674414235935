<template>
  <div class="su-view su-goods">
    <SubTitle :title="'Товары из лотов'" />
    {{isLoading}}
    <div class="users-body" v-if="loading">
      <Loader />
    </div>
    <div class="users-body" v-else>
      <div class="su-goods-info">
        <div class="su-goods__filter">
          <div class="su-goods__filter-title">
            Выбор пользователя
          </div>
          <div class="su-goods__filter-body">
            <div class="su-goods__select-block">
              <div class="su-goods__select">
                <div class="su-goods__select-input"
                  :class="{'opened': showListType, 'empty': userFilterName == 'Не выбрано'}" tabindex="0" 
                  @click="showListType = true" @blur="showListType = false" v-html="userFilterName">
                </div>
              </div>

              <transition name="fade">
                <div class="su-goods__select-list" v-show="showListType">

                  <ul @scroll="uploadInfoList">
                    <li class="su-goods__select-item js-filter-item" @click="toFilter($event, user.id)"
                      v-for="user, idx in getUsers" :key="idx">
                      <div class="su-goods__select-avatar">
                        <img :src="user.profile.avatar.file" alt="avatar" class="avatar" v-if="user.profile.avatar">
                        <img src="@/assets/images/no_avatar.png" alt="avatar" class="avatar" v-else>
                      </div>
                      <div class="su-goods__select-name">
                        {{ user.profile && user.profile.requisites ? user.name : 'нет имени' }}
                      </div>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
            <span class="sort-block">
              <img src="@/assets/images/icons/sort.svg" alt="sort" class="js-tooltip-icon"
                :data-id="'tooltip-id-' + goodsSorting.sortingTabs[0].sortingName">
            </span>

            <Sorting :style="tooltipElemCoord" :sorting="goodsSorting" @addOrdering="addOrdering"/>
          </div>
        </div>
        <div class="cards-list c-company__list js-masonry" v-if="goods.length">
          <Card v-for="deal, idx in goods" :key="idx" :deal="deal" />
        </div>
        <div class="su-empty su-about-empty" v-else>
          <EmptyBlock />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import Loader from '@/components/Loader.vue'
  import EmptyBlock from '@/components/Empty.vue'
  import GoodsSorting from '@/data/GoodsSorting'
  import SubTitle from '@/components/UI/SubTitle.vue'
  import Card from '@/components/Cards/GoodCard.vue'
  import Input from '@/components/UI/Input.vue'
  import Sorting from '@/components/Controlls/Sorting.vue'

  export default {
    name: 'GoodsView',
    components: {
      Loader,
      EmptyBlock,
      SubTitle,
      Card,
      Input,
      Sorting
    },
    data() {
      return {
        isSend: false,
        loading: true,
        showListType: false,
        userFilterName: '<p>Не выбрано</p>',
        showSorting: false,
        showSortingId: -1,
        queryOption: {userId: null, ordering: null},
        tooltipElemCoord: {
          top: '5rem',
          left: '',
          right: ''
        },
        goods: [],
        upload: false
      }
    },
    created() {
      this.$store.dispatch('getAllUsersLight')
    },
    computed: {
      goodsSorting() {
        return GoodsSorting
      },
      isLoading() {
        this.getUsers
      },
      getUsers() {
        let userList = this.$store.getters.getAllUsersLight
        if (userList) {
          this.loading = false
        }
        return userList
      },
    },
    methods: {
      async addOrdering(data) {
        if (this.queryOption.userId == null) return
        this.queryOption.ordering = data.query
        this.isSend = true
        await this.$store.dispatch('uploadLotsDeal', this.queryOption)
        this.isSend = false
        this.goods = this.$store.getters.getDeals
      },
      async toFilter(event, userId) {
        let elem = event.target.classList.contains('js-filter-item') ? event.target : event.target.closest('.js-filter-item')
        let elemHTML = elem.innerHTML
        this.userFilterName = elemHTML
        this.queryOption.userId = userId

        this.isSend = true
        await this.$store.dispatch('uploadLotsDeal', this.queryOption)
        this.isSend = false
        this.goods = this.$store.getters.getDeals
      },

       async uploadInfoList(elem) {
        let list = elem.target
        let item = list.querySelectorAll('.js-filter-item')
        let itemLength = item.length

        if(this.upload == false && list.scrollHeight - 500 < list.scrollTop) {
          let offset = itemLength
          this.upload = true
          await this.$store.dispatch('getAllUsersLight', {offset})
          this.upload = false
        }
      },

      searchUser() {
        console.log(this.userFilterName);
      }
      
    },
  }
</script>

<style lang="scss" scoped>
  .cards-list {
    grid-template-columns: repeat(2, 1fr)
  }

  .su-empty {
    padding-top: 11rem;
  }


</style>