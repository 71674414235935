<template>
  <div class="card-item c-employee js-masonry-item">
    <div class="card-item__content js-masonry-content">
      <div class="c-employee__info">
        <div class="c-employee__info-row">
          <div class="su-about__status" :class="getStatus.class">
            {{ getStatus.text }}
          </div>
          <div class="c-employee__info-added">
            Дата добавления в сервис:
            {{ Math.floor(new Date(+employee.createdAt).getTime() / 1000.0) | moment("DD.MM.YYYY HH:mm") }}
          </div>
        </div>
        <div class="c-employee__info-row">
          <div class="c-employee__info-position">
            {{ employee.position }}
          </div>
          <div class="c-employee__info-network" :class="{'text-green': employee.online }">
            <template v-if="employee.online">
              Online
            </template>
            <template v-else>
              Был в сети:
              <template v-if="employee.wasOnlineAt">
                {{ Math.floor(new Date(+employee.wasOnlineAt).getTime() / 1000.0) | moment("DD.MM.YYYY HH:mm") }}
              </template>
            </template>
          </div>
        </div>
        <div class="c-employee__info-name">
          {{ getFio(employee) }}
        </div>
        <div class="c-employee__info-email">
          {{ employee.email }}
        </div>
      </div>
      <div class="c-employee__permission">
                 <span class="c-employee__permission-span"
                       :class="{'active': employee.employmentPermissions?.canAddWatchers}">
                    Есть право добавления наблюдателей
                </span>
        <span class="c-employee__permission-span" :class="{'active': employee.employmentPermissions?.canSignDocs}">
                    Есть право подписи
                </span>
        <span class="c-employee__permission-span" :class="{'active': employee.employmentPermissions?.employeesAccess}">
                    Есть доступ к карточкам сотрудников
                </span>
        <span class="c-employee__permission-span" :class="{'active': employee.employmentPermissions?.documentsAccess}">
                   Есть доступ к документам должной осмотрительности
                </span>
      </div>
      <div class="c-employee__counterparties">
        <router-link :to="({ name: 'UserEmployeesCounterparties', params: { employeeId: employee.id } })">
          Назначенные контрагенты
          <span> {{ getCounterparties }} </span>
        </router-link>
      </div>
      <div class="c-employee__controlls">
        <router-link :to="({ name: 'UserEmployeesItem', params: { employeeId: employee.id } })" class="btn">
          Подробнее
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/services/API";
import Button from '@/components/UI/Button.vue'

export default {
  components: {
    Button,
  },
  props: {
    employee: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      // showInfo: false
    }
  },
  mounted() {
    API.resizeAllMasonryItems()
  },
  updated() {
    this.$nextTick(function () {
      API.resizeAllMasonryItems()
    })
  },
  computed: {
    getStatus() {
      let isActive = this.employee.isActive
      let status = this.employee.status
      let updateStatus = this.employee.updateStatus

      let statusList = {
        0: {
          text: 'На проверке',
          class: 'su-about__status--new'
        },
        1: {
          text: 'Подтвержденный',
          class: 'su-about__status--active'
        },
        2: {
          text: 'Отклоненный',
          class: 'su-about__status--canceled'
        },
        3: {
          text: 'Заблокированный',
          class: 'su-about__status--closed'
        },
        4: {
          text: 'Удален',
          class: 'su-about__status--canceled'
        },
      }

      if (!isActive) {
        return statusList[3]
      }

      if (status == 1) {
        if (updateStatus == 0 || updateStatus == 2) {
          return statusList[1]
        }
        if (updateStatus == 1) {
          return statusList[0]

        }
        if (updateStatus == 3) {
          return statusList[2]
        }
      }

      return statusList[status];
    },

    getCounterparties() {
      let counterpartiesText = API.numWords(this.employee.counterpartiesCount, [' контрагент', ' контрагента', ' контрагентов'])
      return `${this.employee.counterpartiesCount} ${counterpartiesText}`
    }
  },
  methods: {
    getFio(user) {
      let _parts = [];
      _parts.push(user.lastName)
      _parts.push(user.firstName)
      _parts.push(user.middleName)

      return _parts.join(' ')
    },
  },
}
</script>

<style lang="scss" scoped>

</style>