<template>
    <div class="subtabs">
        <ul class="subtabs__list">
            <li class="subtabs__item" v-for="tab, idx in tabs" :key="idx">
                <router-link :to="{ name: `User${tab.url}`}" class="subtabs__link">
                        {{ tab.title }}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    props: {
        tabs: {
            type: Array,
            require: true
        }
    }
}
</script>

<style lang="scss" scoped>
.subtabs {
    margin-bottom: 1.6rem;
    &__list {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1.6rem;
        padding-bottom: 1.6rem;
        list-style: none;
        overflow: auto;
        &::-webkit-scrollbar {
        height: 1rem;
        }
        &::-webkit-scrollbar-track {
            background: #F7F7FA;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #008069;
            border-radius: 1rem;
        } 
    }
    &__link {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: max-content;
        height: 5.4rem;
        padding: 0 3.2rem;
        font-size: 1.8rem;
        font-weight: 500;
        color: #191B1D;
        background-color: #ffffff;
        border-radius: 3rem;
        text-decoration: none;
        transition: all .2s ease;
        &:hover,
        &.router-link-active {
            background-color: #008069;
            color: #ffffff;
        }
    }
    
}

@media screen and (max-width: 1000px) {
    .subtabs {
        &__list {
            justify-content: flex-start;
            overflow: auto;
        }
        &__link {
            min-width: max-content;
        }
        
    }
}

@media screen and (max-width: 800px) {
    .subtabs {
       margin-top: 7.2rem;
        
    }
}
</style>